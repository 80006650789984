import React from 'react';
import { TableRow } from '@mui/material';
import { TableCell } from '../../../../../components/TableCell';

export function CaseReportRevisionTableRow(props: { revision: number; description: string }) {
  return (
    <TableRow hover>
      <TableCell>{props.revision}</TableCell>
      <TableCell>{props.description}</TableCell>
    </TableRow>
  );
}
