import { gql, useLazyQuery } from '@apollo/client';

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material';
import {
  AssetType,
  caseUtils,
  CloudDesignStatus,
  ExportBlob,
  ICloudDesignQueue,
  ILevels,
  IMeasure,
  IMeasurementPointValues,
  IPlanImplant,
  LevelType,
  measurements,
  Nullable,
  PartType,
  VertebralBody,
} from '@workflow-nx/common';
import { file } from '@workflow-nx/utils';
import { nTopCuts } from '@workflow-nx/ntop';
import { Mesh, Scene } from 'babylonjs';
import FileSaver from 'file-saver';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getMeshBuffer, rotateCamera } from '../../../../../components/SceneComponent/renderer';
import { useInterval } from '../../../../../hooks/useInterval';
import { FeatureFlag, isTlifCOrientationAvailable } from '../../../../../utils/featureFlags';
import { ImplantEditorDialogContext } from './ImplantEditorDialog.context';
import {
  IMPLANT_EDITOR_CAMERA_DISTANCE,
  PLAN_EDITOR_CAMERA_DISTANCE,
} from './ImplantEditorDialog.helpers';
import { getAssetText } from './ImplantEditorDialog.view';
import { ImplantListLevelOptionsMenu } from './ImplantListLevelOptionsMenu';
import { ImplantListLevelView } from './ImplantListLevelView';
import { CameraViewMenu, ImplantListRootOptionsMenu } from './ImplantListRootOptionsMenu';
import { CloudDesignButton } from './components/CloudDesignButton';
import useAuth from 'apps/workflow-client/src/app/hooks/useAuth';

export type ImplantListLevelProps = {
  level: LevelType;
  partType: PartType;
  planImplant: IPlanImplant | undefined;
};

export type ImplantListLevelOptionsType = 'MOVE' | 'EDIT' | 'DOWNLOAD' | 'REMOVE';

export const partTypesWithBulletsList = [
  PartType.ACDF,
  PartType.ACDF_X_LEFT_UP,
  PartType.ALIF,
  PartType.ALIF_X_TWO_DOWN,
  PartType.ALIF_X_TWO_UP,
  PartType.LLIF_LEFT,
  PartType.LLIF_RIGHT,
];

export type ImplantListRootCameraView = 'AXIAL' | 'CORONAL' | 'SAGITTAL_LEFT' | 'SAGITTAL_RIGHT';

export function ImplantList(props: {
  caseId: number;
  disabled?: boolean;
  planId: number;
  levels: ILevels;
  loading?: boolean;
  scene: Scene | undefined;
  onAdd: (level: LevelType) => void;
  onEdit: (level: LevelType, partType: PartType) => void;
  onMove: (level: LevelType, partType: PartType) => void;
  onRemove: (level: LevelType, partType: PartType) => void;
  onUpdate: (level: LevelType) => Promise<void>;
  onClear: () => Promise<boolean>;
}) {
  const { hasFeatureFlag } = useAuth();
  const [caseLevels, setCaseLevels] = useState<ImplantListLevelProps[]>([]);

  const context = useContext(ImplantEditorDialogContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleLoad = useCallback(
    async (planImplants: IPlanImplant[]) => {
      const validCaseLevels = caseUtils.getValidCaseLevels(props.levels);
      const caseLevels: ImplantListLevelProps[] = [];

      for (const caseLevel of validCaseLevels) {
        const level = caseLevel as LevelType;
        const planImplant = planImplants.find((planImplant) => planImplant.level === caseLevel);
        const partType = props.levels[caseLevel as any] as PartType;

        caseLevels.push({
          level,
          partType: partType,
          planImplant,
        });
      }

      setCaseLevels(caseLevels);
    },
    [caseLevels],
  );

  useEffect(() => {
    handleLoad(context.planImplants);
  }, [context.planImplants, context.cloudDesignQueues]);

  const [currentSelectedLevel, setCurrentSelectedLevel] = useState<Nullable<LevelType>>(
    context.state.moveImplantLevel ?? null,
  );
  const currentSelectedImplant = context.state.moveImplant;
  const [implantTaskInProgress, setImplantTaskInProgress] = useState(false);
  const [cloudDesignQueues, setCloudDesignQueues] = useState<ICloudDesignQueue[] | undefined>();
  const cloudDesignQueue: ICloudDesignQueue | undefined = cloudDesignQueues?.find(
    (q) => q.level === currentSelectedLevel,
  );
  const cuttingStatus: CloudDesignStatus | undefined =
    cloudDesignQueue?.status as CloudDesignStatus;

  const [findAssets] = useLazyQuery(gql`
    query FindAssets(
      $caseId: Int!
      $planId: Int
      $assetTypeFilter: [AssetType]
      $showDeleted: Boolean
      $ignorePlanFilter: Boolean
    ) {
      assets(
        caseId: $caseId
        planId: $planId
        assetTypeFilter: $assetTypeFilter
        showDeleted: $showDeleted
        ignorePlanFilter: $ignorePlanFilter
      ) {
        assetId
        assetType
        caseId
        fileName
        metadata
        planId
        signedDownloadUrl
      }
    }
  `);

  useEffect(() => {
    setCurrentSelectedLevel(context.state.moveImplantLevel ?? null);
  }, [context.state.moveImplantLevel]);

  const getCaseLevelData = (newLevel: Nullable<LevelType>) => {
    return caseLevels.find((l) => l.level === newLevel);
  };

  const currentLevelData = getCaseLevelData(currentSelectedLevel);

  const handleReloadLevelImplant = async () => {
    if (!currentSelectedLevel || !props.scene) return;

    const existingImplant = await context.findPlanImplant(currentSelectedLevel);

    if (!existingImplant) {
      enqueueSnackbar('Error loading cut implant', {
        variant: 'error',
      });

      return;
    }

    await context.loadImplantAsset(existingImplant, props.scene);

    enqueueSnackbar('Cut implant successfully loaded', {
      variant: 'success',
    });
  };

  const handleUpdateCutImplants = async (levelType: LevelType) => {
    const levelData = getCaseLevelData(levelType);

    if (!levelData) return;

    const existingImplant = await context.findPlanImplant(levelData.level);

    if (existingImplant) {
      const cutMeshPlan = props.scene?.getMeshByName(`${levelData.level}_APP`) as Mesh;
      if (cutMeshPlan) {
        cutMeshPlan.dispose();
      }

      const cutMeshMinus = props.scene?.getMeshByName(`${levelData.level}_APP_MINUS`) as Mesh;
      if (cutMeshMinus) {
        cutMeshMinus.dispose();
      }

      const implantMesh = props.scene?.getMeshByName(`${levelData.level}_CYBORG_IMPLANT`) as Mesh;
      if (implantMesh) {
        implantMesh.visibility = 1;
      }
    }

    context.updateCloudDesignQueues();
  };

  function checkCutAsset(levelType: LevelType, scene: Scene | undefined) {
    if (!scene) return false;

    const cutAsset = scene.getMeshByName(`${levelType}_APP`);
    const minusCutAsset = scene.getMeshByName(`${levelType}_APP_MINUS`);
    return cutAsset !== null && minusCutAsset !== null;
  }

  const updateCloudDesignQueues = () => {
    context.findCloudDesignQueues().then((cloudDesignQueues) => {
      setCloudDesignQueues([...cloudDesignQueues]);
    });
  };

  useInterval(
    () => {
      if (!hasFeatureFlag?.(FeatureFlag.fastImplantCuttingEnabled)) {
        updateCloudDesignQueues();
      }
    },
    10000,
    [],
  );

  useEffect(() => {
    if (!hasFeatureFlag?.(FeatureFlag.fastImplantCuttingEnabled)) {
      updateCloudDesignQueues();
    }
  }, []);

  const handleDownloadClick = async () => {
    if (!props.scene) {
      throw Error('Unable to find scene');
    }

    if (!currentLevelData) {
      throw Error('Unable to find current level');
    }

    try {
      setImplantTaskInProgress(true);

      const implantLevel = currentLevelData.level;
      const partType = currentLevelData.partType;

      const blobObjArray = await prepAssetsForZip(implantLevel, partType);
      let zipFile;
      if (blobObjArray) {
        zipFile = await file.createZipFile(blobObjArray as ExportBlob[]);
      }

      if (zipFile) {
        FileSaver.saveAs(
          zipFile,
          `${context.activeCase.number}_${context.plan.name.replace(
            ' ',
            '-',
          )}_${implantLevel}-assets.zip`,
        );
      } else {
        throw Error('Unable to zip assets');
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error trying to download assets', {
        variant: 'error',
      });
    } finally {
      setImplantTaskInProgress(false);
    }
  };

  const prepAssetsForZip = async (implantLevel: LevelType, partType: PartType) => {
    function createBlobFromSTL(name: string, prefix: string, scene: Scene) {
      const buffer = getMeshBuffer(name, scene);
      let blob: ExportBlob | null = null;

      if (buffer) {
        blob = {
          blob: new Blob([buffer], {
            type: 'application/octet-stream',
          }),
          name: `${prefix}${name}.stl`,
        };
      }
      return blob;
    }
    const assetsToZip: (ExportBlob | null)[] = [];

    const [superior, inferior] = implantLevel.split('_');

    if (!props?.scene) {
      throw Error(`Unable to find the scene`);
    }

    assetsToZip.push(createBlobFromSTL(`${implantLevel}_IMPLANT_SCREW`, '', props.scene));
    assetsToZip.push(
      createBlobFromSTL(`${implantLevel}_IMPLANT_SCREW_GUIDE`, 'spec_files/', props.scene),
    );
    assetsToZip.push(
      createBlobFromSTL(`${implantLevel}_IMPLANT_INSTRUMENT`, 'spec_files/', props.scene),
    );
    assetsToZip.push(
      createBlobFromSTL(`${implantLevel}_CYBORG_IMPLANT`, 'spec_files/', props.scene),
    );
    assetsToZip.push(
      createBlobFromSTL(`${implantLevel}_${partType}_MINI`, 'spec_files/', props.scene),
    );
    assetsToZip.push(createBlobFromSTL(`${implantLevel}_APP`, '', props.scene));
    assetsToZip.push(createBlobFromSTL(`${implantLevel}_APP_MINUS`, 'spec_files/', props.scene));
    assetsToZip.push(createBlobFromSTL(superior, '', props.scene));
    assetsToZip.push(createBlobFromSTL(inferior, '', props.scene));

    const { data: findAssetsData } = await findAssets({
      variables: {
        caseId: context.plan.caseId,
        planId: context.plan.planId,
        assetTypeFilter: [
          `${implantLevel}_APP_LOG` as AssetType,
          `${implantLevel}_APP_METADATA` as AssetType,
          `${implantLevel}_APP_DIMENSIONS` as AssetType,
          `${implantLevel}_APP_MINUS_METADATA` as AssetType,
          `${implantLevel}_APP_MINUS_DIMENSIONS` as AssetType,
        ],
      },
    });

    if (!findAssetsData?.assets) {
      throw Error(`Unable to find assets for: ${implantLevel}`);
    }

    for (const textAsset of findAssetsData?.assets ?? []) {
      const text = await getAssetText(textAsset.signedDownloadUrl);

      if (text) {
        assetsToZip.push({
          blob: new Blob([text], {
            type: 'text/plain',
          }),
          name: `txt/${textAsset.assetType}.txt`,
        });
      }
    }

    const assetPositions = context.plan.assetPositions;
    const planMeasurementPointValues: IMeasurementPointValues =
      measurements.getMeasurementPointValuesFromAssetPositions(
        assetPositions.plan.points,
        context.activeCase.spineProfile,
        context.activeCase.settings.measurementsVersion,
      );

    const assetPositionMeasurements: IMeasure[] = measurements.getMeasurementsFromAssetPositions(
      assetPositions.plan.points,
    );

    const csvData: string[][] = nTopCuts.getNTopMeasurementsForExport(
      inferior as VertebralBody,
      superior as VertebralBody,
      assetPositionMeasurements,
      planMeasurementPointValues,
    );

    assetsToZip.push({
      blob: new Blob([csvData.join('\n')], {
        type: 'text/csv',
      }),
      name: `txt/${implantLevel}_DATA.csv`,
    });

    return assetsToZip.filter((assetToZip) => assetToZip !== null);
  };

  const handleImplantActionClick = async (option: 'EDIT' | 'REMOVE' | 'DOWNLOAD') => {
    if (!currentLevelData) return;

    switch (option) {
      case 'EDIT':
        props.onEdit(currentLevelData.level, currentLevelData.partType);
        break;
      case 'REMOVE':
        props.onRemove(currentLevelData.level, currentLevelData.partType);
        break;
      case 'DOWNLOAD':
        await handleDownloadClick();
        break;
    }
  };

  const handleSelectLevel = async (newLevel: Nullable<LevelType>) => {
    if (
      (!newLevel && currentSelectedLevel) ||
      (currentSelectedLevel && context.state.moveImplantIsDirty)
    ) {
      const shouldClose = await props.onClear();

      updateCloudDesignQueues();

      if (!shouldClose) return;
    }

    const matchingCaseLevel = getCaseLevelData(newLevel);

    setCurrentSelectedLevel(newLevel);

    context.dispatch({
      type: 'EDIT_IMPLANT_POSITION',
      data: {
        level: newLevel,
        implant: matchingCaseLevel?.planImplant,
      },
    });

    if (matchingCaseLevel) {
      updateCloudDesignQueues();

      props.onMove(matchingCaseLevel.level, matchingCaseLevel.partType);
    }
  };

  const handleDownloadAllClick = async () => {
    const implantLevels = caseLevels?.filter((level) => level.planImplant);
    try {
      setImplantTaskInProgress(true);

      const zipArray = [];
      for (const implant of implantLevels) {
        const tempObjBlob = (await prepAssetsForZip(
          implant?.level,
          implant?.partType,
        )) as ExportBlob[];
        const zipFile = await file.createZipFile(tempObjBlob);

        zipArray.push({ implant, zip: zipFile });
      }

      zipArray.map(async (obj) => {
        FileSaver.saveAs(
          obj.zip,
          `${context.activeCase.number}_${context.plan.name.replace(' ', '-')}_${
            obj.implant?.level
          }-assets.zip`,
        );
      });
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Error trying to download assets', {
        variant: 'error',
      });
    } finally {
      setImplantTaskInProgress(false);
    }
  };

  const switchCamera = (
    cameraView: ImplantListRootCameraView,
    distance: number = PLAN_EDITOR_CAMERA_DISTANCE,
  ) => {
    const scene = props?.scene;
    if (!scene) return;

    if (cameraView === 'AXIAL') {
      rotateCamera(-90, 0, distance, scene);
    }
    if (cameraView === 'CORONAL') {
      rotateCamera(-90, 90, distance, scene);
    }
    if (cameraView === 'SAGITTAL_LEFT') {
      rotateCamera(0, 90, distance, scene);
    }
    if (cameraView === 'SAGITTAL_RIGHT') {
      rotateCamera(180, 90, distance, scene);
    }
  };

  const disabledPartTypes: PartType[] = [
    PartType.TLIFC_OFFSET_LEFT,
    PartType.TLIFC_OFFSET_RIGHT,
    PartType.TLIFC_INLINE_LEFT,
    PartType.TLIFC_INLINE_RIGHT,
  ];

  if (isTlifCOrientationAvailable()) {
    disabledPartTypes.length = 0;
  }

  const addPartTypeDisabled =
    currentSelectedLevel && props.levels[currentSelectedLevel]
      ? disabledPartTypes.includes(props.levels[currentSelectedLevel] as PartType)
      : true;

  const actionsDisabled =
    ['PENDING', 'PROCESSING'].includes(cuttingStatus as string) ||
    implantTaskInProgress ||
    props.disabled ||
    Boolean(context?.state?.moveImplantIsDirty);

  return (
    <Box>
      {props?.loading && <Skeleton variant={'rectangular'} height={75} />}
      {!props?.loading && (
        <Box>
          <Box>
            <FormControl fullWidth>
              <InputLabel>Level</InputLabel>
              <Select
                defaultValue={null}
                disabled={implantTaskInProgress || props.disabled}
                displayEmpty
                fullWidth
                label="Level"
                value={currentSelectedLevel}
                onChange={(e) => {
                  const newLevel = e.target.value as Nullable<LevelType>;

                  handleSelectLevel(newLevel);
                }}
              >
                {caseLevels.map((caseLevel) => {
                  const levelCloudDesignQueue = cloudDesignQueues?.find(
                    (q) => q.level === caseLevel.level,
                  );

                  return (
                    <MenuItem value={caseLevel.level} key={caseLevel.level}>
                      <Box width="100%">
                        <ImplantListLevelView
                          cloudDesignQueue={levelCloudDesignQueue}
                          key={caseLevel.level}
                          caseLevel={caseLevel}
                          disabled={props.disabled}
                          scene={props.scene}
                          onClick={() => {
                            props.onAdd(caseLevel.level);
                          }}
                          onCutCancel={async () => {
                            await handleLoad(context.planImplants);
                          }}
                        />
                      </Box>
                    </MenuItem>
                  );
                })}
              </Select>
              {currentSelectedLevel ? (
                <Box mt={1} flexGrow={1}>
                  <Button
                    disabled={props.disabled}
                    variant="text"
                    size="small"
                    color={context.state.moveImplantIsDirty ? 'error' : 'info'}
                    fullWidth
                    onClick={() => handleSelectLevel(null)}
                  >
                    {context.state.moveImplantIsDirty ? 'Discard Changes' : 'Close'}
                  </Button>
                </Box>
              ) : null}
            </FormControl>
          </Box>
          {currentSelectedLevel && currentLevelData ? (
            currentSelectedImplant ? (
              <Box display="flex" justifyContent={'space-evenly'} mt={2}>
                {!props.disabled ? (
                  <CloudDesignButton
                    caseId={context.plan.caseId}
                    cloudDesignQueue={cloudDesignQueue}
                    disabled={
                      implantTaskInProgress ||
                      ['PENDING', 'PROCESSING'].includes(cuttingStatus as string) ||
                      Boolean(context?.state?.moveImplantIsDirty)
                    }
                    isCut={checkCutAsset(currentLevelData.level, props.scene)}
                    level={currentLevelData.level}
                    onCut={(status) => {
                      if (status === 'PROCESSING') {
                        handleUpdateCutImplants(currentSelectedLevel);
                      } else if (status === 'SUCCESS') {
                        handleReloadLevelImplant();
                      } else {
                        updateCloudDesignQueues();
                      }
                    }}
                    onCutComplete={async () => {
                      await handleReloadLevelImplant();
                    }}
                    onCutCancel={updateCloudDesignQueues}
                    planId={context.plan.planId}
                  />
                ) : null}
                <CameraViewMenu
                  disabled={actionsDisabled}
                  onCameraSelection={(cameraView: ImplantListRootCameraView) => {
                    switchCamera(cameraView, IMPLANT_EDITOR_CAMERA_DISTANCE);
                  }}
                />
                <ImplantListLevelOptionsMenu
                  loading={implantTaskInProgress}
                  disabled={actionsDisabled}
                  downloading={implantTaskInProgress}
                  onSelect={(value) => {
                    setImplantTaskInProgress(true);

                    return handleImplantActionClick(
                      value as 'EDIT' | 'REMOVE' | 'DOWNLOAD',
                    ).finally(() => setImplantTaskInProgress(false));
                  }}
                />
              </Box>
            ) : (
              <>
                <Box mb={2} />
                <Button
                  fullWidth
                  disabled={
                    ['PENDING', 'PROCESSING'].includes(cuttingStatus as string) ||
                    implantTaskInProgress ||
                    addPartTypeDisabled
                  }
                  onClick={() => {
                    props.onAdd(currentSelectedLevel);
                  }}
                  size="large"
                  color="primary"
                  variant="contained"
                >
                  Add Implant
                </Button>
              </>
            )
          ) : (
            <Box display="flex" justifyContent={'space-evenly'} mt={2}>
              <ImplantListRootOptionsMenu
                loading={implantTaskInProgress}
                disabled={implantTaskInProgress || props.disabled}
                downloading={implantTaskInProgress}
                onDownloadAll={async () => {
                  setImplantTaskInProgress(true);

                  //Necessary to remove the initial 1-2 second delay on button press
                  setTimeout(async () => {
                    await handleDownloadAllClick();
                  }, 500);
                }}
                onCameraSelection={(cameraView: ImplantListRootCameraView) => {
                  switchCamera(cameraView);
                }}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
