import React, { useEffect, useState } from 'react';
import {
  AssetType,
  CaseSpineProfile,
  CaseStageType,
  caseUtils,
  DaisySegmentationStatus,
  format,
  IAsset,
  ICaseTag,
  IDaisySegmentationQueue,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  DELETE_EXPORT_DAISY_SEGMENTATION,
  EXPORT_DAISY_SEGMENTATION,
  FIND_ASSETS,
  FIND_DAISY_SEGMENTATION_QUEUE,
} from '../../../../gql';
import { IconFontButton } from '@workflow-nx/ui';
import { Alert, Box, Button, Divider, Stack, Typography, useTheme } from '@mui/material';
import { faPlay, faRedo, faStop } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlower } from '@fortawesome/pro-duotone-svg-icons';
import { useConfirm } from 'material-ui-confirm';
import { canDaisySegmentCase } from '../../../../utils/case';
import { DaisySegmentationCompleteView } from './DaisySegmentationCompleteView';

export function ExportDaisySegmentationView(props: {
  caseId: number;
  caseNumber: string;
  caseTags: ICaseTag[];
  disabled: boolean;
  caseStage: CaseStageType;
  spineProfile: CaseSpineProfile;
  onApproved: () => void;
  onRejected: () => void;
}) {
  const theme = useTheme();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [daisySegmentationQueue, setDaisySegmentationQueue] = useState<IDaisySegmentationQueue>();
  const [hasAllRequiredAssets, setHasAllRequiredAssets] = useState(false);
  const [showAllLogs, setShowAllLogs] = useState(false);
  const [hasHardware, setHasHardware] = useState(false);

  const [exportDaisySegmentation, { loading: loadingExportDaisySegmentation }] =
    useMutation(EXPORT_DAISY_SEGMENTATION);

  const [deleteExportDaisySegmentation, { loading: loadingDeleteExportDaisySegmentation }] =
    useMutation(DELETE_EXPORT_DAISY_SEGMENTATION);

  const { refetch, startPolling, stopPolling } = useQuery(FIND_DAISY_SEGMENTATION_QUEUE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { caseId: props.caseId },
    onCompleted: (data) => {
      setDaisySegmentationQueue(data.daisySegmentationQueue);

      if (data.daisySegmentationQueue) {
        const { status } = data.daisySegmentationQueue;
        if (
          [
            DaisySegmentationStatus.Completed,
            DaisySegmentationStatus.Error,
            DaisySegmentationStatus.None,
          ].includes(status)
        ) {
          stopPolling();
        } else {
          startPolling(15000);
        }
      }
    },
  });

  const [findAssets, { loading: loadingAssets }] = useLazyQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const requiredAssets = data.assets.filter((asset: IAsset) =>
        [AssetType.DicomCt].includes(asset.assetType),
      ).length;
      setHasAllRequiredAssets(requiredAssets > 0);
      setHasHardware(
        data.assets.find((asset: IAsset) => asset.assetType === AssetType.HardwareDaisy),
      );
    },
  });

  const handleExportAssetsClick = async () => {
    try {
      await exportDaisySegmentation({
        variables: {
          caseId: props.caseId,
        },
      });

      setDaisySegmentationQueue(undefined);
      await refetch({ caseId: props.caseId });
      enqueueSnackbar(`The DICOM has been sent for segmentation`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred exporting the DICOM', { variant: 'error' });
    }
  };

  const handleCancelClick = async () => {
    try {
      if (daisySegmentationQueue?.status === DaisySegmentationStatus.Processing) {
        await confirm({
          title: `Cancel segmentation ${props.caseNumber}?`,
          description: (
            <>
              This will remove the case from the segmentation queue. Are you sure you wish to
              continue?
            </>
          ),
        });
      }

      await deleteExportDaisySegmentation({
        variables: {
          daisySegmentationQueueId: daisySegmentationQueue?.daisySegmentationQueueId,
        },
      });

      stopPolling();
      setDaisySegmentationQueue(undefined);
      await refetch({ caseId: props.caseId });
      enqueueSnackbar(`The segmentation request has been cancelled`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred cancelling the segmentation request', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    findAssets({
      variables: {
        caseId: props.caseId,
        assetTypeFilter: [AssetType.DicomCt, AssetType.HardwareDaisy],
      },
    });
  }, []);

  const daisySupportedSpineProfile = caseUtils.isValidDaisySegmentationCase(props.spineProfile);
  const daisySegmentationAllowed = canDaisySegmentCase(
    props.caseTags.map((caseTag) => {
      return { key: caseTag.tag.tagId, value: caseTag.tag.label };
    }),
  );

  const disabled =
    !hasAllRequiredAssets ||
    loadingDeleteExportDaisySegmentation ||
    loadingExportDaisySegmentation ||
    loadingAssets ||
    !daisySupportedSpineProfile;

  const isRunning =
    (!!daisySegmentationQueue && !daisySegmentationQueue?.finishedAt) ||
    loadingExportDaisySegmentation;

  return (
    <>
      <Box p={2} border={`1px solid ${theme.palette.divider}`} borderRadius="5px">
        <Box display="flex" alignItems={'center'}>
          <Box display={'flex'} alignItems={'center'}>
            <FontAwesomeIcon icon={faFlower} size={'lg'} />
            <Box ml={1} />
            <Typography variant={'h4'}>Auto-Segmentation</Typography>
          </Box>
          <Box flexGrow={1} />
          <IconFontButton
            icon={!daisySegmentationQueue ? faPlay : faRedo}
            loading={isRunning}
            disabled={
              daisySegmentationQueue?.status === DaisySegmentationStatus.Processing || disabled
            }
            onClick={handleExportAssetsClick}
          />
          <Box ml={1} />
          <IconFontButton
            icon={faStop}
            disabled={disabled || !daisySegmentationQueue}
            onClick={handleCancelClick}
          />
        </Box>
        {!daisySegmentationAllowed ? (
          <Alert severity={'error'} sx={{ mb: 1 }}>
            Segmentation is not allowed because of the tags assigned to this case.
          </Alert>
        ) : null}
        {!hasHardware ? (
          <Alert severity={'warning'} sx={{ mb: 1 }}>
            Warning: This segmentation has detected hardware!
          </Alert>
        ) : null}
        {!hasAllRequiredAssets ? (
          <Alert severity={'warning'}>
            <Typography variant={'body1'}>
              Cannot send assets for auto-segmentation if there is not a DICOM CT attached to the
              case.
            </Typography>
          </Alert>
        ) : null}
        {hasAllRequiredAssets &&
        !daisySegmentationQueue &&
        !loadingExportDaisySegmentation &&
        daisySegmentationAllowed &&
        daisySupportedSpineProfile ? (
          <Alert severity={'info'}>
            <Typography variant={'body1'}>Auto-segmentation is available!</Typography>
          </Alert>
        ) : null}
        {hasAllRequiredAssets &&
        !daisySegmentationQueue &&
        !loadingExportDaisySegmentation &&
        !daisySupportedSpineProfile ? (
          <Alert severity={'warning'}>
            <Typography variant={'body1'}>
              Auto-segmentation is not currently available for "
              {format.formatCaseSpineProfile(props.spineProfile)}" spine profiles.
            </Typography>
          </Alert>
        ) : null}
        {hasAllRequiredAssets && loadingExportDaisySegmentation ? (
          <Alert severity={'info'}>
            <Typography variant={'body1'}>Auto-segmentation is starting!</Typography>
          </Alert>
        ) : null}
        {daisySegmentationQueue ? (
          <Box my={2}>
            <Divider />
          </Box>
        ) : null}
        {daisySegmentationQueue ? (
          <Box>
            <Typography variant={'body1'}>
              Segmentation started at{' '}
              {format.formatDateTime(daisySegmentationQueue.startedAt as Date)}
            </Typography>
            <Typography variant={'body1'}>
              <strong>Status</strong>
            </Typography>
            <Typography variant={'body1'}>
              Sacrum - {daisySegmentationQueue.sacrumStatus}
            </Typography>
            <Typography variant={'body1'}>
              Vertebrae - {daisySegmentationQueue.vertebraeStatus}
            </Typography>
            {daisySegmentationQueue.status === DaisySegmentationStatus.Completed ? (
              <DaisySegmentationCompleteView
                caseId={props.caseId}
                caseNumber={props.caseNumber}
                caseTags={props.caseTags}
                caseStage={props.caseStage}
                caseSpineProfile={props.spineProfile}
              />
            ) : null}

            <ul
              style={{ listStyleType: 'none', margin: 0, padding: 0, marginTop: theme.spacing(2) }}
            >
              {daisySegmentationQueue?.log?.map((log: string, index: number) => {
                const isLast = daisySegmentationQueue?.log?.length - 1 === index;

                return (
                  <li key={index} hidden={!isLast && !showAllLogs}>
                    <Box ml={2} mt={1}>
                      <Typography variant={'body2'}>{log}</Typography>
                    </Box>
                  </li>
                );
              })}
            </ul>
            {daisySegmentationQueue?.log?.length > 1 ? (
              <Stack direction={'row'} justifyContent={'center'}>
                <Button variant={'text'} onClick={() => setShowAllLogs(!showAllLogs)}>
                  {showAllLogs ? 'Hide' : 'Show'} Full Logs
                </Button>
              </Stack>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </>
  );
}
