import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPSERT_SETTINGS } from '../../../gql';
import { FieldArray, Formik, FormikHelpers, FormikValues } from 'formik';
import { Box, Table, TableBody, TableHead, TableRow, Grid, Divider } from '@mui/material';
import { Button, SelectField } from '@workflow-nx/ui';
import * as Yup from 'yup';
import { TextField as MuiTextField } from '@mui/material';
import {
  IForm20Product,
  Form20ProductType,
  Form20ProductStatusType,
  format,
} from '@workflow-nx/common';
import { TableHeadCell } from '../../../components/TableHeadCell';
import { TableCell } from '../../../components/TableCell';
import { Select } from '../../../components/Select';
import ActionButton from '../../../components/ActionButton';

export function Form20ProductsForm(props: { form20: IForm20Product[]; handleClose: () => void }) {
  const form20ProductTypeMenuItems = [
    { key: Form20ProductType.Implant, value: 'Implant' },
    { key: Form20ProductType.Instrument, value: 'Instrument' },
    { key: Form20ProductType.AlifXScrew, value: 'ALIF/X Screw' },
    { key: Form20ProductType.AcdfXScrew, value: 'ACDF/X Screw' },
  ];

  const form20ProductStatusTypeMenuItems = [
    { key: Form20ProductStatusType.Archived, value: 'Archived' },
    { key: Form20ProductStatusType.InUse, value: 'In Use' },
    { key: Form20ProductStatusType.PreMarket, value: 'PreMarket' },
  ];

  const { enqueueSnackbar } = useSnackbar();
  const [upsertSettings] = useMutation(UPSERT_SETTINGS);
  const [skuValue, setSkuValue] = useState('');
  const [descriptionValue, setDescriptionValue] = useState('');
  const [deviceIdValue, setDeviceIdValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [weightValue, setWeightValue] = useState('');

  const handleSubmitForm = async (
    values: FormikValues,
    { setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      await upsertSettings({
        variables: {
          form20: values.form20,
        },
      });
      setStatus({ success: true });
      enqueueSnackbar('FORM-020 updated', {
        variant: 'success',
      });
      props.handleClose();
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred updating the FORM-020', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box m={1}>
      <Formik
        initialValues={props}
        validationSchema={Yup.object().shape({
          form20: Yup.array().of(
            Yup.object().shape({
              sku: Yup.string().required(),
              description: Yup.string().required(),
              gs1DeviceId: Yup.string().required(),
              type: Yup.string().required(),
              status: Yup.string().required(),
            }),
          ),
        })}
        onSubmit={handleSubmitForm}
        enableReinitialize={true}
      >
        {({ values, submitForm }) => {
          return (
            <Box>
              <FieldArray name="form20">
                {(arrayHelpers) => {
                  return (
                    <>
                      <Box p={2}>
                        <Grid container spacing={2}>
                          <Grid item>
                            <MuiTextField
                              label="SKU"
                              variant="outlined"
                              size="small"
                              value={skuValue}
                              onChange={(e) => setSkuValue(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs>
                            <MuiTextField
                              label="Description"
                              variant="outlined"
                              size="small"
                              value={descriptionValue}
                              onChange={(e) => setDescriptionValue(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs>
                            <MuiTextField
                              label="GS1 Device Indentifier"
                              variant="outlined"
                              size="small"
                              value={deviceIdValue}
                              onChange={(e) => setDeviceIdValue(e.target.value)}
                            />
                          </Grid>
                          <Grid item xs>
                            <Select
                              name={'type'}
                              label={'Type'}
                              value={typeValue}
                              onChange={(e) => setTypeValue(e.target.value)}
                              disabled={false}
                              menuItems={form20ProductTypeMenuItems}
                              fullWidth={true}
                              hideNone={true}
                            />
                          </Grid>
                          <Grid item xs>
                            <MuiTextField
                              label="Weight (grams)"
                              variant="outlined"
                              size="small"
                              value={weightValue}
                              onChange={(e) => setWeightValue(e.target.value)}
                              type="number"
                              inputProps={{ min: 0, max: 1000 }}
                              fullWidth={true}
                            />
                          </Grid>
                          <Grid item xs>
                            <Select
                              name={'status'}
                              label={'Status'}
                              value={statusValue}
                              onChange={(e) => setStatusValue(e.target.value)}
                              disabled={false}
                              menuItems={form20ProductStatusTypeMenuItems}
                              fullWidth={true}
                              hideNone={true}
                            />
                          </Grid>
                          <Grid item xs>
                            <ActionButton
                              variant="outlined"
                              color="primary"
                              fullWidth={true}
                              disabled={
                                !skuValue ||
                                !descriptionValue ||
                                !deviceIdValue ||
                                !statusValue ||
                                !typeValue ||
                                !weightValue
                              }
                              onClick={() => {
                                if (skuValue) {
                                  arrayHelpers.unshift({
                                    sku: skuValue,
                                    description: descriptionValue,
                                    gs1DeviceId: deviceIdValue,
                                    type: typeValue,
                                    status: statusValue,
                                    weight: weightValue,
                                  });
                                  setSkuValue('');
                                  setDescriptionValue('');
                                  setDeviceIdValue('');
                                  setTypeValue('');
                                  setStatusValue('');
                                  setWeightValue('');
                                }
                              }}
                            >
                              Add Product
                            </ActionButton>
                          </Grid>
                        </Grid>
                        <Box my={1} />
                        <Divider />
                      </Box>
                      <Box display={'flex'}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableHeadCell>SKU</TableHeadCell>
                              <TableHeadCell>Product Description</TableHeadCell>
                              <TableHeadCell>GS1 Device Indentifier</TableHeadCell>
                              <TableHeadCell>Type</TableHeadCell>
                              <TableHeadCell>Weight (grams)</TableHeadCell>
                              <TableHeadCell>Status</TableHeadCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {values.form20.map((form20Product, index) => (
                              <TableRow hover key={index}>
                                <TableCell>{form20Product.sku}</TableCell>
                                <TableCell>{form20Product.description}</TableCell>
                                <TableCell>{form20Product.gs1DeviceId}</TableCell>
                                <TableCell>
                                  {format.formatForm20ProductType(form20Product.type)}
                                </TableCell>
                                <TableCell>{form20Product.weight}</TableCell>
                                <TableCell>
                                  <SelectField
                                    name={`form20[${index}].status`}
                                    label={''}
                                    menuItems={form20ProductStatusTypeMenuItems}
                                    disabled={false}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Box>
                      <Box my={1} />
                    </>
                  );
                }}
              </FieldArray>
              <Box mx={1} />
              <Box mt={4} display={'flex'} justifyContent={'flex-end'}>
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  label={'Save FORM-020'}
                  onClick={submitForm}
                />
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Box>
  );
}
