import {
  AssetCategory,
  AssetType,
  ExportBlob,
  IAxes,
  IMeasure,
  IPlanImplant,
  ImplantPosition,
  Interbody,
  LevelType,
  PartType,
  PlanImplantBulletType,
  measurements as measurementUtils,
} from '@workflow-nx/common';
import { Angle, Mesh, Scene, Vector3 } from 'babylonjs';
import { getMeshBuffer } from '../../../../../../components/SceneComponent/renderer';
import { BulletFormValues } from '../Dialogs/BulletForm';
import {
  getImplantBulletDefaults,
  getTargetAssetPositionTranslationAndRotationByTag,
  getTargetImplantDataFromScene,
  getImplantHasScrews,
} from '../utils/implantEditor';
import { CoordinateValueTypes } from './CoordinateInputContainer';

export const getPositionChange = (mesh: Mesh, scene: Scene) => {
  const defaultImplantPosition: ImplantPosition | null =
    getTargetAssetPositionTranslationAndRotationByTag(
      AssetCategory.Implants,
      mesh.name as Interbody,
      scene,
    );
  const defaultPosition = defaultImplantPosition?.centroid ?? Vector3.Zero();

  const absolutePosition = mesh?.getAbsolutePosition();
  const x = (Math.round(((absolutePosition?.x ?? 0) - defaultPosition.x) * 100) / 100).toFixed(2);
  const y = (Math.round(((absolutePosition?.y ?? 0) - defaultPosition.y) * 100) / 100).toFixed(2);
  const z = (Math.round(((absolutePosition?.z ?? 0) - defaultPosition.z) * 100) / 100).toFixed(2);

  return { x, y, z };
};

export const getRotationChange = (mesh: Mesh, scene: Scene) => {
  function getDegrees(radians: number | undefined, originalRadians: number): string {
    const newDegrees = Angle.FromRadians(radians ?? 0).degrees();
    const originalDegrees = Angle.FromRadians(originalRadians).degrees();

    return Math.round(newDegrees - originalDegrees).toFixed(0);
  }

  const defaultImplantPosition: ImplantPosition | null =
    getTargetAssetPositionTranslationAndRotationByTag(
      AssetCategory.Implants,
      mesh.name as Interbody,
      scene,
    );
  const defaultRotation = defaultImplantPosition?.eulerAngles ?? Vector3.Zero();

  const eulerAngles = mesh?.absoluteRotationQuaternion?.toEulerAngles();

  const x = getDegrees(eulerAngles?.x, defaultRotation.x);
  const y = getDegrees(eulerAngles?.y, defaultRotation.y);
  const z = getDegrees(eulerAngles?.z, defaultRotation.z);

  return { x, y, z };
};

export const updateMeshPositionAndRotation = (
  position: Vector3,
  eulerAngles: Vector3,
  mesh: Mesh,
) => {
  const updatedPositionVector = position;

  const eulerQuaternion = new Vector3(eulerAngles.x, eulerAngles.y, eulerAngles.z).toQuaternion();

  mesh.setAbsolutePosition(updatedPositionVector);
  mesh.rotationQuaternion = eulerQuaternion;

  mesh.computeWorldMatrix(true);
};

export const setupMoveLevelView = (levelType: LevelType, implant: IPlanImplant, scene: Scene) => {
  const [superior, inferior] = levelType.split('_');
  const implantMesh = scene.getMeshByName(`${levelType}_CYBORG_IMPLANT`);
  const miniMesh = scene.getMeshByName(`${levelType}_${implant?.partType}_MINI`) as Mesh;
  const screwMesh = scene.getMeshByName(`${levelType}_IMPLANT_SCREW`);
  const screwGuide = scene.getMeshByName(`${levelType}_IMPLANT_SCREW_GUIDE`);
  const instrument = scene.getMeshByName(`${levelType}_IMPLANT_INSTRUMENT`);
  const cutMeshPlan = scene.getMeshByName(`${levelType}_APP`);
  const cutMeshMinus = scene.getMeshByName(`${levelType}_APP_MINUS`);
  const superiorMesh = scene.getMeshByName(superior);
  const inferiorMesh = scene.getMeshByName(inferior);

  scene.getActiveMeshes().forEach((mesh) => {
    mesh.visibility = 0;
  });

  if (superiorMesh) {
    superiorMesh.visibility = 0.6;
  }

  if (inferiorMesh) {
    inferiorMesh.visibility = 0.6;
  }

  if (cutMeshPlan) {
    cutMeshPlan.visibility = 0;

    if (implantMesh) {
      implantMesh.visibility = 1;
    }
  } else {
    if (implantMesh) {
      implantMesh.visibility = 1;
    }
  }

  if (cutMeshMinus) {
    cutMeshMinus.visibility = 0;
  }

  if (miniMesh) {
    miniMesh.visibility = 0;
  }

  if (screwMesh) {
    screwMesh.visibility = 1;
  }

  if (screwGuide) {
    screwGuide.visibility = 0;
  }

  if (instrument) {
    instrument.visibility = 0;
  }
};

function dataViewToBlob(dataView: DataView) {
  // Create an ArrayBuffer from the DataView
  const arrayBuffer = dataView.buffer.slice(
    dataView.byteOffset,
    dataView.byteOffset + dataView.byteLength,
  );

  // Create a Blob from the ArrayBuffer
  return new Blob([arrayBuffer], { type: 'application/octet-stream' });
}

function createBlobFromSTL(name: string, scene: Scene) {
  const dataView = getMeshBuffer(name, scene);
  let blob: ExportBlob | null = null;

  if (dataView && dataView.byteLength > 0) {
    blob = {
      blob: dataViewToBlob(dataView),
      name: `${name}.stl`,
      size: dataView.byteLength,
      assetType: name as AssetType,
    };
  } else {
    return null;
  }

  return blob;
}

export const getImplantData = (
  levelType: LevelType,
  partType: PartType,
  implantMesh: Mesh,
  scene: Scene,
): ExportBlob[] => {
  const blobs: ExportBlob[] = [];

  if (implantMesh) {
    const implantBlob = createBlobFromSTL(`${levelType}_CYBORG_IMPLANT`, scene);
    if (implantBlob) {
      blobs.push(implantBlob);
    }

    const instrumentBlob = createBlobFromSTL(`${levelType}_IMPLANT_INSTRUMENT`, scene);
    if (instrumentBlob) {
      blobs.push(instrumentBlob);
    }

    const hasScrews = getImplantHasScrews(partType);

    if (hasScrews) {
      const implantScrewBlob = createBlobFromSTL(`${levelType}_IMPLANT_SCREW`, scene);
      if (implantScrewBlob) {
        blobs.push(implantScrewBlob);
      }

      const implantScrewGuideBlob = createBlobFromSTL(`${levelType}_IMPLANT_SCREW_GUIDE`, scene);
      if (implantScrewGuideBlob) {
        blobs.push(implantScrewGuideBlob);
      }
    }
  }

  return blobs;
};

export const repositionMesh = (
  positionMesh: Mesh,
  axes: IAxes | undefined,
  level: LevelType,
  measurements: IMeasure[],
  value: number,
  coordinateValueType: CoordinateValueTypes,
) => {
  const updatedCoordinateAdded: number = value ?? 0;
  const targetImplantAssetPosition: IPlanImplant | null = getTargetImplantDataFromScene(
    positionMesh as Mesh,
  ) as IPlanImplant;

  if (targetImplantAssetPosition /*&& targetImplantAssetPositionState*/ && axes) {
    const [superior, inferior] = level.split('_');
    const { xAxis, yAxis, zAxis }: IAxes =
      measurementUtils.evaluateOrthogonalAxisFromMeasurementPoints(
        measurements,
        superior,
        inferior,
      );

    const absPosition = positionMesh.getAbsolutePosition();
    const eulerAngles = positionMesh.absoluteRotationQuaternion.toEulerAngles();
    switch (coordinateValueType) {
      case CoordinateValueTypes.XPosition:
        positionMesh.setAbsolutePosition(
          new Vector3(absPosition.x + updatedCoordinateAdded, absPosition.y, absPosition.z),
        );
        targetImplantAssetPosition.position.x = absPosition.x;
        break;
      case CoordinateValueTypes.YPosition:
        positionMesh.setAbsolutePosition(
          new Vector3(absPosition.x, absPosition.y, absPosition.z + updatedCoordinateAdded),
        );
        targetImplantAssetPosition.position.z = absPosition.z;
        break;
      case CoordinateValueTypes.ZPosition:
        positionMesh.setAbsolutePosition(
          new Vector3(absPosition.x, absPosition.y + updatedCoordinateAdded, absPosition.z),
        );
        targetImplantAssetPosition.position.y = absPosition.y;
        break;
      case CoordinateValueTypes.XRotation:
        positionMesh.rotationQuaternion = new Vector3(
          eulerAngles.x + (updatedCoordinateAdded * Math.PI) / 180,
          eulerAngles.y,
          eulerAngles.z,
        ).toQuaternion();
        targetImplantAssetPosition.rotation.x =
          (positionMesh.absoluteRotationQuaternion.toEulerAngles().x * Math.PI) / 180;
        break;
      case CoordinateValueTypes.YRotation:
        positionMesh.rotationQuaternion = new Vector3(
          eulerAngles.x,
          eulerAngles.y,
          eulerAngles.z + (updatedCoordinateAdded * Math.PI) / 180,
        ).toQuaternion();
        targetImplantAssetPosition.rotation.z =
          (positionMesh.absoluteRotationQuaternion.toEulerAngles().z * Math.PI) / 180;
        break;
      case CoordinateValueTypes.ZRotation:
        positionMesh.rotationQuaternion = new Vector3(
          eulerAngles.x,
          eulerAngles.y + (updatedCoordinateAdded * Math.PI) / 180,
          eulerAngles.z,
        ).toQuaternion();
        targetImplantAssetPosition.rotation.y =
          (positionMesh.absoluteRotationQuaternion.toEulerAngles().y * Math.PI) / 180;
        break;
      default:
        break;
    }
    positionMesh.computeWorldMatrix();

    return { axes: { xAxis, yAxis, zAxis } };
  }

  return { axes };
};

export function getInitialBulletValues(
  partType: PartType,
  bullet: PlanImplantBulletType | undefined,
  threadHeight: number | undefined,
): BulletFormValues {
  const defaults = getImplantBulletDefaults(partType);

  switch (partType) {
    case PartType.ACDF:
    case PartType.ACDF_X_LEFT_UP:
      return {
        angleThreaded: 0,
        heightThreaded: 0,
        angleInsertion: bullet?.insertionSide?.angle ?? defaults.bullet.insertionSide.angle,
        heightInsertion: bullet?.insertionSide?.height ?? defaults.bullet.insertionSide.height,

        insertionBottomAngle:
          bullet?.insertionSide?.bottomAngle ?? defaults.bullet.insertionSide.angle,
        insertionBottomHeight:
          bullet?.insertionSide?.bottomHeight ?? defaults.bullet.insertionSide.height,
        insertionTopAngle: bullet?.insertionSide?.topAngle ?? defaults.bullet.insertionSide.angle,
        insertionTopHeight:
          bullet?.insertionSide?.topHeight ?? defaults.bullet.insertionSide.height,
        threadedBottomAngle: 0,
        threadedBottomHeight: 0,
        threadedTopAngle: 0,
        threadedTopHeight: 0,

        threadHeight: threadHeight ?? defaults.threadHeight,
      };
    case PartType.ALIF:
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      return {
        angleThreaded: 0,
        heightThreaded: 0,
        angleInsertion: bullet?.insertionSide?.angle ?? defaults.bullet.insertionSide.angle,
        heightInsertion: bullet?.insertionSide?.height ?? defaults.bullet.insertionSide.height,

        insertionBottomAngle:
          bullet?.insertionSide?.bottomAngle ?? defaults.bullet.insertionSide.angle,
        insertionBottomHeight:
          bullet?.insertionSide?.bottomHeight ?? defaults.bullet.insertionSide.height,
        insertionTopAngle: bullet?.insertionSide?.topAngle ?? defaults.bullet.insertionSide.angle,
        insertionTopHeight:
          bullet?.insertionSide?.topHeight ?? defaults.bullet.insertionSide.height,
        threadedBottomAngle: 0,
        threadedBottomHeight: 0,
        threadedTopAngle: 0,
        threadedTopHeight: 0,

        threadHeight: threadHeight ?? defaults.threadHeight,
      };
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      return {
        angleThreaded: bullet?.threadedSide?.angle ?? defaults.bullet.threadedSide?.angle ?? 0,
        heightThreaded: bullet?.threadedSide?.height ?? defaults.bullet.threadedSide?.height ?? 0,
        angleInsertion: bullet?.insertionSide?.angle ?? defaults.bullet.insertionSide.angle,
        heightInsertion: bullet?.insertionSide?.height ?? defaults.bullet.insertionSide.height,

        insertionBottomAngle:
          bullet?.insertionSide?.bottomAngle ?? defaults.bullet.insertionSide.angle,
        insertionBottomHeight:
          bullet?.insertionSide?.bottomHeight ?? defaults.bullet.insertionSide.height,
        insertionTopAngle: bullet?.insertionSide?.topAngle ?? defaults.bullet.insertionSide.angle,
        insertionTopHeight:
          bullet?.insertionSide?.topHeight ?? defaults.bullet.insertionSide.height,
        threadedBottomAngle:
          bullet?.threadedSide?.bottomAngle ?? defaults?.bullet?.threadedSide?.angle ?? 0,
        threadedBottomHeight:
          bullet?.threadedSide?.bottomHeight ?? defaults?.bullet?.threadedSide?.height ?? 0,
        threadedTopAngle:
          bullet?.threadedSide?.topAngle ?? defaults?.bullet?.threadedSide?.angle ?? 0,
        threadedTopHeight:
          bullet?.threadedSide?.topHeight ?? defaults?.bullet?.threadedSide?.height ?? 0,

        threadHeight: threadHeight ?? defaults.threadHeight,
      };
    case PartType.TLIFO_LEFT:
    case PartType.TLIFO_RIGHT:
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
    default: {
      return {
        angleThreaded: defaults.bullet.threadedSide?.angle ?? 0,
        heightThreaded: defaults.bullet.threadedSide?.height ?? 0,
        angleInsertion: defaults.bullet.insertionSide.angle,
        heightInsertion: defaults.bullet.insertionSide.height,

        insertionBottomAngle:
          bullet?.insertionSide?.bottomAngle ?? defaults.bullet.insertionSide.angle,
        insertionBottomHeight:
          bullet?.insertionSide?.bottomHeight ?? defaults.bullet.insertionSide.height,
        insertionTopAngle: bullet?.insertionSide?.topAngle ?? defaults.bullet.insertionSide.angle,
        insertionTopHeight:
          bullet?.insertionSide?.topHeight ?? defaults.bullet.insertionSide.height,
        threadedBottomAngle: 0,
        threadedBottomHeight: 0,
        threadedTopAngle: 0,
        threadedTopHeight: 0,

        threadHeight: defaults.threadHeight,
      };
    }
  }
}
