import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_DOWNTIME_DATE,
  DELETE_DOWNTIME_DATE,
  FIND_DOWNTIME_DATES,
  FIND_ORGANIZATIONS,
} from '../../gql';
import * as Yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Stack,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Calendar,
  DatePickerRHF,
  IconFontButton,
  SelectFieldRHF,
  TextFieldRHF,
} from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import {
  DowntimeType,
  format,
  IDowntimeDate,
  IOrganization,
  OrganizationType,
} from '@workflow-nx/common';
import _ from 'lodash';
import DowntimeEventView from './DowntimeEventView';
import { EventSourceInput } from '@fullcalendar/core';
import * as muiColors from '@mui/material/colors';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';

type DowntimeDateEntry = {
  id: number;
  downtimeType: DowntimeType;
  organizationName: string;
  organizationId: number;
  date: string;
  description: string;
};

const validationSchema = Yup.object().shape({
  organizationId: Yup.string().optional(),
  description: Yup.string().required(),
  date: Yup.date().required(),
  downtimeType: Yup.string().required(),
});

export function NetworkDaysForm(props: { onSave: () => void }) {
  const { enqueueSnackbar } = useSnackbar();

  const confirm = useConfirm();

  const [vendorColorMap, setVendorColorMap] = useState<Record<number, string>>({});
  const [downtimeFormOpen, setDowntimeFormOpen] = useState(false);

  const DEFAULT_ORGANIZATION_ID = 0;

  const { data: vendorData, loading: vendorDataLoading } = useQuery<{
    organizations: { organizations: IOrganization[] };
  }>(FIND_ORGANIZATIONS, {
    variables: {
      organizationTypeFilter: [OrganizationType.Vendor],
    },
    onError: () => {
      enqueueSnackbar('An error occurred while fetching vendors.', { variant: 'error' });
    },
    onCompleted: (data) => {
      const colorMap: typeof vendorColorMap = {};

      const vendorColors = [
        muiColors.grey[400],
        muiColors.cyan[400],
        muiColors.teal[400],
        muiColors.orange[400],
        muiColors.green[400],
        muiColors.red[400],
      ];

      const sortedOrganizations = _.sortBy(data.organizations.organizations, 'name');

      // Set color for non-organization downtime dates
      colorMap[DEFAULT_ORGANIZATION_ID] = vendorColors[DEFAULT_ORGANIZATION_ID];

      sortedOrganizations.forEach((o, index) => {
        colorMap[o.organizationId] = vendorColors[index + 1] ?? 'grey';
      });

      setVendorColorMap({ ...colorMap });
    },
  });

  const [findDowntimeDates] = useLazyQuery<{
    downtimeDates: IDowntimeDate[];
  }>(FIND_DOWNTIME_DATES, { fetchPolicy: 'network-only' });

  const [deleteDowntimeDate] = useMutation<
    { deleteDowntimeDate: string },
    { downtimeDateId: number }
  >(DELETE_DOWNTIME_DATE);
  const [createDowntimeDate] = useMutation<
    { createDowntimeDate: string },
    {
      input: {
        organizationId?: number;
        date: string;
        description: string;
        downtimeType: DowntimeType;
      };
    }
  >(CREATE_DOWNTIME_DATE);

  const vendorOrganizations = vendorData?.organizations.organizations ?? [];

  const {
    control,
    handleSubmit,
    reset: resetDowntimeForm,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      organizationId: undefined,
      date: new Date(),
      description: '',
      downtimeType: DowntimeType.Holiday,
    },
    resolver: yupResolver(validationSchema),
  });

  const [sectionOpen, setSectionOpen] = useState(false);

  const [currentVendorTabId, setCurrentVendorTab] = useState<number>(DEFAULT_ORGANIZATION_ID);

  const [downtimeDates, setDowntimeDates] = useState<IDowntimeDate[]>([]);

  const [currentDate, setCurrentDate] = useState(new Date());

  const downtimeDateEntries: DowntimeDateEntry[] = downtimeDates.map((d) => ({
    id: d.downtimeDateId,
    downtimeType: d.downtimeType,
    organizationName: d.organization?.name ?? 'Company',
    organizationId: d.organization?.organizationId ?? DEFAULT_ORGANIZATION_ID,
    date: d.date as unknown as string,
    description: d.description,
  }));

  const downtimeDateEvents: EventSourceInput = downtimeDateEntries.map((d) => ({
    id: d.id.toString(),
    date: d.date,
    groupId: `${d.organizationName} ${format.formatDowntimeType(d.downtimeType)}`,
    title: d.description,
    color: vendorColorMap[d.organizationId],
  }));

  const groupedDowntimeDates = _.groupBy(downtimeDateEntries, 'organizationId');

  const sortedVendors = _.sortBy(vendorOrganizations, 'name');

  const handleMonthChange = (monthDate: Date) => {
    const newCurrentDate = startOfMonth(monthDate);

    setCurrentDate(newCurrentDate);

    handleRefresh(newCurrentDate);
  };

  const handleDeleteDate = async (date: DowntimeDateEntry) => {
    try {
      await confirm({
        allowClose: false,
        title: `Delete downtime date "${date.description}"`,
        description: (
          <>
            The downtime date "{date.description}" occurring on {date.date} will be deleted. Do you
            wish to continue?.
          </>
        ),
      });
    } catch {
      return;
    }

    try {
      const res = await deleteDowntimeDate({ variables: { downtimeDateId: date.id } });

      if (!res.data?.deleteDowntimeDate) throw 'No result';

      enqueueSnackbar(`Downtime date "${date.description}" deleted.`, { variant: 'success' });

      await handleRefresh(currentDate);
    } catch {
      enqueueSnackbar(`An error occurred deleting downtime date "${date.description}".`, {
        variant: 'error',
      });
    }
  };

  const handleSaveDowntimeDate: Parameters<typeof handleSubmit>[0] = async (values) => {
    try {
      const res = await createDowntimeDate({
        variables: {
          input: {
            organizationId: values.organizationId ? Number(values.organizationId) : undefined,
            date: format.formatDate(values.date),
            downtimeType: values.downtimeType as DowntimeType,
            description: values.description,
          },
        },
      });

      if (!res.data?.createDowntimeDate) throw 'No result';

      enqueueSnackbar(`Downtime date "${values.description}" created.`, { variant: 'success' });

      setDowntimeFormOpen(false);

      props.onSave();

      await handleRefresh(currentDate);
    } catch {
      enqueueSnackbar(`An error occurred creating downtime date "${values.description}".`, {
        variant: 'error',
      });
    }
  };

  const handleOpenDowntimeForm = () => {
    resetDowntimeForm();

    setDowntimeFormOpen(true);
  };

  const handleRefresh = async (date: Date) => {
    const startDate = startOfMonth(date);
    const endDate = endOfMonth(date);

    const res = await findDowntimeDates({
      variables: {
        startDate: format.formatDate(startDate),
        endDate: format.formatDate(endDate),
      },
    });

    setDowntimeDates(res.data?.downtimeDates ?? []);
  };

  useEffect(() => {
    handleRefresh(currentDate);
  }, []);

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Production Calendar</Typography>
        <Box>
          <IconFontButton
            icon={sectionOpen ? faChevronCircleUp : faChevronCircleDown}
            onClick={() => setSectionOpen(!sectionOpen)}
          />
        </Box>
      </Box>
      {sectionOpen ? (
        <Box m={1} py={5}>
          <Box display={'flex'} justifyContent={'end'} width="100%" mb={5}>
            <Button
              variant="outlined"
              onClick={handleOpenDowntimeForm}
              disabled={vendorDataLoading}
            >
              Create Downtime
            </Button>
          </Box>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={8}>
              <Calendar
                date={currentDate}
                events={downtimeDateEvents}
                eventContent={(eventInfo) => {
                  const { id, title, groupId } = eventInfo.event;
                  return <DowntimeEventView title={title} description={groupId} id={id} />;
                }}
                onDateChange={handleMonthChange}
              />
            </Grid>
            <Grid item xs={12} lg={4} style={{ paddingTop: 0 }}>
              <Stack gap={2}>
                <Tabs
                  value={currentVendorTabId}
                  onChange={(_, val) => setCurrentVendorTab(val)}
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  <Tab
                    value={DEFAULT_ORGANIZATION_ID}
                    icon={<CircleIcon htmlColor={vendorColorMap[DEFAULT_ORGANIZATION_ID]} />}
                    iconPosition="start"
                    label="general"
                  />
                  {sortedVendors.map((v) => (
                    <Tab
                      value={v.organizationId}
                      key={v.organizationId}
                      icon={<CircleIcon htmlColor={vendorColorMap[v.organizationId]} />}
                      iconPosition="start"
                      label={v.name}
                    />
                  ))}
                </Tabs>

                <List dense>
                  {groupedDowntimeDates[currentVendorTabId]?.map((date, index) => (
                    <React.Fragment key={date.id}>
                      {index !== 0 ? <Divider component="li" /> : null}

                      <ListItem
                        secondaryAction={
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDeleteDate(date)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      >
                        <ListItemAvatar>
                          <ListItemIcon>
                            <CircleIcon htmlColor={vendorColorMap[date.organizationId]} />
                          </ListItemIcon>
                        </ListItemAvatar>
                        <ListItemText primary={date.description} secondary={date.date} />
                      </ListItem>
                    </React.Fragment>
                  ))}
                </List>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      ) : null}

      <Dialog
        open={downtimeFormOpen}
        keepMounted
        onClose={() => setDowntimeFormOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{'Create Downtime Date'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} p={2}>
            <Grid item md={8}>
              <TextFieldRHF
                fullWidth
                control={control as unknown as Control<FieldValues>}
                disabled={isSubmitting}
                name="description"
                label={'Description'}
              />
            </Grid>

            <Grid item md={4}>
              <SelectFieldRHF
                fullWidth
                hideNone
                control={control as unknown as Control<FieldValues>}
                disabled={isSubmitting}
                name="downtimeType"
                label={'Type'}
                menuItems={Object.values(DowntimeType).map((d) => ({
                  key: d,
                  value: format.formatDowntimeType(d),
                }))}
              />
            </Grid>

            <Grid item xs={12}>
              <DatePickerRHF
                control={control as unknown as Control<FieldValues>}
                disabled={isSubmitting}
                name="date"
                label={'Date'}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectFieldRHF
                fullWidth
                control={control as unknown as Control<FieldValues>}
                disabled={isSubmitting}
                name="organizationId"
                label={'Vendor'}
                menuItems={vendorOrganizations.map((vo) => ({
                  key: vo.organizationId.toString(),
                  value: vo.name,
                }))}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDowntimeFormOpen(false)}>Cancel</Button>
          <Button onClick={handleSubmit(handleSaveDowntimeDate)}>Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
