import React from 'react';
import { Box } from '@mui/material';
import { Control, useForm, SubmitHandler } from 'react-hook-form';
import {
  ConfigurationKeyType,
  ConfigurationValueType,
  IConfiguration,
  IConfigurationValueStructure,
  IRosieConfiguration,
  schemas,
} from '@workflow-nx/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { ServicesSectionForm } from './ServicesSectionForm';

export function RosieForm(props: {
  configurationData?: IConfigurationValueStructure[];
  handleSubmit: (formData: IConfiguration) => void;
}) {
  const valueFields = [
    {
      id: 'awsRosieLandmarkingLambdaUrl',
      label: 'AWS Rosie Landmarking Lambda URL',
      type: ConfigurationValueType.Text,
    },
    {
      id: 'awsRosiePlanningLambdaUrl',
      label: 'AWS Rosie Planning Lambda URL',
      type: ConfigurationValueType.Text,
    },
    {
      id: 'awsRosieSQSUrl',
      label: 'AWS Rosie SQS URL',
      type: ConfigurationValueType.Text,
    },
    { id: 'disabled', label: 'Disabled', type: ConfigurationValueType.Boolean },
  ];

  const initialConfigFormData = Object.fromEntries(
    valueFields.map((field) => [
      field.id,
      props.configurationData?.find((config) => config.key === field.id)?.value?.value ??
        (field.type !== ConfigurationValueType.Boolean ? '' : true),
    ]),
  ) as unknown as IRosieConfiguration;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isDirty, dirtyFields },
  } = useForm<IRosieConfiguration>({
    defaultValues: initialConfigFormData ?? undefined,
    resolver: yupResolver(schemas.configurationRosieSchema),
  });

  const handleSubmitForm: SubmitHandler<IRosieConfiguration> = async (data) => {
    const changedValues: IConfigurationValueStructure[] = [];
    const changedFields = props.configurationData ? dirtyFields : data;

    for (const [key] of Object.entries(changedFields)) {
      changedValues.push({
        key,
        value: {
          type: valueFields?.find((field) => field.id === key)?.type ?? ConfigurationValueType.Text,
          value: (data as unknown as Record<string, unknown>)[key],
        },
      });
    }

    props.handleSubmit({ key: ConfigurationKeyType.Rosie, value: changedValues });
  };

  return (
    <Box>
      <form>
        <ServicesSectionForm
          title={'Rosie'}
          fields={valueFields}
          control={control as unknown as Control}
          submitHandler={(evt) => handleSubmit(handleSubmitForm)(evt)}
          disabled={isSubmitting || !isDirty}
        />
      </form>
    </Box>
  );
}
