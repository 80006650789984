import { useQuery } from '@apollo/client';
import { SelectChangeEvent } from '@mui/material';
import { IRegion } from '@workflow-nx/common';
import { sortBy } from 'lodash';
import { useState } from 'react';
import { FIND_REGIONS } from '../gql';
import { Select } from './Select';

export function RegionSelect(props: {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  disabled: boolean;
}) {
  const [menuItems, setMenuItems] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (regionData) => {
      const regions = regionData.regions || [];
      setMenuItems(
        sortBy(
          regions.map((region: IRegion) => {
            return {
              key: region.regionId,
              value: region.name,
            };
          }),
          ['value'],
        ),
      );
    },
  });

  return (
    <Select
      name={'region'}
      label={'Area'}
      value={props.value}
      onChange={props.onChange}
      disabled={props.disabled}
      menuItems={menuItems}
      fullWidth={true}
    />
  );
}
