import { SPINE_PROFILE_CONFIG_MAP } from '../common';
import {
  Axis,
  CaseAnalysisImagingType,
  CaseSpineProfile,
  EndPlate,
  ImagingVertebralBody,
  Position,
  VertebralBody,
} from '../enums';
import { AssetPositionsPoints } from '../interfaces';
import { CaseImagingLandmark } from '../types';
import * as measurements from './measurements';
import { calculateDistanceBetweenPointsAlongAxis } from '@workflow-nx/core-algorithms';
import _ from 'lodash';

export interface SerialImagingLandmark {
  x: number;
  y: number;
  id: string;
}

export const getEndPlatePosId = (endPlate: EndPlate, position: Position): string => {
  let endPlateStr: string;

  switch (endPlate) {
    case EndPlate.Superior:
      endPlateStr = 'S';
      break;
    case EndPlate.Inferior:
      endPlateStr = 'I';
      break;
  }

  let positionStr: string;

  switch (position) {
    case Position.Anterior:
      positionStr = 'A';
      break;
    case Position.Posterior:
      positionStr = 'P';
      break;
    case Position.PatientLeft:
      positionStr = 'L';
      break;
    case Position.PatientRight:
      positionStr = 'R';
      break;
  }

  return `${endPlateStr}${positionStr}`;
};
// NOTE - Should match format returned by Auto-XR
export const getPointId = (
  body: ImagingVertebralBody,
  endPlate: EndPlate,
  position: Position,
): string => {
  return `${body}_${getEndPlatePosId(endPlate, position)}`;
};

export const serializePoint = (data: CaseImagingLandmark): SerialImagingLandmark => {
  const id = getPointId(data.body, data.endPlate, data.position);

  return {
    x: data.x,
    y: data.y,
    id,
  };
};

// NOTE - Should match format returned by Auto-XR
export const deserializePoint = (data: SerialImagingLandmark): CaseImagingLandmark | null => {
  const { x, y, id } = data;

  const parts = id.split('_');

  if (parts.length !== 2) return null;

  const [bodyStr, placement] = parts;

  let body: ImagingVertebralBody | undefined = Object.values(ImagingVertebralBody).find(
    (v) => bodyStr === v,
  );

  if (!body) return null;

  const placementParts = placement.split('');

  if (placementParts.length !== 2) return null;

  const [endPlateStr, positionStr] = placementParts;

  let endPlate: EndPlate | undefined = undefined;

  switch (endPlateStr) {
    case 'S':
      endPlate = EndPlate.Superior;
      break;
    case 'I':
      endPlate = EndPlate.Inferior;
      break;
    default:
      break;
  }

  if (!endPlate) return null;

  let position: Position | undefined = undefined;

  switch (positionStr) {
    case 'A':
      position = Position.Anterior;
      break;
    case 'P':
      position = Position.Posterior;
      break;
    case 'R':
      position = Position.PatientRight;
      break;
    case 'L':
      position = Position.PatientLeft;
      break;
    default:
      break;
  }

  if (!position) return null;

  return {
    x,
    y,
    body,
    endPlate,
    position,
  };
};

export const calibratePixelSpacing = (
  imagingType: CaseAnalysisImagingType,
  assetPositionPoints: AssetPositionsPoints[],
  landmarks: CaseImagingLandmark[],
  spineProfile: CaseSpineProfile,
) => {
  const planMeasurements = measurements.getMeasurementsFromAssetPositions(assetPositionPoints);

  const getKey = (body: VertebralBody, endPlate: EndPlate, position: Position) =>
    `${body}_${endPlate}_${position}`;

  const cyborgMapping = planMeasurements.reduce((prevMap, current) => {
    return {
      ...prevMap,
      [getKey(current.body, current.endPlate, current.position)]: current.point,
    };
  }, {} as Record<string, number[] | undefined>);

  const autoXrMapping = landmarks.reduce((prevMap, current) => {
    return {
      ...prevMap,
      [getKey(current.body as unknown as VertebralBody, current.endPlate, current.position)]: [
        current.x,
        current.y,
      ],
    };
  }, {} as Record<string, number[] | undefined>);

  const getHeights = (body: VertebralBody) => {
    const isAp = imagingType === CaseAnalysisImagingType.AP;

    const positionA = isAp ? Position.PatientRight : Position.Anterior;
    const positionB = isAp ? Position.PatientLeft : Position.Posterior;

    const autoXr_a = autoXrMapping[getKey(body, EndPlate.Superior, positionA)];

    const autoXr_b = autoXrMapping[getKey(body, EndPlate.Superior, positionB)];

    const autoXr_c = autoXrMapping[getKey(body, EndPlate.Inferior, positionA)];

    const autoXr_d = autoXrMapping[getKey(body, EndPlate.Inferior, positionB)];

    const cyborg_a = cyborgMapping[getKey(body, EndPlate.Superior, positionA)];

    const cyborg_b = cyborgMapping[getKey(body, EndPlate.Superior, positionB)];

    const cyborg_c = cyborgMapping[getKey(body, EndPlate.Inferior, positionA)];

    const cyborg_d = cyborgMapping[getKey(body, EndPlate.Inferior, positionB)];

    if (
      !autoXr_a ||
      !autoXr_b ||
      !autoXr_c ||
      !autoXr_d ||
      !cyborg_a ||
      !cyborg_b ||
      !cyborg_c ||
      !cyborg_d
    ) {
      return;
    }

    const autoXr_ab = [
      _.sum([autoXr_a[0], autoXr_b[0]]) / 2,
      _.sum([autoXr_a[1], autoXr_b[1]]) / 2,
    ];

    const autoXr_cd = [
      _.sum([autoXr_c[0], autoXr_d[0]]) / 2,
      _.sum([autoXr_c[1], autoXr_d[1]]) / 2,
    ];

    const autoXrHeight = calculateDistanceBetweenPointsAlongAxis(autoXr_ab, autoXr_cd, Axis.Y);

    const cyborg_ab = isAp
      ? [_.sum([cyborg_a[0], cyborg_b[0]]) / 2, _.sum([cyborg_a[1], cyborg_b[1]]) / 2]
      : [_.sum([cyborg_a[2], cyborg_b[2]]) / 2, _.sum([cyborg_a[1], cyborg_b[1]]) / 2];

    const cyborg_cd = isAp
      ? [_.sum([cyborg_c[0], cyborg_d[0]]) / 2, _.sum([cyborg_c[1], cyborg_d[1]]) / 2]
      : [_.sum([cyborg_c[2], cyborg_d[2]]) / 2, _.sum([cyborg_c[1], cyborg_d[1]]) / 2];

    const cyborgHeight = calculateDistanceBetweenPointsAlongAxis(cyborg_ab, cyborg_cd, Axis.Y);

    return {
      autoXr: autoXrHeight,
      cyborg: cyborgHeight,
    };
  };

  const validVertebralBodies = SPINE_PROFILE_CONFIG_MAP[spineProfile].validVertebralBodies;

  if (!validVertebralBodies.length) {
    return;
  }

  let numBodiesWithHeight = 0;

  let pixelSpacingSum = 0;

  validVertebralBodies.forEach((vBody) => {
    const height = getHeights(vBody);

    if (!height) return;

    const calibratedPixelSpacing = height.cyborg / height.autoXr;

    numBodiesWithHeight += 1;

    pixelSpacingSum += calibratedPixelSpacing;
  });

  if (!numBodiesWithHeight) return 1;

  return pixelSpacingSum / numBodiesWithHeight;
};
