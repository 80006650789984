import React, { useState } from 'react';
import { IImplantPartDetail, ITableHeader, ImplantType, format } from '@workflow-nx/common';
import { Box, Stack, TableBody, TableRow, Tooltip, Typography } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import {
  faPenToSquare,
  faCloudArrowDown,
  faArrowUpRightFromSquare,
} from '@fortawesome/pro-light-svg-icons';
import { TableCell } from '../../../components/TableCell';
import ActionButton from '../../../components/ActionButton';
import { useLazyQuery } from '@apollo/client';
import { EXPORT_IMPLANT_PART_DRAWING } from '../../../gql';
import { CreateImplantPartDetailDialog } from './CreateImplantPartDetailDialog';
import { EditImplantPartDetailDialog } from './EditImplantPartDetailDialog';
import { useSnackbar } from 'notistack';
import { CommonTable } from '../../../components/CommonTable';

export function ImplantPartDetailsTable(props: {
  implantType: ImplantType;
  implantPartDetails: IImplantPartDetail[];
  loading: boolean;
  updateImplantPartDetails: () => void;
}) {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedImplantPartDetail, setSelectedImplantPartDetail] = useState<IImplantPartDetail>();
  const [selectedLoadingRow, setSelectedLoadingRow] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const [exportImplantPartDrawing, { loading: implantPartDrawingLoading }] = useLazyQuery(
    EXPORT_IMPLANT_PART_DRAWING,
  );

  const handleDownloadClick = async (
    fileType: ImplantType | 'FORM_037',
    revisionVersionId: string,
    versionNumber: number,
    id: number,
  ) => {
    try {
      setSelectedLoadingRow(id);
      await exportImplantPartDrawing({
        variables: {
          fileType,
          revisionVersionId,
          versionNumber,
        },
        onCompleted(data) {
          const signedUrl = data?.implantPartDrawing?.signedUrl as string;
          if (signedUrl) {
            window.open(signedUrl, 'implant part drawing');
          } else {
            enqueueSnackbar('An error occurred trying retrieve the corresponding URL', {
              variant: 'error',
            });
          }
        },
        onError(error) {
          console.error(error.message);
          enqueueSnackbar(`An error occurred: ${error.message}`, {
            variant: 'error',
          });
        },
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar('An error occurred trying to download the file', {
        variant: 'error',
      });
    } finally {
      setSelectedLoadingRow(0);
    }
  };

  const headers: ITableHeader[] = [
    {
      id: 'drawingVersion',
      label: 'Drawing Version',
    },
    {
      id: 'drawingNumber',
      label: 'Drawing Number',
    },
    {
      id: 'form037Version',
      label: 'Form-037 Version',
    },
    {
      id: 'onShapeUrl',
      label: 'OnShape URL',
    },
    {
      id: 'activeDate',
      label: 'Active Date',
    },
    {
      id: 'actions',
      label: 'Actions',
    },
  ];

  return (
    <>
      <Box my={3} mx={2}>
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              spacing={2}
              alignItems={'center'}
            >
              <Typography variant={'h4'}>
                {format.formatImplantPartDetailPartType(props.implantType)}
              </Typography>
              <ActionButton variant={'outlined'} onClick={() => setOpenCreateDialog(true)}>
                Add Version
              </ActionButton>
            </Stack>
            <CommonTable
              headers={headers}
              orderBy={{ drawingVersion: 'desc' }}
              rows={
                <TableBody>
                  {props.implantPartDetails.map((implantPartDetail) => (
                    <TableRow key={implantPartDetail.implantPartDetailId}>
                      <TableCell>
                        <Stack
                          direction={'row'}
                          justifyContent={'flex-start'}
                          alignItems={'center'}
                        >
                          <Box maxWidth={100}>
                            <Typography
                              textOverflow={'ellipsis'}
                              overflow={'hidden'}
                              whiteSpace={'nowrap'}
                            >
                              {implantPartDetail.drawingVersionNumber}
                            </Typography>
                          </Box>
                          {implantPartDetail.drawingVersionId ? (
                            <IconFontButton
                              icon={faCloudArrowDown}
                              size={'small'}
                              disabled={
                                props.loading ||
                                !implantPartDetail.implantType ||
                                !implantPartDetail.drawingVersionNumber
                              }
                              onClick={() =>
                                handleDownloadClick(
                                  implantPartDetail.implantType,
                                  implantPartDetail.drawingVersionId,
                                  implantPartDetail.drawingVersionNumber,
                                  implantPartDetail.implantPartDetailId,
                                )
                              }
                              loading={
                                implantPartDrawingLoading &&
                                selectedLoadingRow === implantPartDetail.implantPartDetailId
                              }
                            />
                          ) : null}
                        </Stack>
                      </TableCell>
                      <TableCell>{implantPartDetail.drawingNumber ?? <>&mdash;</>}</TableCell>
                      <TableCell>
                        <Tooltip title={implantPartDetail?.form037VersionId} arrow>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                          >
                            <Box maxWidth={100}>
                              <Typography
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                              >
                                {implantPartDetail.form037VersionNumber}
                              </Typography>
                            </Box>
                            {implantPartDetail.form037VersionId ? (
                              <IconFontButton
                                icon={faCloudArrowDown}
                                size={'small'}
                                onClick={() =>
                                  handleDownloadClick(
                                    'FORM_037',
                                    implantPartDetail?.form037VersionId as string,
                                    implantPartDetail?.form037VersionNumber as number,
                                    implantPartDetail.implantPartDetailId,
                                  )
                                }
                                loading={
                                  implantPartDrawingLoading &&
                                  selectedLoadingRow === implantPartDetail.implantPartDetailId
                                }
                              />
                            ) : null}
                          </Stack>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        <Tooltip title={implantPartDetail?.onShapeUrl} arrow>
                          <Stack
                            direction={'row'}
                            justifyContent={'flex-start'}
                            alignItems={'center'}
                          >
                            {implantPartDetail.onShapeUrl ? (
                              <>
                                <Box maxWidth={100}>
                                  <Typography textOverflow={'ellipsis'} overflow={'hidden'}>
                                    Release {implantPartDetail.onShapeRelease}
                                  </Typography>
                                </Box>
                                <IconFontButton
                                  icon={faArrowUpRightFromSquare}
                                  size={'small'}
                                  onClick={() => {
                                    window.open(implantPartDetail.onShapeUrl, 'onShape');
                                  }}
                                />
                              </>
                            ) : (
                              <>&mdash;</>
                            )}
                          </Stack>
                        </Tooltip>
                      </TableCell>
                      <TableCell>
                        {implantPartDetail?.activeDate ? (
                          format.formatDateTime(implantPartDetail.activeDate, 'MM/dd/yyyy')
                        ) : (
                          <>&mdash;</>
                        )}
                      </TableCell>
                      <TableCell>
                        <IconFontButton
                          icon={faPenToSquare}
                          size={'small'}
                          onClick={() => {
                            setSelectedImplantPartDetail(implantPartDetail);
                            setOpenEditDialog(true);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              }
              loading={props.loading}
            />
          </Stack>
        </Stack>
      </Box>
      <CreateImplantPartDetailDialog
        implantType={props.implantType}
        prevImplantPartDetail={props.implantPartDetails[0]}
        open={openCreateDialog}
        onClose={(shouldUpdate) => {
          if (shouldUpdate) {
            props.updateImplantPartDetails();
          }
          setOpenCreateDialog(false);
        }}
      />
      {selectedImplantPartDetail ? (
        <EditImplantPartDetailDialog
          implantType={props.implantType}
          selectedImplantPartDetail={selectedImplantPartDetail}
          open={openEditDialog}
          onClose={(shouldUpdate) => setOpenEditDialog(false)}
        />
      ) : null}
    </>
  );
}
