import { Alert, Box } from '@mui/material';
import {
  ILevels,
  ISurgeonPreferenceImplant,
  ISurgeonPreferences,
  IUser,
  caseUtils,
  format,
} from '@workflow-nx/common';
import { sortBy } from 'lodash';
import CustomDialog from '../../../../../components/CustomDialog';
import {
  IMPLANT_TYPE_CUSTOM_SORT,
  MEASUREMENT_TYPE_CUSTOM_SORT,
} from '../../../../../utils/surgeonPreferences';
import { ViewSurgeonPreferencesImplant } from './ViewSurgeonPreferencesImplant';
import { ViewSurgeonPreferencesMain } from './ViewSurgeonPreferencesMain';
import { ViewSurgeonPreferencesMeasurement } from './ViewSurgeonPreferencesMeasurement';

export function SurgeonPreferencesMainView(props: {
  caseLevels: ILevels;
  preferences?: ISurgeonPreferences;
}) {
  const sortedSurgeonPreferenceImplants = sortBy(
    props.preferences?.surgeonPreferenceImplants,
    (item) => IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
  );

  const sortedSurgeonPreferenceImplantMeasurements = sortBy(
    props.preferences?.surgeonPreferenceImplantMeasurements,
    (item) => [
      IMPLANT_TYPE_CUSTOM_SORT.indexOf(item.implantType),
      MEASUREMENT_TYPE_CUSTOM_SORT.indexOf(item.measurementType),
    ],
  );

  const foundImplantTypes = caseUtils.getValidImplantTypes(props.caseLevels);

  return (
    <>
      <Box mb={2} />
      {!props.preferences && (
        <Alert severity={'warning'}>The surgeon's preferences have not been set up yet.</Alert>
      )}
      {props.preferences && (
        <Alert severity={'info'}>
          Last updated by: {format.formatName(props.preferences?.updatedByUser)} at{' '}
          {format.formatDateTime(props.preferences?.updatedAt)}
        </Alert>
      )}
      {props.preferences && (
        <Box display={'flex'}>
          <Box width={'100%'}>
            <Box>
              <ViewSurgeonPreferencesMain preferences={props.preferences} />
              {props.preferences?.preferredProcedures?.length ? (
                <>
                  <Box my={3} />
                  <Box>
                    {sortedSurgeonPreferenceImplants?.map(
                      (selectedImplantType: ISurgeonPreferenceImplant) => {
                        return foundImplantTypes?.includes(selectedImplantType?.implantType) ? (
                          <Box mb={4}>
                            <ViewSurgeonPreferencesImplant
                              selectedImplantType={selectedImplantType}
                            />
                            <ViewSurgeonPreferencesMeasurement
                              surgeonPreferenceImplantMeasurements={
                                sortedSurgeonPreferenceImplantMeasurements
                              }
                              implantType={selectedImplantType?.implantType}
                            />
                          </Box>
                        ) : null;
                      },
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
}

export function SurgeonPreferencesViewDialog({
  caseLevels,
  caseNumber,
  surgeonUser,
  preferences,
  onClose,
  open,
}: {
  caseLevels: ILevels;
  caseNumber: string;
  surgeonUser: IUser;
  preferences?: ISurgeonPreferences;
  onClose: () => void;
  open: boolean;
}) {
  return (
    <CustomDialog
      maxWidth={'md'}
      fullHeight={false}
      open={open}
      title={`${format.formatName(surgeonUser)} Preferences - ${caseNumber}`}
      onClose={() => {
        onClose();
      }}
      positiveActionButtons={[]}
    >
      {open ? (
        <SurgeonPreferencesMainView caseLevels={caseLevels} preferences={preferences} />
      ) : null}
    </CustomDialog>
  );
}
