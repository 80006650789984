import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  FIND_LOCATION_AND_REGIONS_AND_USERS,
  FIND_USERS_BY_ROLE,
  UPSERT_LOCATION,
} from '../../../gql';
import CustomDialog from '../../../components/CustomDialog';
import { Grid } from '@mui/material';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import {
  format,
  IRegion,
  ITerritory,
  IUser,
  OrganizationType,
  UserRoleType,
  UserStatusType,
} from '@workflow-nx/common';
import * as Yup from 'yup';
import _ from 'lodash';
import useAuth from '../../../hooks/useAuth';

type LocationFormType = {
  description: string;
  regionId: string;
  territoryId: string;
  associatedUserIds: string[] | undefined;
  externalSyncDataSourceId: string;
};

export function CreateLocationDialog(props: {
  open: boolean;
  organizationId: number;
  organizationType: OrganizationType;
  locationId?: number;
  onClose: (shouldUpdate?: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const { hasRole } = useAuth();
  const [upsertLocation, { loading: loadingUpsertLocation }] = useMutation(UPSERT_LOCATION);
  const [regions, setRegions] = useState<
    {
      key: string;
      value: string;
      territories: any[];
    }[]
  >([]);
  const [territories, setTerritories] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [location, setLocation] = useState<LocationFormType>({
    description: '',
    regionId: '',
    territoryId: '',
    associatedUserIds: [''],
    externalSyncDataSourceId: '',
  });
  const [associatedUsers, setAssociatedUsers] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  const [findLocationAndRegions] = useLazyQuery(FIND_LOCATION_AND_REGIONS_AND_USERS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const regions: {
        key: string;
        value: string;
        territories: any[];
      }[] = (data.regions || []).map((region: IRegion) => {
        const territories = region?.territories?.map((territory: ITerritory) => {
          return { key: territory.territoryId, value: territory.name };
        });

        return { key: region.regionId, value: region.name, territories: territories ?? [] };
      });

      if (data.location) {
        const regionId = data.location?.region?.regionId ?? '';

        const locationUsers =
          data.location?.locationUser && data.location?.locationUser.length
            ? data.location?.locationUser.map((user: { user: IUser }) => {
                return user?.user?.userId;
              })
            : [];

        setLocation({
          ...data.location,
          regionId,
          territoryId: data.location?.territory?.territoryId ?? '',
          associatedUserIds: locationUsers,
          externalSyncDataSourceId: data.location?.externalSyncData?.sourceId ?? '',
        });

        const region = regions.find((region) => region.key.toString() === regionId.toString());
        setTerritories(region?.territories || []);
      }
      setRegions(regions);
    },
  });

  useQuery(FIND_USERS_BY_ROLE, {
    variables: {
      roleFilter: [UserRoleType.CustomerRep, UserRoleType.CustomerRepManager],
      statusFilter: [UserStatusType.Active],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const users: {
        key: string;
        value: string;
      }[] = (data?.users?.users || []).map((user: IUser) => {
        return {
          key: user.userId,
          value: format.formatName({ firstName: user.firstName, lastName: user.lastName }),
        };
      });

      const sortedUsers = _.sortBy(users, 'value');
      setAssociatedUsers(sortedUsers);
    },
  });

  const handleSubmitForm = async (
    values: LocationFormType,
    { setStatus, setSubmitting, resetForm }: FormikHelpers<LocationFormType>,
  ) => {
    try {
      const regionId = Number(values.regionId);
      const territoryId = Number(values.territoryId);
      const userIds = values.associatedUserIds?.filter((id) => Number(id));

      await upsertLocation({
        variables: {
          description: values.description,
          regionId: regionId || undefined,
          territoryId: territoryId || undefined,
          organizationId: Number(props.organizationId),
          locationId: Number(props.locationId),
          associatedUserIds: userIds ?? undefined,
          externalSyncDataSourceId: values.externalSyncDataSourceId,
        },
      });

      setStatus({ success: true });
      enqueueSnackbar(`Location ${props.locationId ? 'Updated' : 'Created'}`, {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err) {
      console.error(err);
      setStatus({ success: false });
      enqueueSnackbar('An error occurred creating the location', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
      resetForm();
    }
  };

  useEffect(() => {
    findLocationAndRegions({
      variables: {
        locationId: props.locationId || 0,
        roleFilter: [UserRoleType.CustomerRep, UserRoleType.CustomerRepManager],
      },
    });
  }, []);

  let dialogTitle = '';
  if (props.locationId) {
    dialogTitle = `Edit ${location.description}`;
  } else {
    if (props.organizationType === OrganizationType.Hospital) {
      dialogTitle = 'Create Hospital';
    } else {
      dialogTitle = 'Create Location';
    }
  }

  return (
    <Formik
      initialValues={location}
      enableReinitialize={true}
      validationSchema={Yup.object().shape({
        description: Yup.string().required('Description is required'),
        regionId: Yup.number().nullable(),
      })}
      onSubmit={handleSubmitForm}
    >
      {({ setFieldValue, resetForm, submitForm, isSubmitting }) => {
        const loading = loadingUpsertLocation;

        return (
          <CustomDialog
            maxWidth={'sm'}
            open={props.open}
            title={dialogTitle}
            onClose={() => {
              resetForm();
              props.onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || loading}
                onClick={() => submitForm()}
                label={props.locationId ? 'Update' : 'Create'}
                loading={isSubmitting || loading}
              />,
            ]}
          >
            <form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField name={'description'} label={'Description'} disabled={isSubmitting} />
                </Grid>
                {props.organizationType === OrganizationType.Hospital ? (
                  <>
                    <Grid item xs={12} md={6}>
                      <SelectField
                        name={'regionId'}
                        label={'Area'}
                        onChange={(event) => {
                          const regionId = event.target.value;
                          const region = regions.find((region) => region.key === regionId);

                          setTerritories(region?.territories || []);
                          setFieldValue('territoryId', '');
                        }}
                        menuItems={regions}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SelectField
                        name={'territoryId'}
                        label={'Region'}
                        menuItems={territories}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <SelectField
                        name={'associatedUserIds'}
                        label={'Associated Customer Reps'}
                        multiple={true}
                        hideNone={true}
                        onChange={(event) => {
                          setFieldValue('associatedUserIds', event.target.value);
                        }}
                        menuItems={associatedUsers}
                        disabled={false}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid item xs={12} md={12}>
                      {hasRole?.([UserRoleType.SiteAdministrator]) ? (
                        <TextField
                          name={'externalSyncDataSourceId'}
                          label={'External Sync Data Source Id'}
                          disabled={isSubmitting}
                        />
                      ) : null}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </form>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
