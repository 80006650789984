import { projectVectorOntoPlane } from '@workflow-nx/core-algorithms';
import * as BABYLON from 'babylonjs';
import {
  BabylonSceneManager,
  LoadedSpineConfig,
  OperationEntry,
  SpineAssetTags,
  Vector3,
} from './BabylonScene';
import {
  AssetType,
  CaseSpineProfile,
  CaseSpineType,
  IAsset,
  PlanAssetColorType,
  VertebralBody,
} from '@workflow-nx/common';

export const getMidpoint = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: (v1.x + v2.x) / 2,
    y: (v1.y + v2.y) / 2,
    z: (v1.z + v2.z) / 2,
  };
};

export const normalize = (v: Vector3): Vector3 => {
  const babylonVector = new BABYLON.Vector3(v.x, v.y, v.z);

  const normalized = BABYLON.Vector3.Normalize(babylonVector);

  return {
    x: normalized.x,
    y: normalized.y,
    z: normalized.z,
  };
};

export const cross = (v1: Vector3, v2: Vector3): Vector3 => {
  const babylonVector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const babylonVector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  const cross = BABYLON.Vector3.Cross(babylonVector1, babylonVector2);

  return {
    x: cross.x,
    y: cross.y,
    z: cross.z,
  };
};

export const dot = (v1: Vector3, v2: Vector3): number => {
  const babylonVector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const babylonVector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  const dot = BABYLON.Vector3.Dot(babylonVector1, babylonVector2);

  return dot;
};

export const subtract = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: v1.x - v2.x,
    y: v1.y - v2.y,
    z: v1.z - v2.z,
  };
};

export const multiply = (v: Vector3, value: number): Vector3 => {
  return {
    x: v.x * value,
    y: v.y * value,
    z: v.z * value,
  };
};

export const add = (v1: Vector3, v2: Vector3): Vector3 => {
  return {
    x: v1.x + v2.x,
    y: v1.y + v2.y,
    z: v1.z + v2.z,
  };
};

export const divide = (v: Vector3, value: number): Vector3 => {
  return {
    x: v.x / value,
    y: v.y / value,
    z: v.z / value,
  };
};

export const projectVectorToPlane = (v: Vector3, normal: Vector3): Vector3 => {
  const babylonV = new BABYLON.Vector3(v.x, v.y, v.z);

  const babylonNormal = new BABYLON.Vector3(normal.x, normal.y, normal.z);

  const plane = BABYLON.Plane.FromPositionAndNormal(BABYLON.Vector3.Zero(), babylonNormal);

  const proj = projectVectorOntoPlane(babylonV, plane);

  return {
    x: proj.x,
    y: proj.y,
    z: proj.z,
  };
};

export const findLateralPlaneAngle = (v1: Vector3, v2: Vector3): number => {
  const vector1 = new BABYLON.Vector3(v1.x, v1.y, v1.z);
  const vector2 = new BABYLON.Vector3(v2.x, v2.y, v2.z);

  const normalizedVector1 = vector1.normalize();
  const normalizedVector2 = vector2.normalize();

  // Calculate the dot product
  const dotProduct = BABYLON.Vector3.Dot(normalizedVector1, normalizedVector2);

  // Calculate the angle in radians
  const angleInRadians = Math.acos(dotProduct);

  // Convert the angle to degrees
  const angleInDegrees = BABYLON.Tools.ToDegrees(angleInRadians);

  return angleInDegrees;
};

export async function getScreenshots(
  offscreenSceneManager: BabylonSceneManager,
  operations?: OperationEntry[],
  generateBothLateralOrientations = false,
): Promise<{ assetType: AssetType; blob: Blob }[]> {
  const screenshots: { assetType: AssetType; blob: Blob }[] = [];

  try {
    if (!offscreenSceneManager) {
      return screenshots;
    }
    const planMeshes = offscreenSceneManager.getMeshesByTags('PLAN');
    const preopMeshes = offscreenSceneManager.getMeshesByTags('PREOP');

    offscreenSceneManager.updateMeshes(
      planMeshes.map((value) => ({
        meshId: value.mesh,
        config: { isEnabled: true, isVisible: true, opacity: 1, excludeAnnotations: true },
      })),
    );

    offscreenSceneManager.updateMeshes(
      preopMeshes.map((value) => ({
        meshId: value.mesh,
        config: { isEnabled: false, isVisible: false, opacity: 0, excludeAnnotations: true },
      })),
    );

    if (operations) {
      offscreenSceneManager.resetOperations();
      offscreenSceneManager.addOperations(operations);
    }

    const desiredWidth = 500;
    const desiredHeight = 750;
    const coronalDataPlan = await offscreenSceneManager.createScreenshot(
      desiredWidth,
      desiredHeight,
      'CORONAL',
    );
    if (coronalDataPlan) {
      screenshots.push({ assetType: AssetType.PlanCoronalImage, blob: coronalDataPlan });
    }

    const sagittalDataPlan = await offscreenSceneManager.createScreenshot(
      desiredWidth,
      desiredHeight,
      'SAGITTAL_LEFT',
    );
    if (sagittalDataPlan) {
      screenshots.push({ assetType: AssetType.PlanLateralImage, blob: sagittalDataPlan });
    }

    if (generateBothLateralOrientations) {
      const sagittalDataPlan = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'SAGITTAL_RIGHT',
      );
      if (sagittalDataPlan) {
        screenshots.push({
          assetType: AssetType.PlanLateralImagePatientRight,
          blob: sagittalDataPlan,
        });
      }
    }

    offscreenSceneManager.updateMeshes(
      planMeshes.map((value) => ({
        meshId: value.mesh,
        config: { isEnabled: false, isVisible: false, opacity: 0, excludeAnnotations: true },
      })),
    );

    offscreenSceneManager.updateMeshes(
      preopMeshes.map((value) => ({
        meshId: value.mesh,
        config: { isEnabled: true, isVisible: true, opacity: 1, excludeAnnotations: true },
      })),
    );

    // create a dummy screenshot to resolve the issue with black vertebrae showing up
    await offscreenSceneManager.createScreenshot(desiredWidth, desiredHeight, 'CORONAL');

    if (preopMeshes && preopMeshes.length) {
      const coronalDataPreop = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'CORONAL',
      );
      if (coronalDataPreop) {
        screenshots.push({ assetType: AssetType.PreopCoronalImage, blob: coronalDataPreop });
      }

      const sagittalDataPreop = await offscreenSceneManager.createScreenshot(
        desiredWidth,
        desiredHeight,
        'SAGITTAL_LEFT',
      );
      if (sagittalDataPreop) {
        screenshots.push({ assetType: AssetType.PreopLateralImage, blob: sagittalDataPreop });
      }

      if (generateBothLateralOrientations) {
        const sagittalDataPreop = await offscreenSceneManager.createScreenshot(
          desiredWidth,
          desiredHeight,
          'SAGITTAL_RIGHT',
        );
        if (sagittalDataPreop) {
          screenshots.push({
            assetType: AssetType.PreopLateralImagePatientRight,
            blob: sagittalDataPreop,
          });
        }
      }
    }
  } catch (e) {
    console.error(e);
  }

  return screenshots;
}

export async function loadOffscreenSpine(
  preopAssets: IAsset[],
  planAssets: IAsset[],
  activeCase: { spineProfile: CaseSpineProfile; spineType: CaseSpineType },
): Promise<BabylonSceneManager> {
  const spineAssets: LoadedSpineConfig[] = [];

  if (preopAssets && preopAssets.length) {
    const preopDaisyAssets = preopAssets.filter(
      (asset: IAsset) => !asset.assetType.endsWith('_DAISY'),
    );

    for (const asset of preopDaisyAssets) {
      if (asset.signedDownloadUrl) {
        spineAssets.push({
          assetType: asset.assetType,
          assetUrl: asset.signedDownloadUrl,
          tag: SpineAssetTags.PreOp,
          otherTags: [SpineAssetTags.Vertebrae],
          opacity: 0,
        });
      }
    }
  }

  if (planAssets && planAssets.length) {
    for (const asset of planAssets) {
      if (asset.signedDownloadUrl) {
        const name = asset.assetType.replace('_APP', '').replace('_IMPLANT_SCREW', '');

        let type: SpineAssetTags.Vertebrae | SpineAssetTags.Implant | SpineAssetTags.Screws =
          asset.assetType.endsWith('_APP') ? SpineAssetTags.Implant : SpineAssetTags.Vertebrae;

        if (asset.assetType.endsWith('_IMPLANT_SCREW')) {
          type = SpineAssetTags.Screws;
        }

        // only include vertebrae for now
        if (type !== SpineAssetTags.Vertebrae) {
          continue;
        }

        spineAssets.push({
          assetType: name as AssetType,
          assetUrl: asset.signedDownloadUrl,
          tag: SpineAssetTags.Plan,
          otherTags: [type],
          opacity: 1,
        });
      }
    }
  }

  const offscreenCanvas = document.createElement('canvas');

  const offscreenSceneManager = new BabylonSceneManager(offscreenCanvas, {
    debug: false,
  });
  offscreenSceneManager.start();

  await offscreenSceneManager.loadSpine(
    activeCase?.spineProfile ?? CaseSpineProfile.LumbarStandard,
    spineAssets,
    {
      PREOP: PlanAssetColorType.PreopVertebrae,
      PLAN: PlanAssetColorType.PlanVertebrae,
    },
  );
  const defaultCameraTarget =
    activeCase.spineType === CaseSpineType.Lumbar ? VertebralBody.L3 : VertebralBody.C4;

  await offscreenSceneManager.zoom({
    cameraView: 'CORONAL',
    distance: 300,
    target: {
      meshName: defaultCameraTarget,
      tags: [SpineAssetTags.Plan, SpineAssetTags.Vertebrae],
    },
    disableAnimation: true,
  });

  return offscreenSceneManager;
}
