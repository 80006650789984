import { faFile } from '@fortawesome/pro-light-svg-icons';
import { faFileEdit } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Stack, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IAsset, ValidFileExtensions, format } from '@workflow-nx/common';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { IconFontButton } from './IconFontButton';
import { blue } from '@mui/material/colors';

const useDropzoneStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
  },
  dragActive: {
    backgroundColor: theme.palette.divider,
    opacity: 0.1,
  },
}));

export const GenericFileDropzone = (props: {
  validFileExtensions: ValidFileExtensions[];
  onFileSelect: (file: File) => void;
  variant?: 'small' | 'medium';
  existingFile?: IAsset;
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const styles = useDropzoneStyles();

  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        if (fileRejections.length > 0) {
          const errorMessage = fileRejections[0].errors[0].message;
          enqueueSnackbar(`Error opening file. ${errorMessage}`, {
            variant: 'error',
          });
          return;
        }

        if (acceptedFiles.length === 0) {
          enqueueSnackbar(`Error opening file. No files were accepted.`, {
            variant: 'error',
          });
          return;
        }

        const allFilesHaveSize = acceptedFiles.every((f) => f.size !== 0);

        if (!allFilesHaveSize) {
          enqueueSnackbar(`Error opening file. File has a size of zero bytes.`, {
            variant: 'error',
          });
          return;
        }

        const acceptedFile = acceptedFiles[0];

        props.onFileSelect(acceptedFile);
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error opening file', {
          variant: 'error',
        });
      } finally {
        // empty block
      }
    },
    [enqueueSnackbar, props],
  );
  const acceptedFileTypes = props.validFileExtensions.join(',');

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: handleDrop,
    accept: acceptedFileTypes,
  });

  if (props.existingFile) {
    return (
      <Paper elevation={4}>
        <Box
          display={'flex'}
          alignItems={'center'}
          alignContent={'center'}
          justifyContent={'space-between'}
          py={1}
          px={3}
        >
          <Stack direction={'row'} alignItems={'center'}>
            <FontAwesomeIcon icon={faFile} size="lg" />

            <Stack ml={2}>
              <Typography>{`${props.existingFile?.fileName ?? '[Unknown]'}`}</Typography>
              <Typography color={'textSecondary'} variant={'caption'}>
                Updated{' '}
                {props.existingFile?.updatedAt
                  ? format.formatDateTime(props.existingFile?.updatedAt)
                  : 'Unknown Date'}{' '}
                by {format.formatName({ ...props.existingFile?.updatedByUser })}
              </Typography>
            </Stack>
          </Stack>

          <Box {...getRootProps()}>
            <input {...getInputProps()} />
            <IconFontButton icon={faFileEdit} size="small" fontColor={blue[500]} />
          </Box>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Box
        className={clsx({
          [styles.gridCell]: true,
          [styles.dragActive]: isDragActive,
        })}
        {...getRootProps()}
        border="1px dashed gray"
        p={props.variant === 'small' ? 1 : 5}
      >
        <Box gridColumn={'span 4'} className={styles.gridCell}>
          <Box
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            height={props.variant === 'small' ? 25 : 50}
          >
            <input {...getInputProps()} />
            <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
              {acceptedFileTypes.length > 0 ? (
                <>{`Drop a file (${acceptedFileTypes}) here or click to browse.`.toUpperCase()}</>
              ) : (
                <>{`Drop any file here or click to browse.`.toUpperCase()}</>
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
};
