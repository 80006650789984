import { Box, Chip, Skeleton, Stack, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useReducer, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AssetType,
  IAsset,
  IPatient,
  IPatientRecord,
  ValidFileExtensions,
  caseUtils,
} from '@workflow-nx/common';
import ActionButton from '../../../../components/ActionButton';
import { EditPatientPelvisSettingsDialog } from './EditPatientPelvisSettingsDialog';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import { useSnackbar } from 'notistack';

type State = {
  pelvisAsset: IAsset | null;
};

type Action = {
  type: 'ASSET_REPLACED' | 'ASSET_DELETED' | 'ASSET_UPLOADED' | 'INIT';
  data: IAsset | null;
};

const pelvisAssetReducer = (state: State, action: Action): State => {
  let updatedState = state;

  switch (action.type) {
    case 'ASSET_REPLACED': {
      const replacedAsset = action.data;
      let replacedPelvisAsset = JSON.parse(JSON.stringify(state.pelvisAsset));

      replacedPelvisAsset = replacedPelvisAsset?.filter(
        (ca: IAsset) => ca.assetType !== replacedAsset?.assetType,
      );
      replacedPelvisAsset.push(replacedAsset);

      updatedState = {
        pelvisAsset: replacedPelvisAsset,
      };
      break;
    }
    case 'ASSET_DELETED': {
      const deletedAsset = action.data;
      let updatedPelvisAsset = JSON.parse(JSON.stringify(state.pelvisAsset));

      updatedPelvisAsset = updatedPelvisAsset?.filter(
        (ca: IAsset) => ca.assetId !== deletedAsset?.assetId,
      );

      updatedState = {
        pelvisAsset: updatedPelvisAsset,
      };

      break;
    }
    case 'ASSET_UPLOADED': {
      const updatedPelvisAsset = JSON.parse(JSON.stringify(state.pelvisAsset));
      const createdAsset = action.data;

      updatedPelvisAsset.push(createdAsset);

      updatedState = {
        pelvisAsset: updatedPelvisAsset,
      };
      break;
    }
    case 'INIT': {
      const pelvisAsset = action?.data ?? null;

      updatedState = {
        pelvisAsset,
      };
      break;
    }
    default:
      throw new Error();
  }
  return updatedState;
};

export function PatientPelvisSettingsView(props: {
  caseId: number;
  patientId: number;
  onUpdate: (patientRecord: IPatientRecord) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const [patient, setPatient] = useState<IPatient>();
  const [patientRecord, setPatientRecord] = useState<IPatientRecord>();
  const [pelvisAsset, setPelvisAsset] = useState<IAsset>();
  const [showEditPatientPelvisSettingsDialog, setShowEditPatientPelvisSettingsDialog] =
    useState(false);
  const [canGeneratePelvis, setCanGeneratePelvis] = useState(false);

  const [exportGeneratedPelvis, { loading: loadingExportGeneratedPelvis }] = useMutation(gql`
    mutation ExportGeneratedPelvis($caseId: Int!) {
      exportGeneratedPelvis(caseId: $caseId) {
        result
      }
    }
  `);

  const { loading: loadingFindPelvisSettings, refetch } = useQuery(
    gql`
      query FindPelvisSettings($caseId: Int!, $patientId: Int!, $assetTypeFilter: [AssetType]) {
        assets(caseId: $caseId, assetTypeFilter: $assetTypeFilter) {
          assetId
          assetType
          caseId
          createdByUser {
            userId
            firstName
            lastName
          }
          createdAt
          fileName
          metadata
          planId
          size
          updatedByUser {
            userId
            firstName
            lastName
          }
          updatedAt
          deletedAt
          version
        }
        patient(patientId: $patientId) {
          patientRecord {
            pelvicIncidence
            pelvicTilt
            crestHeight
            s1AnteriorPubisLength
            s1AnteriorPubisAngle
            biFemoralAxisLength
            biFemoralAxisMidpointS1EndplateMidpoint
            biIliacWidth
            posteriorIliacCrestWidth
            coronalTilt
            posteriorCrestShift
            hasPelvisHighPelvicIncidence
            hasPelvisHighCrest
            hasPelvisOblique
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        caseId: props.caseId,
        patientId: props.patientId,
        assetTypeFilter: [AssetType.Pelvis],
      },
      onCompleted: (data) => {
        setPelvisAsset(data.assets?.[0]);
        setPatient(data.patient);
        setPatientRecord(data.patient.patientRecord);
        setCanGeneratePelvis(caseUtils.canGeneratePelvis(data.patient.patientRecord));
      },
    },
  );

  const [, dispatch] = useReducer(pelvisAssetReducer, {
    pelvisAsset: null,
  });

  async function handleGeneratePelvis() {
    try {
      await exportGeneratedPelvis({ variables: { caseId: props.caseId } });

      await refetch();

      enqueueSnackbar('Pelvis generated successfully', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('An error occurred generating the pelvis', {
        variant: 'error',
      });

      console.error(e);
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: '5px',
        alignItems: 'center',
        mb: 2,
        p: 2,
        border: `1px solid ${grey[300]}`,
      }}
    >
      <Stack gap={2} sx={{ width: '100%' }}>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant={'h4'}>Patient Pelvis Settings</Typography>
          <Box flexGrow={1} />
          <ActionButton
            variant={'outlined'}
            disabled={loadingExportGeneratedPelvis || loadingFindPelvisSettings}
            onClick={() => setShowEditPatientPelvisSettingsDialog(true)}
          >
            <Typography
              color={!loadingExportGeneratedPelvis ? 'secondary' : 'textSecondary'}
              variant={'button'}
              noWrap={true}
            >
              Edit Pelvis Settings
            </Typography>
          </ActionButton>
        </Stack>
        <Stack direction={'row'} gap={1} alignItems={'center'}>
          <Chip
            label={'High PI'}
            color={patientRecord?.hasPelvisHighPelvicIncidence ? 'primary' : undefined}
          />
          <Chip
            label={'High Crest'}
            color={patientRecord?.hasPelvisHighCrest ? 'primary' : undefined}
          />
          <Chip label={'Oblique'} color={patientRecord?.hasPelvisOblique ? 'primary' : undefined} />
          <ActionButton
            disabled={!canGeneratePelvis || loadingFindPelvisSettings}
            variant={'outlined'}
            loading={loadingExportGeneratedPelvis}
            onClick={handleGeneratePelvis}
          >
            <Typography
              color={
                canGeneratePelvis && !loadingExportGeneratedPelvis ? 'secondary' : 'textSecondary'
              }
              variant={'button'}
              noWrap={true}
            >
              Generate Pelvis
            </Typography>
          </ActionButton>
        </Stack>
        <Stack>
          {!loadingExportGeneratedPelvis ? (
            <AssetGridTable
              dispatch={dispatch}
              caseId={props?.caseId}
              assets={pelvisAsset ? [pelvisAsset] : []}
              allowReplace={true}
              validFileExtensions={[ValidFileExtensions.STL]}
              validAssets={[AssetType.Pelvis]}
            />
          ) : (
            <Skeleton height={110} width={'100%'} />
          )}
        </Stack>
      </Stack>

      <EditPatientPelvisSettingsDialog
        patientId={props.patientId}
        patientGender={patient?.gender as 'MALE' | 'FEMALE'}
        patientRecord={patientRecord}
        open={showEditPatientPelvisSettingsDialog}
        onClose={() => setShowEditPatientPelvisSettingsDialog(false)}
        onEdit={async () => {
          await refetch();
          setShowEditPatientPelvisSettingsDialog(false);
          if (patientRecord) {
            props.onUpdate(patientRecord);
          }
        }}
      />
    </Box>
  );
}
