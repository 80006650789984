import * as math from '@workflow-nx/math';
import * as babylon from 'babylonjs';
import {
  CloudDesignQueueType,
  EndPlate,
  LevelSize,
  LevelType,
  PartType,
  Position,
  VertebralBody,
  IMeasure,
  IMeasurementPointValues,
  IPlanImplant,
  caseUtils,
  ImplantReferencePoints,
  PlanVersionType,
} from '@workflow-nx/common';
import { NtopConfiguration } from './nTopUtils';

const getAcdfTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  bulletAngle: number,
  taperHeight: number,
  threadHeight: number,
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: bulletAngle,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: taperHeight,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: threadHeight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
  ],
  title: 'Aprevo ACDF CL_Cut',
});

const getAcdfXTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  bulletAngle: number,
  taperHeight: number,
  threadHeight: number,
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: bulletAngle,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: taperHeight,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: threadHeight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
  ],
  title: 'Aprevo ACDF_X CL_Cut',
});

const getAlifTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  bulletAngle: number,
  taperHeight: number,
  threadHeight: number,
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: bulletAngle,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: taperHeight,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: threadHeight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'Aprevo ALIF CL_Cut',
});

const getAlifXTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  bulletAngle: number,
  taperHeight: number,
  threadHeight: number,
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'Taper Angle',
      type: 'real',
      units: 'deg',
      value: bulletAngle,
    },
    {
      description: '',
      name: 'Bullet Height',
      type: 'real',
      units: 'mm',
      value: taperHeight,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: threadHeight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'Aprevo ALIF/X CL_Cut',
});

const getLlifTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  leftBulletAngle: number,
  leftTaperHeight: number,
  rightBulletAngle: number,
  rightTaperHeight: number,
  threadHeight: number,
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'Right Bullet Angle',
      type: 'real',
      units: 'deg',
      value: rightBulletAngle,
    },
    {
      description: '',
      name: 'Right Bullet Height',
      type: 'real',
      units: 'mm',
      value: rightTaperHeight,
    },
    {
      description: '',
      name: 'Left Bullet Angle',
      type: 'real',
      units: 'deg',
      value: leftBulletAngle,
    },
    {
      description: '',
      name: 'Left Bullet Height',
      type: 'real',
      units: 'mm',
      value: leftTaperHeight,
    },
    {
      description: '',
      name: 'Translate Thread Z',
      type: 'real',
      units: 'mm',
      value: threadHeight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'LLIF CL_Cut',
});

const getTlifOTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'TLIF/O CL_Cut',
});

const getTlifCTemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'TLIF/C CL_Cut',
});

const getTlifCATemplateConfiguration = (
  folder: string,
  implantName: string,
  parasolidName: string,
  centroid: number[],
  posterior: number[],
  anterior: number[],
  patientLeft: number[],
  patientRight: number[],
  implantSize: number,
  onShapePartUrl: string,
): NtopConfiguration => ({
  description: '',
  inputs: [
    {
      description: '',
      name: 'Case Directory',
      type: 'text',
      value: folder,
    },
    {
      description: '',
      name: 'Implant Name',
      type: 'text',
      value: `${implantName}`,
    },
    {
      description: '',
      name: 'Parasolid Name',
      type: 'text',
      value: `${parasolidName}`,
    },
    {
      description: '',
      name: 'Implant Rotation (cor, sag, axial)',
      type: 'vector',
      units: 'rad',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Translate Implant (local XYZ)',
      type: 'vector',
      units: 'mm',
      value: [0.0, 0.0, 0.0],
    },
    {
      description: '',
      name: 'Implant Centroid',
      type: 'vector',
      units: 'mm',
      value: centroid,
    },
    {
      description: '',
      name: 'Implant Posterior',
      type: 'vector',
      units: 'mm',
      value: posterior,
    },
    {
      description: '',
      name: 'Implant Anterior',
      type: 'vector',
      units: 'mm',
      value: anterior,
    },
    {
      description: '',
      name: 'Implant Left',
      type: 'vector',
      units: 'mm',
      value: patientLeft,
    },
    {
      description: '',
      name: 'Implant Right',
      type: 'vector',
      units: 'mm',
      value: patientRight,
    },
    {
      description: '',
      name: 'implant size',
      type: 'real',
      units: 'mm',
      value: implantSize,
    },
    /*
    {
      description: '',
      name: 'onShapePartUrl',
      type: 'text',
      value: onShapePartUrl,
    },
*/
  ],
  title: 'TLIF/CA CL_Cut',
});

export function getImplantSize(size: LevelSize, plusLevelSize: number) {
  let implantSizeValue = 0; // plan
  switch (size) {
    case LevelSize.Plus:
      // default is 2 (for 2mm) bigger than the plan size
      // the implant will grow (plusLevelSize / 2) in
      // each direction
      implantSizeValue = -1 * (plusLevelSize / 2);
      break;
    case LevelSize.Minus:
      implantSizeValue = 0.5;
      break;
  }
  return implantSizeValue;
}

export function getSizeAdjustedBulletHeight(
  bulletHeight: number,
  size: LevelSize,
  plusLevelSize: number,
) {
  let adjustedBulletHeight = bulletHeight; // plan
  switch (size) {
    case LevelSize.Plus:
      // default is 2 (for 2mm) bigger than the plan size
      adjustedBulletHeight = bulletHeight + plusLevelSize;
      break;
    case LevelSize.Minus:
      adjustedBulletHeight = bulletHeight - 1;
      break;
  }
  return adjustedBulletHeight;
}

function vectorFromArray(point?: number[]): babylon.Vector3 {
  if (!point) {
    return babylon.Vector3.Zero();
  }
  return new babylon.Vector3(point[0], point[1], point[2]);
}

export const getImplantSizeName = (levelSize: LevelSize) => {
  return `${levelSize === LevelSize.Normal ? 'plan' : levelSize.toLowerCase()}`;
};

export const getImplantSizeNumber = (levelSize: LevelSize) => {
  switch (levelSize) {
    case LevelSize.Minus:
      return '01';
    case LevelSize.Normal:
      return '02';
    case LevelSize.Plus:
      return '03';
  }
};

export const getConfiguration = (
  caseNumber: string,
  level: LevelType,
  levelSize: LevelSize,
  plusLevelSize: number,
  planImplant: IPlanImplant,
  onShapePartUrl: string,
): NtopConfiguration => {
  /*
    Inherent OnShape differences handled on nTop
    ALIF requires -z axial final rotation
    TLIFO, TLIFC and TLIFCA require 35 degree offset
    Babylon is left-handed system and nTop right-handed
    Swapping of z and y positional points required for centroid plane
  */

  const [child, parent]: string[] = level.split('_');
  const partTypeCode: string = caseUtils.getPartTypeCode(planImplant.partType);
  const threadedSide = planImplant?.bullet?.threadedSide;
  const insertionSide = planImplant?.bullet?.insertionSide;

  // after threadHeight implant size is
  const implantSizeValue = getImplantSize(levelSize, plusLevelSize);

  // centroid plane position
  const {
    centroidPosition,
    anteriorPosition,
    posteriorPosition,
    patientRightPosition,
    patientLeftPosition,
  } = planImplant.referencePoints as ImplantReferencePoints;

  const implantSizeNumber = getImplantSizeNumber(levelSize);
  const implantSizeName = getImplantSizeName(levelSize);
  const implantName = `${caseNumber}_${partTypeCode}.${child}${parent}.${implantSizeNumber}_Rev0`;
  const folder = `C:\\Users\\Administrator\\project\\implant_level`;

  let configuration: any = '';
  // Please reference TP-058 Cloud Design Validation
  const centroid = [centroidPosition.x, centroidPosition.z, centroidPosition.y];
  const posterior = [posteriorPosition.x, posteriorPosition.z, posteriorPosition.y];
  const anterior = [anteriorPosition.x, anteriorPosition.z, anteriorPosition.y];
  const patientLeft = [patientLeftPosition.x, patientLeftPosition.z, patientLeftPosition.y];
  const patientRight = [patientRightPosition.x, patientRightPosition.z, patientRightPosition.y];
  const threadHeight = planImplant?.threadHeight ?? 0;

  switch (planImplant.partType) {
    case PartType.ACDF:
      configuration = getAcdfTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.ACDF_X_LEFT_UP:
      configuration = getAcdfXTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.ALIF:
      configuration = getAlifTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      configuration = getAlifXTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.LLIF_LEFT:
      configuration = getLlifTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        threadedSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(threadedSide?.height ?? 0, levelSize, plusLevelSize),
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.LLIF_RIGHT:
      configuration = getLlifTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        insertionSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(insertionSide?.height ?? 0, levelSize, plusLevelSize),
        threadedSide?.angle ?? 0,
        getSizeAdjustedBulletHeight(threadedSide?.height ?? 0, levelSize, plusLevelSize),
        threadHeight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.TLIFO_LEFT:
    case PartType.TLIFO_RIGHT:
      configuration = getTlifOTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
      configuration = getTlifCTemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
      configuration = getTlifCATemplateConfiguration(
        folder,
        implantName,
        implantSizeName,
        centroid,
        posterior,
        anterior,
        patientLeft,
        patientRight,
        implantSizeValue,
        onShapePartUrl,
      );
      break;
    default:
      break;
  }

  return configuration;
};

export const getNTopMeasurementsForExport = (
  superior: VertebralBody,
  inferior: VertebralBody,
  measurements: IMeasure[],
  measurementPointValues: IMeasurementPointValues,
): string[][] => {
  const rows = [];
  const supA = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Anterior,
  );
  const supP = measurements.find(
    (m) =>
      m.body === superior && m.endPlate === EndPlate.Superior && m.position === Position.Posterior,
  );
  const supPR = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientRight,
  );
  const supPL = measurements.find(
    (m) =>
      m.body === superior &&
      m.endPlate === EndPlate.Superior &&
      m.position === Position.PatientLeft,
  );
  const infA = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Anterior,
  );
  const infP = measurements.find(
    (m) =>
      m.body === inferior && m.endPlate === EndPlate.Inferior && m.position === Position.Posterior,
  );
  const infPR = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientRight,
  );
  const infPL = measurements.find(
    (m) =>
      m.body === inferior &&
      m.endPlate === EndPlate.Inferior &&
      m.position === Position.PatientLeft,
  );
  if (supA && supP && supPR && supPL && infA && infP && infPR && infPL) {
    // calculating the origin (center) between the anterior and posterior for the superior and inferior bodies
    // divisor divides vector by 2 to get halfway point
    const divisor = new babylon.Vector3(2, 2, 2);
    const midpointA = math.getMidpoint(vectorFromArray(supA.point), vectorFromArray(infA.point));
    const midpointP = math.getMidpoint(vectorFromArray(supP.point), vectorFromArray(infP.point));
    const origin = math.getMidpoint(midpointP, midpointA);

    // calculating the AP mean between the superior and inferior bodies
    const superiorAP = vectorFromArray(supA.point).subtract(vectorFromArray(supP.point));
    const inferiorAP = vectorFromArray(infA.point).subtract(vectorFromArray(infP.point));
    const apMean = superiorAP.add(inferiorAP).divide(divisor);

    // calculating the ML mean between the superior and inferior bodies
    const superiorML = vectorFromArray(supPL.point).subtract(vectorFromArray(supPR.point));
    const inferiorML = vectorFromArray(infPL.point).subtract(vectorFromArray(infPR.point));
    const mlMean = superiorML.add(inferiorML).divide(divisor);

    const anteriorHeightKey = `ANTERIOR_HEIGHT.${inferior}.INFERIOR.${superior}.SUPERIOR`;
    const anteriorHeight = measurementPointValues[anteriorHeightKey];

    rows.push(
      [origin.x.toFixed(2), origin.z.toFixed(2), origin.y.toFixed(2)],
      [apMean.x.toFixed(2), apMean.z.toFixed(2), apMean.y.toFixed(2)],
      [mlMean.x.toFixed(2), mlMean.z.toFixed(2), mlMean.y.toFixed(2)],
      [anteriorHeight.toFixed(2), '0.00', '0.00'],
      [
        supPL.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supPL.point[2].toFixed(2),
        supPL.point[1].toFixed(2),
      ],
      [
        supPR.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supPR.point[2].toFixed(2),
        supPR.point[1].toFixed(2),
      ],
      [
        infPL.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infPL.point[2].toFixed(2),
        infPL.point[1].toFixed(2),
      ],
      [
        infPR.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infPR.point[2].toFixed(2),
        infPR.point[1].toFixed(2),
      ],
      [
        supA.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supA.point[2].toFixed(2),
        supA.point[1].toFixed(2),
      ],
      [
        supP.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        supP.point[2].toFixed(2),
        supP.point[1].toFixed(2),
      ],
      [
        infA.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infA.point[2].toFixed(2),
        infA.point[1].toFixed(2),
      ],
      [
        infP.point[0].toFixed(2),
        // NOTE: flipping Y and Z because cyborgs Y is the Z axis in nTopology (due to the orientation of the patient in the CT scan
        infP.point[2].toFixed(2),
        infP.point[1].toFixed(2),
      ],
    );
  }

  return rows;
};

export const getImplantPartTypeCode = (partType: PartType) => {
  let implantTypeCode = 'A';
  if ([PartType.ALIF_X_TWO_DOWN, PartType.ALIF_X_TWO_UP].includes(partType)) {
    implantTypeCode = 'D';
  }
  if ([PartType.LLIF_LEFT, PartType.LLIF_RIGHT].includes(partType)) {
    implantTypeCode = 'C';
  }
  if (
    [
      PartType.TLIFC_OFFSET_LEFT,
      PartType.TLIFC_OFFSET_RIGHT,
      PartType.TLIFC_INLINE_LEFT,
      PartType.TLIFC_INLINE_RIGHT,
    ].includes(partType)
  ) {
    implantTypeCode = 'G';
  }
  if (
    [
      PartType.TLIFCA_OFFSET_LEFT,
      PartType.TLIFCA_OFFSET_RIGHT,
      PartType.TLIFCA_INLINE_LEFT,
      PartType.TLIFCA_INLINE_RIGHT,
    ].includes(partType)
  ) {
    implantTypeCode = 'J';
  }
  if ([PartType.TLIFO_LEFT, PartType.TLIFO_RIGHT].includes(partType)) {
    implantTypeCode = 'H';
  }
  if ([PartType.ACDF].includes(partType)) {
    implantTypeCode = 'K';
  }
  if ([PartType.ACDF_X_LEFT_UP].includes(partType)) {
    implantTypeCode = 'L';
  }
  return implantTypeCode;
};

export const getNTopCutCallFile = (
  partType: PartType,
  implantSizeName: string,
  cloudDesignQueueType: CloudDesignQueueType,
  environment: string,
  version: string,
) => {
  const fileSuffix = cloudDesignQueueType === CloudDesignQueueType.Cut ? 'Cut' : 'Cut_And_Mesh';
  let nTopFile = `ALIF_Implant_${fileSuffix}.ntop`;

  switch (partType) {
    case PartType.ACDF:
      nTopFile = `ACDF_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.ACDF_X_LEFT_UP:
      nTopFile = `ACDFX_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      nTopFile = `ALIFX_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.LLIF_RIGHT:
      nTopFile = `LLIF_RIGHT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.LLIF_LEFT:
      nTopFile = `LLIF_LEFT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFO_RIGHT:
      nTopFile = `TLIFO_RIGHT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFO_LEFT:
      nTopFile = `TLIFO_LEFT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_RIGHT:
      nTopFile = `TLIFC_RIGHT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_INLINE_LEFT:
      nTopFile = `TLIFC_LEFT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_RIGHT:
      nTopFile = `TLIFCA_RIGHT_Implant_${fileSuffix}.ntop`;
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_INLINE_LEFT:
      nTopFile = `TLIFCA_LEFT_Implant_${fileSuffix}.ntop`;
      break;
  }

  if (environment) {
    nTopFile = `${environment}/${version}/${nTopFile}`;
  }

  let exec: string[] = [
    `ntopcl.exe -v 2 -u $env:NTOP_USERNAME -w $env:NTOP_PASSWORD -j input_${implantSizeName}.json --logfile=ntopcl_${implantSizeName}.log ${nTopFile};`,
  ];

  return exec.join(';');
};
