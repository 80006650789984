import { useLazyQuery, useMutation } from '@apollo/client';
import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICase, ILocation, IUser } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import CustomDialog from '../../../components/CustomDialog';
import { UPDATE_CASE_LOCATION } from '../../../gql';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import { SelectField } from '@workflow-nx/ui';
import { gql } from '@apollo/client/core';
import { sortBy } from 'lodash';
import { Select } from '../../../components/Select';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    flex: 1,
    width: '100%',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
}));

const EditCaseLocationButton: React.FC<{ activeCase: ICase }> = ({ activeCase }) => {
  const navigate = useNavigate();

  const [showUpdateCaseLocationDialog, setShowUpdateCaseLocationDialog] = useState(false);
  const [reason, setReason] = useState('');
  const [locationId, setLocationId] = useState(activeCase?.location?.locationId);
  const [locations, setLocations] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  const [updateCaseLocation, { loading }] = useMutation(UPDATE_CASE_LOCATION);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [findAllLocations, { loading: loadingLocations }] = useLazyQuery(
    gql`
      query FindAllLocations($organizationId: Int, $territoryId: Int) {
        surgeonLocations: locations(organizationId: $organizationId) {
          locationId
          description
          territory {
            territoryId
            name
          }
          organization {
            organizationId
            name
          }
        }
        territoryLocations: locations(territoryId: $territoryId) {
          locationId
          description
          territory {
            territoryId
            name
          }
          organization {
            organizationId
            name
          }
        }
      }
    `,
    {
      onCompleted: (data) => {
        const locationsBySurgeon = (data?.surgeonLocations || []).map((location: ILocation) => {
          return {
            key: location.locationId as unknown as string,
            value: location.description,
            parent: 'DEFAULT - ' + location?.organization?.name,
          };
        });

        const locationsByTerritory = sortBy(
          (data?.territoryLocations || [])
            .filter((location: ILocation) => {
              return (
                location?.organization?.organizationId !== activeCase.surgeonUser?.organizationId
              );
            })
            .map((location: ILocation) => {
              return {
                key: location.locationId as unknown as string,
                value: location.description,
                parent:
                  location?.territory?.name?.toUpperCase() +
                  ' TERRITORY - ' +
                  location?.organization?.name,
              };
            }),
          ['parent', 'value'],
        );

        const allLocations = locationsBySurgeon.concat(locationsByTerritory);
        setLocations(allLocations);
      },
    },
  );

  const handleUpdateCaseLocation = async () => {
    try {
      const res = await updateCaseLocation({
        variables: {
          caseId: activeCase.caseId,
          locationId,
          reason,
        },
      });
      navigate(`/app/cases/${res?.data.updateCaseLocation?.case?.caseId}`);

      setShowUpdateCaseLocationDialog(false);

      enqueueSnackbar(`The case location was succesfully updated.`, {
        variant: 'success',
      });
    } catch (error) {
      console.error('Error updating case location:', error);
      enqueueSnackbar(`The case location failed to update.`, {
        variant: 'error',
      });
    } finally {
    }
  };

  const cancelUpdateCaseLocation = () => {
    setShowUpdateCaseLocationDialog(false);
  };

  useEffect(() => {
    if (showUpdateCaseLocationDialog) {
      setLocations([]);
      setReason('');

      if (activeCase.surgeonUser) {
        const organizationId = activeCase.surgeonUser?.organization?.organizationId;

        findAllLocations({
          variables: {
            organizationId: organizationId,
            territoryId: activeCase.surgeonUser?.location?.territoryId,
          },
        });
      }
    }
  }, [showUpdateCaseLocationDialog]);

  return (
    <>
      <ActionButton
        disabled={loading || loadingLocations}
        onClick={() => {
          if (!loading) {
            setShowUpdateCaseLocationDialog(true);
          }
        }}
      >
        Update Location
      </ActionButton>

      <CustomDialog
        maxWidth={'xs'}
        open={showUpdateCaseLocationDialog}
        title={`Update Case Location ${activeCase.number}`}
        onClose={() => {
          cancelUpdateCaseLocation();
        }}
        positiveActionButtons={[]}
        dialogContentClassName={styles.dialogContainerContent}
        containerClassName={styles.dialogContainer}
      >
        <>
          <Stack spacing={2}>
            <Select
              name={'locationId'}
              label={'Location'}
              value={'' + locationId}
              onChange={(event) => setLocationId(event.target.value)}
              disabled={loading || loadingLocations}
              menuItems={locations}
              hideNone={true}
              fullWidth={true}
            />

            <Typography>
              Enter the reason for changing the location of the completed case.
            </Typography>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              value={reason}
              required={true}
              fullWidth={true}
              minRows={3}
              disabled={loading}
              multiline={true}
            />
            {loading ? <Typography>Updating case location...</Typography> : null}
            <ActionButton
              disabled={loading || loadingLocations || reason.length === 0}
              loading={loading || loadingLocations}
              onClick={handleUpdateCaseLocation}
            >
              Update
            </ActionButton>
          </Stack>
        </>
      </CustomDialog>
    </>
  );
};

export default EditCaseLocationButton;
