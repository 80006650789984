import {
  AssetType,
  CaseSpineProfile,
  CaseSpineType,
  IAsset,
  ILevels,
  IMeasure,
  IPlan,
  IPlanImplant,
  LevelType,
  PartType,
  VertebralBody,
} from '@workflow-nx/common';
import { AbstractMesh, Camera, Color3, HighlightLayer, Mesh, Nullable, Scene } from 'babylonjs';
import { ArcRotateCamera } from 'babylonjs/Cameras/arcRotateCamera';
import {
  addImplantSupportAsset,
  addImplantToScene,
  clearMeshes,
  configureImplant,
  configureImplantSupportAssets,
  getOrthoCameraProperties,
  loadAssetsFromAssetPositions,
  removePointOfRotation,
  renderVertebraeLandmarks,
  rotateCamera,
  setAdjacentMeshTransparency,
  setAssetTarget,
  setPointOfRotation,
} from '../../../../../components/SceneComponent/renderer';

export const PLAN_EDITOR_CAMERA_DISTANCE = 300;
export const IMPLANT_EDITOR_CAMERA_DISTANCE = 100;

export type ImplantViewType =
  | 'SCREWS_13'
  | 'SCREWS_15'
  | 'SCREWS_17'
  | 'SCREWS_20'
  | 'SCREWS_25'
  | 'SCREWS_30'
  | 'INSTRUMENT'
  | 'CLEARANCE'
  | 'MINI'
  | 'PART_ONLY'
  | undefined;

export async function setupInitialView(
  plan: IPlan,
  assets: IAsset[],
  measurements: IMeasure[],
  spineProfile: CaseSpineProfile,
  showLandmarks: boolean,
  scene: Scene,
) {
  try {
    clearMeshes(scene.rootNodes);

    await loadAssetsFromAssetPositions(
      plan.assetPositions,
      assets,
      undefined,
      ['plan', 'vertebrae'],
      spineProfile,
      scene,
    );

    if (showLandmarks) {
      await renderVertebraeLandmarks(measurements, spineProfile, scene);
    }

    rotateCamera(-90, 90, PLAN_EDITOR_CAMERA_DISTANCE, scene);
  } catch (e) {
    console.error(e);
  }
}

export function setupEditingView(
  spineType: CaseSpineType,
  implantLevel: LevelType,
  partType: PartType,
  scene: Scene,
) {
  const camera = scene.activeCamera as Nullable<ArcRotateCamera>;

  if (!camera) return;

  const cameraMode: Camera['mode'] = camera.mode;

  scene.getActiveMeshes().forEach((mesh) => (mesh.visibility = 0));

  const implantMesh = scene.getMeshByName(`${implantLevel}_CYBORG_IMPLANT`) as Mesh;
  const miniMesh = scene.getMeshByName(`${implantLevel}_${partType}_MINI`) as Mesh;
  const cutMesh = scene.getMeshByName(`${implantLevel}_APP`) as Mesh;
  const screwMesh = scene.getMeshByName(`${implantLevel}_IMPLANT_SCREW`) as Mesh;
  const guideMesh = scene.getMeshByName(`${implantLevel}_IMPLANT_SCREW_GUIDE`) as Mesh;
  const instrumentMesh = scene.getMeshByName(`${implantLevel}_IMPLANT_INSTRUMENT`) as Mesh;

  if (implantMesh) {
    setAdjacentMeshTransparency(implantLevel, 0.6, scene);

    if (cutMesh) {
      cutMesh.visibility = 1;
      implantMesh.visibility = 0;
    } else {
      implantMesh.visibility = 1;
    }

    if (miniMesh) {
      miniMesh.visibility = 0;
    }

    if (screwMesh) {
      screwMesh.visibility = 1;
      let hl = new HighlightLayer(`${screwMesh.name}-hl`, scene);
      hl.addMesh(screwMesh as Mesh, Color3.Green());
    }

    if (guideMesh) {
      guideMesh.visibility = 1;
      let hl = new HighlightLayer(`${guideMesh.name}-hl`, scene);
      hl.addMesh(guideMesh as Mesh, Color3.Purple());
    }

    if (instrumentMesh) {
      instrumentMesh.visibility = 1;
      let hl = new HighlightLayer(`${instrumentMesh.name}-hl`, scene);
      hl.addMesh(instrumentMesh as Mesh, Color3.Gray());
    }

    rotateCamera(-90, 90, IMPLANT_EDITOR_CAMERA_DISTANCE, scene);

    // NOTE: has to be centerWorld for implants due to their positioning
    setPointOfRotation(implantMesh.getBoundingInfo().boundingBox.centerWorld, scene);

    const radius = 10;

    if (cameraMode === Camera.ORTHOGRAPHIC_CAMERA) {
      const orthoProperties = getOrthoCameraProperties(radius, scene);

      if (orthoProperties) {
        (scene.activeCamera as ArcRotateCamera).orthoBottom = orthoProperties.orthoBottom;
        (scene.activeCamera as ArcRotateCamera).orthoTop = orthoProperties.orthoTop;
        (scene.activeCamera as ArcRotateCamera).orthoLeft = orthoProperties.orthoLeft;
        (scene.activeCamera as ArcRotateCamera).orthoRight = orthoProperties.orthoRight;
      }
    } else {
      (scene.activeCamera as ArcRotateCamera).radius = radius;
    }
  } else {
    removePointOfRotation(scene);
    setAssetTarget(spineType === CaseSpineType.Lumbar ? VertebralBody.L3 : VertebralBody.C4, scene);

    scene.getMeshesByTags('vertebrae || implant').forEach((mesh) => {
      mesh.visibility = 1;
    });

    if (cutMesh) {
      cutMesh.visibility = 1;
      if (implantMesh) {
        (implantMesh as Mesh).visibility = 0;
      }
    } else {
      if (implantMesh) {
        (implantMesh as Mesh).visibility = 1;
      }
    }

    if (screwMesh) {
      screwMesh.visibility = 0;
    }

    if (guideMesh) {
      guideMesh.visibility = 0;
    }

    if (instrumentMesh) {
      instrumentMesh.visibility = 0;
    }

    if (miniMesh) {
      miniMesh.visibility = 0;
    }

    rotateCamera(-90, 90, PLAN_EDITOR_CAMERA_DISTANCE, scene);
  }
}

export function setupSpineView(
  spineType: CaseSpineType,
  levels: ILevels,
  planImplants: IPlanImplant[],
  scene: Scene,
) {
  // turn all meshes back "off"
  scene.getActiveMeshes().forEach((mesh) => {
    mesh.visibility = 0;
  });

  for (let levelKey in levels) {
    if (levelKey === '__typename') {
      continue;
    }

    const levelType = levelKey as LevelType;
    const planImplant = planImplants.find((planImplant) => planImplant.level === levelType);
    const [superior, inferior] = levelType.split('_');

    const superiorMesh = scene.getMeshByName(superior);
    const inferiorMesh = scene.getMeshByName(inferior);
    const implantMesh = scene.getMeshByName(`${levelType}_CYBORG_IMPLANT`) as Mesh;
    const miniMesh = scene.getMeshByName(`${levelType}_${planImplant?.partType}_MINI`) as Mesh;
    const cutMesh = scene.getMeshByName(`${levelType}_APP`) as Mesh;
    const screwMesh = scene.getMeshByName(`${levelType}_IMPLANT_SCREW`) as Mesh;
    const guideMesh = scene.getMeshByName(`${levelType}_IMPLANT_SCREW_GUIDE`) as Mesh;
    const instrumentMesh = scene.getMeshByName(`${levelType}_IMPLANT_INSTRUMENT`) as Mesh;

    if (cutMesh) {
      cutMesh.visibility = 1;
      if (implantMesh) {
        (implantMesh as Mesh).visibility = 0;
      }
    } else {
      if (implantMesh) {
        (implantMesh as Mesh).visibility = 1;
      }
    }

    if (superiorMesh) {
      superiorMesh.visibility = 1;
    }

    if (inferiorMesh) {
      inferiorMesh.visibility = 1;
    }

    if (screwMesh) {
      screwMesh.visibility = 0;
    }

    if (guideMesh) {
      guideMesh.visibility = 0;
    }

    if (instrumentMesh) {
      instrumentMesh.visibility = 0;
    }

    if (miniMesh) {
      miniMesh.visibility = 0;
    }
  }

  const s1Mesh = scene.getMeshByName(AssetType.S1) as Mesh;
  const pelvisMesh = scene.getMeshByName(AssetType.Pelvis) as Mesh;
  if (pelvisMesh && s1Mesh) {
    pelvisMesh.visibility = s1Mesh.visibility;
  }

  removePointOfRotation(scene);
  const defaultAssetTarget =
    spineType === CaseSpineType.Lumbar ? VertebralBody.L3 : VertebralBody.C4;
  setAssetTarget(defaultAssetTarget, scene);
  rotateCamera(-90, 90, PLAN_EDITOR_CAMERA_DISTANCE, scene);
}

export async function loadSupportAsset(
  name: string,
  levelType: LevelType | undefined,
  partType: PartType,
  view: ImplantViewType,
  planImplant: IPlanImplant,
  tags: string[],
  findImplantPart: any,
  scene: Scene,
): Promise<Mesh | null> {
  let parentMesh = scene.getMeshByName(`${levelType}_CYBORG_IMPLANT`);
  let supportMesh = scene.getMeshByName(name);

  if (!supportMesh && planImplant) {
    const { data } = await findImplantPart({
      variables: {
        partType: partType,
        ap: planImplant.ap,
        ml: planImplant.ml,
        cageTaper: planImplant.cageTaper,
        graftWindow: planImplant.graftWindow,
        level: levelType,
        view: view ?? 'PART_ONLY',
        obliqueThreadAngle: planImplant.obliqueThreadAngle,
        cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle,
      },
    });

    if (data?.implantPart?.signedDownloadUrl) {
      await addImplantSupportAsset(
        name,
        levelType as LevelType,
        planImplant,
        tags,
        data.implantPart.signedDownloadUrl,
        scene,
      );
      supportMesh = scene.getMeshByName(name);
    }

    if (supportMesh) {
      supportMesh?.setParent(parentMesh);
    }
  }

  return supportMesh as Mesh;
}

export function findPlanImplantAssets(
  levelType: LevelType,
  assets: IAsset[],
): {
  planAsset?: IAsset;
  planDimensions?: IAsset;
  planMetadata?: IAsset;
  minusAsset?: IAsset;
  minusDimensions?: IAsset;
  implantAsset?: IAsset;
  implantScrew?: IAsset;
  implantScrewGuide?: IAsset;
  instrument?: IAsset;
} {
  return {
    planAsset: assets.find((asset) => asset.assetType === `${levelType}_APP`),
    planDimensions: assets.find((asset) => asset.assetType === `${levelType}_APP_DIMENSIONS`),
    planMetadata: assets.find((asset) => asset.assetType === `${levelType}_APP_METADATA`),
    minusAsset: assets.find((asset) => asset.assetType === `${levelType}_APP_MINUS`),
    minusDimensions: assets.find(
      (asset) => asset.assetType === `${levelType}_APP_MINUS_DIMENSIONS`,
    ),
    implantAsset: assets.find((asset) => asset.assetType === `${levelType}_CYBORG_IMPLANT`),
    implantScrew: assets.find((asset) => asset.assetType === `${levelType}_IMPLANT_SCREW`),
    implantScrewGuide: assets.find(
      (asset) => asset.assetType === `${levelType}_IMPLANT_SCREW_GUIDE`,
    ),
    instrument: assets.find((asset) => asset.assetType === `${levelType}_IMPLANT_INSTRUMENT`),
  };
}

export const loadImplant = async (
  levelType: LevelType,
  signedDownloadUrl: string,
  assetType: AssetType,
  planImplant: IPlanImplant,
  scene: Scene | undefined,
  findImplantPart: any,
  options: {
    color: Color3;
    tags: string[];
    setPosition: boolean;
    defaultVisibility: 0 | 1;
  },
): Promise<AbstractMesh | null> => {
  if (!scene || !signedDownloadUrl) return null;

  const existingMesh = scene.getMeshByName(assetType);
  if (existingMesh) {
    existingMesh.dispose();
  }

  const mesh = await addImplantToScene(signedDownloadUrl, scene);
  if (mesh) {
    const name = assetType;
    mesh.visibility = options.defaultVisibility;
    mesh.isPickable = false;
    configureImplant(
      mesh,
      name,
      planImplant,
      options.setPosition,
      options.tags,
      options.color,
      scene,
    );
  }

  await configureImplantSupportAssets(planImplant, levelType, findImplantPart, scene);

  return mesh;
};
