import {
  AssetCategory,
  AssetPositionsInterbodyCenterPlane,
  AssetPositionsPoints,
  Axis,
  Coordinate,
  EndPlate,
  IMeasure,
  IPlanImplant,
  ImplantMeasurementRange,
  ImplantPosition,
  PartType,
  PlanImplantBulletType,
  Position,
  caseUtils,
  ImplantType,
} from '@workflow-nx/common';
import * as math from '@workflow-nx/math';
import {
  AbstractMesh,
  Matrix,
  Mesh,
  MeshBuilder,
  NullEngine,
  Quaternion,
  Scene,
  Vector3,
} from 'babylonjs';
import { ISettingsForm19 } from '../../../../../../utils/form19';
import { BulletFormValues } from '../Dialogs/BulletForm';

export const setMeshPositionAndRotation = (
  mesh: Mesh,
  positionVector: Vector3,
  rotationX: number,
  rotationY: number,
  rotationZ: number,
) => {
  mesh.setAbsolutePosition(positionVector);
  mesh.rotation._x = rotationX;
  mesh.rotation._y = rotationY;
  mesh.rotation._z = rotationZ;

  mesh.computeWorldMatrix();
};

export const hasImplantPositionChanged = (
  targetImplant: Mesh | undefined,
  position: Coordinate | undefined,
  rotation: Coordinate | undefined,
) => {
  /**
   * When moving the implant in Babylon, the position/rotation may not increment 1 unit exactly
   *
   * A bug in Babylon frequently occurs where the resulting position/rotation differs
   * from what is expected by 0.001 or less
   *
   * This causes the logic in this function to determine that the implant position/rotation has changed
   *
   * Thus, we should not require strict equality but equality after the value has been rounded to 3 decimals
   *
   * 3 decimals chosen as the smallest increment is 0.10
   */
  const precision = 3;

  const quaternion = targetImplant?.absoluteRotationQuaternion.toEulerAngles();

  return (
    targetImplant?.absolutePosition.x?.toFixed(precision) !== position?.x?.toFixed(precision) ||
    targetImplant?.absolutePosition.y?.toFixed(precision) !== position?.y?.toFixed(precision) ||
    targetImplant?.absolutePosition.z?.toFixed(precision) !== position?.z?.toFixed(precision) ||
    quaternion?.x?.toFixed(precision) !== rotation?.x?.toFixed(precision) ||
    quaternion?.y?.toFixed(precision) !== rotation?.y?.toFixed(precision) ||
    quaternion?.z?.toFixed(precision) !== rotation?.z?.toFixed(precision)
  );
};

export const hasImplantBulletChanged = (
  bulletFormValues: BulletFormValues | undefined,
  bullet: PlanImplantBulletType | undefined,
  threadHeight: number | undefined,
) => {
  const existingInsertionSideAngle = bullet?.insertionSide?.angle ?? 0;
  const bulletFormInsertionSideAngle = bulletFormValues?.angleInsertion ?? 0;
  const existingInsertionSideHeight = bullet?.insertionSide?.height ?? 0;
  const bulletFormInsertionSideHeight = bulletFormValues?.heightInsertion ?? 0;
  const existingThreadedSideAngle = bullet?.threadedSide?.angle ?? 0;
  const bulletFormThreadedSideAngle = bulletFormValues?.angleThreaded ?? 0;
  const existingThreadedSideHeight = bullet?.threadedSide?.height ?? 0;
  const bulletFormThreadedSideHeight = bulletFormValues?.heightThreaded ?? 0;

  return (
    existingInsertionSideAngle !== bulletFormInsertionSideAngle ||
    existingInsertionSideHeight !== bulletFormInsertionSideHeight ||
    existingThreadedSideAngle !== bulletFormThreadedSideAngle ||
    existingThreadedSideHeight !== bulletFormThreadedSideHeight ||
    threadHeight !== bulletFormValues?.threadHeight
  );
};

export const hasImplantAsyncBulletChanged = (
  bulletFormValues: BulletFormValues | undefined,
  bullet: PlanImplantBulletType | undefined,
  threadHeight: number | undefined,
) => {
  const existingInsertionSideTopAngle = bullet?.insertionSide?.topAngle ?? 0;
  const existingInsertionSideTopHeight = bullet?.insertionSide?.topHeight ?? 0;
  const existingInsertionSideBottomAngle = bullet?.insertionSide?.bottomAngle ?? 0;
  const existingInsertionSideBottomHeight = bullet?.insertionSide?.bottomHeight ?? 0;

  const bulletFormInsertionTopAngle = bulletFormValues?.insertionTopAngle ?? 0;
  const bulletFormInsertionTopHeight = bulletFormValues?.insertionTopHeight ?? 0;
  const bulletFormInsertionBottomAngle = bulletFormValues?.insertionBottomAngle ?? 0;
  const bulletFormInsertionBottomHeight = bulletFormValues?.insertionBottomHeight ?? 0;

  const existingThreadedSideTopAngle = bullet?.threadedSide?.topAngle ?? 0;
  const existingThreadedSideTopHeight = bullet?.threadedSide?.topHeight ?? 0;
  const existingThreadedSideBottomAngle = bullet?.threadedSide?.bottomAngle ?? 0;
  const existingThreadedSideBottomHeight = bullet?.threadedSide?.bottomHeight ?? 0;

  const bulletFormThreadedTopAngle = bulletFormValues?.threadedTopAngle ?? 0;
  const bulletFormThreadedTopHeight = bulletFormValues?.threadedTopHeight ?? 0;
  const bulletFormThreadedBottomAngle = bulletFormValues?.threadedBottomAngle ?? 0;
  const bulletFormThreadedBottomHeight = bulletFormValues?.threadedBottomHeight ?? 0;

  return (
    existingInsertionSideTopAngle !== bulletFormInsertionTopAngle ||
    existingInsertionSideTopHeight !== bulletFormInsertionTopHeight ||
    existingInsertionSideBottomAngle !== bulletFormInsertionBottomAngle ||
    existingInsertionSideBottomHeight !== bulletFormInsertionBottomHeight ||
    existingThreadedSideTopAngle !== bulletFormThreadedTopAngle ||
    existingThreadedSideTopHeight !== bulletFormThreadedTopHeight ||
    existingThreadedSideBottomAngle !== bulletFormThreadedBottomAngle ||
    existingThreadedSideBottomHeight !== bulletFormThreadedBottomHeight ||
    threadHeight !== bulletFormValues?.threadHeight
  );
};

export const getImplantBulletDefaults = (
  partType: PartType,
): {
  bullet: PlanImplantBulletType;
  threadHeight: number;
} => {
  switch (partType) {
    case PartType.ACDF:
      return {
        bullet: {
          insertionSide: { angle: 35, height: 3 },
        },
        threadHeight: 0,
      };
    case PartType.ACDF_X_LEFT_UP:
      return {
        bullet: {
          insertionSide: { angle: 35, height: 4 },
        },
        threadHeight: 0,
      };
    case PartType.ALIF:
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      return {
        bullet: {
          insertionSide: { angle: 35, height: 3.5 },
        },
        threadHeight: 0,
      };
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      return {
        bullet: {
          insertionSide: { angle: 35, height: 3.5 },
          threadedSide: { angle: 11, height: 11 },
        },
        threadHeight: 0,
      };
    default: {
      return {
        bullet: {
          insertionSide: { angle: 0, height: 0 },
          threadedSide: { angle: 0, height: 0 },
        },
        threadHeight: 0,
      };
    }
  }
};

export const getTargetAssetPositionTranslationAndRotationByTag = (
  assetCategory: AssetCategory,
  tag: string,
  scene: Scene,
): ImplantPosition | null => {
  let positionData: ImplantPosition | null = null;

  const { supA, supP, supPR, supPL, infA, infP, infPR, infPL } =
    getTargetPointsFromAssetPositionsByTag(
      assetCategory,
      tag.replace('_CYBORG_IMPLANT', ''),
      scene,
    );

  if (supA && supP && supPR && supPL && infA && infP && infPR && infPL) {
    positionData = evaluatePositionData(supA, supP, supPR, supPL, infA, infP, infPR, infPL);
  }

  return positionData;
};

export const getValidImplantMeasurementRanges = (
  type: PartType,
  form19: ISettingsForm19 | undefined,
): ImplantMeasurementRange | null => {
  if (!form19) return null;

  let result: ImplantMeasurementRange | null = null;

  switch (type) {
    case PartType.ACDF:
      const acdfAttributes = form19.acdf.attributes;

      result = {
        minAP: acdfAttributes.ap.min,
        maxAP: acdfAttributes.ap.max,
        minML: acdfAttributes.ml.min,
        maxML: acdfAttributes.ml.max,
        minCageTaper: acdfAttributes?.cageTaper?.min ?? 0,
        maxCageTaper: acdfAttributes?.cageTaper?.max ?? 10,
      };
      break;
    case PartType.ACDF_X_LEFT_UP:
      const acdfxAttributes = form19.acdfx.attributes;

      result = {
        minAP: acdfxAttributes.ap.min,
        maxAP: acdfxAttributes.ap.max,
        minML: acdfxAttributes.ml.min,
        maxML: acdfxAttributes.ml.max,
        minCageTaper: acdfxAttributes?.cageTaper?.min ?? 0,
        maxCageTaper: acdfxAttributes?.cageTaper?.max ?? 10,
      };
      break;
    case PartType.ALIF:
      const alifAttributes = form19.alif.attributes;

      result = {
        minAP: alifAttributes.ap.min,
        maxAP: alifAttributes.ap.max,
        minML: alifAttributes.ml.min,
        maxML: alifAttributes.ml.max,
      };
      break;
    case PartType.ALIF_X_TWO_DOWN:
    case PartType.ALIF_X_TWO_UP:
      const alifxAttributes = form19.alifx.attributes;

      result = {
        minAP: alifxAttributes.ap.min,
        maxAP: alifxAttributes.ap.max,
        minML: alifxAttributes.ml.min,
        maxML: alifxAttributes.ml.max,
      };
      break;
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      const llifAttributes = form19.llif.attributes;

      result = {
        minAP: llifAttributes.ap.min,
        maxAP: llifAttributes.ap.max,
        minML: llifAttributes.ml.min,
        maxML: llifAttributes.ml.max,
      };
      break;
    case PartType.TLIFC_OFFSET_LEFT:
    case PartType.TLIFC_OFFSET_RIGHT:
    case PartType.TLIFC_INLINE_LEFT:
    case PartType.TLIFC_INLINE_RIGHT:
      const tlifcAttributes = form19.tlifc.attributes;

      result = {
        minAP: tlifcAttributes.ap.min,
        maxAP: tlifcAttributes.ap.max,
        minML: tlifcAttributes.ml.min,
        maxML: tlifcAttributes.ml.max,
      };
      break;
    case PartType.TLIFCA_OFFSET_LEFT:
    case PartType.TLIFCA_OFFSET_RIGHT:
    case PartType.TLIFCA_INLINE_LEFT:
    case PartType.TLIFCA_INLINE_RIGHT:
      const tlifcaAttributes = form19.tlifca.attributes;

      result = {
        minAP: tlifcaAttributes.ap.min,
        maxAP: tlifcaAttributes.ap.max,
        minML: tlifcaAttributes.ml.min,
        maxML: tlifcaAttributes.ml.max,
      };
      break;
    case PartType.TLIFO_LEFT:
    case PartType.TLIFO_RIGHT:
      const tlifoAttributes = form19.tlifo.attributes;

      result = {
        minAP: tlifoAttributes.ap.min,
        maxAP: tlifoAttributes.ap.max,
        minML: tlifoAttributes.ml.min,
        maxML: tlifoAttributes.ml.max,
      };
      break;
    default:
      break;
  }

  return result;
};

const getTargetPointsFromAssetPositionsByTag = (
  assetCategory: AssetCategory,
  tag: string,
  scene: Scene,
) => {
  //initialize point mesh values
  let supAMesh: AbstractMesh | null = null;
  let supPMesh: AbstractMesh | null = null;
  let supPRMesh: AbstractMesh | null = null;
  let supPLMesh: AbstractMesh | null = null;
  let infAMesh: AbstractMesh | null = null;
  let infPMesh: AbstractMesh | null = null;
  let infPRMesh: AbstractMesh | null = null;
  let infPLMesh: AbstractMesh | null = null;

  //assign correct target point based on assetType
  switch (assetCategory) {
    case AssetCategory.VertebralBodies:
      const targetMeshName: string = tag; // getRawAssetName(tag, AssetSuffix.PreopSuffix);

      supAMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.Anterior} ${EndPlate.Inferior} point`,
      )?.[0];
      supPMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.Posterior} ${EndPlate.Inferior} point`,
      )?.[0];
      supPRMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.PatientRight} ${EndPlate.Inferior} point`,
      )?.[0];
      supPLMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.PatientLeft} ${EndPlate.Inferior} point`,
      )?.[0];
      infAMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.Anterior} ${EndPlate.Superior} point`,
      )?.[0];
      infPMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.Posterior} ${EndPlate.Superior} point`,
      )?.[0];
      infPRMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.PatientRight} ${EndPlate.Superior} point`,
      )?.[0];
      infPLMesh = scene.getMeshesByTags(
        `${targetMeshName} ${Position.PatientLeft} ${EndPlate.Superior} point`,
      )?.[0];
      break;

    case AssetCategory.Implants:
      const [parent, child]: string[] = tag.replace('_CYBORG_IMPLANT', '').split('_');

      supAMesh = scene.getMeshesByTags(
        `${parent} && ${Position.Anterior} && ${EndPlate.Inferior} && point`,
      )?.[0];
      supPMesh = scene.getMeshesByTags(
        `${parent} && ${Position.Posterior} && ${EndPlate.Inferior} && point`,
      )?.[0];
      supPRMesh = scene.getMeshesByTags(
        `${parent} && ${Position.PatientRight} && ${EndPlate.Inferior} && point`,
      )?.[0];
      supPLMesh = scene.getMeshesByTags(
        `${parent} && ${Position.PatientLeft} && ${EndPlate.Inferior} && point`,
      )?.[0];
      infAMesh = scene.getMeshesByTags(
        `${child} && ${Position.Anterior} && ${EndPlate.Superior} && point`,
      )?.[0];
      infPMesh = scene.getMeshesByTags(
        `${child} && ${Position.Posterior} && ${EndPlate.Superior} && point`,
      )?.[0];
      infPRMesh = scene.getMeshesByTags(
        `${child} && ${Position.PatientRight} && ${EndPlate.Superior} && point`,
      )?.[0];
      infPLMesh = scene.getMeshesByTags(
        `${child} && ${Position.PatientLeft} && ${EndPlate.Superior} && point`,
      )?.[0];

      break;

    default:
      break;
  }

  //extract absolute points from point meshes
  const supA: Vector3 | undefined = supAMesh?.getAbsolutePosition();
  const supP: Vector3 | undefined = supPMesh?.getAbsolutePosition();
  const supPR: Vector3 | undefined = supPRMesh?.getAbsolutePosition();
  const supPL: Vector3 | undefined = supPLMesh?.getAbsolutePosition();
  const infA: Vector3 | undefined = infAMesh?.getAbsolutePosition();
  const infP: Vector3 | undefined = infPMesh?.getAbsolutePosition();
  const infPR: Vector3 | undefined = infPRMesh?.getAbsolutePosition();
  const infPL: Vector3 | undefined = infPLMesh?.getAbsolutePosition();

  return {
    supA: supA,
    supP: supP,
    supPR: supPR,
    supPL: supPL,
    infA: infA,
    infP: infP,
    infPR: infPR,
    infPL: infPL,
  };
};

export const evaluatePositionData = (
  supA: Vector3,
  supP: Vector3,
  supPR: Vector3,
  supPL: Vector3,
  infA: Vector3,
  infP: Vector3,
  infPR: Vector3,
  infPL: Vector3,
): ImplantPosition => {
  // divide by divisor vector by 2 to get half-way point
  const divisor = new Vector3(2, 2, 2);

  //calculate the AP and ML mean between the superior and inferior bodies
  const superiorAP = supA.subtract(supP);
  const inferiorAP = infA.subtract(infP);
  const apMean = superiorAP.add(inferiorAP).divide(divisor);

  const superiorML = supPL.subtract(supPR);
  const inferiorML = infPL.subtract(infPR);
  const mlMean = superiorML.add(inferiorML).divide(divisor);

  const midpointA = getMidpoint(supA, infA);
  const midpointP = getMidpoint(supP, infP);
  const midpointPR = getMidpoint(supPR, infPR);
  const midpointPL = getMidpoint(supPL, infPL);

  const midPointAP = getMidpoint(midpointP, midpointA);
  const midPointML = getMidpoint(midpointPR, midpointPL);

  const centroid = getMidpoint(midPointAP, midPointML);

  //define disc coordinate system
  const yPrime = mlMean.cross(apMean);
  const zPrime = mlMean.cross(yPrime);
  const xPrime = apMean.cross(yPrime);

  //define origin for each axis
  const xOrigin = new Vector3(1, 0, 0);
  const yOrigin = new Vector3(0, 1, 0);
  const zOrigin = new Vector3(0, 0, 1);

  //define rotational matrix points
  const A = Math.cos(math.calculateAngleBetweenVectors(xPrime, xOrigin, Axis.X));
  const B = Math.cos(math.calculateAngleBetweenVectors(xPrime, yOrigin, Axis.Y));
  const C = Math.cos(math.calculateAngleBetweenVectors(xPrime, zOrigin, Axis.Z));
  const D = Math.cos(math.calculateAngleBetweenVectors(yPrime, xOrigin, Axis.X));
  const E = Math.cos(math.calculateAngleBetweenVectors(yPrime, yOrigin, Axis.Y));
  const F = Math.cos(math.calculateAngleBetweenVectors(yPrime, zOrigin, Axis.Z));
  const G = Math.cos(math.calculateAngleBetweenVectors(zPrime, xOrigin, Axis.X));
  const H = Math.cos(math.calculateAngleBetweenVectors(zPrime, yOrigin, Axis.Y));
  const I = Math.cos(math.calculateAngleBetweenVectors(zPrime, zOrigin, Axis.Z));

  //create rotational matrix
  const rotationalMatrix = Matrix.FromValues(A, B, C, 0, D, E, F, 0, G, H, I, 0, 0, 0, 0, 1);

  const quaternion = new Quaternion();
  const rotationQuaternion = quaternion.fromRotationMatrix(rotationalMatrix);
  const eulerAngles = rotationQuaternion.toEulerAngles();

  return {
    eulerAngles: eulerAngles,
    centroid: centroid,
  };
};

export function getMidpoint(vectorA: Vector3, vectorB: Vector3): Vector3 {
  const difference = vectorB.subtract(vectorA);
  const midpoint = difference.divide(new Vector3(2, 2, 2));

  return vectorA.add(midpoint);
}

export const getTargetImplantDataFromScene = (mesh: Mesh): null | Partial<IPlanImplant> => {
  let result: Partial<IPlanImplant> | null = null;

  // const targetImplant = scene.getMeshByName(mesh.name);

  if (mesh) {
    const absolutePosition: Vector3 = mesh.getAbsolutePosition();
    const implantRotation = {
      x: mesh.rotation.x,
      y: mesh.rotation.y,
      z: mesh.rotation.z,
    };
    const implantPosition = {
      x: absolutePosition.x,
      y: absolutePosition.y,
      z: absolutePosition.z,
    };

    const metadata = mesh?.metadata;

    result = {
      partType: metadata?.partType,
      screwLength: metadata?.screwLength,
      position: implantPosition,
      rotation: implantRotation,
      referencePoints: getCentroidPlane(implantPosition, implantRotation),
      ap: metadata?.ap,
      ml: metadata?.ml,
    };
  }

  return result;
};

export function getMeasurementsFromAssetPositions(
  assetPositionsPoints: AssetPositionsPoints[],
): IMeasure[] {
  return assetPositionsPoints.map(({ position, name, endPlate, point }) => {
    return {
      position,
      endPlate,
      point: [point.x, point.y, point.z],
      body: name,
    } as IMeasure;
  });
}

export const getImplantTypeTotalLength = (partType: PartType, ap: number, ml: number): number => {
  let totalLength = 0;

  switch (partType) {
    case PartType.ALIF:
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      totalLength = ap;
      break;
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      totalLength = ml;
      break;
    default:
      break;
  }

  return totalLength;
};

export const getImplantTypeTotalWidth = (partType: PartType, ap: number, ml: number): number => {
  let totalWidth = 0;

  switch (partType) {
    case PartType.ALIF:
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      totalWidth = ml;
      break;
    case PartType.LLIF_LEFT:
    case PartType.LLIF_RIGHT:
      totalWidth = ap;
      break;
    default:
      break;
  }

  return totalWidth;
};

// gets centroid plane points of implant
export const getCentroidPlane = (
  position: Coordinate,
  rotation: Coordinate,
): AssetPositionsInterbodyCenterPlane => {
  // spheres are loaded into null scene in flat plane
  const engine: NullEngine = new NullEngine();
  const scene: Scene = new Scene(engine);

  const centroidSphere = MeshBuilder.CreateSphere('centroidSphere', { diameter: 1 }, scene);

  centroidSphere.position = new Vector3(position.x, position.y, position.z);

  centroidSphere.computeWorldMatrix();

  const centerWorld = centroidSphere.getBoundingInfo().boundingBox.centerWorld;

  const gap = 20;

  const anteriorPoint = new Vector3(centerWorld.x, centerWorld.y, centerWorld.z + gap);
  const posteriorPoint = new Vector3(centerWorld.x, centerWorld.y, centerWorld.z - gap);
  const patientLeftPoint = new Vector3(centerWorld.x + gap, centerWorld.y, centerWorld.z);
  const patientRightPoint = new Vector3(centerWorld.x - gap, centerWorld.y, centerWorld.z);

  const anteriorSphere = MeshBuilder.CreateSphere('anteriorSphere', { diameter: 1 }, scene);
  const posteriorSphere = MeshBuilder.CreateSphere('posteriorSphere', { diameter: 1 }, scene);
  const patientLeftSphere = MeshBuilder.CreateSphere('patientLeftSphere', { diameter: 1 }, scene);
  const patientRightSphere = MeshBuilder.CreateSphere('patientRightSphere', { diameter: 1 }, scene);

  anteriorSphere.position = anteriorPoint;
  posteriorSphere.position = posteriorPoint;
  patientLeftSphere.position = patientLeftPoint;
  patientRightSphere.position = patientRightPoint;

  centroidSphere.computeWorldMatrix(true);
  anteriorSphere.computeWorldMatrix(true);
  posteriorSphere.computeWorldMatrix(true);
  patientLeftSphere.computeWorldMatrix(true);
  patientRightSphere.computeWorldMatrix(true);

  anteriorSphere.setParent(centroidSphere);
  posteriorSphere.setParent(centroidSphere);
  patientLeftSphere.setParent(centroidSphere);
  patientRightSphere.setParent(centroidSphere);

  // all plane points are rotated along with centroid sphere
  centroidSphere.rotation = new Vector3(rotation.x, rotation.y, rotation.z);

  centroidSphere.computeWorldMatrix(true);
  anteriorSphere.computeWorldMatrix(true);
  posteriorSphere.computeWorldMatrix(true);
  patientLeftSphere.computeWorldMatrix(true);
  patientRightSphere.computeWorldMatrix(true);

  // resulting positions are collected post rotation
  const centroidPosition = centroidSphere.getAbsolutePosition();
  const anteriorPosition = anteriorSphere.getAbsolutePosition();
  const posteriorPosition = posteriorSphere.getAbsolutePosition();
  const patientLeftPosition = patientLeftSphere.getAbsolutePosition();
  const patientRightPosition = patientRightSphere.getAbsolutePosition();

  return {
    centroidPosition: {
      x: centroidPosition.x,
      y: centroidPosition.y,
      z: centroidPosition.z,
    },
    anteriorPosition: {
      x: anteriorPosition.x,
      y: anteriorPosition.y,
      z: anteriorPosition.z,
    },
    posteriorPosition: {
      x: posteriorPosition.x,
      y: posteriorPosition.y,
      z: posteriorPosition.z,
    },
    patientLeftPosition: {
      x: patientLeftPosition.x,
      y: patientLeftPosition.y,
      z: patientLeftPosition.z,
    },
    patientRightPosition: {
      x: patientRightPosition.x,
      y: patientRightPosition.y,
      z: patientRightPosition.z,
    },
  };
};

export const getImplantHasScrews = (partType?: PartType): boolean => {
  let hasScrews = false;

  if (partType) {
    const implantType = caseUtils.getImplantType(partType);

    hasScrews = [ImplantType.ALIFX, ImplantType.ACDFX].includes(implantType);
  }
  return hasScrews;
};
