import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { ReactNode } from 'react';
import { Permission } from '@workflow-nx/common';

type AuthGuardProps = {
  children?: ReactNode;
  permissions: Permission[];
};

export default function AuthGuard({ children, permissions }: AuthGuardProps): ReactNode | null {
  const { isAuthenticated, hasTokenExpired, hasPermission } = useAuth();
  const location = useLocation();
  const canAccessView = permissions.length ? hasPermission?.([...permissions]) : true;
  const isTokenExpired = hasTokenExpired ? hasTokenExpired() : false;

  if (!isAuthenticated || isTokenExpired) {
    // if the user is not authenticated, and they are navigating to a
    // page within the application, then store it in local storage until
    // they log in again
    if (location.pathname.startsWith('/app')) {
      localStorage.setItem('redirectTo', location.pathname);
    }
    window.location.href = '/login';
  } else {
    // if there is a redirectTo entry, redirect the user to that
    // page and then delete the entry to prevent this from
    // happening a second time
    const redirectTo = localStorage.getItem('redirectTo');
    if (redirectTo) {
      window.location.href = redirectTo;
      localStorage.removeItem('redirectTo');
    }
  }

  if (!canAccessView) {
    window.location.href = '/app/dashboard';
    return null;
  }

  return children ?? null;
}
