import React, { useState } from 'react';
import {
  format,
  PartType,
  ImplantType,
  IPatientRecord,
  ICase,
  CaseSpineProfile,
} from '@workflow-nx/common';
import { caseUtils } from '@workflow-nx/common';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import { ProgressButton, SelectField, TextField } from '@workflow-nx/ui';
import { FIND_SETTINGS, UPSERT_PLAN_IMPLANT } from '../../../../gql';
import * as Yup from 'yup';
import CustomDialog from '../../../../components/CustomDialog';
import { getValidImplantMeasurementRanges } from './ImplantEditorDialog/utils/implantEditor';
import { ISettingsForm19 } from 'apps/workflow-client/src/app/utils/form19';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../utils/featureFlags';
import { PlanImplantLevels } from './PlanImplantsTableView';
import {
  cervicalScrewLengthMenuItems,
  lumbarScrewLengthMenuItems,
  positiveAndNegativeAngleMenuItems,
  positiveAngleMenuItems,
} from './EditPlanNtopImplantsDialog';

export function EditPlanV1ImplantsDialog(props: {
  activeCase?: ICase;
  hasImplantsWithScrews: boolean;
  planId: number;
  planImplantLevels: Partial<PlanImplantLevels>;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const { hasFeatureFlag } = useAuth();
  const [upsertPlanImplant, { loading: upsertPlanImplantLoading }] =
    useMutation(UPSERT_PLAN_IMPLANT);
  const { enqueueSnackbar } = useSnackbar();
  const [form19, setForm19] = useState<ISettingsForm19>();

  const initialValues = props.planImplantLevels;
  const { loading: form19Loading } = useQuery(FIND_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (form19Data) => {
      setForm19(form19Data?.settings?.form19 ? form19Data?.settings?.form19 : {});
    },
  });

  const { hasPelvisHighPelvicIncidence, hasPelvisOblique, hasPelvisHighCrest } = props.activeCase
    ?.patient?.patientRecord as IPatientRecord;

  const handleSubmitForm = async (
    values: FormikValues,
    { setErrors, setStatus, setSubmitting }: FormikHelpers<any>,
  ) => {
    try {
      for (const level of Object.keys(values)) {
        const planImplant = values[level];

        if (planImplant) {
          await upsertPlanImplant({
            variables: {
              planId: props.planId,
              ...planImplant,
              obliqueThreadAngle: planImplant.obliqueThreadAngle
                ? Number(planImplant.obliqueThreadAngle)
                : null,
              cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle
                ? Number(planImplant.cranialCaudalThreadAngle)
                : null,
              screwLength: planImplant.screwLength ? Number(planImplant.screwLength) : null,
              threadHeight: planImplant.threadHeight ? Number(planImplant.threadHeight) : 0,
              position: { x: 0, y: 0, z: 0 },
              rotation: { x: 0, y: 0, z: 0 },
              referencePoints: {
                centroidPosition: { x: 0, y: 0, z: 0 },
                anteriorPosition: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                posteriorPosition: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                patientLeftPosition: {
                  x: 0,
                  y: 0,
                  z: 0,
                },
                patientRightPosition: { x: 0, y: 0, z: 0 },
              },
            },
          });
        }
      }

      setStatus({ success: true });

      enqueueSnackbar('Updating implant interbodies saved', {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: any) {
      console.error(err);
      setStatus({ success: false });
      setErrors({ submit: err.message });
      enqueueSnackbar('An error occurred updating the implant interbodies', {
        variant: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const getValidationSchema = () => {
    const schema: any = {};

    Object.entries(props.planImplantLevels).map(([level, planImplantLevel]) => {
      const implantMeasurementRange = getValidImplantMeasurementRanges(
        planImplantLevel.partType,
        form19,
      );
      schema[level] = Yup.object().shape({
        ap: Yup.number()
          .test('isInRangeAP', 'Value is out of range', function (value) {
            let result = false;

            if (value && implantMeasurementRange) {
              result =
                value >= implantMeasurementRange?.minAP && value <= implantMeasurementRange?.maxAP;
            }
            return result;
          })
          .required(),
        ml: Yup.number()
          .test('isInRangeML', 'Value is out of range', function (value) {
            let result = false;

            if (value && implantMeasurementRange) {
              result =
                value >= implantMeasurementRange?.minML && value <= implantMeasurementRange?.maxML;
            }
            return result;
          })
          .required(),
        screwLength: Yup.string().nullable(),
      });
    });

    return Yup.object().shape(schema);
  };

  const screwLengthMenuItems = [
    CaseSpineProfile.CervicalStandard,
    CaseSpineProfile.CervicalStandardMinusC7,
    CaseSpineProfile.CervicalStandardPlusC8,
  ].includes(props.activeCase?.spineProfile as unknown as CaseSpineProfile)
    ? cervicalScrewLengthMenuItems
    : lumbarScrewLengthMenuItems;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema()}
      enableReinitialize={true}
      onSubmit={handleSubmitForm}
    >
      {({ resetForm, submitForm, isSubmitting, setFieldValue }) => {
        return (
          <CustomDialog
            maxWidth={'lg'}
            open={props.open}
            title={`Edit Plan Implants (V1)`}
            onClose={() => {
              resetForm();
              props.onClose(false);
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                onClick={() => submitForm()}
                label={'Save'}
                loading={isSubmitting || upsertPlanImplantLoading || form19Loading}
              />,
            ]}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align={'left'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>LEVEL</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'left'}>
                    <Typography variant={'body1'} noWrap>
                      <strong>PART</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'} style={{ width: 150 }}>
                    <Typography variant={'body1'} noWrap>
                      <strong>AP</strong>
                    </Typography>
                  </TableCell>
                  <TableCell align={'center'} style={{ width: 150 }}>
                    <Typography variant={'body1'} noWrap>
                      <strong>ML</strong>
                    </Typography>
                  </TableCell>
                  {props.hasImplantsWithScrews ? (
                    <TableCell align={'center'} style={{ width: 175 }}>
                      <Typography variant={'body1'} noWrap>
                        <strong>SCREW LENGTH</strong>
                      </Typography>
                    </TableCell>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                    <TableCell align={'center'} style={{ width: 175 }}>
                      <Typography variant={'body1'} noWrap>
                        <strong>ANGLES</strong>
                      </Typography>
                    </TableCell>
                  ) : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(props.planImplantLevels).map(([level, planImplantLevel]) => {
                  const implantType = caseUtils.getImplantType(planImplantLevel.partType);
                  const implantMeasurementRange = getValidImplantMeasurementRanges(
                    planImplantLevel.partType,
                    form19,
                  );
                  const implantHasScrews = [ImplantType.ALIFX, ImplantType.ACDFX].includes(
                    implantType,
                  );

                  return (
                    <TableRow key={level}>
                      <TableCell>{format.formatInterbodyLevel(planImplantLevel.level)}</TableCell>
                      <TableCell>{format.formatPartType(planImplantLevel.partType)}</TableCell>
                      <TableCell align={'center'}>
                        <TextField
                          name={`${level}.ap`}
                          label={`${implantMeasurementRange?.minAP} to ${implantMeasurementRange?.maxAP}`}
                          disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                          fullWidth
                          type="number"
                          variant={'outlined'}
                          required
                        />
                      </TableCell>
                      <TableCell align={'center'}>
                        <TextField
                          name={`${level}.ml`}
                          label={`${implantMeasurementRange?.minML} to ${implantMeasurementRange?.maxML}`}
                          disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                          fullWidth
                          type="number"
                          variant={'outlined'}
                          required
                        />
                      </TableCell>
                      {props.hasImplantsWithScrews ? (
                        <TableCell align={'center'}>
                          {implantHasScrews ? (
                            <SelectField
                              name={`${level}.screwLength`}
                              label={'Screw Length'}
                              menuItems={screwLengthMenuItems}
                              disabled={
                                !implantHasScrews ||
                                isSubmitting ||
                                upsertPlanImplantLoading ||
                                form19Loading
                              }
                              hideNone={true}
                            />
                          ) : (
                            <>&mdash;</>
                          )}
                        </TableCell>
                      ) : null}
                      {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                        <TableCell align={'center'}>
                          <Stack gap={1}>
                            {!hasPelvisHighPelvicIncidence &&
                            !hasPelvisOblique &&
                            !hasPelvisHighCrest ? (
                              <>&mdash;</>
                            ) : null}

                            {hasPelvisHighPelvicIncidence &&
                            [PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(
                              planImplantLevel.partType,
                            ) ? (
                              <SelectField
                                name={`${level}.cranialCaudalThreadAngle`}
                                label={'Cranial-Caudal'}
                                menuItems={positiveAngleMenuItems}
                                disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                                hideNone={true}
                              />
                            ) : null}
                            {hasPelvisOblique && [ImplantType.LLIF].includes(implantType) ? (
                              <SelectField
                                onChange={() =>
                                  setFieldValue(`${level}.cranialCaudalThreadAngle`, '0', true)
                                }
                                name={`${level}.obliqueThreadAngle`}
                                label={'Oblique'}
                                menuItems={positiveAngleMenuItems}
                                disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                                hideNone={true}
                              />
                            ) : null}
                            {hasPelvisHighCrest && [ImplantType.LLIF].includes(implantType) ? (
                              <SelectField
                                onChange={() =>
                                  setFieldValue(`${level}.obliqueThreadAngle`, '0', true)
                                }
                                name={`${level}.cranialCaudalThreadAngle`}
                                label={'Cranial-Caudal'}
                                menuItems={positiveAndNegativeAngleMenuItems}
                                disabled={isSubmitting || upsertPlanImplantLoading || form19Loading}
                                hideNone={true}
                              />
                            ) : null}
                            {![ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
                              implantType,
                            ) ? (
                              <>&mdash;</>
                            ) : null}
                          </Stack>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CustomDialog>
        );
      }}
    </Formik>
  );
}
