import React, { forwardRef, useImperativeHandle } from 'react';
import { Box, Grid, Stack, Typography } from '@mui/material';
import {
  DatePickerRHF,
  DropzoneRHF,
  ErrorMessageRHF,
  NumberTextFieldRHF,
  TextFieldRHF,
} from '@workflow-nx/ui';
import { Control, FieldValues, useFormContext } from 'react-hook-form';
import * as Yup from 'yup';
import {
  AssetType,
  IAsset,
  IImplantPartDetail,
  ValidFileExtensions,
  format,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import useCreateAndUploadAsset from '../../../hooks/useCreateAndUploadAsset';

export const enum ImplantPartDetailInputType {
  Text = 'TEXT',
  Select = 'SELECT',
  Date = 'DATE',
  MinMaxNumbers = 'MIN_MAX_NUMBERS',
  Number = 'NUMBER',
  Asset = 'ASSET',
}

type NTopAssetFields = {
  nTopAutoOp1Asset?: IAsset | File;
  nTopAutoOp2Asset?: IAsset | File;
  nTopManualOp1Asset?: IAsset | File;
  nTopManualOp2Asset?: IAsset | File;
};

export type ImplantFormType = Required<IImplantPartDetail> &
  Omit<
    IImplantPartDetail,
    | 'implantPartDetailId'
    | 'nTopAutoOp1Asset'
    | 'nTopAutoOp2Asset'
    | 'nTopManualOp1Asset'
    | 'nTopManualOp2Asset'
  > &
  Partial<NTopAssetFields>;

export type ImplantPartDetailsMutationVariablesType = ImplantFormType &
  Omit<
    ImplantFormType,
    'nTopAutoOp1Asset' | 'nTopAutoOp2Asset' | 'nTopManualOp1Asset' | 'nTopManualOp2Asset'
  > & {
    nTopAutoOp1AssetId: number;
    nTopAutoOp2AssetId: number;
    nTopManualOp1AssetId: number;
    nTopManualOp2AssetId: number;
  };

const greenlightGuruDrawingFormData = [
  {
    id: 'drawingNumber',
    label: 'Drawing Number',
    input: ImplantPartDetailInputType.Text,
  },
  {
    id: 'drawingVersionNumber',
    label: 'Version Number',
    input: ImplantPartDetailInputType.Number,
    canBeDisabled: true,
  },
  {
    id: 'drawingDocumentId',
    label: 'Document ID',
    input: ImplantPartDetailInputType.Text,
  },
  {
    id: 'drawingVersionId',
    label: 'Version ID',
    input: ImplantPartDetailInputType.Text,
  },
];

const greenlightGuruForm037FormData = [
  {
    id: 'form037VersionNumber',
    label: 'Version Number',
    input: ImplantPartDetailInputType.Number,
    canBeDisabled: true,
  },
  {
    id: 'form037DocumentId',
    label: 'Document ID',
    input: ImplantPartDetailInputType.Text,
  },
  {
    id: 'form037VersionId',
    label: 'Version ID',
    input: ImplantPartDetailInputType.Text,
  },
  {
    id: 'onShapeUrl',
    label: 'OnShape URL',
    input: ImplantPartDetailInputType.Text,
  },
  {
    id: 'onShapeRelease',
    label: 'OnShape Release',
    input: ImplantPartDetailInputType.Text,
  },
];

const cloudDesignNtopFormData = [
  {
    id: 'nTopAutoOp1Asset',
    label: 'nTop Auto Op1 Asset',
    input: ImplantPartDetailInputType.Asset,
  },
  {
    id: 'nTopAutoOp2Asset',
    label: 'nTop Auto Op2 Asset',
    input: ImplantPartDetailInputType.Asset,
  },
  {
    id: 'nTopManualOp1Asset',
    label: 'nTop Manual Op1 Asset',
    input: ImplantPartDetailInputType.Asset,
  },
  {
    id: 'nTopManualOp2Asset',
    label: 'nTop Manaul Op2 Asset',
    input: ImplantPartDetailInputType.Asset,
  },
];

const implantDimensionsFormData = [
  {
    id: 'ap',
    label: 'AP',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'ml',
    label: 'ML',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'cageHeight',
    label: 'Cage Height',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'cageTaper',
    label: 'Cage Taper',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'coronalAngle',
    label: 'Coronal Angle',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'lordoticAngle',
    label: 'Lordotic Angle',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'bulletAngle',
    label: 'Bullet Angle',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'bulletHeight',
    label: 'Bullet Height',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'bulletThreadHeight',
    label: 'Bullet Taper Height',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'patientContactUpperHeight',
    label: 'PC Upper Height',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
  {
    id: 'patientContactLowerHeight',
    label: 'PC Lower Height',
    input: ImplantPartDetailInputType.MinMaxNumbers,
  },
];

export const getImplantPartDetailSchema = (
  schemaType: 'CREATE' | 'EDIT',
  prevEntry?: IImplantPartDetail,
) => {
  const prevActiveDate = prevEntry?.activeDate;
  const prevDrawingVersion = prevEntry?.drawingVersionNumber;
  const prevForm037Version = prevEntry?.form037VersionNumber;

  const getVersionNumberSchema = (isForm037: boolean, prevVersion?: number) => {
    let schema = Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable();

    if (prevVersion && schemaType === 'CREATE') {
      if (isForm037) {
        schema = schema.min(prevVersion, `Must equal or be more than ${prevVersion}`);
      } else {
        schema = schema.moreThan(prevVersion, `Must be more than ${prevVersion}`);
      }
    }
    schema = schema.required('Field is required');
    return schema;
  };

  const getMinValueSchema = (maxName: string) => {
    const schema = Yup.number()
      .when([maxName], ([maxName], schema) => {
        return schema.lessThan(maxName, 'Min must be less than the Max');
      })
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Min field is required');

    return schema;
  };

  return Yup.object().shape({
    activeDate:
      prevActiveDate && schemaType === 'CREATE'
        ? Yup.date()
            .typeError('Invalid Date')
            .required('Field is required')
            .min(
              prevActiveDate,
              `Must be > than: ${format.formatDateTime(prevActiveDate, 'MM/dd/yyyy')}`,
            )
        : Yup.date().typeError('Invalid Date').required('Field is required'),

    //GG Drawing Details
    drawingNumber: Yup.string().required('Field is required'),
    drawingVersionNumber: getVersionNumberSchema(false, prevDrawingVersion),
    drawingDocumentId: Yup.string().required('Field is required'),
    drawingVersionId: Yup.string().required('Field is required'),

    //GG Form-037 Details
    form037VersionNumber: getVersionNumberSchema(true, prevForm037Version),
    form037DocumentId: Yup.string().required('Field is required'),
    form037VersionId: Yup.string().required('Field is required'),
    onShapeUrl: Yup.string().required('Field is required').url('Must be a valid URL'),
    onShapeRelease: Yup.string().required('Field is required'),

    //Cloud Design nTop Assets
    nTopAutoOp1Asset: Yup.mixed().required('Field is required'),
    nTopAutoOp2Asset: Yup.mixed().required('Field is required'),
    nTopManualOp1Asset: Yup.mixed().required('Field is required'),
    nTopManualOp2Asset: Yup.mixed().required('Field is required'),

    //implant Dimensions Data
    apMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    apMin: getMinValueSchema('apMax'),
    mlMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    mlMin: getMinValueSchema('mlMax'),
    cageHeightMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    cageHeightMin: getMinValueSchema('cageHeightMax'),
    cageTaperMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    cageTaperMin: getMinValueSchema('cageTaperMax'),
    coronalAngleMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    coronalAngleMin: getMinValueSchema('coronalAngleMax'),
    lordoticAngleMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    lordoticAngleMin: getMinValueSchema('lordoticAngleMax'),
    bulletAngleMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    bulletAngleMin: getMinValueSchema('bulletAngleMax'),
    bulletHeightMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    bulletHeightMin: getMinValueSchema('bulletHeightMax'),
    bulletThreadHeightMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    bulletThreadHeightMin: getMinValueSchema('bulletThreadHeightMax'),
    patientContactUpperHeightMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    patientContactUpperHeightMin: getMinValueSchema('patientContactUpperHeightMax'),
    patientContactLowerHeightMax: Yup.number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required('Max field is required'),
    patientContactLowerHeightMin: getMinValueSchema('patientContactLowerHeightMax'),
  });
};

export interface ImplantPartDetailsFormHandle {
  uploadAssetsAndGenerateAPIVariables: (
    data: ImplantFormType,
    dirtyFields: Partial<Readonly<ImplantFormType>>,
  ) => Promise<ImplantPartDetailsMutationVariablesType>;
}

interface SharedFormProps {
  defaultNumberValues?: Record<'drawingVersionNumber' | 'form037VersionNumber', number | undefined>;
}

const ImplantPartDetailForm = forwardRef<ImplantPartDetailsFormHandle, SharedFormProps>(
  (props, ref) => {
    const { enqueueSnackbar } = useSnackbar();
    const { createAndUploadAsset } = useCreateAndUploadAsset();

    const {
      setValue,
      trigger,
      control,
      getValues,
      formState: { isSubmitting },
    } = useFormContext();

    useImperativeHandle(ref, () => ({
      async uploadAssetsAndGenerateAPIVariables(data, dirtyFields) {
        let submitData = data;
        const assetList = [
          'nTopAutoOp1Asset',
          'nTopAutoOp2Asset',
          'nTopManualOp1Asset',
          'nTopManualOp2Asset',
        ];
        const assetIds: Record<
          | 'nTopAutoOp1AssetId'
          | 'nTopAutoOp2AssetId'
          | 'nTopManualOp1AssetId'
          | 'nTopManualOp2AssetId',
          number
        > = {} as Record<string, number>;

        try {
          for (const assetKey of assetList) {
            const asset = data[assetKey as keyof ImplantFormType] as Partial<IAsset> &
              Partial<File>;
            let assetType = undefined;

            //If it's a new asset
            if (!asset?.assetType && dirtyFields[assetKey as keyof ImplantFormType]) {
              const implantTypeArray = data.implantType.toLowerCase().split('_');
              const modifiedImplantType = implantTypeArray
                .map((ele) => ele.charAt(0).toUpperCase() + ele.slice(1))
                .join('');

              let key = '';
              switch (assetKey) {
                case assetList[0]:
                  key = `NtopAutoOp1${modifiedImplantType}File` as keyof typeof AssetType;
                  break;
                case assetList[1]:
                  key = `NtopAutoOp2${modifiedImplantType}File` as keyof typeof AssetType;
                  break;
                case assetList[2]:
                  key = `NtopManualOp1${modifiedImplantType}File` as keyof typeof AssetType;
                  break;
                case assetList[3]:
                  key = `NtopManualOp2${modifiedImplantType}File` as keyof typeof AssetType;
                  break;
                default:
              }
              try {
                if (key in AssetType) {
                  assetType = AssetType[key as keyof typeof AssetType];
                } else {
                  throw new Error(`Key does not exist in AssetType enum: ${key}`);
                }
                const createdAsset = await createAndUploadAsset(getValues(assetKey), assetType);

                if (createdAsset?.createdAsset?.asset?.assetId) {
                  assetIds[(assetKey + 'Id') as keyof typeof assetIds] =
                    createdAsset.createdAsset.asset.assetId;
                } else {
                  throw new Error(`Cannot find assetId for newly created asset: ${assetKey}`);
                }
              } catch (err) {
                enqueueSnackbar(`Upload failed for asset: ${assetKey}`, {
                  variant: 'error',
                });
                throw new Error(err as any);
              }
            } else {
              if (asset?.assetId) {
                assetIds[(assetKey + 'Id') as keyof typeof assetIds] = asset.assetId;
              } else {
                throw new Error(`Cannot find assetId for: ${assetKey}`);
              }
            }

            submitData = Object.fromEntries(
              Object.entries(submitData).filter(([key]) => !assetKey.includes(key)),
            ) as ImplantFormType;
          }
        } catch (err) {
          console.error(err);
          return Promise.reject(new Error('Issue occurred trying to upload assets'));
        }

        return { ...submitData, ...assetIds } as ImplantPartDetailsMutationVariablesType;
      },
    }));

    return (
      <form>
        <Stack spacing={3}>
          <Stack rowGap={1}>
            <Grid container spacing={1} alignItems={'center'} ml={2}>
              <Grid item xs={3} display={'flex'} alignItems={'center'}>
                <Typography variant={'body1'}>Active Date</Typography>
              </Grid>
              <Grid item xs={6}>
                <DatePickerRHF
                  name={'activeDate'}
                  control={control as unknown as Control<FieldValues>}
                  disabled={isSubmitting}
                  showDirtyBackgroundColor={control?._defaultValues?.['activeDate'] ? true : false}
                />
              </Grid>
              <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                <Box pl={2}>
                  <ErrorMessageRHF
                    name={'activeDate'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>

          <Stack rowGap={1}>
            <Typography variant={'button'} fontWeight={600}>
              Greenlight Guru Drawing Details
            </Typography>
            {greenlightGuruDrawingFormData.map((config, index) => {
              let prevVersionNumber;
              const hasDefault = control?._defaultValues?.[config.id] ? true : false;

              if (config.id === 'drawingVersionNumber' || config.id === 'form037VersionNumber') {
                prevVersionNumber = props?.defaultNumberValues?.[config.id];
              }
              return (
                <Grid container spacing={1} alignItems={'center'} key={index} ml={2}>
                  <Grid item xs={3} display={'flex'} alignItems={'center'}>
                    <Box>
                      <Typography variant={'body1'}>{config.label}</Typography>
                      {prevVersionNumber ? (
                        <Typography variant={'body2'} color={'textSecondary'}>
                          {`Previous version ${prevVersionNumber}`}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    {config.input === ImplantPartDetailInputType.Text ? (
                      <TextFieldRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting}
                        showDirtyBackgroundColor={hasDefault}
                      />
                    ) : config.input === ImplantPartDetailInputType.Number ? (
                      <NumberTextFieldRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting}
                        showDirtyBackgroundColor={hasDefault}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                    <Box pl={2}>
                      <ErrorMessageRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>

          <Stack rowGap={1}>
            <Typography variant={'button'} fontWeight={600}>
              Greenlight Guru Form-037 Details
            </Typography>
            {greenlightGuruForm037FormData.map((config, index) => {
              let prevVersionNumber;
              const hasDefault = control?._defaultValues?.[config.id] ? true : false;

              if (config.id === 'drawingVersionNumber' || config.id === 'form037VersionNumber') {
                prevVersionNumber = props?.defaultNumberValues?.[config.id];
              }
              return (
                <Grid container spacing={1} alignItems={'center'} key={index} ml={2}>
                  <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
                    <Box>
                      <Typography variant={'body1'}>{config.label}</Typography>
                      {prevVersionNumber ? (
                        <Typography variant={'body2'} color={'textSecondary'}>
                          {`Previous version ${prevVersionNumber}`}
                        </Typography>
                      ) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    {config.input === ImplantPartDetailInputType.Text ? (
                      <TextFieldRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting}
                        showDirtyBackgroundColor={hasDefault}
                      />
                    ) : config.input === ImplantPartDetailInputType.Number ? (
                      <NumberTextFieldRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                        disabled={isSubmitting}
                        showDirtyBackgroundColor={hasDefault}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={3} md={3} display={'flex'} justifyContent={'flex-start'}>
                    <Box pl={2}>
                      <ErrorMessageRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>

          <Stack rowGap={1}>
            <Typography variant={'button'} fontWeight={600}>
              Cloud Design NTop Files
            </Typography>
            {cloudDesignNtopFormData.map((config, index) => {
              return (
                <Grid container spacing={1} alignItems={'center'} key={index} ml={2}>
                  <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
                    <Box>
                      <Typography variant={'body1'}>{config.label}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <DropzoneRHF
                      name={config.id}
                      control={control as unknown as Control<FieldValues>}
                      validFileExtensions={[ValidFileExtensions.NTOP]}
                      variant={'small'}
                    />
                  </Grid>
                  <Grid item xs={3} md={3} display={'flex'} justifyContent={'flex-start'}>
                    <Box pl={2}>
                      <ErrorMessageRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>

          <Stack rowGap={1}>
            <Typography variant={'button'} fontWeight={600}>
              Implant Dimensions
            </Typography>
            {implantDimensionsFormData.map((config, index) => {
              return (
                <Grid container spacing={1} alignItems={'center'} key={index} ml={2}>
                  <Grid item xs={3} display={'flex'} alignItems={'center'}>
                    <Typography variant={'body1'}>{config.label}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <NumberTextFieldRHF
                      name={config.id + 'Min'}
                      label={'Min'}
                      control={control as unknown as Control<FieldValues>}
                      disabled={isSubmitting}
                      decimalPlaces={2}
                      showDirtyBackgroundColor
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <NumberTextFieldRHF
                      name={config.id + 'Max'}
                      label={'Max'}
                      control={control as unknown as Control<FieldValues>}
                      onChange={async (event: any) => {
                        setValue((config.id + 'Max') as keyof ImplantFormType, event.target.value, {
                          shouldValidate: true,
                        });
                        await trigger((config.id + 'Min') as keyof ImplantFormType);
                      }}
                      disabled={isSubmitting}
                      decimalPlaces={2}
                      showDirtyBackgroundColor
                    />
                  </Grid>
                  <Grid item xs={3} display={'flex'} justifyContent={'flex-start'}>
                    <Stack ml={2}>
                      <ErrorMessageRHF
                        name={config.id + 'Min'}
                        control={control as unknown as Control<FieldValues>}
                      />
                      <ErrorMessageRHF
                        name={config.id + 'Max'}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              );
            })}
          </Stack>
        </Stack>
      </form>
    );
  },
);

export default ImplantPartDetailForm;
