import {
  Alert,
  Box,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  AssetCategory,
  caseUtils,
  format,
  ILevels,
  ImplantPosition,
  ImplantType,
  IPatientRecord,
  IPlanImplant,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { ProgressButton } from '@workflow-nx/ui';
import { Scene } from 'babylonjs';
import { Form, Formik, FormikValues } from 'formik';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import * as Yup from 'yup';
import CustomDialog from '../../../../../../components/CustomDialog';
import useAuth from '../../../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../../../utils/featureFlags';
import { ISettingsForm19 } from '../../../../../../utils/form19';
import {
  getCentroidPlane,
  getImplantBulletDefaults,
  getImplantHasScrews,
  getTargetAssetPositionTranslationAndRotationByTag,
  getValidImplantMeasurementRanges,
} from '../utils/implantEditor';

export const addImplantDialogSchema = function (form19: ISettingsForm19 | undefined) {
  if (!form19) return;

  return Yup.object().shape({
    screwLength: Yup.string()
      .test('screwLengthMissing', 'Screw length cannot be none', function (value) {
        const partType = this.parent.partType;

        const hasScrews = getImplantHasScrews(partType);

        if (!hasScrews) {
          return true;
        }
        return ['13', '15', '17', '20', '25', '30'].includes(value ?? '');
      })
      .required('Screw length is required'),
    ap: Yup.number()
      .test('isInRangeAP', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(
          this.parent.partType,
          form19,
        );
        if (value && implantMeasurementRange) {
          result =
            value >= implantMeasurementRange?.minAP && value <= implantMeasurementRange?.maxAP;
        }
        return result;
      })
      .required(),
    ml: Yup.number()
      .test('isInRangeML', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(
          this.parent.partType,
          form19,
        );
        if (value && implantMeasurementRange) {
          result =
            value >= implantMeasurementRange?.minML && value <= implantMeasurementRange?.maxML;
        }
        return result;
      })
      .required(),
    cageTaper: Yup.number()
      .test('isInRangeCageTaper', 'Value is out of range', function (value) {
        let result = false;

        const implantMeasurementRange = getValidImplantMeasurementRanges(
          this.parent.partType,
          form19,
        );

        const checkValue = value ?? 0;
        if (isNumber(checkValue) && implantMeasurementRange) {
          const minCageTaper = implantMeasurementRange?.minCageTaper ?? 0;
          const maxCageTaper = implantMeasurementRange?.maxCageTaper ?? 10;
          result = checkValue >= minCageTaper && checkValue <= maxCageTaper;
        }

        return result;
      })
      .required(),
  });
};

type AddImplantDialogProps = {
  planId: number;
  caseLevels?: ILevels;
  patientRecord?: IPatientRecord;
  open?: boolean;
  onClose: (addedImplant?: IPlanImplant) => void;
  level: LevelType;
  scene: Scene;
  form19: ISettingsForm19 | undefined;
};

function getDefaultScrewLength(partType: PartType) {
  switch (partType) {
    case PartType.ACDF:
    case PartType.ACDF_X_LEFT_UP:
      return '15';
    case PartType.ALIF_X_TWO_UP:
    case PartType.ALIF_X_TWO_DOWN:
      return '25';
  }
  return '25';
}

export default function AddImplantDialog({
  open,
  onClose,
  caseLevels,
  level,
  scene,
  form19,
  planId,
  patientRecord,
}: AddImplantDialogProps) {
  const { hasFeatureFlag } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const partType = caseLevels?.[level] as PartType;
  const implantMeasurementRange = getValidImplantMeasurementRanges(partType, form19);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = {
    ap: 0,
    ml: 0,
    cageTaper: 0,
    graftWindow: 'GRAFT_SMALL',
    partType,
    screwLength: getDefaultScrewLength(partType),
    excludeImplantSize: false,
    obliqueThreadAngle: '',
    cranialCaudalThreadAngle: '',
  };

  const handleSubmit = async (values: FormikValues) => {
    try {
      setIsLoading(true);

      let implant: IPlanImplant | undefined = undefined;

      const implantPositionsData: ImplantPosition | null =
        getTargetAssetPositionTranslationAndRotationByTag(
          AssetCategory.Implants,
          `${level}_CYBORG_IMPLANT`,
          scene,
        );

      if (implantPositionsData) {
        const implantRotation = {
          x: implantPositionsData.eulerAngles.x,
          y: implantPositionsData.eulerAngles.y,
          z: implantPositionsData.eulerAngles.z,
        };
        const implantPosition = {
          x: implantPositionsData.centroid.x,
          y: implantPositionsData.centroid.y,
          z: implantPositionsData.centroid.z,
        };

        const hasScrews = getImplantHasScrews(partType);
        const interbodyDefaults = getImplantBulletDefaults(partType);

        implant = {
          planImplantId: undefined,
          level,
          planId,
          partType,
          position: implantPosition,
          rotation: implantRotation,
          referencePoints: getCentroidPlane(implantPosition, implantRotation),
          cageTaper: values.cageTaper,
          graftWindow: values.graftWindow,
          ap: values.ap,
          ml: values.ml,
          screwLength: hasScrews ? Number(values.screwLength) : undefined,
          obliqueThreadAngle: Number(values.obliqueThreadAngle) ?? null,
          cranialCaudalThreadAngle: Number(values.cranialCaudalThreadAngle) ?? null,
          bullet: interbodyDefaults.bullet,
          threadHeight: interbodyDefaults.threadHeight,
        };
      }

      onClose(implant);
    } catch (err) {
      console.error('Error loading implant', err);
      enqueueSnackbar(`${level} Implant failed to load`, {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };

  return open ? (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={addImplantDialogSchema(form19)}
      validateOnBlur={false}
      onSubmit={handleSubmit}
    >
      {({
        values,
        resetForm,
        submitForm,
        isSubmitting,
        touched,
        errors,
        handleBlur,
        handleChange,
        setFieldValue,
      }) => {
        const formattedLevelType = format.formatLevelType(level as LevelType);
        const implantType = caseUtils.getImplantType(partType);

        return (
          <CustomDialog
            maxWidth={'sm'}
            open={open}
            title={`Add ${format.formatPartType(partType)} Level ${formattedLevelType}`}
            onClose={() => {
              resetForm();
              onClose();
            }}
            positiveActionButtons={[
              <ProgressButton
                variant={'contained'}
                onClick={submitForm}
                label={'Add Implant'}
                loading={isLoading}
              />,
            ]}
          >
            <Form>
              <Box mb={2}>
                <Alert severity={'info'}>
                  <Typography color={'textPrimary'}>
                    Valid {format.formatPartType(partType)} ranges are &mdash; AP{' '}
                    {format.formatImplantRange('AP', implantMeasurementRange)} ML
                    {format.formatImplantRange('ML', implantMeasurementRange)}
                  </Typography>
                </Alert>
              </Box>
              <Box display={'flex'}>
                <Box>
                  <Box display={'flex'} alignItems={'center'} my={1}>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <Typography variant={'body1'}>AP</Typography>
                    </Box>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <TextField
                        data-testid={'add-implant-input-ap'}
                        size={'small'}
                        name={'ap'}
                        label={''}
                        disabled={isSubmitting}
                        error={Boolean(errors.ap)}
                        helperText={errors.ap ? 'Required' : ''}
                        fullWidth
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ap}
                        variant={'outlined'}
                        InputProps={{
                          inputProps: {
                            min: implantMeasurementRange ? implantMeasurementRange.minAP : null,
                            max: implantMeasurementRange ? implantMeasurementRange.maxAP : null,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} alignItems={'center'} my={1}>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <Typography variant={'body1'}>ML</Typography>
                    </Box>
                    <Box display={'flex'} width={200} justifyContent={'left'}>
                      <TextField
                        data-testid={'add-implant-input-ml'}
                        size={'small'}
                        name={'ml'}
                        label={``}
                        disabled={isSubmitting}
                        error={Boolean(errors.ml)}
                        helperText={errors.ml ? 'Required' : ''}
                        fullWidth
                        type="number"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.ml}
                        variant={'outlined'}
                        InputProps={{
                          inputProps: {
                            min: implantMeasurementRange ? implantMeasurementRange.minML : null,
                            max: implantMeasurementRange ? implantMeasurementRange.maxML : null,
                          },
                        }}
                      />
                    </Box>
                  </Box>
                  {[ImplantType.ALIFX].includes(implantType) ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>ALIF/X Screw Size</Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'screwLength'}
                            value={values.screwLength}
                            error={Boolean(touched.screwLength && errors.screwLength)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'add-implant-screw-length-select-menu-direction'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>No screws</MenuItem>
                            <MenuItem value={'20'}>20mm screws</MenuItem>
                            <MenuItem value={'25'}>25mm screws</MenuItem>
                            <MenuItem value={'30'}>30mm screws</MenuItem>
                          </Select>
                          {errors.screwLength ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {[ImplantType.ACDF, ImplantType.ACDFX].includes(implantType)
                    ? [
                        <Box display={'flex'} alignItems={'center'} my={1}>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <Typography variant={'body1'}>Cage Taper</Typography>
                          </Box>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <TextField
                              data-testid={'add-implant-input-cage-taper'}
                              size={'small'}
                              name={'cageTaper'}
                              label={``}
                              disabled={isSubmitting}
                              error={Boolean(errors.cageTaper)}
                              helperText={errors.cageTaper ? 'Required' : ''}
                              fullWidth
                              type="number"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.cageTaper}
                              variant={'outlined'}
                              InputProps={{
                                inputProps: {
                                  min: implantMeasurementRange
                                    ? implantMeasurementRange.minCageTaper
                                    : null,
                                  max: implantMeasurementRange
                                    ? implantMeasurementRange.maxCageTaper
                                    : null,
                                },
                              }}
                            />
                          </Box>
                        </Box>,
                        <Box display={'flex'} alignItems={'center'} my={1}>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <Typography variant={'body1'}>Graft Window</Typography>
                          </Box>
                          <Box display={'flex'} width={200} justifyContent={'left'}>
                            <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                              <Select
                                name={'graftWindow'}
                                value={values.graftWindow}
                                error={Boolean(touched.graftWindow && errors.graftWindow)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                data-testid={'add-implant-graft-window-select-menu'}
                                defaultValue={'20'}
                              >
                                <MenuItem value={'GRAFT_SMALL'}>Small</MenuItem>
                                <MenuItem value={'GRAFT_BIG'}>Big</MenuItem>
                              </Select>
                              {errors.screwLength ? (
                                <FormHelperText error>Required</FormHelperText>
                              ) : null}
                            </FormControl>
                          </Box>
                        </Box>,
                      ]
                    : null}
                  {[ImplantType.ACDFX].includes(implantType) ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>ACDF/X Screw Size</Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'screwLength'}
                            value={values.screwLength}
                            error={Boolean(touched.screwLength && errors.screwLength)}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'add-implant-screw-length-select-menu-direction'}
                            defaultValue={'15'}
                          >
                            <MenuItem value={''}>No screws</MenuItem>
                            <MenuItem value={'13'}>13mm screws</MenuItem>
                            <MenuItem value={'15'}>15mm screws</MenuItem>
                            <MenuItem value={'17'}>17mm screws</MenuItem>
                            <MenuItem value={'20'}>20mm screws</MenuItem>
                          </Select>
                          {errors.screwLength ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) &&
                  [ImplantType.LLIF].includes(implantType) &&
                  patientRecord?.hasPelvisOblique ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Oblique Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          Oblique Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'obliqueThreadAngle'}
                            value={values.obliqueThreadAngle}
                            error={Boolean(touched.obliqueThreadAngle && errors.obliqueThreadAngle)}
                            onChange={(e) => {
                              setFieldValue(`cranialCaudalThreadAngle`, '0', true);

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            data-testid={'add-implant-oblique-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                          </Select>
                          {errors.obliqueThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) &&
                  [ImplantType.LLIF].includes(implantType) &&
                  patientRecord?.hasPelvisHighCrest ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Cranial-Caudal Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          High Crest Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'cranialCaudalThreadAngle'}
                            value={values.cranialCaudalThreadAngle}
                            error={Boolean(
                              touched.cranialCaudalThreadAngle && errors.cranialCaudalThreadAngle,
                            )}
                            onChange={(e) => {
                              setFieldValue(`obliqueThreadAngle`, '0', true);

                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            data-testid={'add-implant-cranial-caudal-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                            <MenuItem value={'-5'}>-5°</MenuItem>
                            <MenuItem value={'-10'}>-10°</MenuItem>
                            <MenuItem value={'-15'}>-15°</MenuItem>
                            <MenuItem value={'-20'}>-20°</MenuItem>
                          </Select>
                          {errors.cranialCaudalThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                  {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) &&
                  [PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(partType) &&
                  patientRecord?.hasPelvisHighPelvicIncidence ? (
                    <Box display={'flex'} alignItems={'center'} my={1}>
                      <Box width={200} justifyContent={'left'}>
                        <Typography variant={'body1'}>Cranial-Caudal Thread Angle</Typography>
                        <Typography color={'textSecondary'} variant={'body2'}>
                          High PI Pelvis
                        </Typography>
                      </Box>
                      <Box display={'flex'} width={200} justifyContent={'left'}>
                        <FormControl fullWidth={true} variant={'outlined'} size={'small'}>
                          <Select
                            name={'cranialCaudalThreadAngle'}
                            value={values.cranialCaudalThreadAngle}
                            error={Boolean(
                              touched.cranialCaudalThreadAngle && errors.cranialCaudalThreadAngle,
                            )}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            data-testid={'add-implant-cranial-caudal-thread-angle'}
                            defaultValue={'25'}
                          >
                            <MenuItem value={''}>0°</MenuItem>
                            <MenuItem value={'5'}>+5°</MenuItem>
                            <MenuItem value={'10'}>+10°</MenuItem>
                            <MenuItem value={'15'}>+15°</MenuItem>
                            <MenuItem value={'20'}>+20°</MenuItem>
                          </Select>
                          {errors.cranialCaudalThreadAngle ? (
                            <FormHelperText error>Required</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Box>
                    </Box>
                  ) : null}
                </Box>
              </Box>
            </Form>
          </CustomDialog>
        );
      }}
    </Formik>
  ) : null;
}
