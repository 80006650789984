import { ApolloError, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, SelectChangeEvent } from '@mui/material';
import { ILocation, IUser, OrganizationType, UserRoleType, format } from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { sortBy } from 'lodash';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { ComponentProps, useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import CustomDialog from '../../../components/CustomDialog';
import { surgeonDialogSchema } from '../../../extras/schemas';
import {
  CREATE_USER,
  DELETE_USER,
  FIND_LOCATIONS,
  FIND_ORGANIZATIONS,
  UPDATE_USER,
} from '../../../gql';

export interface SurgeonFormType extends FieldValues {
  firstName: string;
  lastName: string;
  email: string;
  linkedOrganizationId?: string | undefined;
  organizationId: string;
  locationId: string;
}

export function CreateSurgeonDialog(props: {
  open: boolean;
  user?: IUser;
  onCreate?: (caseId: string) => void;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [institutions, setInstitutions] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [distributors, setDistributors] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [locations, setLocations] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);
  const [createUser, { loading: loadingCreateUser }] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);
  const confirm = useConfirm();
  const navigate = useNavigate();
  const isEditDialog = !!props.user;
  const formRowTextData: InputConfigRHF[] = [
    {
      id: 'firstName',
      label: 'First Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'lastName',
      label: 'Last Name',
      input: InputTypeRHF.Text,
    },
    {
      id: 'email',
      label: 'Email',
      input: InputTypeRHF.Text,
    },
    {
      id: 'phone',
      label: 'Phone Number',
      input: InputTypeRHF.Phone,
    },
    {
      id: 'organizationId',
      label: 'Institution',
      input: InputTypeRHF.Select,
      menuItems: institutions,
    },
    {
      id: 'locationId',
      label: 'Location',
      input: InputTypeRHF.Select,
      menuItems: locations,
    },
    {
      id: 'linkedOrganizationId',
      label: 'Distributor',
      input: InputTypeRHF.Select,
      menuItems: distributors,
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    setError,
    resetField,
    setValue,
    formState: { isSubmitting },
  } = useForm<FieldValues, SurgeonFormType>({
    defaultValues: {
      firstName: props?.user?.firstName ?? '',
      lastName: props?.user?.lastName ?? '',
      email: props?.user?.email ?? '',
      phone: props?.user?.phone ?? '',
      linkedOrganizationId:
        props?.user?.linkedOrganization?.organizationId?.toString() ?? undefined,
      organizationId: props?.user?.organization?.organizationId.toString() ?? undefined,
      locationId: props?.user?.location?.locationId.toString() ?? undefined,
    },
    resolver: yupResolver(surgeonDialogSchema) as unknown as Resolver<FieldValues, SurgeonFormType>,
  });

  const { loading: loadingFindOrganizations } = useQuery(FIND_ORGANIZATIONS, {
    onCompleted: (orgData) => {
      const instituations: {
        key: string;
        value: string;
      }[] = [];
      const distributors: {
        key: string;
        value: string;
      }[] = [];

      for (const org of orgData.organizations.organizations) {
        if (org.organizationType === OrganizationType.Hospital) {
          instituations.push({
            key: org.organizationId as string,
            value: org.name,
          });
        }
        if (org.organizationType === OrganizationType.Distributor) {
          distributors.push({
            key: org.organizationId as string,
            value: org.name,
          });
        }
      }

      setDistributors(sortBy(distributors, (o) => o.value.toLowerCase()));
      setInstitutions(sortBy(instituations, (o) => o.value.toLowerCase()));
    },
  });
  const [findLocations] = useLazyQuery(FIND_LOCATIONS, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const locations = data.locations || [];
      setLocations(
        sortBy(
          locations.map((location: ILocation) => {
            return {
              key: location.locationId,
              value: location.description,
            };
          }),
          (o) => o.value.toLowerCase(),
        ),
      );
    },
  });

  const handleSubmitForm: SubmitHandler<FieldValues> = async (data) => {
    try {
      const formattedPhone = !isValidPhoneNumber(data.phone)
        ? undefined
        : parsePhoneNumber(data.phone).number;

      const variables = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,

        phone: formattedPhone ?? null,
        role: UserRoleType.Surgeon,
        linkedOrganizationId: data.linkedOrganizationId
          ? parseInt(data.linkedOrganizationId)
          : undefined,
        organizationId: data.organizationId,
        locationId: data.locationId,
      };

      if (isEditDialog) {
        await updateUser({
          variables: {
            userId: props.user?.userId,
            status: props.user?.status,
            ...variables,
          },
        });

        props.onClose(true);
      } else {
        const { data: createUserData } = await createUser({ variables });

        const userId = createUserData.createUser.user.userId;

        if (props.onCreate) {
          props.onCreate(userId);
        }
      }

      reset();
      enqueueSnackbar(isEditDialog ? 'Surgeon Details Updated' : 'Surgeon Created', {
        variant: 'success',
      });
    } catch (err: unknown) {
      console.error(err);
      let errorMessage = '';

      if (err instanceof ApolloError) {
        if (err?.graphQLErrors?.[0]?.extensions?.code === 'EMAIL_NOT_UNIQUE') {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
          resetField('email');
          setError('email', { message: 'Email is not unique' });
          return;
        }
        if ((err?.graphQLErrors?.[0]?.extensions?.code as string)?.includes('PHONE_NOT_UNIQUE')) {
          enqueueSnackbar(err.message, {
            variant: 'error',
          });
          resetField('phone');
          setError('phone', { message: 'Phone number is not unique' });
          return;
        } else {
          errorMessage = 'An error occurred creating the surgeon';
        }
      } else {
        if (isEditDialog) {
          errorMessage = 'An error occurred updating the surgeon details';
        } else {
          errorMessage = 'An error occurred creating the surgeon';
        }
      }
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
    }
  };

  const handleLocationChange = useCallback(
    (event: SelectChangeEvent<HTMLSelectElement>): void => {
      const organizationId = event.target.value;
      if (organizationId) {
        setValue('locationId', '');
        findLocations({
          variables: { organizationId: organizationId },
        });
      } else {
        setValue('locationId', '');
        setLocations([]);
      }
    },
    [findLocations, setValue],
  );

  const handleDeleteUserClick = async () => {
    try {
      await confirm({
        title: `Delete Surgeon ${format.formatName(props.user)}`,
        description: 'Are you sure you want to delete this surgeon?',
      });

      try {
        await deleteUser({
          variables: {
            userId: props.user?.userId,
          },
        });

        enqueueSnackbar(`Surgeon ${format.formatName(props.user)} was successfully deleted.`, {
          variant: 'success',
        });

        navigate('/app/surgeons');
      } catch (errors) {
        console.error(errors);
        enqueueSnackbar('Error deleting Surgeon', {
          variant: 'error',
        });
      }
    } catch (errors) {
      console.error(errors);
    }
  };

  const loading = loadingCreateUser || loadingFindOrganizations;

  useEffect(() => {
    if (props?.user?.organization?.organizationId) {
      findLocations({
        variables: { organizationId: props?.user?.organization?.organizationId },
      });
    }
  }, [props?.user, findLocations]);

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as unknown as Control<FieldValues>,
    disabled: isSubmitting,
    onChange: config.id === 'organizationId' ? handleLocationChange : undefined,
    selectFieldProps: {
      hideNone: true,
    },
  });

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={isEditDialog ? `Edit Surgeon - ${format.formatName(props.user)}` : 'Create Surgeon'}
      onClose={() => {
        reset();
        props.onClose(true);
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting || loading}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={isEditDialog ? 'Save' : 'Create'}
          loading={isSubmitting || loading}
        />,
      ]}
      negativeActionButtons={
        isEditDialog
          ? [
              <Button variant={'outlined'} onClick={handleDeleteUserClick}>
                Delete
              </Button>,
            ]
          : undefined
      }
    >
      <form>
        <Grid container spacing={3}>
          {formRowTextData.map((config) => {
            return <InputRHF key={config.id} {...getInputProps(config)} />;
          })}
        </Grid>
      </form>
    </CustomDialog>
  );
}
