/* eslint-disable @typescript-eslint/no-empty-function */
import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  AssetType,
  CloudDesignQueueType,
  IAsset,
  ICase,
  ICloudDesignQueue,
  IPatientRecord,
  IPlan,
  IPlanImplant,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { Color3, Mesh, Scene } from 'babylonjs';
import { ReactNode, createContext, useContext, useEffect, useReducer, useState } from 'react';
import { FIND_CLOUD_DESIGN_QUEUE_ITEMS } from '../../../../../gql';
import { findPlanImplantAssets, loadImplant } from './ImplantEditorDialog.helpers';
import {
  ImplantEditorActionType,
  ImplantEditorStateType,
  implantEditorReducer,
} from './ImplantEditorDialog.reducer';
import { getAssetText } from './ImplantEditorDialog.view';

import { CaseViewContext } from '../../CaseView.context';
import { getImplantData } from './MoveImplantView/MoveImplantView.helpers';
import useCreateAndUploadAsset from 'apps/workflow-client/src/app/hooks/useCreateAndUploadAsset';
import { useSnackbar } from 'notistack';
import { getImplantHasScrews } from './utils/implantEditor';
import { addAssetToScene } from '../../../../../components/SceneComponent/renderer';
import { FeatureFlag } from 'apps/workflow-client/src/app/utils/featureFlags';
import useAuth from '../../../../../hooks/useAuth';

const initialState: ImplantEditorStateType = {
  vertebralBodyAssets: [],
  caseLevels: [],
  measurements: [],
  isSceneLoaded: false,
  lumbarLordosis: 0,
  lumbarCoronalCobb: 0,
  pelvicIncidence: 0,
};

export const ImplantEditorDialogContext = createContext({
  activeCase: {} as ICase,
  plan: {} as IPlan,
  patientRecord: {} as IPatientRecord,
  cloudDesignQueues: [] as ICloudDesignQueue[],
  planImplants: [] as IPlanImplant[],
  findCloudDesignQueues: (): Promise<ICloudDesignQueue[]> => {
    return new Promise(() => []);
  },
  findPlanImplant: (level: LevelType): Promise<IPlanImplant | undefined> => {
    return new Promise(() => {});
  },
  findPlanImplants: async () => {},
  loadImplantAsset: async (planImplant: IPlanImplant, scene: Scene): Promise<void> => {},
  removePlanImplant: async (planImplantId: number): Promise<void> => {},
  updateCloudDesignQueues: (): void => {},
  uploadAssets: async (
    levelType: LevelType,
    partType: PartType,
    mesh: Mesh,
    scene: Scene,
  ): Promise<void> => {},
  upsertPlanImplant: async (
    planImplantId: number | undefined,
    planId: number,
    planImplant: IPlanImplant,
  ): Promise<void> => {},
  dispatch: (() => {}) as React.Dispatch<ImplantEditorActionType>,
  state: initialState,
  handleSceneReady: (scene: Scene) => {},
  scene: undefined as Scene | undefined,
});

export const ImplantEditorDialogProvider = ({
  plan,
  patientRecord,
  activeCase,
  children,
}: {
  plan: IPlan;
  patientRecord: IPatientRecord;
  activeCase: ICase;
  children: ReactNode;
}) => {
  const { hasFeatureFlag } = useAuth();
  const caseViewContext = useContext(CaseViewContext);
  const { createAndUploadAsset } = useCreateAndUploadAsset();
  const { enqueueSnackbar } = useSnackbar();

  const [scene, setScene] = useState<Scene>();

  const [state, dispatch] = useReducer(
    implantEditorReducer(activeCase, caseViewContext.caseMeasurementsVersion, plan, scene),
    initialState,
  );

  const [planImplants, setPlanImplants] = useState<IPlanImplant[]>([]);
  const [cloudDesignQueues, setCloudDesignQueues] = useState<ICloudDesignQueue[]>([]);

  const [findCloudDesignQueueItems] = useLazyQuery(FIND_CLOUD_DESIGN_QUEUE_ITEMS, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    pollInterval: hasFeatureFlag?.(FeatureFlag.fastImplantCuttingEnabled) ? 0 : 30000,
  });

  const [upsertPlanImplant] = useMutation(gql`
    mutation UpsertPlanImplant($planImplantId: Int, $input: UpsertPlanImplantInput!) {
      upsertPlanImplant(planImplantId: $planImplantId, input: $input) {
        status
      }
    }
  `);
  const [removePlanImplant] = useMutation(gql`
    mutation RemovePlanImplant($planImplantId: Int!) {
      deletePlanImplant(planImplantId: $planImplantId) {
        status
      }
    }
  `);
  const [findImplantPart] = useLazyQuery(gql`
    query FindImplantPart(
      $partType: LevelPart!
      $ap: Int!
      $ml: Int!
      $cageTaper: Int
      $graftWindow: String
      $level: String
      $view: ImplantView
      $obliqueThreadAngle: Int
      $cranialCaudalThreadAngle: Int
    ) {
      implantPart(
        input: {
          partType: $partType
          ap: $ap
          ml: $ml
          cageTaper: $cageTaper
          graftWindow: $graftWindow
          level: $level
          view: $view
          obliqueThreadAngle: $obliqueThreadAngle
          cranialCaudalThreadAngle: $cranialCaudalThreadAngle
        }
      ) {
        signedDownloadUrl
      }
    }
  `);

  const [findPelvisAsset] = useLazyQuery(gql`
    query FindAsset($caseId: Int!, $assetTypeFilter: [AssetType]!) {
      assets(caseId: $caseId, assetTypeFilter: $assetTypeFilter) {
        signedDownloadUrl
      }
    }
  `);

  const [findPlanImplants] = useLazyQuery(
    gql`
      query FindPlanImplants($planId: Int!) {
        planImplants(planId: $planId) {
          planImplantId
          planId
          level
          bullet
          partType
          rotation
          position
          referencePoints
          cageTaper
          graftWindow
          ap
          ml
          screwLength
          threadHeight
          excludedImplantSizes
          obliqueThreadAngle
          cranialCaudalThreadAngle
          measurements
          assets {
            assetId
            assetType
            planId
            signedDownloadUrl
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [findPlanImplant] = useLazyQuery(
    gql`
      query FindPlanImplant($planId: Int!, $level: LevelType) {
        planImplant(planId: $planId, level: $level) {
          planImplantId
          planId
          level
          bullet
          partType
          rotation
          position
          referencePoints
          cageTaper
          graftWindow
          ap
          ml
          screwLength
          threadHeight
          excludedImplantSizes
          obliqueThreadAngle
          cranialCaudalThreadAngle
          assets {
            assetId
            assetType
            planId
            signedDownloadUrl
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
    },
  );

  const handleUploadAssets = async (
    levelType: LevelType,
    partType: PartType,
    mesh: Mesh,
    scene: Scene,
  ) => {
    const blobs = getImplantData(levelType, partType, mesh, scene);

    // now upload the implant, screw and guide assets
    try {
      for (const blob of blobs) {
        const createdAsset = await createAndUploadAsset(
          new File([blob.blob], blob.name, { type: 'application/octet-stream' }),
          blob?.assetType,
          plan?.caseId,
          plan?.planId,
        );
        if (createdAsset?.error) {
          throw new Error(createdAsset?.error);
        }
      }
    } catch (e) {
      console.error(e);
      enqueueSnackbar(`Error uploading asset: ${(e as Error)?.message}`, {
        variant: 'error',
      });
    }

    // dispose of any cut assets
    scene.getMeshByName(`${levelType}_APP`)?.dispose();
    scene.getMeshByName(`${levelType}_APP_MINUS`)?.dispose();
  };

  const handleFindPlanImplants = async () => {
    if (plan) {
      const { data } = await findPlanImplants({ variables: { planId: plan.planId } });
      setPlanImplants(data.planImplants);
    }
  };

  const handleFindCloudDesignQueues = async () => {
    const result = await findCloudDesignQueueItems({
      variables: {
        caseId: plan?.caseId,
        planId: plan?.planId,
        level: undefined,
        type: CloudDesignQueueType.Cut,
      },
    });

    return result.data.cloudDesignQueues ?? [];
  };

  const handleUpdateCloudDesignQueues = async () => {
    const result = await findCloudDesignQueueItems({
      variables: {
        caseId: plan?.caseId,
        planId: plan?.planId,
        level: undefined,
        type: CloudDesignQueueType.Cut,
      },
    });

    setCloudDesignQueues(result.data.cloudDesignQueues);
  };

  const handleFindPlanImplant = async (level: LevelType): Promise<IPlanImplant | undefined> => {
    const response = await findPlanImplant({ variables: { planId: plan?.planId, level } });
    return response.data.planImplant;
  };

  const handleLoadImplantAsset = async (planImplant: IPlanImplant, scene: Scene): Promise<void> => {
    const foundPelvisMesh = scene.getMeshByName(AssetType.Pelvis) as Mesh;

    // If the pelvis mesh exists and hasn't been loaded yet
    if (!foundPelvisMesh) {
      const { data: pelvisData } = await findPelvisAsset({
        variables: {
          caseId: plan.caseId,
          assetTypeFilter: [AssetType.Pelvis],
        },
      });

      if (pelvisData?.assets?.length) {
        const pelvisSignedDownloadUrl = pelvisData?.assets?.[0]?.signedDownloadUrl;
        if (pelvisSignedDownloadUrl) {
          const pelvisMesh = await addAssetToScene(pelvisSignedDownloadUrl, scene);
          if (pelvisMesh) {
            pelvisMesh.name = AssetType.Pelvis;
          }
        }
      }
    }

    const planImplantAssets = findPlanImplantAssets(planImplant.level, planImplant.assets ?? []);
    let shouldSetPlanImplantPosition = false;

    // if the implant asset doesn't exist, then load it
    if (!planImplantAssets?.implantAsset?.signedDownloadUrl) {
      const { data } = await findImplantPart({
        variables: {
          partType: planImplant.partType,
          ap: planImplant.ap,
          ml: planImplant.ml,
          cageTaper: planImplant.cageTaper,
          graftWindow: planImplant.graftWindow,
          level: planImplant.level,
          obliqueThreadAngle: planImplant.obliqueThreadAngle,
          cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle,
        },
      });
      planImplantAssets.implantAsset = {
        signedDownloadUrl: data.implantPart.signedDownloadUrl,
      } as unknown as IAsset;

      // implant position should only be set if the implant is initially retreived from onshape
      shouldSetPlanImplantPosition = true;
    }

    if (planImplantAssets.implantAsset?.signedDownloadUrl) {
      await loadImplant(
        planImplant.level,
        planImplantAssets.implantAsset.signedDownloadUrl,
        `${planImplant.level}_CYBORG_IMPLANT` as AssetType,
        planImplant,
        scene,
        findImplantPart,
        {
          color: Color3.FromHexString('#e6dca6'), // yellow-ish color
          setPosition: shouldSetPlanImplantPosition,
          defaultVisibility: planImplantAssets?.planAsset?.signedDownloadUrl ? 0 : 1,
          tags: ['template', 'implant', planImplant.level],
        },
      );
    }

    if (planImplantAssets?.planAsset?.signedDownloadUrl) {
      const planAssetMesh = await loadImplant(
        planImplant.level,
        planImplantAssets.planAsset.signedDownloadUrl,
        planImplantAssets.planAsset.assetType,
        planImplant,
        scene,
        findImplantPart,
        {
          color: Color3.FromHexString('#d3dbdd'), // titanium color
          setPosition: false,
          defaultVisibility: 1,
          tags: ['plan', 'cut', 'implant', planImplant.level],
        },
      );

      if (planAssetMesh && planImplantAssets?.planDimensions?.signedDownloadUrl && scene) {
        planAssetMesh.metadata.dimensions = await getAssetText(
          planImplantAssets?.planDimensions?.signedDownloadUrl,
        );
      }

      if (planAssetMesh && planImplantAssets?.planMetadata?.signedDownloadUrl && scene) {
        planAssetMesh.metadata.metadata = await getAssetText(
          planImplantAssets?.planMetadata?.signedDownloadUrl,
        );
      }
    }

    if (planImplantAssets?.minusAsset?.signedDownloadUrl) {
      const minusAssetMesh = await loadImplant(
        planImplant.level,
        planImplantAssets.minusAsset.signedDownloadUrl,
        planImplantAssets.minusAsset.assetType,
        planImplant,
        scene,
        findImplantPart,
        {
          color: Color3.FromHexString('#777a7b'),
          setPosition: false,
          defaultVisibility: 0,
          tags: ['minus', 'cut', 'implant', planImplant.level],
        },
      );

      if (minusAssetMesh && planImplantAssets?.minusDimensions?.signedDownloadUrl && scene) {
        minusAssetMesh.metadata.dimensions = await getAssetText(
          planImplantAssets?.minusDimensions?.signedDownloadUrl,
        );
      }
    }
  };

  async function handleUpsertPlanImplant(
    planImplantId: number | undefined,
    planId: number,
    planImplant: IPlanImplant,
  ) {
    const hasScrews = getImplantHasScrews(planImplant.partType);

    await upsertPlanImplant({
      variables: {
        planImplantId,
        input: {
          planId,
          level: planImplant.level,
          partType: planImplant.partType,
          bullet: planImplant.bullet,
          position: planImplant.position,
          rotation: planImplant.rotation,
          referencePoints: planImplant.referencePoints,
          screwLength: hasScrews ? planImplant.screwLength : undefined,
          cageTaper: planImplant.cageTaper,
          graftWindow: planImplant.graftWindow,
          ap: planImplant.ap,
          ml: planImplant.ml,
          threadHeight: planImplant.threadHeight,
          excludedImplantSizes: planImplant.excludedImplantSizes,
          obliqueThreadAngle: planImplant.obliqueThreadAngle,
          cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle,
        },
      },
    });

    await handleFindPlanImplants();
  }

  const handleSceneReady = (scene: Scene) => {
    setScene(scene);
    dispatch({ type: 'SCENE_READY', data: {} });
  };

  const handleRemovePlanImplant = async (planImplantId: number) => {
    await removePlanImplant({ variables: { planImplantId } });

    await handleFindPlanImplants();
  };

  useEffect(() => {
    handleUpdateCloudDesignQueues();
  }, []);

  return (
    <ImplantEditorDialogContext.Provider
      value={{
        activeCase: activeCase,
        plan: plan,
        patientRecord: patientRecord,
        cloudDesignQueues: cloudDesignQueues,
        planImplants: planImplants,
        findCloudDesignQueues: handleFindCloudDesignQueues,
        findPlanImplant: handleFindPlanImplant,
        findPlanImplants: handleFindPlanImplants,
        loadImplantAsset: handleLoadImplantAsset,
        removePlanImplant: handleRemovePlanImplant,
        updateCloudDesignQueues: handleUpdateCloudDesignQueues,
        uploadAssets: handleUploadAssets,
        upsertPlanImplant: handleUpsertPlanImplant,
        handleSceneReady,
        state,
        dispatch,
        scene,
      }}
    >
      {children}
    </ImplantEditorDialogContext.Provider>
  );
};
