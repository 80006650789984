import { useLazyQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client/core';
import {
  faBugs,
  faCircleNotch,
  faDownload,
  faRulerTriangle,
  faScissors,
  faX,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import {
  AssetType,
  CloudDesignStatus,
  ICloudDesignQueue,
  LevelSize,
  LevelType,
  format,
} from '@workflow-nx/common';
import { file } from '@workflow-nx/utils';
import * as FileSaver from 'file-saver';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import {
  CREATE_ASSET_DOWNLOAD_URL,
  DELETE_ASSET,
  DELETE_EXPORT_CLOUD_DESIGN,
  EXPORT_CLOUD_DESIGN_CUT,
  FIND_ASSETS,
  IMPLANT_CREATE_CUT,
} from '../../../../../../gql';
import useAuth from '../../../../../../hooks/useAuth';
import { FeatureFlag } from 'apps/workflow-client/src/app/utils/featureFlags';

export function CloudDesignButton(props: {
  caseId: number;
  planId: number;
  cloudDesignQueue: ICloudDesignQueue | undefined;
  level: LevelType;
  disabled: boolean;
  isCut: boolean;
  onCut: (status: 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'ERROR') => void;
  onCutCancel: () => void;
  onCutComplete?: () => Promise<void>;
}) {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [exportCloudDesignCut, { loading: loadingExportCloudDesignCut }] =
    useMutation(EXPORT_CLOUD_DESIGN_CUT);
  const [implantCreateCut, { loading: loadingImplantCreateCut }] = useMutation(IMPLANT_CREATE_CUT);
  const [findAssets] = useLazyQuery(FIND_ASSETS);
  const [findCloudDesignQueueQuery] = useLazyQuery(
    gql`
      query FindCloudDesignQueue($cloudDesignQueueId: Int!) {
        cloudDesignQueue(cloudDesignQueueId: $cloudDesignQueueId) {
          cloudDesignQueue {
            cloudDesignQueueId
            caseId
            planId
            assetId
            level
            levelSize
            name
            status
            minutesProcessing
            startedAt
            finishedAt
            log
          }
          signedInputDownloadUrl
          signedOutputDownloadUrl
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );
  const [deleteAsset] = useMutation(DELETE_ASSET);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [deleteExportCloudDesign] = useMutation(DELETE_EXPORT_CLOUD_DESIGN);
  const [isCancellingCutLevel, setIsCancellingCutLevel] = useState(false);
  const currentCutStatus = useRef(props.cloudDesignQueue?.status);
  const { hasFeatureFlag } = useAuth();

  const handleFastCutImplantClick = async (): Promise<void> => {
    try {
      await implantCreateCut({
        variables: {
          caseId: props.caseId,
          input: {
            level: props.level,
            levelSize: LevelSize.Normal,
            planId: props.planId,
          },
        },
      });

      await props?.onCutComplete?.();

      enqueueSnackbar(
        `Cutting of
         ${format.formatLevelType(props.level)} complete`,
        {
          variant: 'success',
        },
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        `An error occurred while cutting the implant for ${format.formatLevelType(props.level)}`,
        {
          variant: 'error',
        },
      );
    }
  };

  const handleCutImplantClick = async (): Promise<void> => {
    try {
      // todo: turn into promise and return progress of cutting state
      await exportCloudDesignCut({
        variables: {
          caseId: props.caseId,
          input: {
            level: props.level,
            levelSize: LevelSize.Normal,
            planId: props.planId,
          },
        },
      });

      const { data: findAssetsData } = await findAssets({
        variables: {
          caseId: props.caseId,
          planId: props.planId,
          assetTypeFilter: [`${props.level}_APP`, `${props.level}_APP_MINUS`],
        },
      });

      // delete any existing cut assets for this caseId / planId
      for (const asset of findAssetsData?.assets ?? []) {
        await deleteAsset({
          variables: {
            assetId: asset.assetId,
          },
        });
      }

      enqueueSnackbar(
        `Cutting of
         ${format.formatLevelType(props.level)} initiated`,
        {
          variant: 'success',
        },
      );
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        `An error occurred while initiating cloud design. ${(err as Error).message}`,
        {
          variant: 'error',
        },
      );
    }
  };

  async function handleDownloadCloudDesignAssets(cloudDesignQueueId?: number, name?: string) {
    if (!cloudDesignQueueId) return;

    const { data } = await findCloudDesignQueueQuery({
      variables: {
        cloudDesignQueueId,
      },
    });

    if (data.cloudDesignQueue) {
      const signedInputDownloadUrl = data.cloudDesignQueue.signedInputDownloadUrl;
      const signedOutputDownloadUrl = data.cloudDesignQueue.signedOutputDownloadUrl;

      if (signedInputDownloadUrl) {
        enqueueSnackbar('Downloading Cloud Design input zip file', { variant: 'info' });
        const response = await file.downloadFile(signedInputDownloadUrl);
        FileSaver.saveAs(response.data, `${name ?? 'unknown'}-input.zip`);
      }

      if (signedOutputDownloadUrl) {
        enqueueSnackbar('Downloading Cloud Design output zip file', { variant: 'info' });
        const response = await file.downloadFile(signedOutputDownloadUrl);
        FileSaver.saveAs(response.data, `${name ?? 'unknown'}-output.zip`);
      }
    }
  }

  const handleDownloadAsset = async (assetType: AssetType) => {
    const { data: findAssetsData } = await findAssets({
      variables: {
        caseId: props.caseId,
        planId: props.planId,
        assetTypeFilter: assetType,
      },
    });

    const asset = findAssetsData?.assets?.[0];

    const { data } = await createAssetDownloadUrl({
      variables: {
        assetId: asset?.assetId,
      },
    });

    const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl);

    FileSaver.saveAs(response.data, asset?.fileName);
  };

  const handleCutCancelImplantClick = async () => {
    try {
      if (!props.cloudDesignQueue) {
        return;
      }

      if (props.cloudDesignQueue?.status === CloudDesignStatus.Processing) {
        await confirm({
          title: `Cancel cutting on ${format.formatLevelType(props.level)} ?`,
          description: (
            <>
              This will cancel the cutting of the implant at level{' '}
              {format.formatLevelType(props.level)}. Are you sure you wish to continue?
            </>
          ),
        });
      }

      setIsCancellingCutLevel(true);

      await deleteExportCloudDesign({
        variables: {
          cloudDesignQueueId: props.cloudDesignQueue?.cloudDesignQueueId,
        },
      }).finally(() => {
        setIsCancellingCutLevel(false);
        enqueueSnackbar(`The cloud design operation has been cancelled`, { variant: 'success' });
        props.onCutCancel();
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred cancelling the cloud design operation', {
        variant: 'error',
      });
    }
  };

  const handleClose = async (key: string) => {
    function genericCatchBlock() {
      enqueueSnackbar('Error occurred', {
        variant: 'error',
      });
    }

    switch (key) {
      case 'CANCEL_CUT':
        await handleCutCancelImplantClick();
        break;
      case 'CUT':
        if (props.isCut) {
          try {
            await confirm({
              title: `Re-cut implant at ${format.formatLevelType(props.level)} ?`,
              allowClose: false,
              description: (
                <>
                  This will remove the existing cut and restart the cutting process of the implant
                  at level {format.formatLevelType(props.level)}. Are you sure you wish to continue?
                </>
              ),
            });
          } catch (e) {
            return;
          }
        }
        if (hasFeatureFlag?.(FeatureFlag.fastImplantCuttingEnabled)) {
          setLoading(true);
          await handleFastCutImplantClick();
          setLoading(false);
        } else {
          setLoading(true);
          props.onCut('PENDING');
          handleCutImplantClick()
            .then(() => {
              props.onCut('PROCESSING');
            })
            .catch(() => {
              props.onCut('ERROR');
              genericCatchBlock();
            })
            .finally(() => {
              setLoading(false);
            });
        }

        break;
      case 'DOWNLOAD_ASSET':
        setLoading(true);
        handleDownloadAsset(`${props.level}_APP` as AssetType)
          .catch(genericCatchBlock)
          .finally(() => setLoading(false));
        break;
      case 'DOWNLOAD_CLOUD_DESIGN_ASSETS':
        setLoading(true);
        handleDownloadCloudDesignAssets(
          props?.cloudDesignQueue?.cloudDesignQueueId,
          props?.cloudDesignQueue?.name,
        )
          .catch(genericCatchBlock)
          .finally(() => setLoading(false));
        break;
      case 'DOWNLOAD_DIMENSIONS':
        setLoading(true);
        handleDownloadAsset(`${props.level}_APP_DIMENSIONS` as AssetType)
          .catch(genericCatchBlock)
          .finally(() => setLoading(false));
        break;
    }
  };

  const downloadCloudDesignAssetsLabel = 'Download Cloud Design Files';

  const cutOptions = [
    { key: 'CUT', icon: faScissors, label: 'Re-cut Implant' },
    { key: 'DOWNLOAD_ASSET', icon: faDownload, label: 'Download Implant' },
    { key: 'DOWNLOAD_CLOUD_DESIGN_ASSETS', icon: faBugs, label: downloadCloudDesignAssetsLabel },
    { key: 'DOWNLOAD_DIMENSIONS', icon: faRulerTriangle, label: 'Download Dimensions' },
  ];

  const uncutOptions = [{ key: 'CUT', icon: faScissors, label: 'Cut Implant' }];
  if (props.cloudDesignQueue) {
    uncutOptions.push({
      key: 'DOWNLOAD_CLOUD_DESIGN_ASSETS',
      icon: faBugs,
      label: downloadCloudDesignAssetsLabel,
    });
  }

  const cuttingInProgress = props.cloudDesignQueue?.status === CloudDesignStatus.Processing;

  const availableActions = props.isCut ? cutOptions : uncutOptions;

  useEffect(() => {
    if (
      currentCutStatus.current !== CloudDesignStatus.Success &&
      props.cloudDesignQueue?.status === CloudDesignStatus.Success
    ) {
      props.onCut(CloudDesignStatus.Success);
    }

    currentCutStatus.current = props.cloudDesignQueue?.status;
  }, [props.cloudDesignQueue?.status]);

  if (cuttingInProgress) {
    return (
      <Tooltip title={'Cancel Implant Cutting'}>
        <IconButton disabled={isCancellingCutLevel} onClick={() => handleClose('CANCEL_CUT')}>
          <FontAwesomeIcon icon={faX} size={'sm'} />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <>
        {availableActions.map((action) => (
          <>
            {loading ? (
              <IconButton disabled={true}>
                <FontAwesomeIcon icon={faCircleNotch} spin={true} size={'sm'} />
              </IconButton>
            ) : (
              <Tooltip title={action.label}>
                <IconButton
                  disabled={props.disabled || loadingExportCloudDesignCut}
                  onClick={() => handleClose(action.key)}
                >
                  <FontAwesomeIcon icon={action.icon} size={'sm'} />
                </IconButton>
              </Tooltip>
            )}
          </>
        ))}
      </>
    );
  }
}
