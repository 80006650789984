import {
  AssetType,
  CaseShippingStatusType,
  CaseStageType,
  CaseType,
  EndPlate,
  EventType,
  ImagingVertebralBody,
  InterbodyScrewLengthTypes,
  LevelType,
  MeasurementType,
  Position,
  PrimaryPatientDiagnosisType,
  SecondaryPatientDiagnosisType,
  VendorTaskCategory,
  VertebralBody,
} from './enums';
import { IOrganizationMetadata, IUser } from './interfaces';

export type ImplantMeasurementType = {
  coronal?: string;
  lordosis?: string;
  screwLength?: InterbodyScrewLengthTypes;
};

export type Nullable<T> = T | null;

export type ExportedEventDataset = {
  exportedEventId: number;
  exportedAssetData: Nullable<ExportedLinkedAssetDataset>;
  eventData: {
    note: Nullable<string>;
    occurredAt: Nullable<Date>;
    description: string;
    trackingNumber: Nullable<string>;
    eventType: string;
    carrierType: Nullable<string>;
    dueDate: Nullable<Date>;
    purchaseOrderNumber: Nullable<string>;
  };
};

export type ExportedLinkedAssetDataset = {
  assetId: number;
  assetType: string;
  planId: Nullable<number>;
};

export type ExportedCaseAnalysisDataset = {
  exportedAnalysisId: number;
  exportedAssetData: Nullable<ExportedLinkedAssetDataset>;
  analysisData: {
    analysisType: string;
    status: string;
    caseAnalysisImaging: Array<{
      exportedAssetData: ExportedLinkedAssetDataset;
      pixelSpacing: number | null;
      imagingType: string;
      landmarks: Array<unknown>;
    }>;
    caseAnalysisMeasurements: Array<{
      measurementType: string;
      levelType: string | null;
      value: number;
      justification: string | null;
    }>;
    measurementsVersion: number | null;
    isAutomatic: boolean;
    approvedAt: Date | null;
  };
};

export type ExportedAssetDataset = {
  exportedAssetId: number;
  signedUrl: string;
  exportedPlanId: Nullable<number>;
  assetData: {
    fileName: string;
    assetType: string;
    metadata: Nullable<Record<string, any>>;
    size: number;
    version: Nullable<number>;
  };
};

export type ExportedCaseImplantData = {
  level: string;
  partType: string;
  bullet: Record<string, unknown> | undefined;
  position: Record<string, unknown>;
  rotation: Record<string, unknown>;
  referencePoints: Record<string, unknown>;
  ap: number;
  ml: number;
  screwLength: Nullable<number>;
  threadHeight: number;
  excludedImplantSizes: string[];
  obliqueThreadAngle: Nullable<number>; // V5
  cranialCaudalThreadAngle: Nullable<number>; // V5
};

export type ExportedForm19Data = {
  engineeringApprovedAt?: Nullable<Date>;
  qualityApprovedAt?: Nullable<Date>;
  isImageSliceValid?: Nullable<boolean>;
  isNonConformance?: Nullable<boolean>;
  nonConformanceReason?: Nullable<string>;
  caseRiskAssessment?: Nullable<string>;
  revision?: number;
  description?: Nullable<string>;
  levelNotes?: Array<unknown>;
  data?: Record<string, unknown>;
};

export type ExportedPostOpAnalysisData = {
  analysisType: string;
  lumbarCoronalAngulation?: Nullable<number>;
  lumbarLordosis?: Nullable<number>;
  pelvicTilt?: Nullable<number>;
  thoracicKyphosis?: Nullable<number>;
  sagittalVerticalAxis?: Nullable<number>;
  coronalBalance?: Nullable<number>;
  segmentalLumbarLordosis?: Record<string, unknown>;
  segmentalCoronalAngle?: Record<string, unknown>;
  segmentalAnteriorHeight?: Record<string, unknown>;
  segmentalPosteriorHeight?: Record<string, unknown>;
  angleToS1?: Record<string, unknown>;
  measurementsVersion?: number;
};

export type ExportedCaseLevelData = {
  level: string;
  partType: Nullable<string>;
  isFused: Nullable<boolean>;
  threadType: Nullable<string>;
  threadedHoles: Nullable<number>;
  anteriorInstrumentation: Nullable<boolean>;
  otherVendor: Nullable<boolean>;
  implantType: Nullable<string>;
  implantDirection: Nullable<string>;
  implantOrientation: Nullable<string>;
};

export type ExportedCaseImplantSpecData = {
  level: string;
  partType: string;
  size: Nullable<string>;
  apDepth: Nullable<number>;
  mlWidth: Nullable<number>;
  maxHeight: Nullable<number>;
  bulletHeight: Nullable<number>;
  bulletAngle: Nullable<number>;
  leftBulletHeight: Nullable<number>;
  leftBulletAngle: Nullable<number>;
  rightBulletHeight: Nullable<number>;
  rightBulletAngle: Nullable<number>;
  coronalAngle: Nullable<number>;
  lordoticAngle: Nullable<number>;
  isBulletMinHeightValid: Nullable<boolean>;
  isBulletMinDistanceValid: Nullable<boolean>;
  isCosmeticCheckValid: Nullable<boolean>;
  isPartMarkingsValid: Nullable<boolean>;
  screwLength: Nullable<string>;
  anteriorHeight: Nullable<number>;
  posteriorHeight: Nullable<number>;
  revision: number;
  description: Nullable<string>;
};

export type ExportedCaseResultData = {
  level: string;
  part: string;
  exceptionReason: string;
  result: string;
  screwLengthUsed: Nullable<string>;
  screwsUsed: Nullable<number>;
  size: string;
};

export type ExportedCaseMeasurementData = {
  body: string;
  endPlate: string;
  position: string;
  source: Nullable<string>;
  point: number[];
};

export type ExportedCaseDataset = {
  version: number;
  exportedTimestamp: Date;
  exportedCaseId: number;
  caseData: {
    stage: string;
    number: string;
    levels: Nullable<Record<string, any>>;
    includeRodTemplates: Nullable<boolean>;
    caseType: string;
    spineType?: string; // V4
    shortNumber?: Nullable<string>; // V4
    approach: Nullable<string>;
    surgeryDate: Nullable<Date>;
    receivedAt: Date;
    isSurgeryDateTentative: Nullable<boolean>;
    isXrayNotRequired: Nullable<boolean>;
    liv: Nullable<string>;
    pco: string[];
    pso: string[];
    uiv: Nullable<string>;
    levelsMetadata: Nullable<Record<string, any>>;
    stageSortOrder: number;
    studyDate: Nullable<Record<string, any>>;
    settings: Nullable<Record<string, any>>;
    spineProfile: Nullable<string>;
  };
  caseResultsData?: ExportedCaseResultData[]; // V2
  patientData: {
    firstName: Nullable<string>;
    middleName: Nullable<string>;
    lastName: Nullable<string>;
    birthDate: Nullable<Date>;
    gender: Nullable<string>;
    mrn: Nullable<string>;
  };
  patientRecordData: {
    preopMeasurements: Nullable<Record<string, any>>;
    bmi: Nullable<number>;
    odi: Nullable<number>;
    boneDensity: Nullable<string>;
    tScoreSpine: Nullable<number>;
    tScoreHip: Nullable<number>;
    vasBack: Nullable<number>;
    vasLeg: Nullable<number>;
    pelvicIncidence: Nullable<number>;
    lumbarLordosis: Nullable<number>;
    lumbarCoronalCobb: Nullable<number>;
    coronalBalance: Nullable<number>;
    sagittalVerticalAxis: Nullable<number>;
    pelvicTilt: Nullable<number>; // V5
    crestHeight: Nullable<number>; // V5
    s1AnteriorPubisLength: Nullable<number>; // V5
    s1AnteriorPubisAngle: Nullable<number>; // V5
    biFemoralAxisLength: Nullable<number>; // V5
    biFemoralAxisMidpointS1EndplateMidpoint: Nullable<number>; // V5
    biIliacWidth: Nullable<number>; // V5
    posteriorIliacCrestWidth: Nullable<number>; // V5
    hasPelvisOblique: Nullable<boolean>; // V5
    hasPelvisHighCrest: Nullable<boolean>; // V5
    hasPelvisHighPelvicIncidence: Nullable<boolean>; // V5
  };
  planData: Nullable<{
    name: string;
    status: string;
    approvedAt: Nullable<Date>;
    operations: Nullable<Record<string, any>>;
    isAppReady: Nullable<boolean>;
    assetPositions: Nullable<Record<string, any>>;
    implants: Nullable<Record<string, any>>;
    plusLevelSize: number;
    version: number;
  }>;
  form19Data?: Nullable<ExportedForm19Data>; // V5
  postOpAnalysisData?: ExportedPostOpAnalysisData[]; // V5
  planImplantData: Nullable<ExportedCaseImplantData[]>;
  planImplantSpecData: ExportedCaseImplantSpecData[];
  assets: ExportedAssetDataset[];
  events?: ExportedEventDataset[]; // V2
  caseAnalyses?: ExportedCaseAnalysisDataset[]; // V6
  caseLevels?: ExportedCaseLevelData[]; // V3
  caseMeasurements?: ExportedCaseMeasurementData[]; // V4
};

export type ImportCaseInputType = {
  assignedUserId: Nullable<number>;
  surgeonId: Nullable<number>;
  fieldRepId: Nullable<number>;
  locationId: Nullable<number>;
  receivedAt: Date;
  firstName: string;
  lastName: string;
  caseType: CaseType;
  exportData: ExportedCaseDataset;
};

export type ImportCasePayload = {
  caseId: number;
  caseNumber: string;
  assets: Array<{
    signedUrl: string;
    exportedAssetId: number;
    assetType: AssetType;
    fileName: string;
  }>;
};

export type CaseEventsType = {
  caseId: number;
  number: string;
  stage: string;
  surgeryDate?: Date;
  occurredAt?: Date;
  eventType?: EventType;
  status?: CaseShippingStatusType;
  vendorAssignments: Array<{
    organizationId: number;
    taskCategory: VendorTaskCategory;
    metadata: IOrganizationMetadata;
  }>;
};

export type CaseSetType = {
  caseId: number;
  number: string;
  stage: CaseStageType;
  surgeryDate: Date | string;
};

export type CaseMeasurement = {
  measurementType: MeasurementType;
  levelType: LevelType | null;
  value: number;
  justification: string | null;
};

export type CaseImagingLandmark = {
  x: number;
  y: number;
  endPlate: EndPlate;
  position: Position;
  body: ImagingVertebralBody;
};

export type CaseDiagnosisType = PrimaryPatientDiagnosisType | SecondaryPatientDiagnosisType;

export type CaseDiagnosisInput = {
  isPrimary: boolean;
  diagnosisType: CaseDiagnosisType;
  levelType: LevelType | null;
  body: VertebralBody | null;
  grade: number | null;
};

export enum ProductionPhaseType {
  PreProduction = 'PRE_PRODUCTION',
  AdditivePrinting = 'ADDITIVE_PRINTING',
  PackagerDelivery = 'PACKAGER_DELIVERY',
  Packaging = 'PACKAGING',
  DHRUpload = 'DHR_UPLOAD',
  DHRReview = 'DHR_REVIEW',
  ShipToHospital = 'SHIP_TO_HOSPITAL',
  HospitalDelivery = 'HOSPITAL_DELIVERY',
  Delivered = 'DELIVERED',
}
export type CaseUnassignedType = {
  caseId: number;
  number: string;
  receivedAt: Date | string | null;
  surgeryDate: Date | string | null;
  isSurgeryDateTentative: boolean;
  isSegmented: boolean;
  tbdDate: Date | string | null;
  liveDate: Date | string | null;
  levelCount: number;
  hospital: string | null;
  region: string | null;
  surgeonUser: IUser | null;
};
