import React from 'react';
import { Box, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { AssetType, IAsset, format, ValidFileExtensions, ImplantType } from '@workflow-nx/common';
import { CaseReportFileRow } from './CaseReportFileRow';
import { LevelAssets, InputAssetType } from './CaseReportDialog.reducer';
import { CheckboxRHF } from '@workflow-nx/ui';

interface CaseReportImplantDesignProps {
  control: Control<FieldValues>;
  disabled: boolean;
  isEditingAllowed: boolean;
  hasEditedVertebralBodies: boolean;
  isAlifXIncluded: boolean;
  getAsset: (assetType: AssetType) => IAsset | undefined;
  clearAsset: (assetType: AssetType) => void;
  levelAssets: LevelAssets[];
  caseReportVertebraePreEditImage?: File;
  caseReportVertebraePostEditImage?: File;
  caseReportImplantPreEditImage?: File;
  caseReportImplantPostEditImage?: File;
  caseReportAlifXCagesAnteriorPosteriorConstructImage?: File;
  caseReportAlifXCagesLateralConstructImage?: File;
}

export const CaseReportImplantDesign: React.FC<CaseReportImplantDesignProps> = ({
  control,
  disabled,
  isEditingAllowed,
  hasEditedVertebralBodies,
  isAlifXIncluded,
  getAsset,
  clearAsset,
  levelAssets,
  caseReportVertebraePreEditImage,
  caseReportVertebraePostEditImage,
  caseReportImplantPreEditImage,
  caseReportImplantPostEditImage,
  caseReportAlifXCagesAnteriorPosteriorConstructImage,
  caseReportAlifXCagesLateralConstructImage,
}) => {
  return (
    <>
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>Implant Design Section</Typography>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <Typography variant={'body1'}>
            Were vertebral bodies edited to enhance implant features during implant design?
          </Typography>
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <CheckboxRHF
            name={`hasEditedVertebralBodies`}
            control={control}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>

      {hasEditedVertebralBodies && (
        <>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportVertebraePreEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportVertebraePreEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportVertebraePreEditImage)}
                inputAssetFile={caseReportVertebraePreEditImage}
                onClick={() => clearAsset(AssetType.CaseReportVertebraePreEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportVertebraePostEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportVertebraePostEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportVertebraePostEditImage)}
                inputAssetFile={caseReportVertebraePostEditImage}
                onClick={() => clearAsset(AssetType.CaseReportVertebraePostEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportImplantPreEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportImplantPreEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportImplantPreEditImage)}
                inputAssetFile={caseReportImplantPreEditImage}
                onClick={() => clearAsset(AssetType.CaseReportImplantPreEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportImplantPostEditImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportImplantPostEditImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportImplantPostEditImage)}
                inputAssetFile={caseReportImplantPostEditImage}
                onClick={() => clearAsset(AssetType.CaseReportImplantPostEditImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
        </>
      )}

      {isAlifXIncluded && (
        <>
          <Box display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>
                  All {format.formatImplantTypes(ImplantType.ALIFX)} Cages Plus Previous Hardware
                </strong>
              </Typography>
            </Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(
                  AssetType.CaseReportAlifXCagesAnteriorPosteriorConstructImage,
                )}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportAlifXCagesAnteriorPosteriorConstructImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportAlifXCagesAnteriorPosteriorConstructImage)}
                inputAssetFile={caseReportAlifXCagesAnteriorPosteriorConstructImage}
                onClick={() =>
                  clearAsset(AssetType.CaseReportAlifXCagesAnteriorPosteriorConstructImage)
                }
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
              <Typography variant={'body1'}>
                {format.formatAssetType(AssetType.CaseReportAlifXCagesLateralConstructImage)}
              </Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
              <CaseReportFileRow
                id={'caseReportAlifXCagesLateralConstructImage'}
                label={''}
                editing={isEditingAllowed}
                asset={getAsset(AssetType.CaseReportAlifXCagesLateralConstructImage)}
                inputAssetFile={caseReportAlifXCagesLateralConstructImage}
                onClick={() => clearAsset(AssetType.CaseReportAlifXCagesLateralConstructImage)}
                control={control as unknown as Control<FieldValues>}
                validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}></Box>
          </Box>
        </>
      )}

      {levelAssets?.map((levelAsset: LevelAssets, levelIndex: number) => (
        <>
          <Box key={levelIndex} display={'flex'} alignItems={'center'} mt={4}>
            <Box display={'flex'} justifyContent={'left'}>
              <Typography variant={'h5'}>
                <strong>
                  {format.formatLevelType(levelAsset.levelType)} &mdash;{' '}
                  {format.formatImplantTypes(levelAsset.implantType)}
                </strong>
              </Typography>
            </Box>
          </Box>
          {levelAsset.inputAssets.map((inputAsset: InputAssetType, inputIndex: number) => (
            <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
              <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
                <Typography variant={'body1'}>
                  {format.formatAssetType(inputAsset.assetType)}
                </Typography>
              </Box>
              <Box display={'flex'} width={650} justifyContent={'left'} ml={2}>
                <CaseReportFileRow
                  key={`${levelIndex}.${inputIndex}`}
                  id={`levelAssets[${levelIndex}].inputAssets[${inputIndex}].inputAsset`}
                  label={''}
                  editing={isEditingAllowed}
                  asset={getAsset(inputAsset.assetType as AssetType)}
                  onClick={() => clearAsset(inputAsset.assetType as AssetType)}
                  control={control as unknown as Control<FieldValues>}
                  validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
                  inputAssetFile={inputAsset.inputAsset}
                />
              </Box>
              <Box display={'flex'} width={240} justifyContent={'left'}></Box>
            </Box>
          ))}
        </>
      ))}
    </>
  );
};
