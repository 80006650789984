import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import { IPatientRecord, format } from '@workflow-nx/common';
import { InputConfigRHF, InputRHF, InputTypeRHF, ProgressButton } from '@workflow-nx/ui';
import { isNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { ComponentProps, useEffect } from 'react';
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import CustomDialog from '../../../../components/CustomDialog';
import { UPDATE_PATIENT_RECORD } from '../../../../gql';

export interface EditPatientPelvisSettingsFormType extends FieldValues {
  pelvicIncidence?: number;
  pelvicTilt?: number | null;
  crestHeight?: number | null;
  s1AnteriorPubisLength?: number | null;
  s1AnteriorPubisAngle?: number | null;
  biFemoralAxisLength?: number | null;
  biFemoralAxisMidpointS1EndplateMidpoint?: number | null;
  biIliacWidth?: number | null;
  posteriorIliacCrestWidth?: number | null;
  coronalTilt?: number | null;
  posteriorCrestShift?: number | null;
  hasPelvisHighPelvicIncidence?: boolean;
  hasPelvisHighCrest?: boolean;
  hasPelvisOblique?: boolean;
}

const measurementRanges: {
  [key: string]: {
    min: number;
    max: number;
  };
} = {
  pelvicIncidence: {
    min: 35,
    max: 90,
  },
  pelvicTilt: {
    min: 0,
    max: 45,
  },
  s1AnteriorPubisLength: {
    min: 80,
    max: 160,
  },
  s1AnteriorPubisAngle: {
    min: -20,
    max: 40,
  },
  biFemoralAxisMidpointS1EndplateMidpoint: {
    min: 75,
    max: 125,
  },
  crestHeight: {
    min: 15,
    max: 65,
  },
  biFemoralAxisLength: {
    min: 150,
    max: 215,
  },
  biIliacWidth: {
    min: 230,
    max: 330,
  },
  posteriorIliacCrestWidth: {
    min: 70,
    max: 125,
  },
  coronalTilt: {
    min: -15,
    max: 15,
  },
  posteriorCrestShift: {
    min: 0,
    max: 30,
  },
};

function checkIfRequired(
  parentField: {
    hasPelvisHighPelvicIncidence: boolean;
    hasPelvisHighCrest: boolean;
    hasPelvisOblique: boolean;
  },
  field: string,
  value: number | undefined | null,
) {
  const { hasPelvisHighPelvicIncidence, hasPelvisHighCrest, hasPelvisOblique } = parentField;

  const required = isFieldRequired(
    field,
    hasPelvisHighPelvicIncidence,
    hasPelvisHighCrest || hasPelvisOblique,
  );
  return required ? isNumber(value) : true;
}

const schema = Yup.object().shape({
  pelvicIncidence: Yup.number()
    .min(
      measurementRanges.pelvicIncidence.min,
      `Value must be at least ${measurementRanges.pelvicIncidence.min}`,
    )
    .max(
      measurementRanges.pelvicIncidence.max,
      `Value must be at most ${measurementRanges.pelvicIncidence.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    }),
  pelvicTilt: Yup.number()
    .min(
      measurementRanges.pelvicTilt.min,
      `Value must be at least ${measurementRanges.pelvicTilt.min}`,
    )
    .max(
      measurementRanges.pelvicTilt.max,
      `Value must be at most ${measurementRanges.pelvicTilt.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('pelvicTilt', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'pelvicTilt', value);
    }),
  crestHeight: Yup.number()
    .min(
      measurementRanges.crestHeight.min,
      `Value must be at least ${measurementRanges.crestHeight.min}`,
    )
    .max(
      measurementRanges.crestHeight.max,
      `Value must be at most ${measurementRanges.crestHeight.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('crestHeight', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'crestHeight', value);
    }),
  s1AnteriorPubisLength: Yup.number()
    .min(
      measurementRanges.s1AnteriorPubisLength.min,
      `Value must be at least ${measurementRanges.s1AnteriorPubisLength.min}`,
    )
    .max(
      measurementRanges.s1AnteriorPubisLength.max,
      `Value must be at most ${measurementRanges.s1AnteriorPubisLength.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('s1AnteriorPubisLength', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 's1AnteriorPubisLength', value);
    }),
  s1AnteriorPubisAngle: Yup.number()
    .min(
      measurementRanges.s1AnteriorPubisAngle.min,
      `Value must be at least ${measurementRanges.s1AnteriorPubisAngle.min}`,
    )
    .max(
      measurementRanges.s1AnteriorPubisAngle.max,
      `Value must be at most ${measurementRanges.s1AnteriorPubisAngle.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('s1AnteriorPubisAngle', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 's1AnteriorPubisAngle', value);
    }),
  biFemoralAxisLength: Yup.number()
    .min(
      measurementRanges.biFemoralAxisLength.min,
      `Value must be at least ${measurementRanges.biFemoralAxisLength.min}`,
    )
    .max(
      measurementRanges.biFemoralAxisLength.max,
      `Value must be at most ${measurementRanges.biFemoralAxisLength.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('biFemoralAxisLength', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'biFemoralAxisLength', value);
    }),
  biFemoralAxisMidpointS1EndplateMidpoint: Yup.number()
    .min(
      measurementRanges.biFemoralAxisMidpointS1EndplateMidpoint.min,
      `Value must be at least ${measurementRanges.biFemoralAxisMidpointS1EndplateMidpoint.min}`,
    )
    .max(
      measurementRanges.biFemoralAxisMidpointS1EndplateMidpoint.max,
      `Value must be at most ${measurementRanges.biFemoralAxisMidpointS1EndplateMidpoint.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('biFemoralAxisMidpointS1EndplateMidpoint', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'biFemoralAxisMidpointS1EndplateMidpoint', value);
    }),
  biIliacWidth: Yup.number()
    .min(
      measurementRanges.biIliacWidth.min,
      `Value must be at least ${measurementRanges.biIliacWidth.min}`,
    )
    .max(
      measurementRanges.biIliacWidth.max,
      `Value must be at most ${measurementRanges.biIliacWidth.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('biIliacWidth', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'biIliacWidth', value);
    }),
  posteriorIliacCrestWidth: Yup.number()
    .min(
      measurementRanges.posteriorIliacCrestWidth.min,
      `Value must be at least ${measurementRanges.posteriorIliacCrestWidth.min}`,
    )
    .max(
      measurementRanges.posteriorIliacCrestWidth.max,
      `Value must be at most ${measurementRanges.posteriorIliacCrestWidth.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('posteriorIliacCrestWidth', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'posteriorIliacCrestWidth', value);
    }),
  coronalTilt: Yup.number()
    .min(
      measurementRanges.coronalTilt.min,
      `Value must be at least ${measurementRanges.coronalTilt.min}`,
    )
    .max(
      measurementRanges.coronalTilt.max,
      `Value must be at most ${measurementRanges.coronalTilt.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('coronalTilt', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'coronalTilt', value);
    }),
  posteriorCrestShift: Yup.number()
    .min(
      measurementRanges.posteriorCrestShift.min,
      `Value must be at least ${measurementRanges.posteriorCrestShift.min}`,
    )
    .max(
      measurementRanges.posteriorCrestShift.max,
      `Value must be at most ${measurementRanges.posteriorCrestShift.max}`,
    )
    .transform((value, originalValue) => {
      return originalValue === '' ? null : value;
    })
    .nullable()
    .test('posteriorCrestShift', 'Value is required', function (value) {
      return checkIfRequired(this.parent, 'posteriorCrestShift', value);
    }),
  hasPelvisHighPelvicIncidence: Yup.boolean(),
  hasPelvisHighCrest: Yup.boolean(),
  hasPelvisOblique: Yup.boolean(),
});

function isFieldRequired(
  field: string,
  hasPelvisHighPelvicIncidence: boolean,
  hasPelvisHighCrest: boolean,
) {
  switch (field) {
    case 'pelvicIncidence':
      return hasPelvisHighPelvicIncidence || hasPelvisHighCrest;
    case 'pelvicTilt':
      return hasPelvisHighPelvicIncidence;
    case 'crestHeight':
      return hasPelvisHighCrest;
    case 's1AnteriorPubisLength':
      return hasPelvisHighPelvicIncidence;
    case 's1AnteriorPubisAngle':
      return hasPelvisHighPelvicIncidence;
    case 'biFemoralAxisLength':
      return hasPelvisHighPelvicIncidence;
    case 'biFemoralAxisMidpointS1EndplateMidpoint':
      return hasPelvisHighPelvicIncidence;
    case 'biIliacWidth':
      return hasPelvisHighCrest;
    case 'posteriorIliacCrestWidth':
      return hasPelvisHighCrest;
    case 'coronalTilt':
      return hasPelvisHighCrest;
    case 'posteriorCrestShift':
      return hasPelvisHighCrest;
  }
  return false;
}

export function EditPatientPelvisSettingsDialog(props: {
  patientId: number;
  patientRecord: IPatientRecord | undefined;
  patientGender: 'MALE' | 'FEMALE';
  open: boolean;
  onEdit: () => void;
  onClose: () => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updatePatientRecord, { loading: loadingUpdatePatientRecord }] =
    useMutation(UPDATE_PATIENT_RECORD);

  const formRowTextData: InputConfigRHF[] = [
    {
      id: 'pelvicIncidence',
      label: `Pelvic Incidence`,
      input: InputTypeRHF.Number,
    },
    {
      id: 'pelvicTilt',
      label: 'Pelvic Tilt',
      input: InputTypeRHF.Number,
    },
    {
      id: 's1AnteriorPubisLength',
      label: 'S1 Anterior Pubis Length',
      input: InputTypeRHF.Number,
    },
    {
      id: 's1AnteriorPubisAngle',
      label: 'S1 Anterior Pubis Angle',
      input: InputTypeRHF.Number,
    },
    {
      id: 'biFemoralAxisMidpointS1EndplateMidpoint',
      label: 'Bi-Femoral Axis Midpoint to S1 Endplate Midpoint',
      input: InputTypeRHF.Number,
    },
    {
      id: 'crestHeight',
      label: 'Crest Height',
      input: InputTypeRHF.Number,
    },
    {
      id: 'biFemoralAxisLength',
      label: 'Bi-Femoral Axis Length',
      input: InputTypeRHF.Number,
    },
    {
      id: 'biIliacWidth',
      label: 'Bi-Iliac Width',
      input: InputTypeRHF.Number,
    },
    {
      id: 'posteriorIliacCrestWidth',
      label: 'Posterior Iliac Crest Width',
      input: InputTypeRHF.Number,
    },
    {
      id: 'coronalTilt',
      label: 'Coronal Tilt',
      input: InputTypeRHF.Number,
    },
    {
      id: 'posteriorCrestShift',
      label: 'Posterior Crest Shift',
      input: InputTypeRHF.Number,
    },
    {
      id: 'hasPelvisHighPelvicIncidence',
      label: 'High-PI Pelvis',
      input: InputTypeRHF.Switch,
    },
    {
      id: 'hasPelvisHighCrest',
      label: 'High Crest Pelvis',
      input: InputTypeRHF.Switch,
    },
    {
      id: 'hasPelvisOblique',
      label: 'Oblique Pelvis',
      input: InputTypeRHF.Switch,
    },
  ];

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isSubmitting },
  } = useForm<EditPatientPelvisSettingsFormType>({
    resolver: yupResolver(schema),
  });

  const handleSubmitForm: SubmitHandler<EditPatientPelvisSettingsFormType> = async (data) => {
    try {
      await updatePatientRecord({
        variables: {
          patientId: props.patientId,
          pelvicIncidence: data.pelvicIncidence,
          pelvicTilt: data.pelvicTilt,
          crestHeight: data.crestHeight,
          s1AnteriorPubisLength: data.s1AnteriorPubisLength,
          s1AnteriorPubisAngle: data.s1AnteriorPubisAngle,
          biFemoralAxisLength: data.biFemoralAxisLength,
          biFemoralAxisMidpointS1EndplateMidpoint: data.biFemoralAxisMidpointS1EndplateMidpoint,
          biIliacWidth: data.biIliacWidth,
          posteriorIliacCrestWidth: data.posteriorIliacCrestWidth,
          coronalTilt: data.coronalTilt,
          posteriorCrestShift: data.posteriorCrestShift,
          hasPelvisHighPelvicIncidence: data.hasPelvisHighPelvicIncidence,
          hasPelvisHighCrest: data.hasPelvisHighCrest,
          hasPelvisOblique: data.hasPelvisOblique,
        },
      });

      enqueueSnackbar('Patient pelvis settings updated', {
        variant: 'success',
      });

      props.onEdit();
      reset();
    } catch (err: unknown) {
      console.error(err);

      enqueueSnackbar('An error occurred updating the patient pelvis settings', {
        variant: 'error',
      });
    }
  };

  const getInputProps = (config: InputConfigRHF): ComponentProps<typeof InputRHF> => ({
    config: config,
    control: control as unknown as Control<FieldValues>,
    disabled: isSubmitting,
  });

  watch();

  useEffect(() => {
    if (props.patientRecord) {
      reset({
        pelvicIncidence: props.patientRecord.pelvicIncidence,
        pelvicTilt: props.patientRecord.pelvicTilt ?? 19,
        s1AnteriorPubisLength: props.patientRecord.s1AnteriorPubisLength ?? 124,
        s1AnteriorPubisAngle: props.patientRecord.s1AnteriorPubisAngle ?? 16,
        biFemoralAxisMidpointS1EndplateMidpoint:
          props.patientRecord.biFemoralAxisMidpointS1EndplateMidpoint ?? 104,
        crestHeight: props.patientRecord.crestHeight ?? 41,
        biFemoralAxisLength: props.patientRecord.biFemoralAxisLength ?? 178,
        biIliacWidth: props.patientRecord.biIliacWidth ?? 282,
        posteriorIliacCrestWidth: props.patientRecord.posteriorIliacCrestWidth ?? 95,
        coronalTilt: props.patientRecord.coronalTilt ?? 0,
        posteriorCrestShift: props.patientRecord.posteriorCrestShift ?? 0,
        hasPelvisHighPelvicIncidence: props.patientRecord.hasPelvisHighPelvicIncidence,
        hasPelvisHighCrest: props.patientRecord.hasPelvisHighCrest,
        hasPelvisOblique: props.patientRecord.hasPelvisOblique,
      });
    }
  }, [props.patientRecord]);

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={'Edit Patient Pelvis Settings'}
      onClose={() => {
        reset();
        props.onClose();
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={isSubmitting}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Update'}
          loading={isSubmitting || loadingUpdatePatientRecord}
        />,
      ]}
    >
      <form>
        <Grid container spacing={3}>
          <Grid item container xs={12} spacing={1} alignItems={'center'} mb={2}>
            <Grid item xs={3} md={3} display={'flex'} alignItems={'center'}>
              <Typography variant={'h5'}>Gender</Typography>
            </Grid>
            <Grid item xs={6} md={6}>
              <Typography variant={'body1'}>{format.formatGender(props.patientGender)}</Typography>
            </Grid>
            <Grid item xs={3} md={3} display={'flex'} justifyContent={'center'}></Grid>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent={'center'} alignItems={'center'}>
          {formRowTextData.map((config) => {
            const required = isFieldRequired(
              config.id,
              !!getValues().hasPelvisHighPelvicIncidence,
              !!getValues().hasPelvisHighCrest || !!getValues().hasPelvisOblique,
            );

            const hasMeasurementRange = !!measurementRanges[config.id];
            const textFieldProps = {
              helperText: hasMeasurementRange
                ? `Valid range is ${measurementRanges[config.id].min} to ${
                    measurementRanges[config.id].max
                  }`
                : undefined,
            };
            return (
              <InputRHF
                key={config.id}
                {...getInputProps(config)}
                required={required}
                textFieldProps={textFieldProps}
              />
            );
          })}
        </Grid>
      </form>
    </CustomDialog>
  );
}
