import React, { Dispatch, useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { FIND_USERS_BY_REGION_TERRITORY, FIND_USERS_BY_ROLE } from '../../../gql';
import { format, IUser, UserRoleType } from '@workflow-nx/common';
import { Box, Button, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { Select } from '../../../components/Select';
import { CaseCalendarActionType } from './CaseCalendar.reducer';
import { groupUsersByRole } from '../../../utils/user';
import { CalendarCaseCategory } from './config';
import TagSelect from '../../../components/TagSelect';
import { UserAutocomplete } from '../../../components/UserAutocomplete';
import { OrganizationSelect } from '../../../components/OrganizationSelect';
import { RegionSelect } from '../../../components/RegionSelect';
import _ from 'lodash';

export function CaseCalendarFilter(props: {
  state: {
    organization?: string;
    region?: string;
    surgeon?: string;
    fieldRep?: string;
    caseStatus?: string;
    caseTags?: string[];
    showTentativeSurgeryDates?: boolean;
    showDraftCases?: boolean;
  };
  loading: boolean;
  dispatch: Dispatch<CaseCalendarActionType>;
}) {
  const [surgeons, setSurgeons] = useState<IUser[]>([]);
  const [fieldReps, setFieldReps] = useState<IUser[]>([]);
  const { data } = useQuery(FIND_USERS_BY_ROLE);

  const [findRegionUsers] = useLazyQuery(FIND_USERS_BY_REGION_TERRITORY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (usersByTerritoryData) => {
      const fieldReps = (usersByTerritoryData?.usersByRegionTerritory || []).map((user: IUser) => {
        return user;
      });
      const sortedFieldReps = _.sortBy(fieldReps);
      setFieldReps(sortedFieldReps);
    },
  });

  useEffect(() => {
    const users = data?.users;

    if (props.state.region) {
      findRegionUsers({
        variables: { regionId: props.state.region || null, roleFilter: [UserRoleType.FieldRep] },
      });
      if (users?.users?.length > 0) {
        const { surgeons } = groupUsersByRole(users.users);
        setSurgeons(surgeons);
      }
    } else {
      const users = data?.users;
      if (users?.users?.length > 0) {
        const { surgeons, fieldReps } = groupUsersByRole(users.users);

        setSurgeons(surgeons);
        setFieldReps(fieldReps);
      }
    }
  }, [props.state.region, data?.users]);

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <OrganizationSelect
          value={props.state.organization ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'ORGANIZATION_CHANGED',
              data: event.target.value,
            });
          }}
          disabled={props.loading}
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'surgeon'}
          label={'Surgeon'}
          value={props.state?.surgeon ? props.state?.surgeon?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'SURGEON_CHANGED',
              data: newVal || '',
            });
          }}
          users={surgeons}
          disabled={props.loading}
          fullWidth={true}
          startWithLastName
        />
      </Box>
      <Box>
        <UserAutocomplete
          name={'fieldRep'}
          label={`${format.formatUserRole(UserRoleType.FieldRep)}`}
          value={props.state?.fieldRep ? props.state?.fieldRep?.toString() : undefined}
          onChange={(newVal) => {
            props.dispatch({
              type: 'FIELD_REP_CHANGED',
              data: newVal || '',
            });
          }}
          users={fieldReps}
          disabled={props.loading}
          fullWidth={true}
          startWithLastName
        />
      </Box>
      <Box>
        <Select
          name={'caseStatus'}
          label={'Case Status'}
          value={props.state.caseStatus}
          onChange={(event) => {
            props.dispatch({
              type: 'CASE_STATUS_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={Object.values(CalendarCaseCategory).map((categoryName) => ({
            key: categoryName,
            value: categoryName,
          }))}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <RegionSelect
          value={props.state.region ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'REGION_CHANGED',
              data: event.target.value,
            });
          }}
          disabled={props.loading}
        />
      </Box>
      <Box>
        <TagSelect
          label={'Case Tags'}
          value={props.state?.caseTags ? props.state?.caseTags : []}
          onChange={(newValue) => {
            props.dispatch({
              type: 'CASE_TAGS_CHANGED',
              data: newValue,
            });
          }}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showTentativeSurgeryDates}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_TENTATIVE_SURGERY_DATES_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Show Tentative?"
          />
        </FormGroup>
      </Box>
      <Box>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={props.state.showDraftCases}
                onChange={(event) => {
                  props.dispatch({
                    type: 'SHOW_DRAFT_CASES_CHANGED',
                    data: event.target.checked,
                  });
                }}
              />
            }
            label="Show Draft?"
          />
        </FormGroup>
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
