import {
  IUser,
  UserRoleType,
  format,
  IRegion,
  ITerritory,
  UserStatusType,
} from '@workflow-nx/common';
import { Avatar, Box, Chip, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  FIND_LOCATION_AND_REGIONS_AND_USERS,
  FIND_USERS,
  FIND_USERS_BY_REGION_TERRITORY,
} from '../../../gql';
import { FormattedFieldView } from '../../../components/FormattedFieldView';
import _ from 'lodash';
import { grey } from '@mui/material/colors';

export function ViewSurgeonDetails({
  user,
  profilePhotoUrl,
}: {
  user: IUser;
  profilePhotoUrl: string | undefined;
}) {
  const [territory, setTerritory] = useState<ITerritory>();
  const [region, setRegion] = useState<IRegion>();
  const [associatedCssUsersNames, setAssociatedCssUsersNames] = useState<string[]>([]);
  const [associatedSalesLeadersNames, setAssociatedSalesLeadersNames] = useState<string[]>([]);
  const [associatedSalesAgentsNames, setAssociatedSalesAgentsNames] = useState<string[]>([]);

  useQuery(FIND_LOCATION_AND_REGIONS_AND_USERS, {
    variables: {
      locationId: user?.location?.locationId || 0,
      roleFilter: [UserRoleType.CustomerRep],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setTerritory(data.location?.territory);
      setRegion(data.location?.region);

      const cssUsers: string[] = (data.location?.locationUser || []).map(
        (user: { user: IUser }) => {
          return format.formatName({
            firstName: user.user.firstName,
            lastName: user.user.lastName,
          });
        },
      );
      const sortedUsers = _.sortBy(cssUsers);
      setAssociatedCssUsersNames(sortedUsers);
    },
  });

  useQuery(FIND_USERS_BY_REGION_TERRITORY, {
    variables: { territoryId: territory?.territoryId || null, roleFilter: [UserRoleType.FieldRep] },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (usersByTerritoryData) => {
      const salesLeaders: string[] = (usersByTerritoryData?.usersByRegionTerritory || []).map(
        (user: IUser) => {
          return format.formatName({ firstName: user.firstName, lastName: user.lastName });
        },
      );
      const sortedSalesLeaders = _.sortBy(salesLeaders);
      setAssociatedSalesLeadersNames(sortedSalesLeaders);
    },
  });

  useQuery(FIND_USERS, {
    variables: { associatedUserIds: [user.userId] },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const associatedUsers: string[] = (data?.users?.users || []).map((user: IUser) => {
        return format.formatName({ firstName: user.firstName, lastName: user.lastName });
      });
      const sortedAssociatedUsers = _.sortBy(associatedUsers);
      setAssociatedSalesAgentsNames(sortedAssociatedUsers);
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm container wrap={'wrap'}>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Surgeon'}
            value={
              <Box>
                <Typography variant={'body1'}>
                  {user ? format.formatName(user) : <span>&mdash;</span>}
                  <Box my={1} />
                  {user?.status !== UserStatusType.Active ? (
                    <Chip label={user?.status?.toUpperCase()} />
                  ) : null}
                </Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView label={'Email'} value={user?.email} />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Phone'}
            value={user?.phone ? format.formatPhoneNumber(user?.phone) : <>&mdash;</>}
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Distributor'}
            value={user.linkedOrganization ? user?.linkedOrganization.name : <>&mdash;</>}
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView label={'Institution'} value={user?.organization?.name} />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView label={'Location'} value={user?.location?.description} />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView label={'Area'} value={region?.name ? region?.name : <>&mdash;</>} />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Region'}
            value={territory?.name ? territory?.name : <>&mdash;</>}
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Sales Leader'}
            value={
              associatedSalesLeadersNames.length ? (
                associatedSalesLeadersNames.join(', ')
              ) : (
                <>&mdash;</>
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'CSS'}
            value={
              associatedCssUsersNames.length ? associatedCssUsersNames.join(', ') : <>&mdash;</>
            }
          />
        </Grid>
        <Grid item xs={3}>
          <FormattedFieldView
            label={'Sales Agents'}
            value={
              associatedSalesAgentsNames.length ? (
                associatedSalesAgentsNames.join(', ')
              ) : (
                <>&mdash;</>
              )
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={'auto'}>
        <Avatar
          src={profilePhotoUrl}
          sx={{
            bgcolor: grey[300],
            border: `2px solid ${grey[400]}`,
            maxHeight: '200px',
            maxWidth: '200px',
            height: '200px',
            width: '200px',
            margin: 'auto 0',
          }}
        />
      </Grid>
    </Grid>
  );
}
