import { useMutation } from '@apollo/client';
import { Alert, Box, Stack, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ICase } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import CustomDialog from '../../../components/CustomDialog';
import { CLONE_CASE } from '../../../gql';
import { useNavigate } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    flex: 1,
    width: '100%',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
}));

const CloneCaseButton: React.FC<{ activeCase: ICase }> = ({ activeCase }) => {
  const navigate = useNavigate();

  const [showCloneCaseDialog, setShowCloneCaseDialog] = useState(false);
  const [cloningCase, setCloningCase] = useState(false);
  const [reason, setReason] = useState('');

  const [cloneCase] = useMutation(CLONE_CASE);
  const [cloneAbortController, setCloneAbortController] = useState<AbortController | null>(null);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleCloneCase = async () => {
    try {
      setCloningCase(true);

      const controller = new AbortController();

      setCloneAbortController(controller);

      const res = await cloneCase({
        variables: {
          caseId: activeCase.caseId,
          reason,
        },
      });

      navigate(`/app/cases/${res?.data.cloneCase?.caseId}`);

      enqueueSnackbar(`The case was successfully cloned.`, {
        variant: 'success',
      });
    } catch (error) {
      console.error('Error cloning case:', error);
      enqueueSnackbar(`The case failed to clone.`, {
        variant: 'error',
      });
    } finally {
      setCloningCase(false);
      setCloneAbortController(null);
    }
  };

  const cancelClone = () => {
    cloneAbortController?.abort();

    setCloneAbortController(null);
    setShowCloneCaseDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          color: cloningCase ? 'InactiveCaptionText' : 'inherit',
          cursor: cloningCase ? 'wait' : 'pointer',
        }}
        width={'100%'}
        onClick={() => {
          if (!cloningCase) {
            setShowCloneCaseDialog(true);
          }
        }}
      >
        Clone Case
      </Box>

      <CustomDialog
        maxWidth={'xs'}
        open={showCloneCaseDialog}
        title={`Clone Case ${activeCase.number}`}
        onClose={() => {
          cancelClone();
        }}
        positiveActionButtons={[]}
        dialogContentClassName={styles.dialogContainerContent}
        containerClassName={styles.dialogContainer}
      >
        <Stack spacing={2}>
          <Alert severity={'info'}>Cloning a case will create it in the planning stage</Alert>
          <Typography>Enter the reason for cloning case.</Typography>
          <TextField
            onChange={(e) => setReason(e.target.value)}
            value={reason}
            required={true}
            fullWidth={true}
            minRows={3}
            disabled={cloningCase}
            multiline={true}
          />
          {cloningCase ? (
            <Typography>Cloning case, this could take a few minutes...</Typography>
          ) : (
            <ActionButton onClick={handleCloneCase}>Clone Case</ActionButton>
          )}
        </Stack>
      </CustomDialog>
    </>
  );
};

export default CloneCaseButton;
