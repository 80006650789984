import { useMutation } from '@apollo/client';
import {
  faCloudDownload,
  faEye,
  faFileEdit,
  faHistory,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssetType, IAsset, ValidFileExtensions, format } from '@workflow-nx/common';
import { dicom, file } from '@workflow-nx/utils';
import * as FileSaver from 'file-saver';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import { CREATE_ASSET_DOWNLOAD_URL, DELETE_ASSET } from '../../gql';
import { AssetMeshViewDialog } from '../AssetMeshView/AssetMeshViewDialog';
import { AssetMetadataViewDialog } from '../AssetMetadataViewDialog';
import { ImageViewDialog } from '../ImageViewDialog';
import { PDFViewDialog } from '../PDFViewDialog';
import { AssetHistoryDialog } from './AssetHistoryDialog';
import useCreateAndUploadAsset from '../../hooks/useCreateAndUploadAsset';

const useStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  gridCellOverflow: {
    backgroundColor: 'white',
    height: 50,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

export function AssetGridTableRow(props: {
  caseId: number;
  allowReplace: boolean;
  asset: IAsset;
  disabledAsset?: boolean;
  onDelete: () => void;
  onReplace: (asset: IAsset) => void;
  readOnly: boolean;
  showHistory: boolean;
  disableDelete: boolean;
  validFileExtensions: ValidFileExtensions[];
}) {
  const styles = useStyles();
  const [deleteAsset, { loading }] = useMutation(DELETE_ASSET);
  const [createAssetDownloadUrl] = useMutation(CREATE_ASSET_DOWNLOAD_URL);
  const [downloading, setDownloading] = useState(false);
  const [downloadPercent, setDownloadPercent] = useState(0);
  const [openAssetViewer, setOpenAssetViewer] = useState(false);
  const [openAssetMetadataViewer, setOpenAssetMetadataViewer] = useState(false);
  const [openImageViewer, setOpenImageViewer] = useState(false);
  const [openPDFViewer, setOpenPDFViewer] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const confirm = useConfirm();
  const { createAndUploadAsset, uploadProgress } = useCreateAndUploadAsset();
  const [uploading, setUploading] = useState(false);

  const handlePreviewClick = () => {
    const assetType = props.asset.assetType;

    switch (assetType) {
      case AssetType.StandingXrayAP:
      case AssetType.StandingXrayLateral:
      case AssetType.PostOpIntraOpStandingXrayLateral:
      case AssetType.PostOpSixWeeksStandingXrayLateral:
      case AssetType.PostOpSixMonthsStandingXrayLateral:
      case AssetType.PostOpOneYearStandingXrayLateral:
      case AssetType.PostOpTwoYearStandingXrayLateral:
      case AssetType.PostOpIntraOpStandingXrayAp:
      case AssetType.PostOpSixWeeksStandingXrayAp:
      case AssetType.PostOpSixMonthsStandingXrayAp:
      case AssetType.PostOpOneYearStandingXrayAp:
      case AssetType.PostOpTwoYearStandingXrayAp:
      case AssetType.Form19ApprovalSignature:
      case AssetType.L1L2ImplantMeasurementImage:
      case AssetType.L2L3ImplantMeasurementImage:
      case AssetType.L3L4ImplantMeasurementImage:
      case AssetType.L4L5ImplantMeasurementImage:
      case AssetType.L5L6ImplantMeasurementImage:
      case AssetType.L4S1ImplantMeasurementImage:
      case AssetType.L5S1ImplantMeasurementImage:
      case AssetType.L6S1ImplantMeasurementImage:
        setOpenImageViewer(true);
        break;
      case AssetType.DicomCt:
      case AssetType.DicomXray:
      case AssetType.DicomMri:
      case AssetType.ParasolidExport:
      case AssetType.PostOpIntraOpXray:
      case AssetType.PostOpOneWeekXray:
      case AssetType.PostOpSixWeeksXray:
      case AssetType.PostOpSixMonthsXray:
      case AssetType.PostOpOneYearXray:
      case AssetType.PostOpTwoYearXray:
      case AssetType.PostOpIntraOp:
      case AssetType.PostOpIntraOpCt:
      case AssetType.PostOpOneWeekCt:
      case AssetType.PostOpSixWeeksCt:
      case AssetType.PostOpSixMonthsCt:
      case AssetType.PostOpOneYearCt:
      case AssetType.PostOpTwoYearCt:
      case AssetType.PostOpIntraOpMri:
      case AssetType.PostOpOneWeekMri:
      case AssetType.PostOpSixWeeksMri:
      case AssetType.PostOpSixMonthsMri:
      case AssetType.PostOpOneYearMri:
      case AssetType.PostOpTwoYearMri:
        setOpenAssetMetadataViewer(true);
        break;
      case AssetType.BookingSheet:
        if (props.asset.fileName.endsWith('.pdf')) {
          setOpenPDFViewer(true);
        } else {
          setOpenImageViewer(true);
        }
        break;
      default:
        break;
    }
    if (props.asset.fileName.endsWith('.stl') || props.asset.fileName.endsWith('.obj')) {
      setOpenAssetViewer(true);
    } else if (props.asset.fileName.endsWith('.pdf')) {
      setOpenPDFViewer(true);
    }
  };

  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        if (!props.allowReplace) {
          return;
        }

        if (fileRejections.length > 0) {
          const errorMessage = fileRejections[0].errors[0].message;
          enqueueSnackbar(`Error uploading asset. ${errorMessage}`, {
            variant: 'error',
          });
          return;
        }

        if (acceptedFiles.length === 0) {
          return;
        }

        const allFilesHaveSize = acceptedFiles.every((f) => f.size !== 0);

        if (!allFilesHaveSize) {
          enqueueSnackbar(`Error uploading asset. File has a size of zero bytes.`, {
            variant: 'error',
          });
          return;
        }

        await confirm({
          title: 'Replace Asset',
          description: `This will replace delete the asset ${props.asset.assetType}.`,
        });

        const acceptedFile = acceptedFiles[0];

        setUploading(true);

        let metadata = null;
        if (dicom.PARSABLE_DICOM_TYPES.includes(props.asset.assetType)) {
          try {
            metadata = await dicom.getDicomMetadata(acceptedFile, props.asset.assetType);
          } catch (e) {
            console.error('Error processing DICOM', e);
          }
        }

        const createdAsset = await createAndUploadAsset(
          acceptedFile,
          props.asset.assetType,
          props.asset.caseId,
          props.asset.planId,
          metadata,
        );

        if (createdAsset?.createdAsset && !createdAsset.error) {
          props.onReplace(createdAsset?.createdAsset?.asset);
          enqueueSnackbar(
            `Asset ${format.formatAssetType(props.asset.assetType)} successfully replaced`,
            {
              variant: 'success',
            },
          );
        } else if (createdAsset.error) {
          throw new Error(createdAsset.error);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error replacing asset', {
          variant: 'error',
        });
      } finally {
        setUploading(false);
      }
    },
    [createAndUploadAsset, enqueueSnackbar, props, confirm],
  );

  const handleDownloadAsset = async () => {
    try {
      setDownloading(true);
      const { data } = await createAssetDownloadUrl({
        variables: {
          assetId: props.asset?.assetId,
        },
      });

      const response = await file.downloadFile(data.createAssetDownloadUrl.signedUrl, {
        onDownloadProgress: (percentComplete) => {
          setDownloadPercent(percentComplete);
        },
      });

      FileSaver.saveAs(response.data, props.asset?.fileName);
    } catch (e) {
      enqueueSnackbar('Error Downloading File', {
        variant: 'error',
      });
    } finally {
      setDownloadPercent(0);
      setDownloading(false);
    }
  };

  const handleDeleteAsset = async () => {
    try {
      await confirm({
        description: `This will permanently delete the asset ${props.asset.assetType}.`,
      });

      await deleteAsset({
        variables: {
          assetId: props.asset.assetId,
        },
      });

      props.onDelete();

      enqueueSnackbar(
        `Asset ${format.formatAssetType(props.asset.assetType)} was deleted successfully`,
        {
          variant: 'success',
        },
      );
    } catch (e) {
      enqueueSnackbar('Error deleting asset', {
        variant: 'error',
      });
      console.error(e);
    }
  };

  const acceptedFileTypes = props.validFileExtensions.join(',');
  const { open, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: handleDrop,
    accept: acceptedFileTypes,
  });

  return (
    <>
      {!uploading ? (
        <>
          {!downloading ? (
            <>
              <Box className={styles.gridCell}>
                <Box display={'flex'} alignItems={'center'} height={50}>
                  <Typography variant={'body1'} color={props.disabledAsset ? 'grey' : undefined}>
                    {format.formatAssetType(props.asset.assetType)}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.gridCellOverflow}>
                <Box display={'flex'} alignItems={'center'} height={50}>
                  <Typography
                    variant={'body1'}
                    noWrap={true}
                    color={props.disabledAsset ? 'grey' : undefined}
                  >
                    {props.asset.fileName}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.gridCellOverflow}>
                <Box display={'flex'} alignItems={'center'} height={50}>
                  <Typography
                    variant={'body1'}
                    noWrap={true}
                    color={props.disabledAsset ? 'grey' : undefined}
                  >
                    {format.formatDateTime(props.asset.updatedAt)} by{' '}
                    {format.formatName(props.asset.updatedByUser)}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.gridCell}>
                <Box display={'flex'} alignItems={'center'} height={50}>
                  <Typography variant={'body1'} color={props.disabledAsset ? 'grey' : undefined}>
                    {format.formatBytes(props.asset.size)}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.gridCell}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={50}>
                  <Typography variant={'body1'} color={props.disabledAsset ? 'grey' : undefined}>
                    {props.asset.version}
                  </Typography>
                </Box>
              </Box>
              <Box className={styles.gridCell}>
                <Box display={'flex'} alignItems={'center'} height={50}>
                  <IconButton
                    disabled={
                      loading ||
                      props.disabledAsset ||
                      props.asset.assetType === AssetType.Form19 ||
                      props.asset.assetType === AssetType.Form18 ||
                      props.asset.assetType.endsWith('_DIMENSIONS') ||
                      props.asset.assetType.endsWith('_CLI')
                    }
                    onClick={handlePreviewClick}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </IconButton>
                  <IconButton
                    disabled={loading || props.disabledAsset}
                    onClick={handleDownloadAsset}
                    size="large"
                  >
                    <FontAwesomeIcon icon={faCloudDownload} />
                  </IconButton>
                  {props.showHistory ? (
                    <IconButton
                      disabled={loading || props.disabledAsset}
                      onClick={() => setShowHistory(true)}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faHistory} />
                    </IconButton>
                  ) : null}
                  {props.allowReplace && !props.readOnly ? (
                    <>
                      <input {...getInputProps()} />
                      <IconButton
                        disabled={loading || props.disabledAsset}
                        onClick={() => open()}
                        size="large"
                      >
                        <FontAwesomeIcon icon={faFileEdit} />
                      </IconButton>
                    </>
                  ) : null}
                  {!props.readOnly && !props.disableDelete ? (
                    <IconButton
                      disabled={loading || props.disabledAsset}
                      onClick={handleDeleteAsset}
                      size="large"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                  ) : null}
                </Box>
              </Box>
            </>
          ) : null}
          {downloading ? (
            <Box gridColumn={'span 6'} className={styles.gridCell}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                height={50}
              >
                <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
                  {`Downloading ${format.formatAssetType(props.asset.assetType)}`.toUpperCase()}
                </Typography>
                <Box width={'100%'}>
                  <LinearProgress
                    color={'secondary'}
                    variant={'determinate'}
                    value={downloadPercent}
                  />
                </Box>
              </Box>
            </Box>
          ) : null}
        </>
      ) : null}

      {uploading ? (
        <>
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            height={50}
            gridColumn={'span 6'}
            className={styles.gridCell}
          >
            <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
              {`Uploading replacement ${props.asset.assetType}`.toUpperCase()}
            </Typography>
            <Box width={'100%'}>
              <LinearProgress color={'secondary'} variant={'determinate'} value={uploadProgress} />
            </Box>
          </Box>
        </>
      ) : null}

      <AssetMeshViewDialog
        open={openAssetViewer}
        asset={props.asset}
        onClose={() => setOpenAssetViewer(false)}
      />
      <AssetMetadataViewDialog
        open={openAssetMetadataViewer}
        asset={props.asset}
        onClose={() => setOpenAssetMetadataViewer(false)}
      />
      <ImageViewDialog
        open={openImageViewer}
        asset={props.asset}
        onClose={() => setOpenImageViewer(false)}
      />
      <PDFViewDialog
        open={openPDFViewer}
        asset={props.asset}
        onClose={() => setOpenPDFViewer(false)}
      />
      <AssetHistoryDialog
        open={showHistory}
        asset={props.asset}
        onClose={() => setShowHistory(false)}
      />
    </>
  );
}
