import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  Chip,
  Container,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CaseStageType,
  CaseType,
  format,
  IRegion,
  ITableHeader,
  UserRoleType,
  CaseUnassignedType,
} from '@workflow-nx/common';
import ActionButton from '../../components/ActionButton';
import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { FIND_UNASSIGNED_CASES, FIND_REGIONS, UPDATE_CASE_ASSIGNMENT } from '../../gql';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';
import { CustomAppBar } from '../../components/CustomAppBar';
import { CommonTable } from '../../components/CommonTable';
import { grey } from '@mui/material/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMemoCircleInfo, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { useTheme } from '@mui/styles';

const headers: ITableHeader[] = [
  {
    id: 'priority',
    sortable: false,
    label: 'Priority',
  },
  {
    id: 'region',
    sortable: false,
    label: 'Region',
  },
  {
    id: 'number',
    sortable: false,
    label: 'Case Number',
  },
  {
    id: 'levelCount',
    sortable: false,
    label: 'Planned Levels',
  },
  {
    id: 'liveDate',
    sortable: false,
    label: 'Live Date',
  },
  {
    id: 'surgeryDate',
    sortable: false,
    label: 'Surgery Date',
  },
  {
    id: 'surgeonUser',
    sortable: false,
    label: 'Surgeon',
  },
  {
    id: 'hospital',
    sortable: false,
    label: 'Hospital',
  },
  {
    id: 'action',
    sortable: false,
    label: 'Action',
  },
];

const CaseQueueView = () => {
  const { hasRole, user } = useAuth();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [updateCaseAssignment, { loading: updateAssignmentLoading }] =
    useMutation(UPDATE_CASE_ASSIGNMENT);
  const [regions, setRegions] = useState<{ key: string; value: number }[]>([]);
  const [queueEntries, setQueueEntries] = useState<CaseUnassignedType[]>([]);
  const [selectedRegions, setSelectedRegions] = useState<number[]>(() => {
    const saved = localStorage.getItem('CasesQueue.Regions');
    if (!saved) return [];

    try {
      const cleaned =
        saved.startsWith('"') && saved.endsWith('"') ? JSON.parse(saved) : JSON.parse(saved);
      return cleaned;
    } catch {
      return [];
    }
  });
  const theme = useTheme();

  const [findCase] = useLazyQuery(
    gql`
      query FindCase($caseId: Int!) {
        case(caseId: $caseId) {
          caseId
          assignedId
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    },
  );

  const { loading: regionsLoading } = useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (regionData) => {
      const regions: { key: string; value: number }[] = (regionData.regions || []).map(
        (region: IRegion) => {
          return { key: region.name, value: region.regionId };
        },
      );
      setRegions(regions);
    },
  });

  const { loading, networkStatus, refetch } = useQuery(FIND_UNASSIGNED_CASES, {
    variables: {
      input: {
        stageFilter: [CaseStageType.Open, CaseStageType.Segmentation],
        regionFilter: selectedRegions.length ? selectedRegions : undefined,
        caseTypeFilter: [CaseType.Live],
        showTentativeSurgeryDates: true,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const unassignedCases: CaseUnassignedType[] = data?.casesUnassigned ?? [];
      setQueueEntries(unassignedCases);
    },
  });

  const showLoadingIndicator = loading && networkStatus === 1;

  const handleAssignmentClick = async (caseId: number) => {
    try {
      const { data } = await findCase({
        variables: { caseId },
      });

      const foundCase = data.case;
      if (foundCase.assignedId) {
        enqueueSnackbar(`Case is already assigned`, {
          variant: 'error',
        });
        return;
      }

      const userId = user?.userId;
      await updateCaseAssignment({
        variables: {
          caseId,
          userId,
        },
      });
      navigate(`/app/cases/${caseId}`);
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error assigning user to case', {
        variant: 'error',
      });
    } finally {
      await refetch();
    }
  };

  const handleChooseRegionClick = async (regionId: number | null) => {
    let newRegions: number[] = [];
    if (regionId) {
      const foundRegion = selectedRegions.some((region) => region === regionId);
      if (foundRegion) {
        newRegions = selectedRegions.filter((region) => region !== regionId);
      } else {
        newRegions = [...selectedRegions, regionId];
      }
    }
    try {
      localStorage.setItem('CasesQueue.Regions', `[${newRegions}]`);

      setSelectedRegions(newRegions);
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error filtering regions', {
        variant: 'error',
      });
    }
  };

  return (
    <Page title={'Case Queue'}>
      <Container maxWidth={false}>
        <CustomAppBar title={`Case Priority Queue`} />
        <Card>
          <CardContent>
            <Stack gap={3}>
              <Stack direction="row" justifyContent={'space-between'}>
                <>
                  <Box>
                    <ActionButton
                      variant={'outlined'}
                      color={'secondary'}
                      onClick={() => refetch()}
                      loading={loading}
                      disabled={regionsLoading || loading || updateAssignmentLoading}
                    >
                      Refresh
                    </ActionButton>
                  </Box>
                  <ButtonGroup>
                    {regions.map((region) => (
                      <Button
                        variant={
                          selectedRegions.some((entry) => entry === region.value)
                            ? 'contained'
                            : undefined
                        }
                        onClick={() => handleChooseRegionClick(region.value)}
                        disabled={regionsLoading || loading || updateAssignmentLoading}
                      >
                        {region.key}
                      </Button>
                    ))}
                  </ButtonGroup>
                </>
              </Stack>
              {!queueEntries.length && !(updateAssignmentLoading || loading || regionsLoading) ? (
                <Stack justifyContent={'center'} alignItems={'center'} py={18} gap={2}>
                  <FontAwesomeIcon icon={faMemoCircleInfo} size={'5x'} color={grey[500]} />
                  <Typography variant={'h2'}>No cases found</Typography>
                  <ActionButton
                    size="small"
                    variant={'outlined'}
                    color={'secondary'}
                    onClick={() => handleChooseRegionClick(null)}
                  >
                    Reset Filters
                  </ActionButton>
                </Stack>
              ) : (
                <CommonTable
                  headers={headers}
                  loading={showLoadingIndicator}
                  rows={
                    <TableBody style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                      {queueEntries?.map((row, index) => {
                        return (
                          <TableRow hover key={`Queue-Row-${index + 1}`}>
                            <TableCell align={'center'}>
                              <Typography>{index + 1}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography>{row.region ?? <>&mdash;</>}</Typography>
                            </TableCell>
                            <TableCell>
                              <Box maxWidth={'140px'}>
                                <Stack
                                  flexDirection={'row'}
                                  gap={1}
                                  alignItems={'center'}
                                  justifyContent={'space-between'}
                                >
                                  <Link
                                    underline={'none'}
                                    variant={'body1'}
                                    href={`/app/cases/${row.caseId}`}
                                  >
                                    <Typography>{row.number}</Typography>
                                  </Link>
                                  {!row.isSegmented && (
                                    <Tooltip title={'Not Segmented'}>
                                      <FontAwesomeIcon
                                        icon={faTriangleExclamation}
                                        color={theme.palette.warning.main}
                                        size={'lg'}
                                      />
                                    </Tooltip>
                                  )}
                                </Stack>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Typography>{row.levelCount ?? <>&mdash;</>}</Typography>
                            </TableCell>
                            <TableCell>
                              <Typography noWrap>
                                {(row.liveDate as string) || <>&mdash;</>}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack flexDirection={'row'} gap={1} alignItems={'center'}>
                                <Typography noWrap>
                                  {row.surgeryDate ? (row.surgeryDate as string) : 'TBD'}
                                </Typography>
                                {row.isSurgeryDateTentative && (
                                  <Chip
                                    size={'small'}
                                    color={'secondary'}
                                    label={'Tent.'}
                                    sx={{
                                      '&.MuiChip-root': {
                                        height: '15px',
                                      },
                                      fontSize: '10px',
                                      padding: '1px',
                                    }}
                                  />
                                )}
                              </Stack>
                            </TableCell>
                            <div style={{ display: 'table-cell' }}>
                              <TableCell
                                sx={{
                                  display: 'grid',
                                  minWidth: 0,
                                }}
                              >
                                <Typography
                                  noWrap
                                  textOverflow={'ellipsis'}
                                  overflow={'hidden'}
                                  minWidth={0}
                                >
                                  {row.surgeonUser ? (
                                    format.formatName(row.surgeonUser)
                                  ) : (
                                    <>&mdash;</>
                                  )}
                                </Typography>
                              </TableCell>
                            </div>
                            <div style={{ display: 'table-cell' }}>
                              <TableCell
                                sx={{
                                  display: 'grid',
                                  minWidth: 0,
                                }}
                              >
                                <Typography
                                  noWrap
                                  textOverflow={'ellipsis'}
                                  overflow={'hidden'}
                                  minWidth={0}
                                >
                                  {row.hospital ?? <>&mdash;</>}
                                </Typography>
                              </TableCell>
                            </div>
                            <TableCell>
                              {index <= 0 && (
                                <ActionButton
                                  sx={{ textWrap: 'nowrap', padding: '0', paddingX: '4px' }}
                                  size={'small'}
                                  variant={'outlined'}
                                  color={'secondary'}
                                  loading={updateAssignmentLoading}
                                  disabled={
                                    !hasRole?.([
                                      UserRoleType.CaseEngineer,
                                      UserRoleType.CaseAdmin,
                                    ]) || loading
                                  }
                                  onClick={() => handleAssignmentClick(row.caseId)}
                                >
                                  Assign To Me
                                </ActionButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  }
                />
              )}
            </Stack>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default CaseQueueView;
