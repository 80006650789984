import { faCaretRight as faCaretRightSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertTitle, Box, Card, CardContent, Tab, Tabs, Typography } from '@mui/material';
import { CaseStageType, CaseType, caseUtils, ICase } from '@workflow-nx/common';
import { Dispatch, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CaseCompleteTabView } from './CaseCompleteTab/CaseCompleteTabView';
import { CaseDesignTabView } from './CaseDesignTab/CaseDesignTabView';
import { EditCaseDetailsDialog } from './CaseDetails/EditCaseDetailsDialog';
import { ViewCaseDetails } from './CaseDetails/ViewCaseDetails';
import { CasePlanningTabView } from './CasePlanningTab/CasePlanningTabView';
import { CaseProductionTabView } from './CaseProductionTab/CaseProductionTab.view';
import { CaseProposedTabView } from './CaseProposedTab/CaseProposedTabView';
import { CaseQaReviewTabView } from './CaseQaReviewTabView';
import { CaseReadyTabView } from './CaseReadyTabView';
import { CaseSegmentationTabView } from './CaseSegmentationTab/CaseSegmentationTabView';
import { CaseViewActionType } from './CaseView';

type CaseTabViewProps = {
  activeCase: ICase;
  canEdit: boolean;
  dispatch: Dispatch<CaseViewActionType>;
};

function TabLabelWithStageIcon(props: {
  currentStage: CaseStageType;
  tabStage: CaseStageType;
  label: string;
}) {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <Typography noWrap>
        {props.currentStage === props.tabStage ? (
          <>
            <FontAwesomeIcon icon={faCaretRightSolid} color={'action'} />
            <Box component={'span'} pl={1} />
          </>
        ) : null}
      </Typography>
      <span>{props.label}</span>
    </Box>
  );
}

function getStageTabViewFromHash(hash: string, currentStage: CaseStageType): CaseStageType | null {
  // Expected format: #case-{STAGE_NAME}
  const stageName = hash.split('-')[1];

  if (!stageName) return null;

  const toLower = stageName.toLowerCase();

  switch (toLower) {
    case CaseStageType.Segmentation.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Segmentation, currentStage)
        ? CaseStageType.Segmentation
        : null;
    case CaseStageType.Planning.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Planning, currentStage)
        ? CaseStageType.Planning
        : null;
    case CaseStageType.Proposed.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Proposed, currentStage)
        ? CaseStageType.Proposed
        : null;
    case CaseStageType.Design.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Design, currentStage)
        ? CaseStageType.Design
        : null;
    case CaseStageType.QaReview.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.QaReview, currentStage)
        ? CaseStageType.QaReview
        : null;
    case CaseStageType.Production.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Production, currentStage)
        ? CaseStageType.Production
        : null;
    case CaseStageType.Ready.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Ready, currentStage)
        ? CaseStageType.Ready
        : null;
    case CaseStageType.Complete.toLowerCase():
      return !caseUtils.isCaseStageTabDisabled(CaseStageType.Complete, currentStage)
        ? CaseStageType.Complete
        : null;
  }

  return null;
}

export function CaseTabView({ activeCase, dispatch }: CaseTabViewProps) {
  const [openEditCaseDialog, setOpenEditCaseDialog] = useState(false);
  const { hash } = useLocation();
  const [stageTabValue, setStageTabValue] = useState(
    getStageTabViewFromHash(hash, activeCase.stage) ?? CaseStageType.Segmentation,
  );

  const caseTabsEnabled = activeCase.caseType !== CaseType.Draft;

  const showCaseTypeUpgrade =
    activeCase.caseType === CaseType.Draft && !activeCase.caseCancellation;

  const handleUpdate = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      dispatch({ type: 'refetch' });
    }
    setOpenEditCaseDialog(false);
  };

  useEffect(() => {
    if (activeCase?.stage) {
      setStageTabValue(
        getStageTabViewFromHash(hash, activeCase.stage) ||
          (activeCase.stage === CaseStageType.Open ? CaseStageType.Segmentation : activeCase.stage),
      );
    }
  }, [activeCase, hash]);

  return (
    <>
      <Card>
        <CardContent>
          <Box m={1}>
            <ViewCaseDetails
              onChange={() => handleUpdate(true)}
              onEdit={() => setOpenEditCaseDialog(true)}
              activeCase={activeCase}
            />
          </Box>
        </CardContent>
      </Card>
      <Box my={2} />

      {showCaseTypeUpgrade ? (
        <Box m={2}>
          <Alert severity={'warning'}>
            <AlertTitle>Draft Case</AlertTitle>
            The case is currently in draft. Work cannot process until a designer is assigned.
          </Alert>
        </Box>
      ) : null}

      {caseTabsEnabled ? (
        <Box display={'flex'} justifyContent={'center'}>
          <Tabs
            value={stageTabValue ?? CaseStageType.Segmentation}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, newValue) => {
              setStageTabValue(newValue);
            }}
            style={{ marginBottom: 8 }}
          >
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'Segmentation'}
                  tabStage={CaseStageType.Segmentation}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.Segmentation}
              disabled={caseUtils.isCaseStageTabDisabled(
                CaseStageType.Segmentation,
                activeCase.stage,
              )}
            />
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'Planning'}
                  tabStage={CaseStageType.Planning}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.Planning}
              disabled={caseUtils.isCaseStageTabDisabled(CaseStageType.Planning, activeCase.stage)}
            />
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'Proposed'}
                  tabStage={CaseStageType.Proposed}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.Proposed}
              disabled={caseUtils.isCaseStageTabDisabled(CaseStageType.Proposed, activeCase.stage)}
            />
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'Design'}
                  tabStage={CaseStageType.Design}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.Design}
              disabled={caseUtils.isCaseStageTabDisabled(CaseStageType.Design, activeCase.stage)}
            />
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'QA Review'}
                  tabStage={CaseStageType.QaReview}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.QaReview}
              disabled={caseUtils.isCaseStageTabDisabled(CaseStageType.QaReview, activeCase.stage)}
            />
            <Tab
              label={
                <TabLabelWithStageIcon
                  label={'Production'}
                  tabStage={CaseStageType.Production}
                  currentStage={activeCase.stage}
                />
              }
              value={CaseStageType.Production}
              disabled={caseUtils.isCaseStageTabDisabled(
                CaseStageType.Production,
                activeCase.stage,
              )}
            />
            {activeCase.stage !== CaseStageType.Complete ? (
              <Tab
                label={
                  <TabLabelWithStageIcon
                    label={'Ready'}
                    tabStage={CaseStageType.Ready}
                    currentStage={activeCase.stage}
                  />
                }
                value={CaseStageType.Ready}
                disabled={caseUtils.isCaseStageTabDisabled(CaseStageType.Ready, activeCase.stage)}
              />
            ) : null}
            {activeCase.stage === CaseStageType.Complete ? (
              <Tab
                label={
                  <TabLabelWithStageIcon
                    label={'Complete'}
                    tabStage={CaseStageType.Complete}
                    currentStage={activeCase.stage}
                  />
                }
                value={CaseStageType.Complete}
                disabled={caseUtils.isCaseStageTabDisabled(
                  CaseStageType.Complete,
                  activeCase.stage,
                )}
              />
            ) : null}
          </Tabs>
        </Box>
      ) : null}

      {!activeCase?.assignedUser ? (
        <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Segmentation}>
          {stageTabValue === CaseStageType.Segmentation ? (
            <CaseSegmentationTabView activeCase={activeCase} dispatch={dispatch} />
          ) : null}
        </div>
      ) : null}

      {activeCase?.assignedUser ? (
        <>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Segmentation}>
            {stageTabValue === CaseStageType.Segmentation ? (
              <CaseSegmentationTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Planning}>
            {stageTabValue === CaseStageType.Planning ? (
              <CasePlanningTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Proposed}>
            {stageTabValue === CaseStageType.Proposed ? (
              <CaseProposedTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Design}>
            {stageTabValue === CaseStageType.Design ? (
              <CaseDesignTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.QaReview}>
            {stageTabValue === CaseStageType.QaReview ? (
              <CaseQaReviewTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Production}>
            {stageTabValue === CaseStageType.Production ? (
              <CaseProductionTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Ready}>
            {stageTabValue === CaseStageType.Ready ? (
              <CaseReadyTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
          <div role={'tabpanel'} hidden={stageTabValue !== CaseStageType.Complete}>
            {stageTabValue === CaseStageType.Complete ? (
              <CaseCompleteTabView activeCase={activeCase} dispatch={dispatch} />
            ) : null}
          </div>
        </>
      ) : null}
      {openEditCaseDialog ? (
        <EditCaseDetailsDialog
          open={openEditCaseDialog}
          activeCase={activeCase}
          onClose={handleUpdate}
        />
      ) : null}
    </>
  );
}
