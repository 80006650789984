import React from 'react';
import { IActivity, format } from '@workflow-nx/common';
import { Box, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { SelectFieldRHF, CheckboxRHF, ErrorMessageRHF } from '@workflow-nx/ui';
import RadioGroupRHF from '../../../../../components/RadioGroupRHF';
import {
  segmentationImagingAssessmentTypeOptions,
  segmentationSliceThicknessTypeMenuItems,
} from './caseReportMenuOptions';

interface CaseReportImagingAndSegmentationProps {
  control: Control<FieldValues>;
  segmentedByQualifiedOperator?: boolean;
  segmentationOperators: { key: string; value: string }[];
  segmentationReviewers: { key: string; value: string }[];
  disabled: boolean;
  autoSegmentaionApprovalActivity?: IActivity | undefined;
}

export const CaseReportImagingAndSegmentation: React.FC<CaseReportImagingAndSegmentationProps> = ({
  control,
  segmentedByQualifiedOperator,
  segmentationOperators,
  segmentationReviewers,
  disabled,
  autoSegmentaionApprovalActivity,
}) => {
  return (
    <>
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>Case Imaging and Segmentation Section</strong>
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Case Imaging</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <RadioGroupRHF
            name={'segmentationImagingAssessment'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            hideLabel={false}
            disabled={disabled}
            row={true}
            options={segmentationImagingAssessmentTypeOptions}
            onChange={(e) => {}}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'segmentationImagingAssessment'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Segmentation Slice Thickness</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <SelectFieldRHF
            name={'segmentationSliceThickness'}
            control={control as unknown as Control<FieldValues>}
            label={''}
            hideNone={true}
            menuItems={segmentationSliceThicknessTypeMenuItems}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'segmentationSliceThickness'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Segmentation Passed?</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <CheckboxRHF
            name={`segmentationPassed`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Performed by Qualified Operator</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <CheckboxRHF
            name={`segmentedByQualifiedOperator`}
            control={control as unknown as Control<FieldValues>}
            label={''}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
      {!segmentedByQualifiedOperator ? (
        <>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Segmentation Operator</Typography>
            </Box>
            {autoSegmentaionApprovalActivity ? (
              <>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <Typography variant={'body1'}>Auto-Segmentation</Typography>
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}></Box>
              </>
            ) : (
              <>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'segmentationPerformedBy'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={segmentationOperators}
                    disabled={disabled}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'segmentationPerformedBy'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </>
            )}
          </Box>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Segmentation Reviewer</Typography>
            </Box>
            {autoSegmentaionApprovalActivity ? (
              <>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <Typography variant={'body1'}>
                    {format.formatName(autoSegmentaionApprovalActivity.createdByUser)}
                  </Typography>
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}></Box>
              </>
            ) : (
              <>
                <Box display={'flex'} width={650} justifyContent={'left'}>
                  <SelectFieldRHF
                    name={'segmentationReviewedBy'}
                    control={control as unknown as Control<FieldValues>}
                    label={''}
                    hideNone={true}
                    menuItems={segmentationReviewers}
                    disabled={disabled}
                  />
                </Box>
                <Box display={'flex'} width={240} justifyContent={'left'}>
                  <ErrorMessageRHF
                    name={'segmentationReviewedBy'}
                    control={control as unknown as Control<FieldValues>}
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      ) : null}
    </>
  );
};
