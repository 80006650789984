import {
  ICase,
  IAsset,
  AssetType,
  LevelType,
  CaseReportStatusType,
  CaseRiskAssessmentType,
  SagittalGoalType,
  CoronalGoalType,
  SegmentationImagingAssessmentType,
  SegmentationType,
  LordosisDifferenceRationaleType,
  StatementType,
  Statement,
  PathologyType,
  ICaseReport,
  IPatientRecord,
  ImplantType,
  ISurgeonPreferences,
  SegmentationSliceThicknessType,
  IUser,
  ICaseLevel,
  IPlan,
  caseUtils,
  CaseSpineProfile,
  CaseSpineType,
  PlanFeedbackType,
  ICaseAnalysis,
  IActivity,
} from '@workflow-nx/common';

import {
  getCaseLevelCaseReportAssets,
  getCaseLevelSurgeonPreferences,
  getCaseReportCaseData,
} from '../../../../../utils/caseReport/caseReport';
import { getCaseReportStatements } from '../../../../../utils/caseReport/caseReportStatements';
import { getCasePathologies } from '../../../../../utils/caseReport/caseReportPathologies';
import { getCaseReportRevisions } from '../../../../../utils/caseReport/caseReportRevisions';
import {
  CaseReportAlignmentGoalType,
  getProposedLumbarMeasurements,
} from '../../../../../utils/caseReport/caseReportAlignmentGoals';

export type CaseReportDialogStateType = {
  CaseData?: CaseReportCaseData;
  isStateReady?: boolean;
  caseReportVertebraePreEditImage?: File;
  caseReportVertebraePostEditImage?: File;
  caseReportImplantPreEditImage?: File;
  caseReportImplantPostEditImage?: File;
  caseReportStandingXrayLateralMeasured?: File;
  caseReportCorrectionNoteImage?: File;
  caseReportAlifXCagesAnteriorPosteriorConstructImage?: File;
  caseReportAlifXCagesLateralConstructImage?: File;
  assets: Pick<
    IAsset,
    | 'assetId'
    | 'assetType'
    | 'caseId'
    | 'planId'
    | 'fileName'
    | 'size'
    | 'version'
    | 'signedDownloadUrl'
  >[];
  caseReportId: number;
  caseId: number;
  status: CaseReportStatusType;
  riskAssessment?: CaseRiskAssessmentType;
  initialCaseRiskAssessment?: CaseRiskAssessmentType | string;
  correctionGoalSagittal?: SagittalGoalType;
  correctionGoalSagittalOther?: string;
  correctionGoalCoronal?: CoronalGoalType;
  correctionGoalCoronalOther?: CoronalGoalType;
  correctionGoalDiscHeightOnly: boolean;
  correctionGoalNote?: string;
  segmentationAssetId?: number | null;
  segmentationSliceThickness?: SegmentationSliceThicknessType;
  segmentationPassed: boolean;
  segmentationImagingAssessment?: SegmentationImagingAssessmentType;
  segmentationImageDate?: Date | null;
  segmentedByQualifiedOperator?: boolean;
  segmentationPerformedByUser?: IUser;
  segmentationPerformedBy?: number;
  segmentationReviewedByUser?: IUser;
  segmentationReviewedBy?: number;
  segmentationType?: SegmentationType;
  reportReviewRequestedAt?: Date;
  reportReviewedByUser?: IUser;
  reportReviewedBy?: number | string;
  reportReviewedAt?: Date;
  reportReviewNote?: string;
  measurementLordosisDifference?: number;
  measurementLordosisDifferenceRationale: LordosisDifferenceRationaleType[];
  measurementLordosisDifferenceRationaleNote?: string;
  measurementNote?: string;
  planFeedback?: PlanFeedbackType[];
  planFeedbackOther?: string;
  hasEditedVertebralBodies?: boolean;
  caseReportStatements: CaseReportStatementType[];
  casePathologies: CasePathologyType[];
  caseReportRevisions: CaseReportRevisionType[];
  patientRecord?: PatientRecord;
  levelAssets?: LevelAssets[];
  deletedAssetIds?: number[];
  preOpMeasurements?: Pick<ICaseAnalysis, 'analysisType' | 'caseAnalysisMeasurements'>;
  planMeasurements?: Pick<ICaseAnalysis, 'analysisType' | 'caseAnalysisMeasurements'>;
  preferences?: ISurgeonPreferences;
  caseReportAlignmentGoalTypes: CaseReportAlignmentGoalType[];
  proposedLumbarMeasurements?: ProposedLumbarMeasurementType;
  caseEncodedToBase64Images?: CaseEncodedToBase64ImageType;
  autoSegmentaionApprovalActivity?: IActivity | undefined;
};

export type LevelAssets = {
  levelType: LevelType;
  implantType: ImplantType;
  inputAssets: InputAssetType[];
};

export type InputAssetType = {
  level?: LevelType;
  inputAsset?: File | null;
  deletedAssetId?: number | undefined;
  assetType?: AssetType;
  asset?: Pick<
    IAsset,
    | 'assetId'
    | 'assetType'
    | 'caseId'
    | 'planId'
    | 'fileName'
    | 'size'
    | 'version'
    | 'signedDownloadUrl'
  >;
};

export type CaseEncodedToBase64ImageType = {
  encodedVertebraePreEditImage?: string | undefined;
  encodedVertebraePostEditImage?: string | undefined;
  encodedImplantPreEditImage?: string | undefined;
  encodedImplantPostEditImage?: string | undefined;
  encodedStandingXrayLateralMeasured?: string | undefined;
  encodedCorrectionNoteImage?: string | undefined;
  encodedAlifXCagesAnteriorPosteriorConstructImage?: string | undefined;
  encodedAlifXCagesLateralConstructImage?: string | undefined;
  encodedPreopLateralImage?: string | undefined;
  encodedPreopCoronalImage?: string | undefined;
  encodedPlanLateralImage?: string | undefined;
  encodedPlanCoronalImage?: string | undefined;
  encodedAssetLevels: EncodedAssetLevelType[];
};

export type EncodedAssetLevelType = {
  level?: LevelType;
  implantType?: ImplantType;
  assetType?: AssetType;
  encodedImage?: string | undefined;
};

export type CaseReportStatementType = {
  statementType: StatementType;
  statement: Statement;
  response?: boolean;
  note?: string;
};

export type CasePathologyType = {
  pathologyType: PathologyType;
  pathologySelected: boolean;
  pathologyNote?: string;
};

export type CaseReportRevisionType = {
  revision: number;
  description: string;
};

export type PatientRecord = {
  patientId?: number;
  pelvicIncidence?: number | null;
  lumbarLordosis?: number;
  lumbarCoronalCobb?: number;
  sagittalVerticalAxis?: number;
  l4S1LordoticDistribution?: number;
  globalTilt?: number;
  pelvicTilt?: number;
  sacralSlope?: number;
  c7SagittalVerticalLine?: number;
};

export type CaseReportCaseData = {
  caseId: number;
  caseNumber: string;
  caseShortNumber: string | undefined;
  assignedUser: IUser;
  fieldRepUser: IUser;
  surgeonUser: IUser;
  caseLevels: ICaseLevel[];
  patientBirthDate: Date | string;
  patientGender: string;
  mrn?: string;
  planId: number;
  planName: string;
  planDescription?: string;
  plusLevelSize: number;
  caseSpineProfile: CaseSpineProfile;
  caseSpineType: CaseSpineType;
};

export type ProposedLumbarMeasurementType = {
  preOpLumbarMeasurements: LumbarMeasurementType[];
  planLumbarMeasurements: LumbarMeasurementType[];
};

export type LumbarMeasurementType = {
  level: LevelType;
  segmentalLumbarLordosis: number;
  angleToS1: number;
  segmentalCoronalAngle: number;
  segmentalAnteriorHeight: number;
  segmentalPosteriorHeight: number;
};

export interface Asset {
  assetId?: number;
  assetType?: AssetType;
  caseId?: number;
  fileName?: string;
  metadata?: any;
  size?: number;
  version?: number;
  planId?: number;
  signedDownloadUrl?: string;
}

export enum FormActionType {
  SaveCaseReport = 'SAVE_CASE_REPORT',
  PreviewCaseReportPdf = 'PREVIEW_CASE_REPORT_PDF',
  CreateCaseReportPeerReviewRequest = 'CREATE_CASE_REPORT_PEER_REVIEW_REQUEST',
  ApproveCaseReportPeerReview = 'APPROVE_CASE_REPORT_PEER_REVIEW',
  RejectCaseReportPeerReview = 'REJECT_CASE_REPORT_PEER_REVIEW',
}

export type SubmitHandlerWithAction<T> = (
  data: T,
  formActionType: FormActionType,
) => void | Promise<void>;

export type CaseReportDialogActionType = {
  type: 'INIT';
  data?: any;
};

export const CaseReportDialogReducer =
  (activeCase: ICase, casePlan?: IPlan) =>
  (
    state: CaseReportDialogStateType,
    action: CaseReportDialogActionType,
  ): CaseReportDialogStateType => {
    let updatedState: CaseReportDialogStateType = JSON.parse(JSON.stringify(state));
    switch (action.type) {
      case 'INIT': {
        const CaseData = getCaseReportCaseData(activeCase, casePlan);
        const caseReport: ICaseReport = action?.data?.caseReport ?? {};
        const pathologies = action?.data?.caseReport?.casePathologies ?? [];
        const reportStatements = action?.data?.caseReport?.caseReportStatements ?? [];
        const reportRevisions = action?.data?.caseReport?.caseReportRevisions ?? [];
        const patientRecords: IPatientRecord = action?.data?.patient?.patientRecord ?? undefined;
        const preOpMeasurements = action?.data?.caseMeasurements?.preOpMeasurements ?? undefined;
        const planMeasurements = action?.data?.caseMeasurements?.planMeasurements ?? undefined;
        const surgeonPreferences: ISurgeonPreferences =
          action?.data?.user?.preferences ?? undefined;
        const caseAssets = (action?.data?.assets as IAsset[]) ?? [];
        const autoSegmentaionApprovalActivity = (action?.data?.activities?.activities ?? [])?.[0];
        const segmentationType = action?.data?.activities?.count
          ? SegmentationType.Automated
          : SegmentationType.Manual;
        const autoSegmentaionApproverId = autoSegmentaionApprovalActivity?.createdByUser?.userId;
        const casePathologies = getCasePathologies(pathologies);
        const validCaseLevels = caseUtils.getValidCaseLevelRecords(CaseData.caseLevels ?? []);
        const caseReportStatements = getCaseReportStatements(reportStatements, validCaseLevels);
        const caseReportRevisions = getCaseReportRevisions(reportRevisions);
        const caseLevelAssets = getCaseLevelCaseReportAssets(activeCase, caseAssets);
        const caseLevelSurgeonPreferences = getCaseLevelSurgeonPreferences(
          activeCase?.levels,
          surgeonPreferences,
        );
        const initialCaseRiskAssessment = caseUtils.findCaseRiskAssessment(activeCase.caseLevels);
        const caseRiskAssessment = caseReport?.riskAssessment ?? initialCaseRiskAssessment;
        const patientRecord: PatientRecord = {
          patientId: patientRecords?.patientId ?? updatedState?.patientRecord?.patientId,
          pelvicIncidence:
            patientRecords?.pelvicIncidence ?? updatedState?.patientRecord?.pelvicIncidence,
          lumbarLordosis:
            patientRecords?.lumbarLordosis ?? updatedState?.patientRecord?.lumbarLordosis,
          lumbarCoronalCobb:
            patientRecords?.lumbarCoronalCobb ?? updatedState?.patientRecord?.lumbarCoronalCobb,
          sagittalVerticalAxis:
            patientRecords?.sagittalVerticalAxis ??
            updatedState?.patientRecord?.sagittalVerticalAxis,
          l4S1LordoticDistribution:
            patientRecords?.l4S1LordoticDistribution ??
            updatedState?.patientRecord?.l4S1LordoticDistribution,
          globalTilt: patientRecords?.globalTilt ?? updatedState?.patientRecord?.globalTilt,
          pelvicTilt: patientRecords?.pelvicTilt ?? updatedState?.patientRecord?.pelvicTilt,
          sacralSlope: patientRecords?.sacralSlope ?? updatedState?.patientRecord?.sacralSlope,
          c7SagittalVerticalLine:
            patientRecords?.c7SagittalVerticalLine ??
            updatedState?.patientRecord?.c7SagittalVerticalLine,
        };

        const proposedLumbarMeasurements = getProposedLumbarMeasurements(
          preOpMeasurements,
          planMeasurements,
          activeCase.spineProfile,
        );

        const dicomCtAssets = (action?.data?.dicomCtAssets as IAsset[]) ?? [];
        const foundDicomCtAsset = dicomCtAssets.find(
          (asset) => asset.assetType === AssetType.DicomCt,
        );
        const segmentationAssetId = foundDicomCtAsset?.assetId ?? null;
        const segmentationImageDate = foundDicomCtAsset?.metadata?.studyDate ?? null;

        updatedState = {
          CaseData: CaseData,
          isStateReady: true,
          caseReportVertebraePreEditImage: updatedState.caseReportVertebraePreEditImage,
          caseReportVertebraePostEditImage: updatedState.caseReportVertebraePostEditImage,
          caseReportImplantPreEditImage: updatedState.caseReportImplantPreEditImage,
          caseReportImplantPostEditImage: updatedState.caseReportImplantPostEditImage,
          caseReportStandingXrayLateralMeasured: updatedState.caseReportStandingXrayLateralMeasured,
          caseReportCorrectionNoteImage: updatedState.caseReportCorrectionNoteImage,
          caseReportAlifXCagesAnteriorPosteriorConstructImage:
            updatedState.caseReportAlifXCagesAnteriorPosteriorConstructImage,
          caseReportAlifXCagesLateralConstructImage:
            updatedState.caseReportAlifXCagesLateralConstructImage,
          assets: caseAssets,
          caseReportId: caseReport?.caseReportId ?? updatedState.caseReportId,
          caseId: caseReport?.caseId ?? updatedState.caseId,
          status: caseReport?.status ?? updatedState.status,
          riskAssessment: caseRiskAssessment,
          initialCaseRiskAssessment,
          correctionGoalSagittal:
            caseReport?.correctionGoalSagittal ?? updatedState.correctionGoalSagittal,
          correctionGoalSagittalOther:
            caseReport?.correctionGoalSagittalOther ?? updatedState.correctionGoalSagittalOther,
          correctionGoalCoronal:
            caseReport?.correctionGoalCoronal ?? updatedState.correctionGoalCoronal,
          correctionGoalCoronalOther:
            caseReport?.correctionGoalCoronalOther ?? updatedState.correctionGoalCoronalOther,
          correctionGoalDiscHeightOnly:
            caseReport?.correctionGoalDiscHeightOnly ?? updatedState.correctionGoalDiscHeightOnly,
          correctionGoalNote: caseReport?.correctionGoalNote ?? updatedState.correctionGoalNote,
          segmentationAssetId: caseReport?.segmentationAssetId ?? segmentationAssetId,
          segmentationSliceThickness:
            caseReport?.segmentationSliceThickness ?? updatedState.segmentationSliceThickness,
          segmentationPassed: caseReport?.segmentationPassed ?? updatedState.segmentationPassed,
          segmentationImagingAssessment:
            caseReport?.segmentationImagingAssessment ?? updatedState.segmentationImagingAssessment,
          segmentationImageDate: caseReport?.segmentationImageDate ?? segmentationImageDate,
          segmentedByQualifiedOperator:
            caseReport?.segmentedByQualifiedOperator ?? updatedState.segmentedByQualifiedOperator,
          segmentationPerformedByUser:
            caseReport?.segmentationPerformedByUser ?? updatedState.segmentationPerformedByUser,
          segmentationPerformedBy:
            caseReport?.segmentationPerformedByUser?.userId ?? updatedState.segmentationPerformedBy,
          segmentationReviewedByUser:
            caseReport?.segmentationReviewedByUser ?? updatedState.segmentationReviewedByUser,
          segmentationReviewedBy: autoSegmentaionApproverId
            ? autoSegmentaionApproverId
            : caseReport?.segmentationReviewedByUser?.userId ?? updatedState.segmentationReviewedBy,
          segmentationType: segmentationType,
          reportReviewRequestedAt:
            caseReport?.reportReviewRequestedAt ?? updatedState.reportReviewRequestedAt,
          reportReviewedByUser:
            caseReport?.reportReviewedByUser ?? updatedState.reportReviewedByUser,
          reportReviewedBy:
            caseReport?.reportReviewedByUser?.userId ?? updatedState.reportReviewedBy,
          reportReviewedAt: caseReport?.reportReviewedAt ?? updatedState.reportReviewedAt,
          reportReviewNote: caseReport?.reportReviewNote ?? updatedState.reportReviewNote,
          measurementLordosisDifference:
            caseReport?.measurementLordosisDifference ?? updatedState.measurementLordosisDifference,
          measurementLordosisDifferenceRationale:
            caseReport?.measurementLordosisDifferenceRationale ??
            updatedState.measurementLordosisDifferenceRationale,
          measurementLordosisDifferenceRationaleNote:
            caseReport?.measurementLordosisDifferenceRationaleNote ??
            updatedState.measurementLordosisDifferenceRationaleNote,
          measurementNote: caseReport?.measurementNote ?? updatedState.measurementNote,
          planFeedback: caseReport?.planFeedback ?? updatedState.planFeedback,
          planFeedbackOther: caseReport?.planFeedbackOther ?? updatedState.planFeedbackOther,
          hasEditedVertebralBodies:
            caseReport?.hasEditedVertebralBodies ?? updatedState.hasEditedVertebralBodies,
          caseReportStatements: caseReportStatements,
          casePathologies: casePathologies,
          caseReportRevisions: caseReportRevisions,
          patientRecord: patientRecord,
          levelAssets: caseLevelAssets,
          deletedAssetIds: updatedState.deletedAssetIds,
          preOpMeasurements,
          planMeasurements,
          preferences: caseLevelSurgeonPreferences,
          caseReportAlignmentGoalTypes: updatedState.caseReportAlignmentGoalTypes,
          proposedLumbarMeasurements,
          autoSegmentaionApprovalActivity,
        };
      }
    }
    return updatedState;
  };
