import React, { ChangeEvent } from 'react';
import { Box, Typography } from '@mui/material';
import { Control, FieldValues } from 'react-hook-form';
import { CheckboxRHF, SelectFieldRHF, ErrorMessageRHF, TextFieldRHF } from '@workflow-nx/ui';
import {
  SagittalGoalType,
  CoronalGoalType,
  format,
  AssetType,
  IAsset,
  ValidFileExtensions,
} from '@workflow-nx/common';
import { sagittalGoalTypeMenuItems, coronalGoalTypeMenuItems } from './caseReportMenuOptions';
import { CaseReportFileRow } from './CaseReportFileRow';

interface CaseReportCorrectionGoalsProps {
  control: Control<FieldValues>;
  correctionGoalDiscHeightOnly: boolean;
  correctionGoalSagittal?: SagittalGoalType;
  correctionGoalCoronal?: CoronalGoalType;
  disabled: boolean;
  isEditingAllowed: boolean;
  getAsset: (assetType: AssetType) => IAsset | undefined;
  clearAsset: (assetType: AssetType) => void;
  inputAssetFile?: File | null;
  handleDiscOnlyChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const CaseReportCorrectionGoals: React.FC<CaseReportCorrectionGoalsProps> = ({
  control,
  correctionGoalDiscHeightOnly,
  correctionGoalSagittal,
  correctionGoalCoronal,
  disabled,
  isEditingAllowed,
  getAsset,
  clearAsset,
  inputAssetFile,
  handleDiscOnlyChange,
}) => {
  return (
    <>
      <Box display={'flex'} alignItems={'center'} mt={4}>
        <Box display={'flex'} justifyContent={'left'}>
          <Typography variant={'h5'}>
            <strong>Correction Goals Section</strong>
          </Typography>
        </Box>
      </Box>

      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Disc Space Only</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <CheckboxRHF
            name={`correctionGoalDiscHeightOnly`}
            control={control}
            label={''}
            disabled={disabled}
            onChange={handleDiscOnlyChange}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>

      {!correctionGoalDiscHeightOnly && (
        <>
          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Sagittal Correction</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <SelectFieldRHF
                name={'correctionGoalSagittal'}
                control={control}
                label={''}
                hideNone={true}
                menuItems={sagittalGoalTypeMenuItems}
                disabled={disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF name={'correctionGoalSagittal'} control={control} />
            </Box>
          </Box>

          {correctionGoalSagittal === SagittalGoalType.Other && (
            <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Sagittal Correction Other</Typography>
              </Box>
              <Box display={'flex'} width={650} justifyContent={'left'}>
                <TextFieldRHF
                  name={'correctionGoalSagittalOther'}
                  control={control}
                  label={''}
                  fullWidth={true}
                  multiline={false}
                  disabled={disabled}
                />
              </Box>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <ErrorMessageRHF name={'correctionGoalSagittalOther'} control={control} />
              </Box>
            </Box>
          )}

          <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <Typography variant={'body1'}>Coronal Correction</Typography>
            </Box>
            <Box display={'flex'} width={650} justifyContent={'left'}>
              <SelectFieldRHF
                name={'correctionGoalCoronal'}
                control={control}
                label={''}
                hideNone={true}
                menuItems={coronalGoalTypeMenuItems}
                disabled={disabled}
              />
            </Box>
            <Box display={'flex'} width={240} justifyContent={'left'}>
              <ErrorMessageRHF name={'correctionGoalCoronal'} control={control} />
            </Box>
          </Box>

          {correctionGoalCoronal === CoronalGoalType.Other && (
            <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <Typography variant={'body1'}>Coronal Correction Other</Typography>
              </Box>
              <Box display={'flex'} width={650} justifyContent={'left'}>
                <TextFieldRHF
                  name={'correctionGoalCoronalOther'}
                  control={control}
                  label={''}
                  fullWidth={true}
                  multiline={false}
                  disabled={disabled}
                />
              </Box>
              <Box display={'flex'} width={240} justifyContent={'left'}>
                <ErrorMessageRHF name={'correctionGoalCoronalOther'} control={control} />
              </Box>
            </Box>
          )}
        </>
      )}
      <Box display={'flex'} alignItems={'flex-start'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <Typography variant={'body1'}>Correction Note</Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <TextFieldRHF
            name={`correctionGoalNote`}
            control={control}
            label={''}
            fullWidth={true}
            multiline={true}
            disabled={disabled}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}></Box>
      </Box>
      <Box display={'flex'} alignItems={'center'} my={2} gap={1}>
        <Box display={'flex'} width={240} justifyContent={'left'} alignItems={'center'}>
          <Typography variant={'body1'}>
            {format.formatAssetType(AssetType.CaseReportCorrectionNoteImage)}
          </Typography>
        </Box>
        <Box display={'flex'} width={650} justifyContent={'left'}>
          <CaseReportFileRow
            id={'caseReportCorrectionNoteImage'}
            label={'Correction Note Image'}
            editing={isEditingAllowed}
            asset={getAsset(AssetType.CaseReportCorrectionNoteImage)}
            onClick={() => clearAsset(AssetType.CaseReportCorrectionNoteImage)}
            control={control as unknown as Control<FieldValues>}
            validFileExtensions={[ValidFileExtensions.JPG, ValidFileExtensions.PNG]}
            inputAssetFile={inputAssetFile}
          />
        </Box>
        <Box display={'flex'} width={240} justifyContent={'left'}>
          <ErrorMessageRHF
            name={'caseReportCorrectionNoteImage'}
            control={control as unknown as Control<FieldValues>}
          />
        </Box>
      </Box>
    </>
  );
};
