import {
  AssetType,
  IAsset,
  ICase,
  IPlan,
  LevelType,
  LEVEL_CONFIG_MAP,
  ValidFileExtensions,
  IPlanImplant,
  CloudDesignStatus,
} from '@workflow-nx/common';
import React, { Dispatch } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { ExportCloudDesignAssetsButton } from './ExportCloudDesignAssetsButton';
import { AssetGridTable } from '../../../../components/AssetGridTable/AssetGridTable';
import { CloudDesignStatusView } from './CloudDesignStatusView';

function getValidStlAssets(levelType: LevelType) {
  const levelConfig = LEVEL_CONFIG_MAP[levelType];

  let stlAssetTypes: AssetType[] = levelConfig ? Object.values(levelConfig.implantAssets.stl) : [];

  let txtAssetTypes: AssetType[] = levelConfig
    ? Object.values(levelConfig.implantAssets.dimensions)
    : [];

  return { stlAssetTypes, txtAssetTypes };
}

export function LevelAssetGridView(props: {
  activeCase: ICase;
  dispatch: Dispatch<any>;
  levelType: LevelType;
  plan: IPlan;
  planAssets: IAsset[];
  readonly: boolean;
}) {
  const theme = useTheme();
  const { stlAssetTypes, txtAssetTypes } = getValidStlAssets(props.levelType);

  const [superior, inferior] = props.levelType.split('_');
  const foundPlanImplant = props?.plan?.implants?.find(
    (pi: IPlanImplant) => pi.level == props.levelType,
  );

  const disabledAssetTypes: AssetType[] = [];
  if (foundPlanImplant) {
    if (foundPlanImplant?.excludedImplantSizes?.length) {
      disabledAssetTypes.push(
        `${props.levelType}_${foundPlanImplant?.excludedImplantSizes[0]}` as AssetType,
      );
      disabledAssetTypes.push(
        `${props.levelType}_${foundPlanImplant?.excludedImplantSizes[0]}_METADATA` as AssetType,
        `${props.levelType}_${foundPlanImplant?.excludedImplantSizes[0]}_DIMENSIONS` as AssetType,
      );
    }
  }

  return (
    <>
      <Box
        mb={2}
        display={'flex'}
        borderRadius="5px"
        style={{ border: `1px solid ${theme.palette.grey[200]}` }}
      >
        <Box
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          bgcolor={theme.palette.grey[200]}
          p={3}
          mr={2}
        >
          <Typography variant={'h1'}>{superior}</Typography>
          <Typography variant={'h1'}>&mdash;</Typography>
          <Typography variant={'h1'}>{inferior}</Typography>
        </Box>
        <Box width={'100%'} p={2}>
          <Box display={'flex'} alignItems={'center'} mb={2}>
            <Typography variant={'h4'}>Assets</Typography>
            <Box flexGrow={1} />
            <ExportCloudDesignAssetsButton
              disabled={props.readonly}
              caseId={props.activeCase.caseId}
              planId={props?.plan?.planId}
              levelType={props.levelType}
            />
          </Box>
          <AssetGridTable
            dispatch={props.dispatch}
            caseId={props.activeCase.caseId}
            planId={props.plan?.planId}
            assets={props.planAssets || []}
            validFileExtensions={[ValidFileExtensions.STL]}
            validAssets={stlAssetTypes}
            disabledAssetTypes={disabledAssetTypes}
            readOnly={props.readonly}
          />
          <Box my={2} />
          <AssetGridTable
            dispatch={props.dispatch}
            caseId={props.activeCase.caseId}
            planId={props.plan?.planId}
            assets={props.planAssets || []}
            validFileExtensions={[ValidFileExtensions.TXT]}
            validAssets={txtAssetTypes}
            disabledAssetTypes={disabledAssetTypes}
            readOnly={props.readonly}
          />
          <CloudDesignStatusView
            caseId={props.activeCase.caseId}
            planId={props.plan.planId}
            disabled={props.readonly}
            levelType={props.levelType}
            onStatusChange={(status: CloudDesignStatus) => {
              props.dispatch({
                type: 'EXPORT_CLOUD_DESIGN_STATUS_CHANGED',
                data: status,
              });
            }}
          />
        </Box>
      </Box>
    </>
  );
}
