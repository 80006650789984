import {
  AssetType,
  format,
  IAsset,
  ICase,
  ICaseImplantSpecification,
  IPostOpAnalysis,
  MeasurementsVersionType,
  PostOpAnalysisType,
} from '@workflow-nx/common';
import React, { Dispatch, useState } from 'react';
import { Alert, Box, Card, CardContent, Paper, Stack, Tab, Typography } from '@mui/material';
import { AnalysisTableView } from './AnalysisTableView';
import { ImplantAnalysisTableView } from './ImplantAnalysisTableView';
import { EditImplantAnalysisDialog } from './EditImplantAnalysisDialog';
import { EditPostOpAnalysisDialog } from './EditPostOpAnalysisDialog';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { PostOpAssetsView } from './PostOpAssetsView';
import { PreOpStandingXrayAssetGridTable } from '../../../components/PreOpStandingXrayAssetGridTable';

type PostOpAnalysisAssetType =
  | AssetType.PostOpIntraOp
  | AssetType.PostOpOneWeek
  | AssetType.PostOpSixWeeks
  | AssetType.PostOpSixMonths
  | AssetType.PostOpOneYear
  | AssetType.PostOpTwoYear;

export function PostOpCaseDetailsView(props: {
  activeCase: ICase | null;
  assets: IAsset[];
  caseImplantSpecifications?: ICaseImplantSpecification[];
  dispatch: Dispatch<any>;
  loading: boolean;
  onImport: () => Promise<void>;
  preOp?: IPostOpAnalysis;
  onReload: () => void;
  plan?: IPostOpAnalysis;
  postOpIntraOp?: IPostOpAnalysis;
  postOpOneWeek?: IPostOpAnalysis;
  postOpSixWeeks?: IPostOpAnalysis;
  postOpSixMonths?: IPostOpAnalysis;
  postOpOneYear?: IPostOpAnalysis;
  postOpTwoYears?: IPostOpAnalysis;
  measurementsVersion: MeasurementsVersionType;
}) {
  const [showEditPostOpAnalysisDialog, setShowEditPostOpAnalysisDialog] = useState(false);
  const [showEditImplantAnalysisDialog, setShowEditImplantAnalysisDialog] = useState(false);
  const [selectedAnalysisType, setSelectedAnalysisType] = useState<PostOpAnalysisAssetType>(
    AssetType.PostOpSixWeeks,
  );

  function getPostOpAnalysisValues(postOpAssetType: PostOpAnalysisAssetType) {
    switch (postOpAssetType) {
      case AssetType.PostOpIntraOp:
        return props.postOpIntraOp;
      case AssetType.PostOpOneWeek:
        return props.postOpOneWeek;
      case AssetType.PostOpSixWeeks:
        return props.postOpSixWeeks;
      case AssetType.PostOpSixMonths:
        return props.postOpSixMonths;
      case AssetType.PostOpOneYear:
        return props.postOpOneYear;
      case AssetType.PostOpTwoYear:
        return props.postOpTwoYears;
    }
  }

  function getPostOpAnalysisType(postOpAssetType: PostOpAnalysisAssetType) {
    switch (postOpAssetType) {
      case AssetType.PostOpIntraOp:
        return PostOpAnalysisType.PostOpIntraOp;
      case AssetType.PostOpOneWeek:
        return PostOpAnalysisType.PostOpOneWeek;
      case AssetType.PostOpSixWeeks:
        return PostOpAnalysisType.PostOpSixWeeks;
      case AssetType.PostOpSixMonths:
        return PostOpAnalysisType.PostOpSixMonths;
      case AssetType.PostOpOneYear:
        return PostOpAnalysisType.PostOpOneYear;
      case AssetType.PostOpTwoYear:
        return PostOpAnalysisType.PostOpTwoYears;
    }
  }

  const analysisAssetTypes = [
    AssetType.PostOpIntraOp,
    AssetType.PostOpOneWeek,
    AssetType.PostOpSixWeeks,
    AssetType.PostOpSixMonths,
    AssetType.PostOpOneYear,
    AssetType.PostOpTwoYear,
  ];

  return (
    <>
      <Card>
        <CardContent>
          {props.activeCase ? (
            <>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} width={'100%'}>
                <Box width={'85%'} my={2}>
                  {props.measurementsVersion === MeasurementsVersionType.Version1 ? (
                    <Box mb={1}>
                      <Alert severity={'warning'}>Showing version 1 measurements</Alert>
                    </Box>
                  ) : null}
                  <AnalysisTableView
                    label={'PRE-OP'}
                    activeCase={props.activeCase}
                    valueType={'PRE_OP'}
                    analysisValues={props.preOp}
                  />
                  <Stack spacing={2} mt={2}>
                    <Typography variant={'body1'}>
                      <strong>Pre-op Standing X-Ray</strong>
                    </Typography>
                    <Paper>
                      <Box p={1}>
                        <PreOpStandingXrayAssetGridTable caseId={props?.activeCase.caseId} />
                      </Box>
                    </Paper>
                  </Stack>
                </Box>
                <Box width={'85%'} my={2}>
                  {props.measurementsVersion === MeasurementsVersionType.Version1 ? (
                    <Box mb={1}>
                      <Alert severity={'warning'}>Showing version 1 measurements</Alert>
                    </Box>
                  ) : null}
                  <AnalysisTableView
                    label={'PLAN'}
                    activeCase={props.activeCase}
                    valueType={'PLAN'}
                    analysisValues={props.plan}
                  />
                </Box>
                <Box width={'85%'} my={2}>
                  <ImplantAnalysisTableView
                    activeCase={props.activeCase}
                    caseImplantSpecifications={props.caseImplantSpecifications}
                    caseResults={props?.activeCase?.caseResults}
                    onImport={props.onImport}
                    onEdit={() => {
                      setShowEditImplantAnalysisDialog(true);
                    }}
                  />
                </Box>

                <Box my={2} sx={{ width: '100%' }}>
                  <TabContext value={selectedAnalysisType}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
                      <TabList
                        centered={true}
                        onChange={(_, newValue: string) => {
                          setSelectedAnalysisType(newValue as PostOpAnalysisAssetType);
                        }}
                      >
                        {analysisAssetTypes.map((postOpAsset) => {
                          return (
                            <Tab label={format.formatAssetType(postOpAsset)} value={postOpAsset} />
                          );
                        })}
                      </TabList>
                    </Box>
                    {analysisAssetTypes.map((postOpAsset) => {
                      return (
                        <TabPanel value={postOpAsset}>
                          <Stack gap={2} px={3}>
                            <PostOpAssetsView
                              label={format.formatAssetType(postOpAsset)}
                              assetType={postOpAsset}
                              dispatch={props.dispatch}
                              activeCase={props.activeCase as ICase}
                              assets={props.assets}
                            />
                            <AnalysisTableView
                              label={format.formatAssetType(postOpAsset)}
                              activeCase={props.activeCase as ICase}
                              valueType={'POST_OP'}
                              analysisValues={getPostOpAnalysisValues(
                                postOpAsset as PostOpAnalysisAssetType,
                              )}
                              onEdit={() => {
                                setSelectedAnalysisType(postOpAsset as PostOpAnalysisAssetType);
                                setShowEditPostOpAnalysisDialog(true);
                              }}
                              deltaValues={props.plan}
                              onMeasure={() => {}}
                              expanded={true}
                            />
                          </Stack>
                        </TabPanel>
                      );
                    })}
                  </TabContext>
                </Box>
              </Box>
            </>
          ) : null}
        </CardContent>
      </Card>
      {showEditImplantAnalysisDialog && props.activeCase ? (
        <EditImplantAnalysisDialog
          caseId={Number(props.activeCase.caseId)}
          activeCase={props.activeCase}
          caseResults={props.activeCase.caseResults}
          caseImplantSpecifications={props.caseImplantSpecifications}
          onClose={() => {
            setShowEditImplantAnalysisDialog(false);
            props.onReload();
          }}
          open={showEditImplantAnalysisDialog}
        />
      ) : null}
      {showEditPostOpAnalysisDialog && props.activeCase ? (
        <EditPostOpAnalysisDialog
          caseId={Number(props.activeCase.caseId)}
          activeCase={props.activeCase}
          analysisType={getPostOpAnalysisType(selectedAnalysisType)}
          onClose={() => {
            setShowEditPostOpAnalysisDialog(false);
            props.onReload();
          }}
          open={showEditPostOpAnalysisDialog}
        />
      ) : null}
    </>
  );
}
