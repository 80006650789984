import { faFile } from '@fortawesome/pro-light-svg-icons';
import { faTrashXmark } from '@fortawesome/pro-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { ComponentProps, memo, useEffect, useState } from 'react';
import { Control, FieldValues, useController } from 'react-hook-form';
import { GenericFileDropzone } from './GenericFileDropzone';
import { IconFontButton } from './IconFontButton';

const DropzoneRHF = memo(function ({
  name,
  control,
  validFileExtensions,
  variant,
}: {
  name: string;
  variant?: 'small' | 'medium';
  control: Control<FieldValues>;
  validFileExtensions: ComponentProps<typeof GenericFileDropzone>['validFileExtensions'];
}) {
  const {
    field: { onChange: hookFormOnChange },
    formState,
  } = useController({
    name,
    control,
  });

  const [currentFile, setCurrentFile] = useState<File | null>();
  const defaultValue = control?._defaultValues?.[name];

  useEffect(() => {
    if (!formState.isDirty) {
      hookFormOnChange(null);
      setCurrentFile(null);
    }
  }, [formState.isDirty]);

  return (
    <>
      {currentFile ? (
        <Paper elevation={4}>
          <Box
            display={'flex'}
            alignItems={'center'}
            alignContent={'center'}
            justifyContent={'space-between'}
            py={2}
            px={3}
          >
            <Stack direction={'row'} alignItems={'center'}>
              <FontAwesomeIcon icon={faFile} size="lg" />
              <Stack ml={2}>
                <Typography>{currentFile.name}</Typography>
                <Typography color={'textSecondary'} variant={'caption'}>
                  New Unsaved File
                </Typography>
              </Stack>
            </Stack>

            <IconFontButton
              icon={faTrashXmark}
              size="small"
              fontColor="red"
              onClick={() => {
                setCurrentFile(null);
                hookFormOnChange(defaultValue);
              }}
            />
          </Box>
        </Paper>
      ) : (
        <GenericFileDropzone
          validFileExtensions={validFileExtensions}
          onFileSelect={function (file: File): void {
            setCurrentFile(file);
            hookFormOnChange(file);
          }}
          variant={variant}
          existingFile={defaultValue}
        />
      )}
    </>
  );
});

export { DropzoneRHF };
