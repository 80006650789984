import React, { Dispatch, useState } from 'react';
import { useQuery } from '@apollo/client';
import { format, UserStatusType, IRegion, ITerritory } from '@workflow-nx/common';
import { FIND_REGIONS } from '../../../gql';
import { Box, Button } from '@mui/material';
import { Select } from '../../../components/Select';
import { ListUsersActionType } from './ListUsers.reducer';
import { getSurgeonFilteredUserRolesKVPSorted } from '../../../utils/user';

const roles = getSurgeonFilteredUserRolesKVPSorted();

const statuses = [
  { key: UserStatusType.Active, value: format.formatUserStatus(UserStatusType.Active) },
  { key: UserStatusType.Inactive, value: format.formatUserStatus(UserStatusType.Inactive) },
  { key: UserStatusType.Pending, value: format.formatUserStatus(UserStatusType.Pending) },
];

export function UserTableFilter(props: {
  state: {
    role?: string;
    status?: string;
    region?: number;
    territory?: number;
  };
  loading: boolean;
  dispatch: Dispatch<ListUsersActionType>;
}) {
  const [regions, setRegions] = useState<
    {
      key: string;
      value: string;
      territories: any[];
    }[]
  >([]);

  useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (regionData) => {
      const regions: {
        key: string;
        value: string;
        territories: any[];
      }[] = (regionData.regions || []).map((region: IRegion) => {
        const territories = region?.territories?.map((territory: ITerritory) => {
          return { key: territory.territoryId, value: territory.name };
        });
        return { key: region.regionId, value: region.name, territories: territories ?? [] };
      });
      setRegions(regions);
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'role'}
          label={'Role'}
          value={props.state.role?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'ROLE_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={roles}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'status'}
          label={'Status'}
          value={props.state.status?.toString()}
          onChange={(event) => {
            props.dispatch({
              type: 'STATUS_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={statuses}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'region'}
          label={'Area'}
          value={props.state.region?.toString() ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'REGION_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={regions}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Select
          name={'territory'}
          label={'Region'}
          value={props.state.territory?.toString() ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'TERRITORY_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={
            props.state.region
              ? regions.find((region) => region.key == props.state.region?.toString())
                  ?.territories ?? []
              : []
          }
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
