import React, { useEffect, useState } from 'react';
import {
  AssetType,
  CaseSpineProfile,
  CaseStageType,
  DaisySegmentationStatus,
  format,
  IAsset,
  ICaseTag,
  IDaisySegmentationQueue,
} from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_AUTO_SEGMENTATION,
  DELETE_EXPORT_DAISY_SEGMENTATION,
  FIND_ASSETS,
  FIND_DAISY_SEGMENTATION_QUEUE,
} from '../../../../gql';
import { IconFontButton } from '@workflow-nx/ui';
import { Alert, Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import {
  faFlower,
  faPlay,
  faRedo,
  faStop,
  faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useConfirm } from 'material-ui-confirm';
import { canDaisySegmentCase } from '../../../../utils/case';
import { DaisySegmentationCompleteView } from './DaisySegmentationCompleteView';
import { faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';

export function AutoSegmentationView(props: {
  caseId: number;
  caseNumber: string;
  caseTags: ICaseTag[];
  disabled: boolean;
  caseStage: CaseStageType;
  spineProfile: CaseSpineProfile;
  onApproved: () => void;
  onRejected: () => void;
}) {
  const theme = useTheme();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [daisySegmentationQueue, setDaisySegmentationQueue] = useState<IDaisySegmentationQueue>();
  const [hasAllRequiredAssets, setHasAllRequiredAssets] = useState(false);

  const [createAutoSegmentation, { loading: loadingCreateAutoSegmentation }] =
    useMutation(CREATE_AUTO_SEGMENTATION);

  const [deleteExportDaisySegmentation, { loading: loadingDeleteExportDaisySegmentation }] =
    useMutation(DELETE_EXPORT_DAISY_SEGMENTATION);

  const { refetch, startPolling, stopPolling } = useQuery(FIND_DAISY_SEGMENTATION_QUEUE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { caseId: props.caseId },
    onCompleted: (data) => {
      setDaisySegmentationQueue(data.daisySegmentationQueue);

      if (data.daisySegmentationQueue) {
        const { status } = data.daisySegmentationQueue;
        if (
          [
            DaisySegmentationStatus.Success,
            DaisySegmentationStatus.Completed,
            DaisySegmentationStatus.Error,
            DaisySegmentationStatus.None,
          ].includes(status)
        ) {
          stopPolling();
        } else {
          startPolling(15000);
        }
      }
    },
  });

  const [findAssets, { loading: loadingAssets }] = useLazyQuery(FIND_ASSETS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const requiredAssets = data.assets.filter((asset: IAsset) =>
        [AssetType.DicomCt].includes(asset.assetType),
      ).length;
      setHasAllRequiredAssets(requiredAssets > 0);
    },
  });

  const handleExportAssetsClick = async () => {
    try {
      await createAutoSegmentation({
        variables: {
          caseId: props.caseId,
        },
      });

      setDaisySegmentationQueue(undefined);
      await refetch({ caseId: props.caseId });
      enqueueSnackbar(`The DICOM has been sent for segmentation`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred exporting the DICOM', { variant: 'error' });
    }
  };

  const handleCancelClick = async () => {
    try {
      if (daisySegmentationQueue?.status === DaisySegmentationStatus.Processing) {
        await confirm({
          title: `Cancel segmentation ${props.caseNumber}?`,
          description: (
            <>
              This will remove the case from the segmentation queue. Are you sure you wish to
              continue?
            </>
          ),
        });
      }

      await deleteExportDaisySegmentation({
        variables: {
          daisySegmentationQueueId: daisySegmentationQueue?.daisySegmentationQueueId,
        },
      });

      stopPolling();
      setDaisySegmentationQueue(undefined);
      await refetch({ caseId: props.caseId });
      enqueueSnackbar(`The segmentation request has been cancelled`, { variant: 'success' });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('An error occurred cancelling the segmentation request', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    findAssets({
      variables: {
        caseId: props.caseId,
        assetTypeFilter: [AssetType.DicomCt, AssetType.HardwareDaisy],
      },
    });
  }, []);

  const daisySegmentationAllowed = canDaisySegmentCase(
    props.caseTags.map((caseTag) => {
      return {
        key: caseTag.tag.tagId,
        value: caseTag.tag.label,
      };
    }),
  );

  const disabled =
    !hasAllRequiredAssets ||
    loadingDeleteExportDaisySegmentation ||
    loadingCreateAutoSegmentation ||
    loadingAssets;

  const isRunning =
    (!!daisySegmentationQueue && !daisySegmentationQueue?.finishedAt) ||
    loadingCreateAutoSegmentation;

  const progressLog = [...(daisySegmentationQueue?.log ?? [])].reverse();

  return (
    <>
      <Stack p={2} spacing={1} border={`1px solid ${theme.palette.divider}`} borderRadius="5px">
        <Stack direction={'row'} spacing={1} alignItems={'center'}>
          <FontAwesomeIcon icon={faFlower} size={'xl'} />
          <Typography variant={'h4'}>Auto-Segmentation</Typography>
          <Box flexGrow={1} />
          <IconFontButton
            icon={!daisySegmentationQueue ? faPlay : faRedo}
            loading={isRunning}
            disabled={
              daisySegmentationQueue?.status === DaisySegmentationStatus.Processing || disabled
            }
            onClick={handleExportAssetsClick}
          />
          {daisySegmentationQueue?.status === DaisySegmentationStatus.Processing ? (
            <IconFontButton
              icon={faStop}
              disabled={disabled || !daisySegmentationQueue}
              onClick={handleCancelClick}
            />
          ) : null}
        </Stack>
        {!daisySegmentationAllowed ? (
          <Alert severity={'error'}>
            Segmentation is not allowed because of the tags assigned to this case.
          </Alert>
        ) : null}
        {!hasAllRequiredAssets ? (
          <Alert severity={'warning'}>
            <Typography variant={'body1'}>
              Cannot send assets for auto-segmentation if there is not a DICOM CT attached to the
              case.
            </Typography>
          </Alert>
        ) : null}
        {hasAllRequiredAssets &&
        !daisySegmentationQueue &&
        !loadingCreateAutoSegmentation &&
        daisySegmentationAllowed ? (
          <Alert severity={'info'}>
            <Typography variant={'body1'}>Auto-segmentation is available!</Typography>
          </Alert>
        ) : null}
        {hasAllRequiredAssets && loadingCreateAutoSegmentation ? (
          <Alert severity={'info'}>
            <Typography variant={'body1'}>Auto-segmentation is starting!</Typography>
          </Alert>
        ) : null}
        {daisySegmentationQueue ? <Divider /> : null}
        {daisySegmentationQueue ? (
          <Box>
            <Typography variant={'body1'} my={1}>
              Segmentation started at{' '}
              {format.formatDateTime(daisySegmentationQueue.startedAt as Date)}
            </Typography>

            <Stack direction={'row'} justifyContent={'space-between'}>
              <Typography variant={'body1'}>
                <strong>Status</strong>
              </Typography>
              <Typography variant={'body1'}>{daisySegmentationQueue.status}</Typography>
            </Stack>
            {[DaisySegmentationStatus.Completed, DaisySegmentationStatus.Success].includes(
              daisySegmentationQueue.status,
            ) ? (
              <DaisySegmentationCompleteView
                caseId={props.caseId}
                caseNumber={props.caseNumber}
                caseTags={props.caseTags}
                caseStage={props.caseStage}
                caseSpineProfile={props.spineProfile}
              />
            ) : null}

            <Box mx={2} my={2}>
              <Typography variant={'body1'}>
                <strong>
                  {
                    progressLog?.find(
                      (logEntry: Record<string, string>) => logEntry.type === 'ExecutionStarted',
                    )?.input?.key
                  }
                </strong>
              </Typography>
            </Box>

            <Stack spacing={1} sx={{ height: 200, overflowY: 'auto' }}>
              {progressLog?.map((log: Record<string, string>, index: number) => {
                return (
                  <Stack direction={'row'} justifyContent={'space-between'} spacing={1} key={index}>
                    <Typography variant={'body2'}>
                      {log?.eventType === 'STATE_ENTER' ? <FontAwesomeIcon icon={faPlus} /> : null}
                      {log?.eventType === 'STATE_EXIT' ? <FontAwesomeIcon icon={faMinus} /> : null}
                      {log?.eventType === 'ERROR' ? (
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                      ) : null}
                      &nbsp;{log?.stateName === 'UNKNOWN' ? log.type : log?.stateName}
                    </Typography>
                    {log?.timestamp ? (
                      <Typography variant={'body2'}>{format.formatTime(log?.timestamp)}</Typography>
                    ) : null}
                  </Stack>
                );
              })}
            </Stack>
          </Box>
        ) : null}
      </Stack>
    </>
  );
}
