import { format } from '@workflow-nx/common';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_CASE_REPORT_REVISION } from '../../../../../gql';
import { Box, Grid, Typography } from '@mui/material';
import CustomDialog from '../../../../../components/CustomDialog';
import * as Yup from 'yup';
import { ErrorMessageRHF, InputTypeRHF, ProgressButton, TextFieldRHF } from '@workflow-nx/ui';
import { useForm, Control, FieldValues, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from 'lodash';

interface ICreateCaseReportRevisionValues {
  description: string;
}

const formRowTextData = [
  {
    id: 'description',
    label: 'Description',
    input: InputTypeRHF.Text,
    extra: false,
  },
];

export function CreateCaseReportRevisionDialog({
  caseReportId,
  revision,
  open,
  onClose,
}: {
  caseReportId: number;
  revision: number;
  open: boolean;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [createCaseReportRevision] = useMutation(CREATE_CASE_REPORT_REVISION);
  //const nextRevision = revision + 1 ;

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = useForm<ICreateCaseReportRevisionValues>({
    defaultValues: {
      description: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        description: Yup.string().required('Field must be filled out'),
      }),
    ),
  });

  const handleSubmitForm: SubmitHandler<ICreateCaseReportRevisionValues> = async (values) => {
    try {
      await createCaseReportRevision({
        variables: {
          caseReportId: caseReportId,
          revision: revision,
          description: values.description,
        },
      });

      enqueueSnackbar('Case report new revision created', {
        variant: 'success',
      });
      reset();
      onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the case report new revision', {
        variant: 'error',
      });
    }
  };

  return (
    <CustomDialog
      maxWidth={'md'}
      open={open}
      title={`Create New Revision`}
      onClose={() => {
        reset();
        onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          loading={isSubmitting}
          disabled={isSubmitting}
          label={'Create'}
        />,
      ]}
    >
      {open ? (
        <Box>
          <form>
            <Grid container spacing={3}>
              {formRowTextData.map((config, index) => {
                return (
                  <Grid item container xs={12} spacing={1} alignItems={'center'} key={index}>
                    <Grid item xs={3} md={3} display={'flex'} alignSelf={'flex-start'}>
                      <Typography variant={'body1'}>{config.label}</Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      {config.input === InputTypeRHF.Text ? (
                        <TextFieldRHF
                          name={config.id}
                          control={control as unknown as Control<FieldValues>}
                          disabled={isSubmitting}
                          multiline={config.id === 'description'}
                        />
                      ) : null}
                    </Grid>
                    <Grid item xs={3} md={3} display={'flex'} justifyContent={'flex-start'}>
                      <ErrorMessageRHF
                        name={config.id}
                        control={control as unknown as Control<FieldValues>}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </form>
        </Box>
      ) : null}
    </CustomDialog>
  );
}
