import { Suspense, Fragment } from 'react';
import DashboardLayout from './layouts/DashboardLayout';
import LoginView from './views/auth/LoginView';
import CodeView from './views/auth/CodeView';
import LoadingScreen from './components/LoadingScreen';
import GuestGuard from './components/GuestGuard';
import DashboardView from './views/DashboardView';
import AuthGuard from './components/AuthGuard';
import ListUsersView from './views/users/ListUsersView/ListUsers.view';
import ListSurgeonsView from './views/users/ListSurgeonsView/ListSurgeons.view';
import ListOrganizationsView from './views/organizations/ListOrganizationsView/ListOrganizations.view';
import CaseView from './views/cases/CaseView/CaseView';
import OrganizationView from './views/organizations/OrganizationView/OrganizationView.view';
import UserView from './views/users/UserView/UserView.view';
import SurgeonView from './views/users/SurgeonView/SurgeonView.view';
import ListCaseTriageView from './views/cases/ListCaseTriageView/ListCaseTriage.view';
import CaseCalendarView from './views/cases/CaseCalendarView/CaseCalendar.view';
import ListTasksView from './views/tasks/ListTasksView';
import ListPostOpCasesView from './views/post-op-cases/ListPostOpCases/ListPostOpCases.view';
import PostOpCaseView from './views/post-op-cases/PostOpCaseView/PostOpCase.view';
import { SettingsView } from './views/SettingsView';
import { Routes, Route, Navigate } from 'react-router-dom';
import ListCasesView from './views/cases/ListCasesView/ListCases.view';
import ListCaseEventsView from './views/cases/ListCasesView/ListCasesViewProductionFocused/ListCaseEvents.view';
import TOTPView from './views/auth/TOTPView';
import ListHealthSystemsView from './views/organizations/ListHealthSystemsView/ListHealthSystems.view';
import CaseQueueView from './views/CaseQueue/CaseQueueView';
import { Permission } from '@workflow-nx/common';

export const renderRoutes = (routes: any[] = []) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        {routes.map((route: any, i: number) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          return (
            <Route key={i} path={route.path} element={Component ? <Component /> : <Layout />}>
              {route?.routes?.map((childRoute: any, j: number) => {
                const Component = childRoute.component;
                const permissions = childRoute.permissions || [];

                return (
                  <Route
                    key={j}
                    path={childRoute.path}
                    element={
                      <Guard permissions={permissions}>
                        <Component />
                      </Guard>
                    }
                  />
                );
              })}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
};

const routes = [
  {
    guard: GuestGuard,
    path: '/login',
    component: LoginView,
  },
  {
    guard: GuestGuard,
    path: '/code',
    component: CodeView,
  },
  {
    guard: GuestGuard,
    path: '/totp',
    component: TOTPView,
  },
  {
    path: '/app/*',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        path: 'dashboard',
        component: DashboardView,
        permissions: [],
      },
      {
        path: 'settings',
        component: SettingsView,
        permissions: [
          Permission.ManageSiteSettings,
          Permission.ManageSiteConfiguration,
          Permission.ManageImplantPartDetails,
          Permission.ManageProductionSettings,
        ],
      },
      {
        path: 'case-queue',
        component: CaseQueueView,
        permissions: [Permission.ListCases],
      },
      {
        path: 'cases',
        component: ListCasesView,
        permissions: [Permission.ListCases],
      },
      {
        path: 'case-events',
        component: ListCaseEventsView,
        permissions: [Permission.ListCases],
      },
      {
        path: 'case-triage',
        component: ListCaseTriageView,
        permissions: [Permission.ListCases],
      },
      {
        path: 'post-op-analysis',
        component: ListPostOpCasesView,
        permissions: [Permission.ListPostOpAnalyses],
      },
      {
        path: 'post-op-analysis/:caseId',
        component: PostOpCaseView,
        permissions: [Permission.ViewPostOpAnalysis],
      },
      {
        path: 'case-calendar',
        component: CaseCalendarView,
        permissions: [Permission.ListCases],
      },
      {
        path: 'cases/:caseId',
        component: CaseView,
        permissions: [Permission.ViewCase],
      },
      {
        path: 'users',
        component: ListUsersView,
        permissions: [Permission.ListUsers],
      },
      {
        path: 'user/:userId',
        component: UserView,
        permissions: [Permission.ViewUser],
      },
      {
        path: 'surgeons',
        component: ListSurgeonsView,
        permissions: [Permission.ListSurgeons],
      },
      {
        path: 'surgeon/:userId',
        component: SurgeonView,
        permissions: [Permission.ViewSurgeon],
      },
      {
        path: 'tasks',
        component: ListTasksView,
        permissions: [Permission.ListTasks],
      },
      {
        path: 'organizations',
        component: ListOrganizationsView,
        permissions: [Permission.ListOrganizations],
      },
      {
        path: 'organizations/:organizationId',
        component: OrganizationView,
        permissions: [Permission.ViewOrganization],
      },
      {
        path: 'health-systems',
        component: ListHealthSystemsView,
        permissions: [Permission.ListOrganizations],
      },
      {
        path: 'health-systems/:organizationId',
        component: OrganizationView,
        permissions: [Permission.ViewOrganization],
      },
      {
        path: '*',
        component: () => <Navigate to="/app/dashboard" />,
      },
    ],
  },
  {
    path: '*',
    component: () => <Navigate to="/app/dashboard" />,
  },
];

export default routes;
