import React, { Dispatch, useState } from 'react';
import { IRegion } from '@workflow-nx/common';
import { Box, Button } from '@mui/material';
import { Select } from '../../../components/Select';
import { useQuery } from '@apollo/client';
import { FIND_REGIONS } from '../../../gql';
import { ListOrganizationsActionType, ListOrganizationsStateType } from './HealthSystemsListTable';

export function HealthSystemsTableFilter(props: {
  state: ListOrganizationsStateType;
  loading: boolean;
  dispatch: Dispatch<ListOrganizationsActionType>;
}) {
  const [regions, setRegions] = useState<
    {
      key: string;
      value: string;
    }[]
  >([]);

  useQuery(FIND_REGIONS, {
    fetchPolicy: 'network-only',
    onCompleted: (regionData) => {
      const regions: {
        key: string;
        value: string;
      }[] = (regionData.regions || []).map((region: IRegion) => {
        return { key: region.regionId, value: region.name };
      });
      setRegions(regions);
    },
  });

  return (
    <Box display={'flex'} flexDirection={'column'} gap={2}>
      <Box>
        <Select
          name={'regionId'}
          label={'Area'}
          value={props.state.regionId?.toString() ?? ''}
          onChange={(event) => {
            props.dispatch({
              type: 'REGION_CHANGED',
              data: event.target.value,
            });
          }}
          menuItems={regions}
          disabled={props.loading}
          fullWidth={true}
        />
      </Box>
      <Box>
        <Box textAlign={'center'}>
          <Button onClick={() => props.dispatch({ type: 'RESET_FILTERS' })}>Reset Filters</Button>
        </Box>
      </Box>
    </Box>
  );
}
