import React, { useRef } from 'react';
import CustomDialog from '../../../components/CustomDialog';
import { ProgressButton } from '@workflow-nx/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ImplantType, format, IImplantPartDetail } from '@workflow-nx/common';
import { UPDATE_IMPLANT_PART_DETAIL } from '../../../gql';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import ImplantPartDetailForm, {
  ImplantFormType,
  ImplantPartDetailsFormHandle,
  getImplantPartDetailSchema,
} from './ImplantPartDetailForm';
import { date } from '@workflow-nx/utils';

export function EditImplantPartDetailDialog(props: {
  implantType: ImplantType;
  open: boolean;
  selectedImplantPartDetail: IImplantPartDetail;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [updateImplantPartDetail, { loading }] = useMutation(UPDATE_IMPLANT_PART_DETAIL);
  const formRef = useRef<ImplantPartDetailsFormHandle | null>(null);

  const formMethods = useForm<ImplantFormType>({
    defaultValues: {
      ...props?.selectedImplantPartDetail,
      implantPartDetailId: props?.selectedImplantPartDetail?.implantPartDetailId,
      implantType: props.implantType,
      activeDate: props?.selectedImplantPartDetail?.activeDate
        ? (date.parseCalendarDateFromString(
            props.selectedImplantPartDetail.activeDate as unknown as string,
          ) as Date)
        : '',
    },
    resolver: yupResolver(
      getImplantPartDetailSchema('EDIT', props?.selectedImplantPartDetail),
    ) as unknown as Resolver<ImplantFormType>,
  });
  const {
    handleSubmit,
    reset,
    formState: { isDirty, dirtyFields, isSubmitting },
  } = formMethods;

  const handleSubmitForm: SubmitHandler<ImplantFormType> = async (data) => {
    try {
      const submitData = await formRef.current?.uploadAssetsAndGenerateAPIVariables(
        data,
        dirtyFields as Partial<Readonly<ImplantFormType>>,
      );

      await updateImplantPartDetail({
        variables: submitData,
      });

      reset();
      enqueueSnackbar('New implant part version created', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the new version', {
        variant: 'error',
      });
    }
  };

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`Edit Version for: ${format.formatImplantPartDetailPartType(props.implantType)}`}
      onClose={() => {
        reset();
        props.onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Edit'}
          disabled={loading || !isDirty}
          loading={isSubmitting}
        />,
      ]}
    >
      {props.open ? (
        <FormProvider {...formMethods}>
          <ImplantPartDetailForm ref={formRef} />
        </FormProvider>
      ) : null}
    </CustomDialog>
  );
}
