import { CASE_PHASE_CONFIGS, ORDERED_CASE_PHASE_EVENT_LIST } from '../common';
import { EventType } from '../enums';
import { ProductionPhaseType } from '../types';

export const getPhaseIndex = (phaseType: ProductionPhaseType): number | null => {
  return CASE_PHASE_CONFIGS.findIndex((p) => p.phaseType === phaseType);
};

export const getPhaseEvents = (phaseType: ProductionPhaseType): EventType[] | null => {
  const phaseIndex = getPhaseIndex(phaseType);

  if (!phaseIndex) return null;

  const currentPhase = CASE_PHASE_CONFIGS[phaseIndex];

  const previousPhaseIndex = phaseIndex - 1;

  const previousPhase =
    previousPhaseIndex !== -1 ? CASE_PHASE_CONFIGS[previousPhaseIndex] : undefined;

  return ORDERED_CASE_PHASE_EVENT_LIST.slice(
    (previousPhase?.endIndex ?? -1) + 1,
    currentPhase.endIndex + 1,
  ).flatMap((e) => e);
};

export const getRemainingEvents = (currentPhaseType: ProductionPhaseType): EventType[] => {
  const phaseIndex = getPhaseIndex(currentPhaseType);

  if (!phaseIndex) return [];

  const currentPhase = CASE_PHASE_CONFIGS[phaseIndex];

  const remainingEvents: EventType[] = ORDERED_CASE_PHASE_EVENT_LIST.slice(
    (currentPhase?.endIndex ?? -1) + 1,
  ).flatMap((e) => e);

  return remainingEvents;
};
