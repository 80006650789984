import {
  AssetPositions,
  AssetType,
  getAnalysisAssetTypes,
  IAsset,
  ICase,
  ICaseImplantSpecification,
  IPostOpAnalysis,
  PostOpAnalysisType,
} from '@workflow-nx/common';

export type PostOpCaseActionType = {
  type: string;
  data?: any;
};

export type PostOpAnalysisState = {
  analysis?: IPostOpAnalysis;
  xr?: IAsset;
  ct?: IAsset;
  mri?: IAsset;
  standingAp?: IAsset;
  standingLateral?: IAsset;
};

export type PostOpCaseStateType = {
  activeCase: ICase | null;
  assetPositions?: AssetPositions;
  commentCount?: number;

  [PostOpAnalysisType.PreOp]: PostOpAnalysisState;
  [PostOpAnalysisType.Plan]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpIntraOp]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpOneWeek]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpSixWeeks]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpSixMonths]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpOneYear]: PostOpAnalysisState;
  [PostOpAnalysisType.PostOpTwoYears]: PostOpAnalysisState;

  caseImplantSpecifications?: ICaseImplantSpecification[];
};

export const VALID_ANALYSIS_TYPES = [
  PostOpAnalysisType.PostOpIntraOp,
  PostOpAnalysisType.PostOpOneWeek,
  PostOpAnalysisType.PostOpSixWeeks,
  PostOpAnalysisType.PostOpSixMonths,
  PostOpAnalysisType.PostOpOneYear,
  PostOpAnalysisType.PostOpTwoYears,
];

export const PostOpCaseReducer = (
  state: PostOpCaseStateType,
  action: PostOpCaseActionType,
): PostOpCaseStateType => {
  let updatedState = JSON.parse(JSON.stringify(state)) as PostOpCaseStateType;

  switch (action.type) {
    case 'ASSET_REPLACED': {
      const replacedAsset: IAsset = action.data;

      VALID_ANALYSIS_TYPES.forEach((analysisType) => {
        const analysisAssetTypes = getAnalysisAssetTypes(analysisType);

        if (replacedAsset.assetType === analysisAssetTypes?.zip) {
          updatedState[analysisType].xr = replacedAsset;
        } else if (replacedAsset.assetType === analysisAssetTypes?.ct) {
          updatedState[analysisType].ct = replacedAsset;
        } else if (replacedAsset.assetType === analysisAssetTypes?.mri) {
          updatedState[analysisType].mri = replacedAsset;
        } else if (replacedAsset.assetType === analysisAssetTypes?.ap) {
          updatedState[analysisType].standingAp = replacedAsset;
        } else if (replacedAsset.assetType === analysisAssetTypes?.lateral) {
          updatedState[analysisType].standingLateral = replacedAsset;
        }
      });

      updatedState = {
        ...updatedState,
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };
      break;
    }
    case 'ASSET_DELETED': {
      const deletedAsset = action.data;

      VALID_ANALYSIS_TYPES.forEach((analysisType) => {
        const analysisAssetTypes = getAnalysisAssetTypes(analysisType);

        if (deletedAsset.assetType === analysisAssetTypes?.zip) {
          updatedState[analysisType].xr = undefined;
        } else if (deletedAsset.assetType === analysisAssetTypes?.ct) {
          updatedState[analysisType].ct = undefined;
        } else if (deletedAsset.assetType === analysisAssetTypes?.mri) {
          updatedState[analysisType].mri = undefined;
        } else if (deletedAsset.assetType === analysisAssetTypes?.ap) {
          updatedState[analysisType].standingAp = undefined;
        } else if (deletedAsset.assetType === analysisAssetTypes?.lateral) {
          updatedState[analysisType].standingLateral = undefined;
        }
      });

      updatedState = {
        ...updatedState,
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };

      break;
    }
    case 'ASSET_UPLOADED': {
      const createdAsset = action.data;

      VALID_ANALYSIS_TYPES.forEach((analysisType) => {
        const analysisAssetTypes = getAnalysisAssetTypes(analysisType);

        if (createdAsset.assetType === analysisAssetTypes?.zip) {
          updatedState[analysisType].xr = createdAsset;
        } else if (createdAsset.assetType === analysisAssetTypes?.ct) {
          updatedState[analysisType].ct = createdAsset;
        } else if (createdAsset.assetType === analysisAssetTypes?.mri) {
          updatedState[analysisType].mri = createdAsset;
        } else if (createdAsset.assetType === analysisAssetTypes?.ap) {
          updatedState[analysisType].standingAp = createdAsset;
        } else if (createdAsset.assetType === analysisAssetTypes?.lateral) {
          updatedState[analysisType].standingLateral = createdAsset;
        }
      });

      updatedState = {
        ...updatedState,
        activeCase: updatedState.activeCase,
        assetPositions: updatedState.assetPositions,
        commentCount: updatedState.commentCount,
        caseImplantSpecifications: updatedState.caseImplantSpecifications,
      };
      break;
    }
    case 'INIT':
      const postOpAssets: IAsset[] = action?.data?.assets ?? [];

      updatedState = {
        activeCase: action.data.case,
        assetPositions: action?.data?.planByStatus?.assetPositions,
        commentCount: action?.data?.comments?.count ?? 0,

        [PostOpAnalysisType.PreOp]: { analysis: action?.data?.preOp },
        [PostOpAnalysisType.Plan]: { analysis: action?.data?.plan },
        [PostOpAnalysisType.PostOpIntraOp]: { analysis: action?.data?.postOpIntraOp },
        [PostOpAnalysisType.PostOpOneWeek]: { analysis: action?.data?.postOpOneWeek },
        [PostOpAnalysisType.PostOpSixWeeks]: { analysis: action?.data?.postOpSixWeeks },
        [PostOpAnalysisType.PostOpSixMonths]: { analysis: action?.data?.postOpSixMonths },
        [PostOpAnalysisType.PostOpOneYear]: { analysis: action?.data?.postOpOneYear },
        [PostOpAnalysisType.PostOpTwoYears]: { analysis: action?.data?.postOpTwoYears },

        caseImplantSpecifications:
          action?.data?.caseImplantSpecifications?.caseImplantSpecifications ?? [],
      };

      VALID_ANALYSIS_TYPES.forEach((analysisType) => {
        const analysisAssetTypes = getAnalysisAssetTypes(analysisType);

        updatedState[analysisType].xr = postOpAssets.find(
          (a) => a.assetType === analysisAssetTypes?.zip,
        );
        updatedState[analysisType].ct = postOpAssets.find(
          (a) => a.assetType === analysisAssetTypes?.ct,
        );
        updatedState[analysisType].mri = postOpAssets.find(
          (a) => a.assetType === analysisAssetTypes?.mri,
        );
        updatedState[analysisType].standingAp = postOpAssets.find(
          (a) => a.assetType === analysisAssetTypes?.ap,
        );
        updatedState[analysisType].standingLateral = postOpAssets.find(
          (a) => a.assetType === analysisAssetTypes?.lateral,
        );
      });

      break;
  }

  return updatedState;
};
