import { makeStyles } from '@mui/styles';
import { ICase, IPlan, MeasurementsVersionType } from '@workflow-nx/common';
import { BabylonScene } from '@workflow-nx/scene';
import { DraggableCard } from '@workflow-nx/ui';
import { useSnackbar } from 'notistack';
import { useContext, useEffect } from 'react';
import CustomDialog from '../../../../../components/CustomDialog';
import BabylonSceneMenu from './BabylonSceneMenu';
import { ImplantHeaderView } from './ImplantHeaderView';
import { ImplantNavigationView } from './ImplantNavigationView';
import { MeasurementCardsView } from './MeasurementCardsView';
import { PlanNavigationView } from './PlanNavigationView';
import { PlanViewDialogContext } from './PlanViewDialog.context';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    flex: 1,
    width: '100%',
    height: 'calc(100vh - 100px)',
  },
  dialogContainerContent: {
    display: 'flex',
    maxWidth: '100%',
  },
  spineCanvasRoot: {
    '& canvas': {
      width: '100%',
      height: '100%',
      touchAction: 'none',
    },
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

export function PlanViewDialog(props: {
  activeCase: ICase;
  caseMeasurementsVersion: MeasurementsVersionType;
  onClose: () => void;
  open: boolean;
  plan?: IPlan;
  showDaisy?: boolean;
}) {
  const planViewDialogContext = useContext(PlanViewDialogContext);
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.open && planViewDialogContext.scene) {
      planViewDialogContext
        .loadAssets()
        .then(() => {
          console.log('assets loaded');
        })
        .catch((e) => {
          enqueueSnackbar(`Error loading assets.`, {
            variant: 'error',
          });

          console.error('assets failed to load', e);
        });
    }
  }, [props.open, planViewDialogContext.scene]);

  return (
    <CustomDialog
      fullScreen={true}
      open={props.open}
      title={`View ${props.plan ? 'Plan' : 'Segmentation'} - ${props.activeCase.number}`}
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[]}
      dialogContentClassName={styles.dialogContainerContent}
      containerClassName={styles.dialogContainer}
    >
      {props.open ? (
        <>
          {!planViewDialogContext.loading && planViewDialogContext.implantState.level !== null ? (
            <ImplantHeaderView implant={planViewDialogContext.implantState} />
          ) : null}

          {!planViewDialogContext.loading &&
          planViewDialogContext.showMeasurements &&
          props.plan ? (
            <MeasurementCardsView
              activeCase={props.activeCase}
              caseMeasurementsVersion={props.caseMeasurementsVersion}
              planVisible={planViewDialogContext.levelState.planVisible && !!props.plan}
              preopDaisyVisible={false}
              preopVisible={planViewDialogContext.levelState.preopVisible}
              plan={props.plan}
              planDiff={planViewDialogContext.planDiff}
            />
          ) : null}
          {!planViewDialogContext.loading && planViewDialogContext.implantState.level === null ? (
            <DraggableCard initialPosition={{ top: 85, right: 65 }} width={60}>
              <PlanNavigationView showDaisy={props.showDaisy} />
            </DraggableCard>
          ) : null}
          {!planViewDialogContext.loading && planViewDialogContext.implantState.level !== null ? (
            <DraggableCard initialPosition={{ top: 85, right: 65 }} width={60}>
              <ImplantNavigationView />
            </DraggableCard>
          ) : null}

          <>
            <BabylonSceneMenu
              scene={planViewDialogContext.scene}
              loading={planViewDialogContext.loading}
            />
            <BabylonScene onReady={planViewDialogContext.handleSceneReady} />
          </>
        </>
      ) : null}
    </CustomDialog>
  );
}
