import { Box, Grid, Typography } from '@mui/material';
import { PartTypeAutocomplete } from '../../../../components/PartTypeAutocomplete';
import { Checkbox } from '@workflow-nx/ui';
import { FormikValues, useFormikContext } from 'formik';
import {
  CaseSpineProfile,
  CaseSpineType,
  caseUtils,
  format,
  IApplicationSettings,
  LEVEL_CONFIG_MAP,
  PartType,
} from '@workflow-nx/common';
import { date } from '@workflow-nx/utils';
import { getCaseSpineType } from '../../../../../../../../libs/common/src/lib/utils/case';
import useAuth from '../../../../hooks/useAuth';

function getInterbodyMenuItems(
  spineProfile: CaseSpineProfile,
  product: IApplicationSettings['product'],
): { key: string; label: string }[] {
  const lumbarMenuItems = [
    {
      key: PartType.FUSED,
      label: format.formatPartType(PartType.FUSED),
    },
    {
      key: PartType.ALIF,
      label: format.formatPartType(PartType.ALIF),
    },
    {
      key: PartType.ALIF_X_TWO_UP,
      label: format.formatPartType(PartType.ALIF_X_TWO_UP),
    },
    {
      key: PartType.ALIF_X_TWO_DOWN,
      label: format.formatPartType(PartType.ALIF_X_TWO_DOWN),
    },
    {
      key: PartType.LLIF_RIGHT,
      label: format.formatPartType(PartType.LLIF_RIGHT),
    },
    {
      key: PartType.LLIF_LEFT,
      label: format.formatPartType(PartType.LLIF_LEFT),
    },
    {
      key: PartType.TLIFO_RIGHT,
      label: format.formatPartType(PartType.TLIFO_RIGHT),
    },
    {
      key: PartType.TLIFO_LEFT,
      label: format.formatPartType(PartType.TLIFO_LEFT),
    },
    {
      key: PartType.TLIFC_INLINE_RIGHT,
      label: format.formatPartType(PartType.TLIFC_INLINE_RIGHT),
    },
    {
      key: PartType.TLIFC_INLINE_LEFT,
      label: format.formatPartType(PartType.TLIFC_INLINE_LEFT),
    },
    {
      key: PartType.TLIFC_OFFSET_RIGHT,
      label: format.formatPartType(PartType.TLIFC_OFFSET_RIGHT),
    },
    {
      key: PartType.TLIFC_OFFSET_LEFT,
      label: format.formatPartType(PartType.TLIFC_OFFSET_LEFT),
    },
  ];

  if (
    !!product?.tlifCArticulatingStartDate &&
    date.isBeforeToday(product.tlifCArticulatingStartDate as string)
  ) {
    lumbarMenuItems.push(
      {
        key: PartType.TLIFCA_INLINE_RIGHT,
        label: format.formatPartType(PartType.TLIFCA_INLINE_RIGHT),
      },
      {
        key: PartType.TLIFCA_INLINE_LEFT,
        label: format.formatPartType(PartType.TLIFCA_INLINE_LEFT),
      },
      {
        key: PartType.TLIFCA_OFFSET_RIGHT,
        label: format.formatPartType(PartType.TLIFCA_OFFSET_RIGHT),
      },
      {
        key: PartType.TLIFCA_OFFSET_LEFT,
        label: format.formatPartType(PartType.TLIFCA_OFFSET_LEFT),
      },
    );
  }

  const cervicalMenuItems = [
    {
      key: PartType.FUSED,
      label: format.formatPartType(PartType.FUSED),
    },
    {
      key: PartType.ACDF,
      label: format.formatPartType(PartType.ACDF),
    },
    {
      key: PartType.ACDF_X_LEFT_UP,
      label: format.formatPartType(PartType.ACDF_X_LEFT_UP),
    },
  ];

  const spineType = getCaseSpineType(spineProfile);
  const menuItems = spineType === CaseSpineType.Lumbar ? lumbarMenuItems : cervicalMenuItems;
  return menuItems.filter((partType) => !!partType) as { key: string; label: string }[];
}

export function InterbodyCaseDetailsForm(props: {
  disabled: boolean;
  caseSpineProfile?: CaseSpineProfile;
}) {
  const { values, setFieldValue } = useFormikContext<FormikValues>();

  const { product } = useAuth();

  const interbodyMenuItems = getInterbodyMenuItems(
    props.caseSpineProfile ?? CaseSpineProfile.LumbarStandard,
    product,
  );

  const interbodyLevels = caseUtils
    .getLevelsSortedByHierarchy(props.caseSpineProfile, 'desc')
    .map((level) => {
      const levelConfig = LEVEL_CONFIG_MAP[level];

      const mergedFieldName = `${levelConfig.inferiorVertebrae}${levelConfig.superiorVertebrae}`;

      return { key: `level${mergedFieldName}`, value: format.formatLevelType(level) };
    });

  return (
    <Grid container spacing={2} direction={'column'}>
      {interbodyLevels.map((level) => {
        const hasLevelValue = caseUtils.isValidLevelPartType(values[level.key]);

        return (
          <Grid item xs key={level.key}>
            <Box display={'flex'}>
              <PartTypeAutocomplete
                name={level.key}
                label={level.value}
                value={values[level.key] || null}
                options={interbodyMenuItems}
                disabled={props.disabled}
                fullWidth={true}
                onChange={(value) => {
                  setFieldValue(level.key, value);
                }}
              />
              <Box pl={1} />
              <Checkbox
                name={`${level.key}Metadata.anteriorInstrumentation`}
                label={
                  <Typography variant={'body1'} noWrap={true}>
                    Ant. Inst.
                  </Typography>
                }
                disabled={props.disabled || !hasLevelValue}
              />
              <Box pl={1} />
              <Checkbox
                name={`${level.key}Metadata.otherVendor`}
                label={
                  <Typography variant={'body1'} noWrap={true}>
                    Other Vendor
                  </Typography>
                }
                disabled={props.disabled || hasLevelValue}
              />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
