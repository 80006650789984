import React from 'react';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { TableHeadCell } from '../../../../../components/TableHeadCell';
import { CaseReportRevisionTableRow } from './CaseReportRevisionTableRow';
import { CaseReportRevisionType } from './CaseReportDialog.reducer';

interface CaseReportRevisionTableProps {
  caseReportRevisions: CaseReportRevisionType[];
}

export function CaseReportRevisionsViewTable({
  caseReportRevisions,
}: CaseReportRevisionTableProps) {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableHeadCell>Version</TableHeadCell>
          <TableHeadCell>Description of Updates</TableHeadCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {caseReportRevisions?.map(
          (caseReportRevision: CaseReportRevisionType, caseReportRevisionIndex: number) => {
            return (
              <CaseReportRevisionTableRow
                key={`${caseReportRevisionIndex}`}
                revision={caseReportRevision?.revision}
                description={caseReportRevision?.description}
              />
            );
          },
        )}
      </TableBody>
    </Table>
  );
}
