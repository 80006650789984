import { Box, Stack, TextField, Typography } from '@mui/material';
import { IPlanImplant, PartType } from '@workflow-nx/common';
import { Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ILimits, ISettingsForm19ImplantAttributes } from '../../../../../../utils/form19';
import { BulletFormValues } from './BulletForm';

enum BulletSide {
  None = 'NONE',
  ThreadedSide = 'THREADED_SIDE',
  InsertionSide = 'INSERTION_SIDE',
}

type BulletFormProps = {
  bullet: BulletFormValues;
  planImplant: IPlanImplant;
  plusLevelSize: number;
  implantAttributes: ISettingsForm19ImplantAttributes;
  disabled: boolean;
  onChange: (values: BulletFormValues) => void;
};

export const bulletFormSchema = function (
  height: ILimits | undefined,
  implantType: PartType,
  plusLevelSize: number,
) {
  if (!height) return;

  // Min and max height adjustments:
  // For the min value, since the minimum is for a minus size implant (ie. 2.1) and we're doing the correction against a
  // plan implant, we don't want to allow the user to enter a value that will end up being smaller than
  // the minimum when the minus size is generated (ie. 3.1 - 1 for minus is 2.1).

  // For the max value, we subtract the plusLevelSize (1 or 2) so we don't generate over the max
  const max = height.max - (plusLevelSize ?? 0);
  const min = height.min + 1;

  const schema: any = {
    angleInsertion: Yup.number()
      .test('isInRangeAngleInsertion', `Out of range 0 - 180`, function (value) {
        let result = false;
        if (value) {
          result = value >= 0 && value <= 180;
        }
        return result;
      })
      .required('Angle is required'),
    heightInsertion: Yup.number()
      .test('isInRangeheightInsertion', `Out of range ${min} - ${max}`, function (value) {
        let result = false;
        if (value) {
          result = value >= min && value <= max;
        }
        return result;
      })
      .required('Angle is required'),
  };
  if (implantType === PartType.LLIF_LEFT || implantType === PartType.LLIF_RIGHT) {
    schema.heightThreaded = Yup.number()
      .test('isInRangeHeightThreaded', `Out of range ${min} - ${max}`, function (value) {
        let result = false;
        if (value) {
          result = value >= min && value <= max;
        }
        return result;
      })
      .required('Angle is required');
    schema.angleThreaded = Yup.number()
      .test('isInRangeangleThreaded', `Out of range 0 - 180`, function (value) {
        let result = false;
        if (value) {
          result = value >= 0 && value <= 180;
        }
        return result;
      })
      .required('Angle is required');
  }

  return Yup.object().shape(schema);
};

export default function AsyncBulletForm({
  bullet,
  planImplant,
  plusLevelSize,
  implantAttributes,
  disabled,
  onChange,
}: BulletFormProps) {
  const handleBulletChange = (bulletSide: BulletSide, values: BulletFormValues) => {
    onChange(values);
  };

  const handleThreadHeightChange = (values: BulletFormValues) => {
    onChange(values);
  };

  useEffect(() => {
    if (
      ![
        PartType.TLIFC_OFFSET_LEFT,
        PartType.TLIFC_OFFSET_RIGHT,
        PartType.TLIFO_LEFT,
        PartType.TLIFO_RIGHT,
      ].includes(planImplant.partType)
    ) {
      handleBulletChange(BulletSide.InsertionSide, bullet);

      if (
        planImplant.partType === PartType.LLIF_LEFT ||
        planImplant.partType === PartType.LLIF_RIGHT
      ) {
        handleBulletChange(BulletSide.ThreadedSide, bullet);
      }
    }
  }, []);

  return (
    <Formik
      initialValues={bullet}
      enableReinitialize={true}
      validationSchema={bulletFormSchema(
        implantAttributes.bulletTaperHeight,
        planImplant.partType,
        plusLevelSize,
      )}
      validateOnBlur={true}
      onSubmit={() => undefined}
    >
      {({ values, isSubmitting, touched, errors, handleBlur, handleChange }) => {
        return (
          <Stack mt={1} gap={1}>
            <Typography variant={'h6'} color="text.secondary">
              Insertion Side
            </Typography>
            <Stack gap={1}>
              <Stack>
                <Stack direction={'row'} gap={1}>
                  <TextField
                    data-testid={'insertion-top-angle'}
                    size={'small'}
                    name={'insertionTopAngle'}
                    label={'Top Angle'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.insertionTopAngle && errors.insertionTopAngle)}
                    fullWidth
                    helperText={touched.insertionTopAngle && errors.insertionTopAngle}
                    type="number"
                    variant={'outlined'}
                    value={values.insertionTopAngle}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: 0,
                      max: 180,
                      step: '.2',
                    }}
                  />

                  <TextField
                    data-testid={'insertion-top-height'}
                    size={'small'}
                    name={'insertionTopHeight'}
                    label={'Top Height'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.insertionTopHeight && errors.insertionTopHeight)}
                    fullWidth
                    helperText={touched.insertionTopHeight && errors.insertionTopHeight}
                    // FormHelperTextProps={{
                    //   classes: { root: classes.helperText },
                    // }}
                    type="number"
                    variant={'outlined'}
                    value={values.insertionTopHeight}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: implantAttributes.bulletTaperHeight.min,
                      max: implantAttributes.bulletTaperHeight.max,
                      step: '.2',
                    }}
                  />
                </Stack>
              </Stack>
              <Stack>
                <Stack direction={'row'} gap={1}>
                  <TextField
                    data-testid={'insertion-bottom-angle'}
                    size={'small'}
                    name={'insertionBottomAngle'}
                    label={'Bottom Angle'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.insertionBottomAngle && errors.insertionBottomAngle)}
                    helperText={touched.insertionBottomAngle && errors.insertionBottomAngle}
                    // FormHelperTextProps={{
                    //   classes: { root: classes.helperText },
                    // }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.insertionBottomAngle}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: 0,
                      max: 180,
                      step: '.2',
                    }}
                  />
                  <TextField
                    data-testid={'insertion-bottom-height'}
                    size={'small'}
                    name={'insertionBottomHeight'}
                    label={'Bottom Height'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.insertionBottomHeight && errors.insertionBottomHeight)}
                    helperText={touched.insertionBottomHeight && errors.insertionBottomHeight}
                    // FormHelperTextProps={{
                    //   classes: { root: classes.helperText },
                    // }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.insertionBottomHeight}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.InsertionSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: implantAttributes.bulletTaperHeight.min,
                      max: implantAttributes.bulletTaperHeight.max,
                      step: '.2',
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
            {planImplant.partType === PartType.LLIF_LEFT ||
            planImplant.partType === PartType.LLIF_RIGHT ? (
              <Stack gap={1}>
                <Typography variant={'h6'} color="text.secondary">
                  Threaded Side
                </Typography>
                <Stack gap={1}>
                  <Stack direction={'row'} gap={1}>
                    <TextField
                      data-testid={'threaded-top-angle'}
                      size={'small'}
                      name={'threadedTopAngle'}
                      label={'Top Angle'}
                      disabled={isSubmitting || disabled}
                      error={Boolean(touched.threadedTopAngle && errors.threadedTopAngle)}
                      helperText={touched.threadedTopAngle && errors.threadedTopAngle}
                      // FormHelperTextProps={{
                      //   classes: { root: classes.helperText },
                      // }}
                      fullWidth
                      type="number"
                      variant={'outlined'}
                      value={values.threadedTopAngle}
                      onBlur={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleChange(e);
                      }}
                      onKeyUp={() => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                      }}
                      inputProps={{
                        min: 0,
                        max: 180,
                        step: '.2',
                      }}
                    />
                    <TextField
                      data-testid={'threaded-top-height'}
                      size={'small'}
                      name={'threadedTopHeight'}
                      label={'Top Height'}
                      disabled={isSubmitting || disabled}
                      error={Boolean(touched.threadedTopHeight && errors.threadedTopHeight)}
                      helperText={touched.threadedTopHeight && errors.threadedTopHeight}
                      // FormHelperTextProps={{
                      //   classes: { root: classes.helperText },
                      // }}
                      fullWidth
                      type="number"
                      variant={'outlined'}
                      value={values.threadedTopHeight}
                      onBlur={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleChange(e);
                      }}
                      onKeyUp={() => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                      }}
                      inputProps={{
                        min: implantAttributes.patientContactUpperHeight.min,
                        max: implantAttributes.patientContactUpperHeight.max,
                        step: '.1',
                      }}
                    />
                  </Stack>
                  <Stack direction={'row'} gap={1}>
                    <TextField
                      data-testid={'threaded-bottom-angle'}
                      size={'small'}
                      name={'threadedBottomAngle'}
                      label={'Bottom Angle'}
                      disabled={isSubmitting || disabled}
                      error={Boolean(touched.threadedBottomAngle && errors.threadedBottomAngle)}
                      helperText={touched.threadedBottomAngle && errors.threadedBottomAngle}
                      // FormHelperTextProps={{
                      //   classes: { root: classes.helperText },
                      // }}
                      fullWidth
                      type="number"
                      variant={'outlined'}
                      value={values.threadedBottomAngle}
                      onBlur={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleChange(e);
                      }}
                      onKeyUp={() => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                      }}
                      inputProps={{
                        min: 0,
                        max: 180,
                        step: '.2',
                      }}
                    />
                    <TextField
                      data-testid={'threaded-bottom-height'}
                      size={'small'}
                      name={'threadedBottomHeight'}
                      label={'Bottom Height'}
                      disabled={isSubmitting || disabled}
                      error={Boolean(touched.threadedBottomHeight && errors.threadedBottomHeight)}
                      helperText={touched.threadedBottomHeight && errors.threadedBottomHeight}
                      // FormHelperTextProps={{
                      //   classes: { root: classes.helperText },
                      // }}
                      fullWidth
                      type="number"
                      variant={'outlined'}
                      value={values.threadedBottomHeight}
                      onBlur={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleBlur(e);
                      }}
                      onChange={(e) => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                        handleChange(e);
                      }}
                      onKeyUp={() => {
                        handleBulletChange(BulletSide.ThreadedSide, values);
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: 'transparent',
                        },
                      }}
                      inputProps={{
                        min: implantAttributes.patientContactUpperHeight.min,
                        max: implantAttributes.patientContactUpperHeight.max,
                        step: '.1',
                      }}
                    />
                  </Stack>
                </Stack>
              </Stack>
            ) : null}
            {planImplant.partType === PartType.ALIF ||
            planImplant.partType === PartType.ALIF_X_TWO_UP ||
            planImplant.partType === PartType.ALIF_X_TWO_DOWN ||
            planImplant.partType === PartType.LLIF_LEFT ||
            planImplant.partType === PartType.LLIF_RIGHT ? (
              <Box>
                <Typography variant={'h6'} color="text.secondary">
                  Thread Height
                </Typography>
                <Box mt={1}>
                  <TextField
                    data-testid={'thread-height-input'}
                    size={'small'}
                    name={'threadHeight'}
                    label={''}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.threadHeight && errors.threadHeight)}
                    helperText={touched.threadHeight && errors.threadHeight}
                    // FormHelperTextProps={{
                    //   classes: { root: classes.helperText },
                    // }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.threadHeight}
                    onBlur={(e) => {
                      handleThreadHeightChange(values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleThreadHeightChange(values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleThreadHeightChange(values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: implantAttributes.bulletTaperHeight.min, // TODO: Define definition
                      max: implantAttributes.bulletTaperHeight.max,
                      step: '.2',
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </Stack>
        );
      }}
    </Formik>
  );
}
