import React, { useEffect, useState } from 'react';
import { IImplantPartDetail, IOnShapeSettings, ImplantType, format } from '@workflow-nx/common';
import { Box, Tab, Tabs, Typography, useTheme } from '@mui/material';
import { IconFontButton } from '@workflow-nx/ui';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { useQuery } from '@apollo/client';
import { FIND_IMPLANT_PART_DETAILS } from '../../../gql';
import _ from 'lodash';
import { ImplantPartDetailsTable } from './ImplantPartDetailsTable';
import { blue, green, grey } from '@mui/material/colors';

export function ImplantPartDetailsView(props: {
  onShape: IOnShapeSettings;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const [open, setOpen] = useState(false);
  const [implantPartDetailsData, setImplantPartDetailsData] = useState<IImplantPartDetail[]>([]);
  const [tabValue, setTabValue] = useState(ImplantType.ALIF);
  const theme = useTheme();
  const lumbarColor = blue[400];
  const cervicalColor = green[400];

  const tabDataRows = [
    {
      id: ImplantType.ALIF,
      label: format.formatImplantPartDetailPartType(ImplantType.ALIF),
      color: lumbarColor,
    },
    {
      id: ImplantType.ALIFX,
      label: format.formatImplantPartDetailPartType(ImplantType.ALIFX),
      color: lumbarColor,
    },
    {
      id: ImplantType.LLIF,
      label: format.formatImplantPartDetailPartType(ImplantType.LLIF),
      color: lumbarColor,
    },
    {
      id: ImplantType.TLIFC,
      label: format.formatImplantPartDetailPartType(ImplantType.TLIFC),
      color: lumbarColor,
    },
    {
      id: ImplantType.TLIFCA,
      label: format.formatImplantPartDetailPartType(ImplantType.TLIFCA),
      color: lumbarColor,
    },
    {
      id: ImplantType.TLIFO,
      label: format.formatImplantPartDetailPartType(ImplantType.TLIFO),
      color: lumbarColor,
      lastLumbar: true,
    },
    {
      id: ImplantType.ACDF,
      label: format.formatImplantPartDetailPartType(ImplantType.ACDF),
      color: cervicalColor,
    },
    {
      id: ImplantType.ACDFX,
      label: format.formatImplantPartDetailPartType(ImplantType.ACDFX),
      color: cervicalColor,
    },
  ];

  const { loading, refetch, data } = useQuery(FIND_IMPLANT_PART_DETAILS, {
    fetchPolicy: 'network-only',
  });

  const handleClose = () => {
    if (open) {
      props.onClose(false);
    }
    setOpen(!open);
  };

  useEffect(() => {
    setImplantPartDetailsData(
      _.orderBy(
        data?.implantPartDetails?.implantPartDetails ?? [],
        ['activeDate', 'drawingVersionNumber', 'implantType'],
        ['desc', 'desc', 'desc'],
      ),
    );
  }, [data, loading]);

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Implant Management</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open ? (
        <Box m={1}>
          <Box
            mt={1}
            bgcolor={grey[100]}
            borderRadius={5}
            display={'flex'}
            justifyContent={'center'}
            width={'100%'}
            alignItems={'stretch'}
          >
            <Tabs
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              variant={'scrollable'}
              scrollButtons="auto"
              onChange={(_, newValue) => {
                setTabValue(newValue);
              }}
            >
              {tabDataRows.map((tab) => (
                <Tab
                  label={tab.label}
                  value={tab.id}
                  key={`tab-${tab.id}`}
                  sx={{
                    color: tab?.color ?? undefined,
                    borderRight: tab?.lastLumbar ? `2px solid ${theme.palette.divider}` : undefined,
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <div role={'tabpanel'}>
            {tabDataRows.map((tab) => {
              if (tabValue === tab.id) {
                return (
                  <ImplantPartDetailsTable
                    key={`ImplantPartDetailsTable - ${tab.id}`}
                    implantType={tab.id}
                    implantPartDetails={implantPartDetailsData.filter(
                      (data) => data.implantType === tab.id,
                    )}
                    loading={loading}
                    updateImplantPartDetails={refetch}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </Box>
      ) : null}
    </>
  );
}
