import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { faBrainCircuit } from '@fortawesome/pro-light-svg-icons';
import {
  Alert,
  Box,
  Checkbox as MuiCheckbox,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { AutoCorrectionLevelGoalsType } from '@workflow-nx/auto-correct';
import {
  AutoCorrectStatus,
  CaseApproachType,
  caseUtils,
  CoronalCorrectionGoalType,
  format,
  HeightRestorationGoalType,
  IAutoCorrectQueue,
  ICase,
  ILevels,
  ISurgeonPreferences,
  LevelType,
  PartType,
} from '@workflow-nx/common';
import { IconFontButton } from '@workflow-nx/ui';
import CustomDialog from '../../../../../components/CustomDialog';
import { cloneDeep, isNil } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import ActionButton from '../../../../../components/ActionButton';
import {
  CREATE_AUTO_CORRECT_QUEUE_ENTRY,
  DELETE_AUTO_CORRECT_QUEUE_ENTRY,
  FIND_AUTO_CORRECT_QUEUE,
} from '../../../../../gql';
import { SurgeonPreferencesMainView } from '../SurgeonPreferencesView/SurgeonPreferencesViewDialog';
import { gql } from '@apollo/client/core';
import { useConfirm } from 'material-ui-confirm';

function formatAutoCorrectStatus(status: AutoCorrectStatus) {
  switch (status) {
    case AutoCorrectStatus.Processing:
      return 'Creating correction plan';
    case AutoCorrectStatus.Error:
      return 'Error creating correction plan';
    case AutoCorrectStatus.Completed:
      return 'Completed creating correction plan';
    default:
      return status;
  }
}

function getAutoCorrectSeverity(status: AutoCorrectStatus) {
  switch (status) {
    case AutoCorrectStatus.Error:
      return 'error';
    case AutoCorrectStatus.Completed:
      return 'success';
    default:
      return 'info';
  }
}

export function AutoCorrectView(props: {
  activeCase: ICase;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const confirm = useConfirm();

  const [showAutoCorrectDialog, setShowAutoCorrectDialog] = useState<boolean>(false);
  const [autoCorrectQueue, setAutoCorrectQueue] = useState<IAutoCorrectQueue | null>(null);
  const [surgeonPreferences, setSurgeonPreferences] = useState<ISurgeonPreferences | undefined>();
  const [shouldGenerateMeasurements, setShouldGenerateMeasurements] = useState<boolean>(false);
  const [sagittalGoal, setSagittalGoal] = useState<CaseApproachType>(CaseApproachType.GapScore);
  const [coronalGoal, setCoronalGoal] = useState<CoronalCorrectionGoalType>(
    CoronalCorrectionGoalType.DiscSpaceParallelization,
  );
  const [heightRestorationGoal, setHeightRestorationGoal] = useState<HeightRestorationGoalType>(
    HeightRestorationGoalType.TEM013,
  );
  const [activeLevelGoals, setActiveLevelGoals] = useState<AutoCorrectionLevelGoalsType[]>([]);

  const [createAutoCorrectionQueueEntry, { loading: loadingCreateAutoCorrectQueueEntry }] =
    useMutation(CREATE_AUTO_CORRECT_QUEUE_ENTRY);
  const [deleteAutoCorrectionQueueEntry, { loading: loadingDeleteAutoCorrectQueueEntry }] =
    useMutation(DELETE_AUTO_CORRECT_QUEUE_ENTRY);
  const { refetch, startPolling, stopPolling } = useQuery(FIND_AUTO_CORRECT_QUEUE, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { caseId: props.activeCase.caseId },
    onCompleted: (data) => {
      if (
        autoCorrectQueue &&
        autoCorrectQueue.status !== data.autoCorrectQueue.status &&
        data.autoCorrectQueue.status === AutoCorrectStatus.Completed
      ) {
        props.onClose(true);
      }

      setAutoCorrectQueue(data.autoCorrectQueue);
    },
  });

  const [findSurgeon] = useLazyQuery(
    gql`
      query FindSurgeon($userId: Int!, $patientId: Int!) {
        user(userId: $userId) {
          userId
          firstName
          lastName
          email
          role
          preferences {
            surgeonPreferenceId
            userId
            preferredProcedures
            sagittalGoalPrimary
            sagittalGoalSecondary
            sagittalGoalOther
            coronalGoalPrimary
            coronalGoalSecondary
            navigationSystems
            navigationSystemOther
            targetHeightMetric
            excludedInstruments
            reusableInstruments
            note
            createdAt
            updatedAt
            createdByUser {
              userId
              firstName
              lastName
            }
            updatedByUser {
              userId
              firstName
              lastName
            }
            surgeonPreferenceImplants {
              surgeonPreferenceImplantId
              surgeonPreferenceId
              implantType
              positioning
              direction
              orientation
              orientationByLevels {
                L3_L4
                L4_L5
                L5_S1
              }
              screwLength
              maximizeFootprint
              plusSizeImplant
              minimalHeightSpecification
              excludedImplantSizes
              note
            }
            surgeonPreferenceImplantMeasurements {
              surgeonPreferenceImplantMeasurementId
              surgeonPreferenceId
              implantType
              measurementType
              min
              max
            }
          }
        }
        patient(patientId: $patientId) {
          patientId
          birthDate
          patientRecord {
            pelvicIncidence
          }
        }
      }
    `,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      variables: {
        userId: Number(props?.activeCase?.surgeonUser?.userId),
        patientId: Number(props?.activeCase?.patient?.patientId),
      },
      onCompleted: (data) => {
        setSurgeonPreferences(data.user.preferences);
      },
    },
  );

  const { enqueueSnackbar } = useSnackbar();

  const initializeActiveLevelGoals = (): AutoCorrectionLevelGoalsType[] => {
    const levelGoals: AutoCorrectionLevelGoalsType[] = [];

    const activeLevels: ILevels = props.activeCase.levels;
    for (const level in activeLevels) {
      if (activeLevels[level] && activeLevels[level] !== PartType.NONE && level !== '__typename') {
        levelGoals.push({
          lordosis: 0,
          level: level as LevelType,
          posteriorHeightRange: {
            min: null,
            max: null,
          },
          anteriorHeightRange: {
            min: null,
            max: null,
          },
          implantType: format.formatImplantType(activeLevels[level] as PartType),
        });
      }
    }

    return levelGoals;
  };

  useEffect(() => {
    const levelGoals = initializeActiveLevelGoals();
    setActiveLevelGoals(levelGoals);

    if (props?.activeCase?.surgeonUser?.userId) {
      findSurgeon();
    }

    if (!hasPelvicIncidence) {
      setSagittalGoal(CaseApproachType.None);
    }
  }, []);

  useEffect(() => {
    if (autoCorrectQueue) {
      const { status } = autoCorrectQueue;
      if ([AutoCorrectStatus.Completed, AutoCorrectStatus.Error].includes(status)) {
        stopPolling();
      } else {
        startPolling(15000);
      }
    }
  }, [autoCorrectQueue]);

  const handleAutoCorrectClicked = async () => {
    try {
      await createAutoCorrectionQueueEntry({
        variables: {
          caseId: props.activeCase.caseId,
          shouldGenerateMeasurements,
          sagittalGoal,
          coronalGoal,
          heightRestorationGoal,
          activeLevelGoals: JSON.stringify(activeLevelGoals),
        },
      });

      await refetch();

      enqueueSnackbar('Case sent for auto-correct successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error sending case to auto-correct', {
        variant: 'error',
      });
    }
  };

  const handleDeleteAutoCorrectClicked = async () => {
    try {
      setShowAutoCorrectDialog(false);

      await deleteAutoCorrectionQueueEntry({
        variables: {
          autoCorrectQueueId: autoCorrectQueue?.autoCorrectQueueId,
        },
      });

      await refetch();

      enqueueSnackbar('Auto-correct cancelled successfully', {
        variant: 'success',
      });
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Error cancelling auto-correct', {
        variant: 'error',
      });
    }
  };

  const renderLevelSpecificGoalForm = (): ReactNode => {
    if (
      sagittalGoal !== CaseApproachType.Other &&
      heightRestorationGoal !== HeightRestorationGoalType.Specified
    ) {
      return null;
    }

    const activeLevels = caseUtils.getValidCaseLevels(props.activeCase.levels);
    const formElements: ReactNode[] = [];

    const isValueFilled = (value: number | null): boolean => !isNil(value) && !isNaN(value);

    const isPosteriorPartiallyFilled = (targetLevel: AutoCorrectionLevelGoalsType): boolean => {
      const hasMin = isValueFilled(targetLevel.posteriorHeightRange.min);
      const hasMax = isValueFilled(targetLevel.posteriorHeightRange.max);
      return (hasMin || hasMax) && !(hasMin && hasMax);
    };

    const isAnteriorPartiallyFilled = (targetLevel: AutoCorrectionLevelGoalsType): boolean => {
      const hasMin = isValueFilled(targetLevel.anteriorHeightRange.min);
      const hasMax = isValueFilled(targetLevel.anteriorHeightRange.max);
      return (hasMin || hasMax) && !(hasMin && hasMax);
    };

    activeLevels.forEach((level) => {
      const targetLevel = activeLevelGoals.find((g) => g.level === level);
      if (!targetLevel) return;

      const posteriorNeedsCompletion = isPosteriorPartiallyFilled(targetLevel);
      const anteriorNeedsCompletion = isAnteriorPartiallyFilled(targetLevel);

      formElements.push(
        <Grid item xs={4}>
          <Typography>
            <strong>{format.formatInterbodyLevel(level as LevelType)}</strong>
          </Typography>
        </Grid>,
        <Grid item xs={8}>
          <Stack direction="row" gap={1}>
            {sagittalGoal === CaseApproachType.Other && (
              <TextField
                label="Lordosis"
                variant="outlined"
                type="number"
                size="small"
                value={targetLevel.lordosis ?? ''}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const activeLevelGoalClone = cloneDeep(activeLevelGoals);
                  const targetActiveLevel = activeLevelGoalClone.find((g) => g.level === level);
                  if (targetActiveLevel) {
                    targetActiveLevel.lordosis =
                      e.target.value === '' ? 0 : parseFloat(e.target.value);
                    setActiveLevelGoals(activeLevelGoalClone);
                  }
                }}
                inputProps={{ step: 0.1 }}
              />
            )}

            {heightRestorationGoal === HeightRestorationGoalType.Specified && (
              <>
                <TextField
                  label="Post. min"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={targetLevel.posteriorHeightRange.min ?? ''}
                  disabled={anteriorNeedsCompletion}
                  error={posteriorNeedsCompletion}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const activeLevelGoalClone = cloneDeep(activeLevelGoals);
                    const targetActiveLevel = activeLevelGoalClone.find((g) => g.level === level);
                    if (targetActiveLevel) {
                      targetActiveLevel.posteriorHeightRange.min =
                        e.target.value === '' ? null : parseFloat(e.target.value);
                      targetActiveLevel.anteriorHeightRange = { min: null, max: null };
                      setActiveLevelGoals(activeLevelGoalClone);
                    }
                  }}
                  inputProps={{ step: 0.1 }}
                />
                <TextField
                  label="Post. max"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={targetLevel.posteriorHeightRange.max ?? ''}
                  disabled={anteriorNeedsCompletion}
                  error={posteriorNeedsCompletion}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const activeLevelGoalClone = cloneDeep(activeLevelGoals);
                    const targetActiveLevel = activeLevelGoalClone.find((g) => g.level === level);
                    if (targetActiveLevel) {
                      targetActiveLevel.posteriorHeightRange.max =
                        e.target.value === '' ? null : parseFloat(e.target.value);
                      targetActiveLevel.anteriorHeightRange = { min: null, max: null };
                      setActiveLevelGoals(activeLevelGoalClone);
                    }
                  }}
                  inputProps={{ step: 0.1 }}
                />
                <TextField
                  label="Ant. min"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={targetLevel.anteriorHeightRange.min ?? ''}
                  disabled={posteriorNeedsCompletion}
                  error={anteriorNeedsCompletion}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const activeLevelGoalClone = cloneDeep(activeLevelGoals);
                    const targetActiveLevel = activeLevelGoalClone.find((g) => g.level === level);
                    if (targetActiveLevel) {
                      targetActiveLevel.anteriorHeightRange.min =
                        e.target.value === '' ? null : parseFloat(e.target.value);
                      targetActiveLevel.posteriorHeightRange = { min: null, max: null };
                      setActiveLevelGoals(activeLevelGoalClone);
                    }
                  }}
                  inputProps={{ step: 0.1 }}
                />
                <TextField
                  label="Ant. max"
                  variant="outlined"
                  type="number"
                  size="small"
                  value={targetLevel.anteriorHeightRange.max ?? ''}
                  disabled={posteriorNeedsCompletion}
                  error={anteriorNeedsCompletion}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    const activeLevelGoalClone = cloneDeep(activeLevelGoals);
                    const targetActiveLevel = activeLevelGoalClone.find((g) => g.level === level);
                    if (targetActiveLevel) {
                      targetActiveLevel.anteriorHeightRange.max =
                        e.target.value === '' ? null : parseFloat(e.target.value);
                      targetActiveLevel.posteriorHeightRange = { min: null, max: null };
                      setActiveLevelGoals(activeLevelGoalClone);
                    }
                  }}
                  inputProps={{ step: 0.1 }}
                />
              </>
            )}
          </Stack>
        </Grid>,
      );
    });

    return formElements;
  };

  const validateAutoCorrectionForm = (): boolean => {
    if (heightRestorationGoal === HeightRestorationGoalType.Specified) {
      return activeLevelGoals.every((levelGoal) => {
        const hasValidPosterior =
          levelGoal.posteriorHeightRange.min !== null &&
          levelGoal.posteriorHeightRange.max !== null;

        const hasValidAnterior =
          levelGoal.anteriorHeightRange.min !== null && levelGoal.anteriorHeightRange.max !== null;

        return hasValidPosterior || hasValidAnterior;
      });
    }

    return true;
  };

  const isProcessing = autoCorrectQueue?.status === AutoCorrectStatus.Processing;
  const activePlan = props.activeCase.plans.find((plan) => plan.isActive);
  const spineProfileLandmarkCount = caseUtils.getCaseSpineProfile(
    props.activeCase.spineProfile,
  ).landmarkCount;
  const preopLandmarkCount = activePlan?.assetPositions.preop.points.length;
  const hasLandmarks = preopLandmarkCount === spineProfileLandmarkCount;
  const hasPelvicIncidence = !!props.activeCase.patient.patientRecord.pelvicIncidence;

  return (
    <>
      <CustomDialog
        maxWidth={'lg'}
        fullHeight={false}
        open={showAutoCorrectDialog}
        title={`aprevo® Digital Planning - ${props.activeCase.number}`}
        onClose={() => {
          setShowAutoCorrectDialog(false);
        }}
        preventScrolling={true}
        positiveActionButtons={[
          <ActionButton
            color={'secondary'}
            variant={'outlined'}
            disabled={!validateAutoCorrectionForm()}
            onClick={!isProcessing ? handleAutoCorrectClicked : handleDeleteAutoCorrectClicked}
            loading={
              !isProcessing
                ? loadingCreateAutoCorrectQueueEntry
                : loadingDeleteAutoCorrectQueueEntry
            }
          >
            {!isProcessing ? 'Run' : 'Cancel'}
          </ActionButton>,
        ]}
      >
        <Stack direction={'row'} gap={2} divider={<Divider orientation="vertical" flexItem />}>
          <Box sx={{ width: '60%' }}>
            <Typography variant={'h3'} sx={{ mb: 2 }}>
              Configuration
            </Typography>
            <Grid container alignContent={'flex-start'} alignItems="center" spacing={2}>
              <Grid item xs={4}>
                <Typography>
                  <strong>Coronal Goal</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <Select
                    size={'small'}
                    disabled={
                      loadingCreateAutoCorrectQueueEntry ||
                      loadingDeleteAutoCorrectQueueEntry ||
                      isProcessing
                    }
                    name="coronalGoal"
                    onChange={(event: SelectChangeEvent<string>) => {
                      setCoronalGoal(event.target.value as CoronalCorrectionGoalType);
                    }}
                    value={coronalGoal}
                    labelId={'coronalGoal-label'}
                  >
                    <MenuItem value={CoronalCorrectionGoalType.DiscSpaceParallelization}>
                      {format.formatCoronalCorrectionGoalType(
                        CoronalCorrectionGoalType.DiscSpaceParallelization,
                      )}
                    </MenuItem>
                    <MenuItem value={CoronalCorrectionGoalType.FloorParallelization}>
                      {format.formatCoronalCorrectionGoalType(
                        CoronalCorrectionGoalType.FloorParallelization,
                      )}
                    </MenuItem>
                    <MenuItem
                      value={CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization}
                    >
                      {format.formatCoronalCorrectionGoalType(
                        CoronalCorrectionGoalType.HightestSuperiorEndplateParallelization,
                      )}
                    </MenuItem>
                    <MenuItem value={CoronalCorrectionGoalType.ZeroCoronal}>
                      {format.formatCoronalCorrectionGoalType(
                        CoronalCorrectionGoalType.ZeroCoronal,
                      )}
                    </MenuItem>
                    <MenuItem value={CoronalCorrectionGoalType.None}>
                      {format.formatCoronalCorrectionGoalType(CoronalCorrectionGoalType.None)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <strong>Sagittal Goal</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <InputLabel
                    sx={{ backgroundColor: 'white' }}
                    id="sagittalGoal-label"
                  ></InputLabel>
                  <Select
                    size={'small'}
                    disabled={
                      loadingCreateAutoCorrectQueueEntry ||
                      loadingDeleteAutoCorrectQueueEntry ||
                      isProcessing
                    }
                    name={'sagittalGoal'}
                    onChange={(event: SelectChangeEvent<string>) => {
                      setSagittalGoal(event.target.value as CaseApproachType);
                    }}
                    value={sagittalGoal}
                    labelId={'sagittalGoal-label'}
                  >
                    {hasPelvicIncidence
                      ? [
                          <MenuItem key="age-adjusted" value={CaseApproachType.AgeAdjusted}>
                            {format.formatCaseApproach(CaseApproachType.AgeAdjusted)}
                          </MenuItem>,
                          <MenuItem key="gap-score" value={CaseApproachType.GapScore}>
                            {format.formatCaseApproach(CaseApproachType.GapScore)}
                          </MenuItem>,
                          <MenuItem key="zero-mismatch" value={CaseApproachType.ZeroMismatch}>
                            {format.formatCaseApproach(CaseApproachType.ZeroMismatch)}
                          </MenuItem>,
                        ]
                      : null}
                    <MenuItem value={CaseApproachType.Other}>Level specific</MenuItem>
                    <MenuItem value={CaseApproachType.None}>
                      {format.formatCaseApproach(CaseApproachType.None)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <strong>Height Restoration Goal</strong>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <FormControl>
                  <InputLabel
                    sx={{ backgroundColor: 'white' }}
                    id="heightRestorationGoal-label"
                  ></InputLabel>
                  <Select
                    size={'small'}
                    disabled={
                      loadingCreateAutoCorrectQueueEntry ||
                      loadingDeleteAutoCorrectQueueEntry ||
                      isProcessing
                    }
                    name={'height-restoration'}
                    onChange={(event: SelectChangeEvent<string>) => {
                      setHeightRestorationGoal(event.target.value as HeightRestorationGoalType);
                    }}
                    value={heightRestorationGoal}
                    labelId={'heightRestorationGoal-label'}
                  >
                    <MenuItem value={HeightRestorationGoalType.TEM013}>
                      {format.formatHeightRestorationGoalType(HeightRestorationGoalType.TEM013)}
                    </MenuItem>
                    <MenuItem value={HeightRestorationGoalType.Specified}>
                      {format.formatHeightRestorationGoalType(HeightRestorationGoalType.Specified)}
                    </MenuItem>
                    <MenuItem value={HeightRestorationGoalType.None}>
                      {format.formatHeightRestorationGoalType(HeightRestorationGoalType.None)}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {renderLevelSpecificGoalForm()}
              <Grid item xs={4}>
                <Typography>
                  <strong>Generate Landmarks</strong>
                </Typography>
                {hasLandmarks ? (
                  <Typography variant={'caption'} color={'textSecondary'}>
                    Landmarking points already exist
                  </Typography>
                ) : null}
              </Grid>
              <Grid item xs={8}>
                <MuiCheckbox
                  disabled={
                    loadingCreateAutoCorrectQueueEntry ||
                    loadingDeleteAutoCorrectQueueEntry ||
                    isProcessing
                  }
                  name="shouldGenerateMeasurements"
                  checked={shouldGenerateMeasurements}
                  onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked && hasLandmarks) {
                      try {
                        await confirm({
                          title: 'Generate Landmarks',
                          description:
                            'Existing landmarks will be replaced with new landmarking points. Are you sure you want to proceed?',
                        });

                        setShouldGenerateMeasurements(!event.target.checked);
                      } catch (error) {
                        setShouldGenerateMeasurements(event.target.checked);
                        return;
                      }
                    } else {
                      setShouldGenerateMeasurements(event.target.checked);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {!hasPelvicIncidence ? (
                  <Alert severity={'warning'}>
                    <Typography
                      variant={'body1'}
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      No pelvic incidence detected, thus <b>sagittal goal</b> options are limited.
                    </Typography>
                  </Alert>
                ) : null}
                {autoCorrectQueue ? (
                  <Alert severity={getAutoCorrectSeverity(autoCorrectQueue.status)}>
                    <Typography
                      variant={'body1'}
                      sx={{
                        width: '100%',
                        textAlign: 'center',
                      }}
                    >
                      {formatAutoCorrectStatus(autoCorrectQueue.status)}
                      {autoCorrectQueue?.finishedAt ? (
                        <>
                          {' '}
                          &mdash; Process finished at{' '}
                          {format.formatDateTime(autoCorrectQueue.finishedAt as Date)} (
                          {autoCorrectQueue.minutesProcessing}min)
                        </>
                      ) : null}
                    </Typography>
                  </Alert>
                ) : null}
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: '40%' }}>
            <Typography variant={'h3'}>
              {format.formatName(props.activeCase.surgeonUser)} Preferences
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 200px)' }}>
              <Box sx={{ flex: '1 1 auto', overflowY: 'auto' }}>
                <SurgeonPreferencesMainView
                  preferences={surgeonPreferences}
                  caseLevels={props.activeCase.levels}
                />
              </Box>
            </Box>
          </Box>
        </Stack>
      </CustomDialog>
      <IconFontButton
        loading={isProcessing}
        size={'large'}
        onClick={() => setShowAutoCorrectDialog(true)}
        icon={faBrainCircuit}
      />
    </>
  );
}
