import { Box, Grid, Stack, Typography } from '@mui/material';
import { Control, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProgressButton, TextFieldRHF } from '@workflow-nx/ui';
import * as Yup from 'yup';

import CustomDialog from '../../../../components/CustomDialog';

export function CaseTypeChangeReasonDialog(props: {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}) {
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required(),
  });

  interface formValuesType {
    reason: string;
  }

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const handleSubmitForm: SubmitHandler<formValuesType> = async (values) => {
    props.onSubmit(values.reason);
  };

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title="Changing case from Live to Draft"
      onClose={() => {
        props.onClose();
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          loading={isSubmitting}
          disabled={isSubmitting}
          label={'Save'}
        />,
      ]}
    >
      {props.open ? (
        <form>
          <Stack spacing={1}>
            <Typography variant={'body1'}>
              Enter the reason why the case is being moved from Live to Draft
            </Typography>
            <TextFieldRHF
              control={control as unknown as Control}
              disabled={isSubmitting}
              name="reason"
              label={'Reason'}
            />
          </Stack>
        </form>
      ) : null}
    </CustomDialog>
  );
}
