import { Box, LinearProgress, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { AssetType, IAsset, format, ValidFileExtensions } from '@workflow-nx/common';
import { DropzoneOptions, FileRejection, useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { dicom, testing } from '@workflow-nx/utils';
import useCreateAndUploadAsset from '../../hooks/useCreateAndUploadAsset';

const useStyles = makeStyles((theme: Theme) => ({
  gridCell: {
    backgroundColor: 'white',
    height: 50,
  },
  dragActive: {
    backgroundColor: theme.palette.divider,
    opacity: 0.1,
  },
}));

export function AssetGridDropZoneTableRow(props: {
  caseId: number;
  planId?: number;
  assetType: AssetType;
  validFileExtensions: ValidFileExtensions[];
  onUpload: (asset: IAsset) => void;
  readOnly: boolean;
}) {
  const styles = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { createAndUploadAsset, uploadProgress } = useCreateAndUploadAsset();

  const [uploading, setUploading] = useState(false);
  const handleDrop: DropzoneOptions['onDrop'] = useCallback(
    async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      try {
        if (fileRejections.length > 0) {
          const errorMessage = fileRejections[0].errors[0].message;
          enqueueSnackbar(`Error uploading asset. ${errorMessage}`, {
            variant: 'error',
          });
          return;
        }

        if (acceptedFiles.length === 0) {
          return;
        }

        const allFilesHaveSize = acceptedFiles.every((f) => f.size !== 0);

        if (!allFilesHaveSize) {
          enqueueSnackbar(`Error uploading asset. File has a size of zero bytes.`, {
            variant: 'error',
          });
          return;
        }

        const acceptedFile = acceptedFiles[0];

        setUploading(true);

        let metadata = null;
        if (dicom.PARSABLE_DICOM_TYPES.includes(props.assetType)) {
          try {
            metadata = await dicom.getDicomMetadata(acceptedFile, props.assetType);
          } catch (e) {
            console.error('Error processing DICOM', e);
          }
        }

        const createdAsset = await createAndUploadAsset(
          acceptedFile,
          props.assetType,
          props.caseId,
          props.planId,
          metadata,
        );

        if (createdAsset?.createdAsset && !createdAsset.error) {
          props.onUpload(createdAsset.createdAsset.asset);
          enqueueSnackbar(
            `Asset ${format.formatAssetType(props.assetType)} successfully uploaded`,
            {
              variant: 'success',
            },
          );
        } else if (createdAsset.error) {
          throw new Error(createdAsset.error);
        }
      } catch (e) {
        console.error(e);
        enqueueSnackbar('Error uploading asset', {
          variant: 'error',
        });
      } finally {
        setUploading(false);
      }
    },
    [createAndUploadAsset, enqueueSnackbar, props],
  );

  const acceptedFileTypes = props.validFileExtensions.join(',');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: handleDrop,
    accept: acceptedFileTypes,
    disabled: props.readOnly,
  });

  return (
    <Box
      gridColumn={'span 6'}
      className={clsx({
        [styles.gridCell]: true,
        [styles.dragActive]: isDragActive,
      })}
      {...getRootProps()}
    >
      <Box gridColumn={'span 4'} className={styles.gridCell}>
        {props.readOnly ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={50}>
            <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
              {`${format.formatAssetType(props.assetType)} file (${acceptedFileTypes})`}
            </Typography>
          </Box>
        ) : !uploading ? (
          <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={50}>
            <input
              data-test-id={testing.toKebabCase(props.assetType + 'asset-grid-drop-zone-table-row')}
              {...getInputProps()}
            />
            <Typography color={'textSecondary'} align={'center'} variant={'h5'}>
              {props.readOnly
                ? format.formatAssetType(props.assetType)
                : `Drop ${format.formatAssetType(
                    props.assetType,
                  )} file (${acceptedFileTypes}) here or click to browse. `.toUpperCase()}
            </Typography>
          </Box>
        ) : (
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            height={50}
          >
            <Typography color={'textSecondary'} align={'center'} variant={'h6'}>
              {`Uploading ${format.formatAssetType(props.assetType)}`.toUpperCase()}
            </Typography>
            <Box width={'100%'}>
              <LinearProgress color={'secondary'} variant={'determinate'} value={uploadProgress} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
