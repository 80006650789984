import { BusinessDate, HolidayDate } from '@workflow-nx/common';
import * as dateFns from 'date-fns';
import { DateTime } from 'luxon';

export const distanceInYears = (isoDate: string, round = false) => {
  const years = Math.abs(DateTime.fromISO(isoDate, { zone: 'utc' }).diffNow('years').years);

  return round ? Math.round(years) : Math.floor(years);
};

export const distanceInDays = (isoDate: string): number => {
  return Math.abs(Math.round(DateTime.fromISO(isoDate, { zone: 'utc' }).diffNow('days').days));
};

export const distanceInMonths = (isoDate: string): number => {
  return Math.abs(Math.round(DateTime.fromISO(isoDate, { zone: 'utc' }).diffNow('months').months));
};

export const distanceInWeeks = (isoDate: string): number => {
  return Math.abs(Math.round(DateTime.fromISO(isoDate, { zone: 'utc' }).diffNow('weeks').weeks));
};

export const timeSinceInWeeks = (date: string, pastDate: string): number => {
  return Math.abs(
    Math.round(
      DateTime.fromISO(date, { zone: 'utc' }).diff(
        DateTime.fromISO(pastDate, { zone: 'utc' }),
        'weeks',
      ).weeks,
    ),
  );
};

export const distanceInMinutes = (isoDate: string) => {
  const utcDateTime = DateTime.fromISO(isoDate, { zone: 'utc' });
  const utcNow = DateTime.utc();
  const duration = utcDateTime.diff(utcNow, 'minutes');

  return Math.abs(Math.round(duration.minutes));
};

export const distanceInWeeksWithFloor = (isoDate: string): number => {
  return Math.floor(DateTime.fromISO(isoDate, { zone: 'utc' }).diffNow('weeks').weeks);
};

export const parse = (value: string, format = 'yyyy-MM-dd'): Date => {
  if (value) {
    return DateTime.fromFormat(value, format).toJSDate();
  }
  return new Date();
};

export const parseISO = (value: string): Date => {
  if (value) {
    return DateTime.fromISO(value, { zone: 'utc' }).toJSDate();
  }
  return new Date();
};

export const parseCalendarDateFromString = (value: string): Date | null => {
  const isoDate = DateTime.fromISO(value, { zone: 'utc' });

  if (!isoDate.isValid) {
    return null;
  }

  return isoDate ? new Date(isoDate.year, isoDate.month - 1, isoDate.day) : null;
};

export function subtract(value: Date, duration: Duration) {
  if (value && dateFns.isValid(value)) {
    return dateFns.sub(value, duration);
  }
  return new Date();
}

export function add(value: Date, duration: Duration) {
  if (value && dateFns.isValid(value)) {
    return dateFns.add(value, duration);
  }
  return new Date();
}

export function daysSince(dateLeft: Date, dateRight: Date) {
  try {
    return dateFns.differenceInDays(dateLeft, dateRight);
  } catch (e) {
    console.log(`Error calculating days since`, dateLeft, dateRight);
  }
  return 0;
}

export function yearsSince(value: Date) {
  if (value && dateFns.isValid(value)) {
    return dateFns.differenceInYears(new Date(), value);
  }
  return 0;
}

export function checkIfDateRangesOverlap(dateRanges: { start: Date; end: Date }[]): {
  overlap: boolean;
  ranges: { start: Date; end: Date }[];
} {
  const sortedRanges = dateRanges.sort((previous, current) => {
    // get the start date from previous and current
    const previousTime = previous.start.getTime();
    const currentTime = current.start.getTime();

    // if the previous is earlier than the current
    if (previousTime < currentTime) {
      return -1;
    }

    // if the previous time is the same as the current time
    if (previousTime === currentTime) {
      return 0;
    }

    // if the previous time is later than the current time
    return 1;
  });

  const initialValue: { overlap: boolean; ranges: any[] } = { overlap: false, ranges: [] };
  return sortedRanges.reduce((result, current, idx, arr) => {
    if (idx === 0) {
      return result;
    }
    const previous = arr[idx - 1];

    const previousEnd = previous.end.getTime();
    const currentStart = current.start.getTime();
    const overlap = previousEnd >= currentStart;

    if (overlap) {
      result.overlap = true;
      (result.ranges as any[]).push({
        previous: previous,
        current: current,
      });
    }

    return result;
  }, initialValue);
}

export const isHoliday = (date: BusinessDate, holidays: HolidayDate[]) => {
  return !!holidays.find(
    (holiday) =>
      date.year === holiday.year && date.month === holiday.month && date.day === holiday.day,
  );
};

export const isWeekend = (date: BusinessDate) => {
  return date.weekday == 6 || date.weekday == 7;
};

export const isBusinessDay = (date: BusinessDate, holidays: HolidayDate[]) => {
  return !isHoliday(date, holidays) && !isWeekend(date);
};

export function getCurrentISODateString() {
  return new Date(
    DateTime.fromJSDate(new Date(), { zone: 'UTC' }).toFormat('yyyy-MM-dd'),
  ).toISOString();
}

export function getCurrentISODateTimeString() {
  return new Date().toISOString();
}

export function getISODate(date: Date) {
  return DateTime.fromJSDate(date).toISODate();
}

export const isAfter = (date: string, isAfterDate: string): boolean => {
  return DateTime.fromISO(date, { zone: 'utc' }) > DateTime.fromISO(isAfterDate, { zone: 'utc' });
};

export const isBeforeToday = (isAfterDate: string): boolean => {
  return isAfter(DateTime.utc().toISO(), isAfterDate);
};

export const isClose = (date: string, targetDate: string, monthsUntil: number): boolean => {
  const approachingDate = subtract(DateTime.fromISO(targetDate, { zone: 'utc' }).toJSDate(), {
    months: monthsUntil,
  });

  // check if the target date is within `monthsUtil` of the date
  return DateTime.fromISO(date, { zone: 'utc' }).toJSDate() >= approachingDate;
};

export const isApproaching = (date: string, monthsUntil: number): boolean => {
  return isClose(new Date().toISOString(), date, monthsUntil);
};

export function getDateStringAtMidnight(dateStr: string) {
  return DateTime.fromISO(dateStr).startOf('day').toJSDate();
}

export function addBusinessDays(datetime: DateTime, days: number, downtimeDates?: Date[]) {
  if (!datetime.isValid) {
    return datetime;
  }

  const downtimeSet = new Set(
    downtimeDates?.map((date) => DateTime.fromJSDate(date).toISODate()) ?? [],
  );

  const isNegative = days < 0;

  // Rounds business days
  let businessDaysLeftToAdd = isNegative ? Math.round(-1 * days) : Math.round(days);

  while (businessDaysLeftToAdd > 0) {
    const oneDayByDirection = isNegative ? -1 : 1;

    datetime = datetime.plus({ days: oneDayByDirection });

    const dayOfWeek = datetime.weekday;

    if (dayOfWeek <= 5 && !downtimeSet.has(datetime.toISODate())) {
      businessDaysLeftToAdd--;
    }
  }

  return datetime;
}

export function countBusinessDays(
  startDate: DateTime,
  endDate: DateTime,
  downtimeDates?: Date[],
): number {
  if (!startDate.isValid || !endDate.isValid) {
    return 0;
  }

  // Ensure startDate is before endDate
  if (startDate > endDate) {
    return -1 * countBusinessDays(endDate, startDate, downtimeDates);
  }

  // Create a set of downtime dates for efficient lookup
  const downtimeSet = new Set(
    downtimeDates?.map((date) => DateTime.fromJSDate(date).toISODate()) ?? [],
  );

  let businessDays = 0;
  let currentDate = startDate.startOf('day');
  const lastDate = endDate.startOf('day');

  // Loop through each day and count if it's a business day
  while (currentDate <= lastDate) {
    const dayOfWeek = currentDate.weekday;

    // Check if it's a weekday (1-5 = Monday-Friday) and not in downtime dates
    if (dayOfWeek >= 1 && dayOfWeek <= 5 && !downtimeSet.has(currentDate.toISODate())) {
      businessDays++;
    }

    currentDate = currentDate.plus({ days: 1 });
  }

  return businessDays;
}
