import {
  EventType,
  getEventAssetType,
  isPurchaseOrderEvent,
  isTimeSensitiveEvent,
  isTrackableEvent,
} from '@workflow-nx/common';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import * as Yup from 'yup';

export const createEventDialogSchema = Yup.object().shape({
  occurredAt: Yup.date().typeError('Invalid Date').required('Field is required'),
  eventType: Yup.string().required('Field is required'),
  description: Yup.string(),
  note: Yup.string(),
  trackingNumber: Yup.string().when('eventType', {
    is: (eventType: EventType) => isTrackableEvent(eventType)?.required,
    then: (schema) => schema.required(`Tracking number is required.`),
    otherwise: (schema) => schema.optional(),
  }),
  carrierType: Yup.string().when('eventType', {
    is: (eventType: EventType) => isTrackableEvent(eventType)?.required,
    then: (schema) => schema.required(`Carrier type is required.`),
    otherwise: (schema) => schema.optional(),
  }),
  asset: Yup.mixed().when('eventType', {
    is: (e: EventType) => (e ? getEventAssetType(e) !== null : false),
    then: (schema) =>
      schema.required(`Asset is required`).test('fileSize', 'The file is too large', (value) => {
        const file = value as File | undefined | null;

        if (!file) return false;

        return file.size >= Math.pow(2, 7);
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});

export const CreateEventAssetDialogSchema = Yup.object().shape({
  occurredAt: Yup.date().typeError('Invalid Date').required('Field is required'),
  description: Yup.string(),
  note: Yup.string(),
  purchaseOrderNumber: Yup.string().when('eventType', {
    is: (eventType: EventType) => isPurchaseOrderEvent(eventType)?.required,
    then: (schema) => schema.required(`Purchase order # is required.`),
    otherwise: (schema) => schema.nullable(),
  }),
  dueDate: Yup.date()
    .typeError('Invalid Date')
    .when('eventType', {
      is: (eventType: EventType) => isTimeSensitiveEvent(eventType)?.required,
      then: (schema) => schema.required(`Due date is required.`),
      otherwise: (schema) => schema.optional(),
    }),
});

export const createEventsDialogSchema = Yup.object().shape({
  occurredAt: Yup.date().typeError('Invalid Date').required('Field is required'),
  eventType: Yup.string().required('Field is required'),
  description: Yup.string(),
  note: Yup.string(),
  trackingNumber: Yup.string(),
  carrierType: Yup.string(),
});

export const editVendorAssignmentDialogSchema = Yup.object().shape({
  additivePrintingVendor: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
  finalShipmentVendor: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable(),
});

export const updateCaseNumberDialogSchema = Yup.object().shape({
  receivedAt: Yup.date().required('Field is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
});

export const createUserDialogSchema = Yup.object().shape({
  role: Yup.string().required('Role is required'),
  organizationId: Yup.number().required('Institution is required'),
  locationId: Yup.number().required('Location is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .matches(
      /^[+\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/,
      'Must be a valid email',
    ),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string()
    .nullable()
    .test('phone', 'Invalid phone number', function (value) {
      if (!value || value.trim() === '' || /^\+\d*$/.test(value.trim())) {
        return true; // null values, empty strings, or just country codes
      } else {
        return isPossiblePhoneNumber(value);
      }
    }),
});

export const surgeonDialogSchema = Yup.object().shape({
  organizationId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('Institution is required'),
  locationId: Yup.number()
    .transform((value) => (Number.isNaN(value) ? null : value))
    .nullable()
    .required('Location is required'),
  linkedOrganizationId: Yup.string().required('Distributor is required'),
  email: Yup.string()
    .email('Must be a valid email')
    .max(255)
    .required('Email is required')
    .matches(
      /^[+\w-]+(\.[\w-]+)*@([a-z0-9-]+(\.[a-z0-9-]+)*?\.[a-z]{2,6}|(\d{1,3}\.){3}\d{1,3})(:\d{4})?$/,
      'Must be a valid email',
    ),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  phone: Yup.string()
    .nullable()
    .test('phone', 'Invalid phone number', function (value) {
      if (!value || value.trim() === '' || /^\+\d*$/.test(value.trim())) {
        return true; // null values, empty strings, or just country codes
      } else {
        return isPossiblePhoneNumber(value);
      }
    }),
});

export const createOrganizationDialogSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  organizationType: Yup.string().required('Institution type is required'),
  priceLevelType: Yup.string().required(),
  oneLevel: Yup.number().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.number().required(),
  }),
  twoLevel: Yup.number().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.number().required(),
  }),
  threeLevel: Yup.number().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.number().required(),
  }),
  fourLevel: Yup.number().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.number().required(),
  }),
  fiveLevel: Yup.number().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.number().required(),
  }),
  startDate: Yup.date().when('organizationType', {
    is: 'HOSPITAL',
    then: () => Yup.date().required(),
  }),
  endDate: Yup.date().nullable(),
});

export const updateOrganizationDialogSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  externalSyncDataSourceId: Yup.string(),
  organizationType: Yup.string().required('Institution type is required'),
  heatTreatAvailable: Yup.boolean(),
});

export const updatePatientDetailsSchema = Yup.object().shape({
  //For if we ever want to do validation on phone numbers
  /*   phoneNumber: Yup.string()
    .nullable()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .length(10, 'Must be 10 digits'), */
  email: Yup.string().email().nullable(),
});
export const updatePatientRecordsSchema = Yup.object().shape({
  assignedId: Yup.number().nullable(),
  surgeonId: Yup.number().nullable(),
  surgeryDate: Yup.date().nullable(),
  pelvicIncidence: Yup.number()
    .nullable()
    .min(1, 'Must be 1\u00b0 or more')
    .max(180, 'Must be 180\u00b0 or less'),
  slopeOfLineOfSight: Yup.number().nullable(),
  levels: Yup.array().min(0, 'There must be at least level'),
});

export const createPlanSchema = Yup.object().shape({
  name: Yup.string().max(255).required(),
});

export const createTaskDialogSchema = Yup.object().shape({
  description: Yup.string().required('Task Description is required'),
  assignedId: Yup.number().required('Assigned To is required'),
  priority: Yup.string().required('Priority is required'),
});

export const updateTaskDialogSchema = Yup.object().shape({});

export const createImplantPartDetailDialogSchema = Yup.object().shape({
  occurredAt: Yup.date().typeError('Invalid Date').required('Field is required'),
  eventType: Yup.string().required('Field is required'),
  description: Yup.string(),
  note: Yup.string(),
  trackingNumber: Yup.string().when('eventType', {
    is: (eventType: EventType) => isTrackableEvent(eventType),
    then: (schema) => schema.required(`Tracking number is required.`),
    otherwise: (schema) => schema.nullable(),
  }),
  carrierType: Yup.string().when('eventType', {
    is: (eventType: EventType) => isTrackableEvent(eventType),
    then: (schema) => schema.required(`Carrier type is required.`),
    otherwise: (schema) => schema.nullable(),
  }),
  asset: Yup.mixed().when('eventType', {
    is: (e: EventType) => (e ? getEventAssetType(e) !== null : false),
    then: (schema) =>
      schema.required(`Asset is required`).test('fileSize', 'The file is too large', (value) => {
        const file = value as File | undefined | null;

        if (!file) return false;

        return file.size >= Math.pow(2, 7);
      }),
    otherwise: (schema) => schema.nullable(),
  }),
});
