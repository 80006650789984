import { Box, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { IPlanImplant, PartType } from '@workflow-nx/common';
import { Scene } from 'babylonjs';
import { Formik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { ILimits, ISettingsForm19ImplantAttributes } from '../../../../../../utils/form19';

enum BulletSide {
  None = 'NONE',
  ThreadedSide = 'THREADED_SIDE',
  InsertionSide = 'INSERTION_SIDE',
}

type BulletFormProps = {
  bullet: BulletFormValues;
  planImplant: IPlanImplant;
  plusLevelSize: number;
  implantAttributes: ISettingsForm19ImplantAttributes;
  disabled: boolean;
  scene: Scene | null;
  onChange: (values: BulletFormValues) => void;
};

export const bulletFormSchema = function (
  height: ILimits | undefined,
  implantType: PartType,
  plusLevelSize: number,
) {
  if (!height) return;

  // Min and max height adjustments:
  // For the min value, since the minimum is for a minus size implant (ie. 2.1) and we're doing the correction against a
  // plan implant, we don't want to allow the user to enter a value that will end up being smaller than
  // the minimum when the minus size is generated (ie. 3.1 - 1 for minus is 2.1).

  // For the max value, we subtract the plusLevelSize (1 or 2) so we don't generate over the max
  const max = height.max - (plusLevelSize ?? 0);
  const min = height.min + 1;

  const schema: any = {
    angleInsertion: Yup.number()
      .test('isInRangeAngleInsertion', `Out of range 0 - 180`, function (value) {
        let result = false;
        if (value) {
          result = value >= 0 && value <= 180;
        }
        return result;
      })
      .required('Angle is required'),
    heightInsertion: Yup.number()
      .test('isInRangeheightInsertion', `Out of range ${min} - ${max}`, function (value) {
        let result = false;
        if (value) {
          result = value >= min && value <= max;
        }
        return result;
      })
      .required('Angle is required'),
  };
  if (implantType === PartType.LLIF_LEFT || implantType === PartType.LLIF_RIGHT) {
    schema.heightThreaded = Yup.number()
      .test('isInRangeHeightThreaded', `Out of range ${min} - ${max}`, function (value) {
        let result = false;
        if (value) {
          result = value >= min && value <= max;
        }
        return result;
      })
      .required('Angle is required');
    schema.angleThreaded = Yup.number()
      .test('isInRangeangleThreaded', `Out of range 0 - 180`, function (value) {
        let result = false;
        if (value) {
          result = value >= 0 && value <= 180;
        }
        return result;
      })
      .required('Angle is required');
  }

  return Yup.object().shape(schema);
};

export type BulletFormValues = {
  // bullet form values
  angleThreaded: number;
  angleInsertion: number;
  heightThreaded: number;
  heightInsertion: number;
  // async bullet form values
  threadedTopAngle?: number;
  threadedTopHeight?: number;
  threadedBottomAngle?: number;
  threadedBottomHeight?: number;
  insertionTopAngle?: number;
  insertionTopHeight?: number;
  insertionBottomAngle?: number;
  insertionBottomHeight?: number;
  threadHeight: number;
};

const useStyles = makeStyles(() => ({
  helperText: {
    display: 'flex',
    position: 'absolute',
    top: '37.5px',
    margin: '4px',
    lineHeight: 'normal',
    fontSize: '10px',
  },
}));

export default function BulletForm({
  bullet,
  planImplant,
  plusLevelSize,
  implantAttributes,
  disabled,
  onChange,
  scene,
}: BulletFormProps) {
  const classes = useStyles();

  const handleBulletChange = (bulletSide: BulletSide, values: BulletFormValues) => {
    onChange(values);
  };

  const handleThreadHeightChange = (values: BulletFormValues) => {
    if (!scene) return;

    onChange(values);
  };

  useEffect(() => {
    if (
      ![
        PartType.TLIFC_OFFSET_LEFT,
        PartType.TLIFC_OFFSET_RIGHT,
        PartType.TLIFO_LEFT,
        PartType.TLIFO_RIGHT,
      ].includes(planImplant.partType)
    ) {
      handleBulletChange(BulletSide.InsertionSide, bullet);

      if (
        planImplant.partType === PartType.LLIF_LEFT ||
        planImplant.partType === PartType.LLIF_RIGHT
      ) {
        handleBulletChange(BulletSide.ThreadedSide, bullet);
      }
    }
  }, []);

  return (
    <Formik
      initialValues={bullet}
      enableReinitialize={true}
      validationSchema={bulletFormSchema(
        implantAttributes.bulletTaperHeight,
        planImplant.partType,
        plusLevelSize,
      )}
      validateOnBlur={true}
      onSubmit={() => undefined}
    >
      {({ values, isSubmitting, touched, errors, handleBlur, handleChange }) => {
        return (
          <Box mt={1}>
            <Typography variant={'h6'} color="text.secondary">
              Insertion Side
            </Typography>
            <Box display={'flex'} alignItems={'center'} justifyContent={'stretch'} mt={2} mb={2}>
              <TextField
                data-testid={'angle-input'}
                size={'small'}
                name={'angleInsertion'}
                label={'Planar Angle'}
                disabled={isSubmitting || disabled}
                error={Boolean(touched.angleInsertion && errors.angleInsertion)}
                helperText={touched.angleInsertion && errors.angleInsertion}
                FormHelperTextProps={{
                  classes: { root: classes.helperText },
                }}
                fullWidth
                type="number"
                variant={'outlined'}
                value={values.angleInsertion}
                onBlur={(e) => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                  handleChange(e);
                }}
                onKeyUp={() => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                }}
                InputLabelProps={{
                  style: {
                    backgroundColor: 'transparent',
                  },
                }}
                inputProps={{
                  min: 0,
                  max: 180,
                  step: '.2',
                }}
              />
              <Box mx={1} />
              <TextField
                data-testid={'taper-height-input'}
                size={'small'}
                name={'heightInsertion'}
                label={'Bullet Height'}
                disabled={isSubmitting || disabled}
                error={Boolean(touched.heightInsertion && errors.heightInsertion)}
                helperText={touched.heightInsertion && errors.heightInsertion}
                FormHelperTextProps={{
                  classes: { root: classes.helperText },
                }}
                fullWidth
                type="number"
                variant={'outlined'}
                value={values.heightInsertion}
                onBlur={(e) => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                  handleBlur(e);
                }}
                onChange={(e) => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                  handleChange(e);
                }}
                onKeyUp={() => {
                  handleBulletChange(BulletSide.InsertionSide, values);
                }}
                InputLabelProps={{
                  style: {
                    backgroundColor: 'transparent',
                  },
                }}
                inputProps={{
                  min: implantAttributes.bulletTaperHeight.min,
                  max: implantAttributes.bulletTaperHeight.max,
                  step: '.2',
                }}
              />
            </Box>
            {planImplant.partType === PartType.LLIF_LEFT ||
            planImplant.partType === PartType.LLIF_RIGHT ? (
              <Box>
                <Typography variant={'h6'} color="text.secondary">
                  Threaded Side
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  justifyContent={'stretch'}
                  mt={1}
                  mb={2}
                >
                  <TextField
                    data-testid={'angle-input'}
                    size={'small'}
                    name={'angleThreaded'}
                    label={'Angle'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.angleThreaded && errors.angleThreaded)}
                    helperText={touched.angleThreaded && errors.angleThreaded}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.angleThreaded}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: 0,
                      max: 180,
                      step: '.2',
                    }}
                  />
                  <Box mx={1} />
                  <TextField
                    data-testid={'taper-height-input'}
                    size={'small'}
                    name={'heightThreaded'}
                    label={'Bullet Height'}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.heightThreaded && errors.heightThreaded)}
                    helperText={touched.heightThreaded && errors.heightThreaded}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.heightThreaded}
                    onBlur={(e) => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleBulletChange(BulletSide.ThreadedSide, values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: implantAttributes.patientContactUpperHeight.min,
                      max: implantAttributes.patientContactUpperHeight.max,
                      step: '.1',
                    }}
                  />
                </Box>
              </Box>
            ) : null}
            {planImplant.partType === PartType.ALIF ||
            planImplant.partType === PartType.ALIF_X_TWO_UP ||
            planImplant.partType === PartType.ALIF_X_TWO_DOWN ||
            planImplant.partType === PartType.LLIF_LEFT ||
            planImplant.partType === PartType.LLIF_RIGHT ? (
              <Box>
                <Typography variant={'h6'} color="text.secondary">
                  Thread Height
                </Typography>
                <Box mt={1}>
                  <TextField
                    data-testid={'thread-height-input'}
                    size={'small'}
                    name={'threadHeight'}
                    label={''}
                    disabled={isSubmitting || disabled}
                    error={Boolean(touched.threadHeight && errors.threadHeight)}
                    helperText={touched.threadHeight && errors.threadHeight}
                    FormHelperTextProps={{
                      classes: { root: classes.helperText },
                    }}
                    fullWidth
                    type="number"
                    variant={'outlined'}
                    value={values.threadHeight}
                    onBlur={(e) => {
                      handleThreadHeightChange(values);
                      handleBlur(e);
                    }}
                    onChange={(e) => {
                      handleThreadHeightChange(values);
                      handleChange(e);
                    }}
                    onKeyUp={() => {
                      handleThreadHeightChange(values);
                    }}
                    InputLabelProps={{
                      style: {
                        backgroundColor: 'transparent',
                      },
                    }}
                    inputProps={{
                      min: implantAttributes.bulletTaperHeight.min, // TODO: Define definition
                      max: implantAttributes.bulletTaperHeight.max,
                      step: '.2',
                    }}
                  />
                </Box>
              </Box>
            ) : null}
          </Box>
        );
      }}
    </Formik>
  );
}
