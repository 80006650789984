import React, { Dispatch, useContext, useReducer, useState } from 'react';
import Page from '../../../components/Page';
import { Badge, Box, Button, Card, CardContent, Container, Tab, Tabs } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { PostOpCaseReducer, PostOpCaseStateType, VALID_ANALYSIS_TYPES } from './PostOpCase.reducer';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AssetType,
  getAnalysisAssetTypes,
  IAsset,
  MeasurementsVersionType,
  PostOpAnalysisType,
} from '@workflow-nx/common';
import { FIND_POST_OP_ANALYSIS_DATA, IMPORT_CASE_IMPLANT_SPECIFICATION } from '../../../gql';
import { CustomAppBar } from '../../../components/CustomAppBar';
import { ViewCaseDetails } from '../../cases/CaseView/CaseDetails/ViewCaseDetails';
import { EditCaseDetailsDialog } from '../../cases/CaseView/CaseDetails/EditCaseDetailsDialog';
import { DiscussionTabView } from '../../cases/CaseView/DiscussionTab/DiscussionTabView';
import { CaseViewContext, CaseViewProvider } from '../../cases/CaseView/CaseView.context';
import { MeasurementsVersionToggle } from '../../cases/CaseView/MeasurementsVersionToggle';
import { PostOpCaseDetailsView } from './PostOpCaseDetailsView';
import _ from 'lodash';

function PostOpDetailsView({
  caseId,
  dispatch,
  loading,
  onMeasurementsVersionChange,
  onReload,
  state,
}: {
  caseId: string | undefined;
  loading: boolean;
  state: PostOpCaseStateType;
  dispatch: Dispatch<any>;
  onMeasurementsVersionChange: (measurementsVersion: MeasurementsVersionType) => void;
  onReload: () => void;
}) {
  const [viewTabValue, setViewTabValue] = useState('CASE');
  const [importCaseImplantSpecification, { loading: loadingImport }] = useMutation(
    IMPORT_CASE_IMPLANT_SPECIFICATION,
    { variables: { caseId: Number(caseId) } },
  );

  const caseViewContext = useContext(CaseViewContext);
  const [openEditCaseDialog, setOpenEditCaseDialog] = useState(false);

  const validAssets = VALID_ANALYSIS_TYPES.flatMap((analysisType) => [
    state[analysisType].xr,
    state[analysisType].ct,
    state[analysisType].mri,
    state[analysisType].standingAp,
    state[analysisType].standingLateral,
  ]).filter((a) => Boolean(a)) as IAsset[];

  const handleUpdateCase = (shouldUpdate: boolean) => {
    if (shouldUpdate) {
      onReload();
    }
    setOpenEditCaseDialog(false);
  };

  const handleCaseImplantSpecificationsImport = async () => {
    try {
      await importCaseImplantSpecification();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <CustomAppBar
        title={`${state?.activeCase?.number} Post-Op Analysis`}
        navigateBackUrl={'/app/post-op-analysis'}
        actions={[
          <MeasurementsVersionToggle
            originalCaseMeasurementsVersion={state?.activeCase?.settings.measurementsVersion}
            selectedCaseMeasurementsVersion={caseViewContext.caseMeasurementsVersion}
            onChange={() => {
              onMeasurementsVersionChange(caseViewContext.toggleCaseMeasurementsVersion());
            }}
          />,
          <Button href={`/app/cases/${caseId}`}>View Case</Button>,
        ]}
      />
      {state?.activeCase ? (
        <Box my={2}>
          <Card>
            <CardContent>
              <Box m={1}>
                <ViewCaseDetails
                  activeCase={state?.activeCase}
                  onEdit={() => setOpenEditCaseDialog(true)}
                />
              </Box>
            </CardContent>
          </Card>
        </Box>
      ) : null}
      <Tabs
        value={viewTabValue}
        centered={true}
        indicatorColor="primary"
        textColor="primary"
        variant="standard"
        onChange={(_, newValue) => {
          setViewTabValue(newValue);
        }}
        style={{ marginBottom: 8 }}
      >
        <Tab label={'Post-op Details'} value={'CASE'} />
        <Tab
          label={
            <Badge badgeContent={state.commentCount} color="secondary">
              Discussion
            </Badge>
          }
          value={'DISCUSSION'}
        />
      </Tabs>
      <div role={'tabpanel'} hidden={viewTabValue !== 'CASE'}>
        {viewTabValue === 'CASE' ? (
          <Box mt={3}>
            <PostOpCaseDetailsView
              activeCase={state.activeCase}
              caseImplantSpecifications={state.caseImplantSpecifications}
              plan={state[PostOpAnalysisType.Plan].analysis}
              postOpIntraOp={state[PostOpAnalysisType.PostOpIntraOp].analysis}
              postOpOneWeek={state[PostOpAnalysisType.PostOpOneWeek].analysis}
              postOpSixWeeks={state[PostOpAnalysisType.PostOpSixWeeks].analysis}
              postOpSixMonths={state[PostOpAnalysisType.PostOpSixMonths].analysis}
              postOpOneYear={state[PostOpAnalysisType.PostOpOneYear].analysis}
              postOpTwoYears={state[PostOpAnalysisType.PostOpTwoYears].analysis}
              preOp={state[PostOpAnalysisType.PreOp].analysis}
              dispatch={dispatch}
              assets={validAssets}
              measurementsVersion={caseViewContext.caseMeasurementsVersion}
              loading={loading || loadingImport}
              onImport={handleCaseImplantSpecificationsImport}
              onReload={() => onReload()}
            />
          </Box>
        ) : null}
      </div>
      <div role={'tabpanel'} hidden={viewTabValue !== 'DISCUSSION'}>
        {viewTabValue === 'DISCUSSION' && state.activeCase ? (
          <DiscussionTabView caseId={state.activeCase.caseId} readOnly={true} />
        ) : null}
      </div>
      {state.activeCase ? (
        <EditCaseDetailsDialog
          open={openEditCaseDialog}
          activeCase={state.activeCase}
          onClose={handleUpdateCase}
        />
      ) : null}
    </>
  );
}

const PostOpCaseView = () => {
  const navigate = useNavigate();
  const { caseId } = useParams<{ caseId: string }>();

  const { refetch, loading } = useQuery(FIND_POST_OP_ANALYSIS_DATA, {
    variables: {
      caseId: Number(caseId),
      assetTypeFilter: [
        ..._.compact(
          Object.values(PostOpAnalysisType).flatMap((a) => {
            const analysisAssetTypes = getAnalysisAssetTypes(a);

            if (!analysisAssetTypes) return [];

            return [
              analysisAssetTypes.zip,
              analysisAssetTypes.ct,
              analysisAssetTypes.mri,
              analysisAssetTypes.ap,
              analysisAssetTypes.lateral,
            ];
          }),
        ),
      ],
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (!data?.case) {
        navigate('/app/post-op-analysis');

        return;
      }
      dispatch({ type: 'INIT', data });
    },
    onError: () => {
      navigate('/app/post-op-analysis');
    },
  });

  const initialState: PostOpCaseStateType = {
    activeCase: null,
    [PostOpAnalysisType.PreOp]: {},
    [PostOpAnalysisType.Plan]: {},
    [PostOpAnalysisType.PostOpIntraOp]: {},
    [PostOpAnalysisType.PostOpOneWeek]: {},
    [PostOpAnalysisType.PostOpSixWeeks]: {},
    [PostOpAnalysisType.PostOpSixMonths]: {},
    [PostOpAnalysisType.PostOpOneYear]: {},
    [PostOpAnalysisType.PostOpTwoYears]: {},
  };

  const [state, dispatch] = useReducer(PostOpCaseReducer, initialState);
  return (
    <Page title={'Post-Op Analysis'}>
      <CaseViewProvider measurementsVersion={MeasurementsVersionType.Version2}>
        <Container maxWidth={'lg'}>
          <PostOpDetailsView
            caseId={caseId}
            state={state}
            loading={loading}
            dispatch={dispatch}
            onMeasurementsVersionChange={(measurementsVersion: MeasurementsVersionType) => {
              refetch({
                caseId: Number(caseId),
                // @ts-ignore
                measurementsVersion,
              });
            }}
            onReload={() => refetch()}
          />
        </Container>
      </CaseViewProvider>
    </Page>
  );
};

export default PostOpCaseView;
