import { gql } from '@apollo/client/core';

export const CASES_FIELDS_FRAGMENT = gql`
  fragment casesFields on Case {
    caseId
    spineType
    spineProfile
    number
    shortNumber
    caseType
    stage
    receivedAt
    deletedAt
    patient {
      gender
      birthDate
    }
    studyDate {
      assetType
      studyDate
      expiryDate
      daysOld
      monthsUntilExpiry
      isExpiryDateSetManually
      manualExpiryReason
    }
    location {
      locationId
      description
      organization {
        organizationId
        name
      }
    }
    surgeonUser {
      userId
      firstName
      lastName
      organization {
        organizationId
        name
      }
      firstCaseId
    }
    fieldRepUser {
      userId
      role
      firstName
      lastName
    }
    surgeryDate
    isSurgeryDateTentative
    isXrayNotRequired
    assignedUser {
      userId
      firstName
      lastName
    }
    levels {
      C2_C3
      C3_C4
      C4_C5
      C5_C6
      C6_C7
      C7_C8
      C7_T1
      C8_T1
      C6_T1
      L1_L2
      L2_L3
      L3_L4
      L4_L5
      L5_S1
      L5_L6
      L6_S1
      L4_S1
    }
    caseResults {
      result
    }
    caseCancellation {
      cancelledAt
      cancellationType
      reason
      note
    }
    comments {
      commentId
      comment
      isPinned
      createdAt
      updatedAt
      createdByUser {
        userId
        firstName
        lastName
        email
        role
      }
    }
    events {
      eventId
      eventType
      description
      note
      occurredAt
    }
    tasks {
      taskId
      description
      status
      statusDescription
      dueDate
      priority
      createdAt
      updatedAt
      assignedUser {
        userId
        firstName
        lastName
        email
        role
      }
    }
    proposedAt
    deliveredAt
    updatedAt
  }
`;
export const ASSET_FIELDS_FRAGMENT = gql`
  fragment assetFields on Asset {
    assetId
    assetType
    caseId
    createdByUser {
      userId
      firstName
      lastName
    }
    createdAt
    fileName
    metadata
    planId
    size
    updatedByUser {
      userId
      firstName
      lastName
    }
    updatedAt
    deletedAt
    version
  }
`;

export const USER_FIELDS_FRAGMENT = gql`
  fragment userFields on User {
    userId
    firstName
    lastName
    email
    phone
    status
    role
    permissions
    userType
    reportsToUser {
      userId
      firstName
      lastName
      role
    }
    preferences {
      surgeonPreferenceId
      userId
      preferredProcedures
      sagittalGoalPrimary
      sagittalGoalSecondary
      sagittalGoalOther
      coronalGoalPrimary
      coronalGoalSecondary
      navigationSystems
      navigationSystemOther
      targetHeightMetric
      excludedInstruments
      reusableInstruments
      note
      createdAt
      updatedAt
      createdByUser {
        userId
        firstName
        lastName
      }
      updatedByUser {
        userId
        firstName
        lastName
      }
      surgeonPreferenceImplants {
        surgeonPreferenceImplantId
        surgeonPreferenceId
        implantType
        positioning
        direction
        orientation
        orientationByLevels {
          L3_L4
          L4_L5
          L5_S1
        }
        screwLength
        maximizeFootprint
        plusSizeImplant
        minimalHeightSpecification
        excludedImplantSizes
        note
      }
      surgeonPreferenceImplantMeasurements {
        surgeonPreferenceImplantMeasurementId
        surgeonPreferenceId
        implantType
        measurementType
        min
        max
      }
    }
    createdAt
    updatedAt
    organization {
      organizationId
      name
      organizationType
    }
    linkedOrganization {
      organizationId
      name
      organizationType
    }
    location {
      locationId
      description
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
    }
    userRegionTerritories {
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
      userId
    }
    associatedUserIds
    createdByUser {
      userId
      firstName
      lastName
    }
    updatedByUser {
      userId
      firstName
      lastName
    }
  }
`;
/*
export const USER_FIELDS_FRAGMENT = gql`
  fragment userFields on User {
    userId
    firstName
    lastName
    email
    status
    role
    permissions
    userType
    reportsToUser {
      userId
      firstName
      lastName
      role
    }
    preferences {
      surgeonPreferenceId
      userId
      fieldRepUser {
        userId
        firstName
        lastName
        email
      }
      mismatchCorrectionGoalType
      mismatchCorrectionGoalOther
      navigationSystems
      interbodySystems
      interbodySystemsOther
      footprintSize
      anteriorHeight
      posteriorHeight
      lordosis
      positioning
      surgeonApproach
      likeSymmetricImplants
      note
    }
    createdAt
    updatedAt
    organization {
      organizationId
      name
      organizationType
    }
    linkedOrganization {
      organizationId
      name
      organizationType
    }
    location {
      locationId
      description
    }
    userRegionTerritories {
      region {
        regionId
        name
      }
      territory {
        territoryId
        name
      }
      userId
    }
    createdByUser {
      userId
      firstName
      lastName
    }
    updatedByUser {
      userId
      firstName
      lastName
    }
  }
`;
*/
export const PLANS_FIELDS_FRAGMENT = gql`
  fragment plansFields on Plan {
    planId
    caseId
    approvedAt
    status
    name
    isAppReady
    isActive
    plusLevelSize
    version
    operations {
      body
      operation
      value
    }
    assets {
      assetId
      assetType
      caseId
      createdAt
      createdByUser {
        firstName
        lastName
      }
      deletedAt
      fileName
      metadata
      planId
      size
      updatedAt
      updatedByUser {
        firstName
        lastName
      }
      version
    }
    assetPositions
    createdAt
    createdByUser {
      userId
      firstName
      lastName
    }
    deletedAt
    updatedAt
    updatedByUser {
      userId
      firstName
      lastName
    }
    planShippingLabels {
      contentNumber
      patientInitials
      indexNumber
      imagingDate
      expirationDate
      lotNumber
      patientId
      mrn
      hospital
      surgeonName
      ref
      gtin
      productDescription
      insertionSide
      level
      cageOrientation
      plusSize
      threadAngle
    }
    planKitBom {
      kitCartons {
        kitName
        kitItems {
          itemNumber
          partNumber
          description
          itemType
          weight
        }
        itemsCount
        totalWeight
      }
      excludedItems {
        itemNumber
        partNumber
        description
        itemType
        weight
      }
    }
  }
`;

export const CASE_FIELDS_FRAGMENT = gql`
  fragment caseFields on Case {
    deletedAt
    caseId
    caseType
    receivedAt
    approach
    number
    shortNumber
    spineType
    spineProfile
    stage
    studyDate {
      assetId
      assetType
      daysOld
      studyDate
      expiryDate
      monthsUntilExpiry
      isExpiryDateSetManually
      manualExpiryReason
    }
    includeRodTemplates
    levels {
      C2_C3
      C3_C4
      C4_C5
      C5_C6
      C6_C7
      C7_C8
      C7_T1
      C8_T1
      C6_T1
      L1_L2
      L2_L3
      L3_L4
      L4_L5
      L5_S1
      L5_L6
      L6_S1
      L4_S1
    }
    levelsMetadata {
      C2_C3 {
        anteriorInstrumentation
        otherVendor
      }
      C3_C4 {
        anteriorInstrumentation
        otherVendor
      }
      C4_C5 {
        anteriorInstrumentation
        otherVendor
      }
      C5_C6 {
        anteriorInstrumentation
        otherVendor
      }
      C6_C7 {
        anteriorInstrumentation
        otherVendor
      }
      C7_C8 {
        anteriorInstrumentation
        otherVendor
      }
      C7_T1 {
        anteriorInstrumentation
        otherVendor
      }
      C8_T1 {
        anteriorInstrumentation
        otherVendor
      }
      C6_T1 {
        anteriorInstrumentation
        otherVendor
      }
      L1_L2 {
        anteriorInstrumentation
        otherVendor
      }
      L2_L3 {
        anteriorInstrumentation
        otherVendor
      }
      L3_L4 {
        anteriorInstrumentation
        otherVendor
      }
      L4_L5 {
        anteriorInstrumentation
        otherVendor
      }
      L5_S1 {
        anteriorInstrumentation
        otherVendor
      }
      L5_L6 {
        anteriorInstrumentation
        otherVendor
      }
      L6_S1 {
        anteriorInstrumentation
        otherVendor
      }
      L4_S1 {
        anteriorInstrumentation
        otherVendor
      }
    }
    assets {
      assetId
      assetType
      fileName
      size
      metadata
      createdAt
      updatedAt
      updatedByUser {
        firstName
        lastName
      }
      version
    }
    caseCancellation {
      cancelledAt
      cancellationType
      reason
      note
    }
    caseHold {
      holdType
      reason
    }
    plans {
      planId
      name
      status
      assetPositions
      isActive
      updatedAt
      approvedAt
      plusLevelSize
      version
      updatedByUser {
        userId
        firstName
        lastName
      }
      planShippingLabels {
        contentNumber
        patientInitials
        indexNumber
        imagingDate
        expirationDate
        lotNumber
        patientId
        mrn
        hospital
        surgeonName
        ref
        gtin
        productDescription
        insertionSide
        level
        cageOrientation
        plusSize
        threadAngle
      }
      planKitBom {
        kitCartons {
          kitName
          kitItems {
            itemNumber
            partNumber
            description
            itemType
            weight
          }
          itemsCount
          totalWeight
        }
        excludedItems {
          itemNumber
          partNumber
          description
          itemType
          weight
        }
      }
    }
    patient {
      patientId
      firstName
      middleName
      lastName
      birthDate
      gender
      mrn
      uniquePatientId
      contactCadence
      contactPreference
      email
      phoneNumber
      hasPermissionToContact
      hasPermissionToSchedule
      participantId
      patientRecord {
        patientRecordId
        pelvicIncidence
        lumbarCoronalCobb
        lumbarLordosis
        sagittalVerticalAxis
        coronalBalance
        bmi
        boneDensity
        lumbarCoronalCobb
        lumbarLordosis
        odi
        tScoreHip
        tScoreSpine
        vasBack
        vasLeg
        l4S1LordoticDistribution
        globalTilt
        pelvicTilt
        sacralSlope
        c7SagittalVerticalLine
        slopeOfLineOfSight
        hasPelvisHighCrest
        hasPelvisHighPelvicIncidence
        hasPelvisOblique
      }
    }
    caseLevels {
      level
      partType
      isFused
      threadType
      threadedHoles
      anteriorInstrumentation
      otherVendor
      implantType
      implantDirection
      implantOrientation
    }
    location {
      locationId
      description
      organization {
        organizationId
        name
      }
    }
    surgeonUser {
      userId
      role
      firstName
      lastName
      linkedOrganization {
        organizationId
        name
        organizationType
      }
      location {
        locationId
        locationType
        territoryId
      }
      organization {
        organizationId
        name
        organizationType
      }
    }
    surgeryDate
    isSurgeryDateTentative
    isXrayNotRequired
    pco
    pso
    uiv
    liv
    excludedInstruments
    assignedUser {
      userId
      role
      firstName
      lastName
    }
    fieldRepUser {
      userId
      role
      firstName
      lastName
    }
    createdAt
    activities {
      createdByUser {
        userId
        firstName
        lastName
      }
      activityType
      originId
      originType
      delta
    }
    proposedAt
  }
`;

export const TAG_CATEGORY_FIELDS_FRAGMENT = gql`
  fragment tagCategoryFields on TagCategory {
    tagCategoryId
    label
    color
  }
`;

export const TAG_FIELDS_FRAGMENT = gql`
  ${TAG_CATEGORY_FIELDS_FRAGMENT}

  fragment tagFields on Tag {
    tagId
    tagCategoryId
    label
    tagCategory {
      ...tagCategoryFields
    }
  }
`;
