import React, { useState } from 'react';
import {
  CaseSpineProfile,
  CaseStageType,
  caseUtils,
  format,
  ICase,
  ImplantType,
  IPatientRecord,
  IPlanImplant,
  LevelType,
  PartType,
  PlanImplantBulletType,
} from '@workflow-nx/common';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useMutation, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ProgressButton, SelectFieldRHF } from '@workflow-nx/ui';
import { nTopFileParser } from '@workflow-nx/ntop';
import { FIND_SETTINGS, UPSERT_PLAN_IMPLANT } from '../../../../gql';
import * as Yup from 'yup';
import CustomDialog from '../../../../components/CustomDialog';
import { getValidImplantMeasurementRanges } from './ImplantEditorDialog/utils/implantEditor';
import { ISettingsForm19 } from 'apps/workflow-client/src/app/utils/form19';
import useAuth from '../../../../hooks/useAuth';
import { FeatureFlag } from '../../../../utils/featureFlags';
import { PlanImplantLevels } from './PlanImplantsTableView';
import { FileInput } from './FileInput';
import { Control, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NtopMetadataPopover } from './NtopMetadataPopover';

export const positiveAngleMenuItems = [
  {
    key: '0',
    value: '0°',
  },
  {
    key: '5',
    value: '+5°',
  },
  {
    key: '10',
    value: '+10°',
  },
  {
    key: '15',
    value: '+15°',
  },
  {
    key: '20',
    value: '+20°',
  },
];

export const positiveAndNegativeAngleMenuItems = [
  {
    key: '0',
    value: '0°',
  },
  {
    key: '5',
    value: '+5°',
  },
  {
    key: '10',
    value: '+10°',
  },
  {
    key: '15',
    value: '+15°',
  },
  {
    key: '20',
    value: '+20°',
  },
  {
    key: '-5',
    value: '-5°',
  },
  {
    key: '-10',
    value: '-10°',
  },
  {
    key: '-15',
    value: '-15°',
  },
  {
    key: '-20',
    value: '-20°',
  },
];

export const lumbarScrewLengthMenuItems = [
  {
    key: '20',
    value: format.formatImplantScrewLength(20),
  },
  {
    key: '25',
    value: format.formatImplantScrewLength(25),
  },
  {
    key: '30',
    value: format.formatImplantScrewLength(30),
  },
];

export const cervicalScrewLengthMenuItems = [
  {
    key: '13',
    value: format.formatImplantScrewLength(13),
  },
  {
    key: '15',
    value: format.formatImplantScrewLength(15),
  },
  {
    key: '17',
    value: format.formatImplantScrewLength(17),
  },
  {
    key: '20',
    value: format.formatImplantScrewLength(20),
  },
];

export function EditPlanNtopImplantsDialog(props: {
  activeCase?: ICase;
  hasImplantsWithScrews: boolean;
  planId: number;
  planImplantLevels: Partial<PlanImplantLevels>;
  onClose: (shouldUpdate: boolean) => void;
  open: boolean;
}) {
  const { hasFeatureFlag } = useAuth();
  const [upsertPlanImplant, { loading: upsertPlanImplantLoading }] =
    useMutation(UPSERT_PLAN_IMPLANT);
  const { enqueueSnackbar } = useSnackbar();
  const [form19, setForm19] = useState<ISettingsForm19>();

  const initialValues = props.planImplantLevels;
  const { loading: form19Loading } = useQuery(FIND_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (form19Data) => {
      setForm19(form19Data?.settings?.form19 ? form19Data?.settings?.form19 : {});
    },
  });
  const { hasPelvisHighPelvicIncidence, hasPelvisOblique, hasPelvisHighCrest } = props.activeCase
    ?.patient?.patientRecord as IPatientRecord;
  const handleSubmitForm: SubmitHandler<PlanImplantLevels> = async (values) => {
    try {
      for (const level of Object.keys(values)) {
        const planImplant = values[level as LevelType] as IPlanImplant;

        if (planImplant) {
          await upsertPlanImplant({
            variables: {
              ...planImplant,
              obliqueThreadAngle: planImplant.obliqueThreadAngle
                ? Number(planImplant.obliqueThreadAngle)
                : null,
              cranialCaudalThreadAngle: planImplant.cranialCaudalThreadAngle
                ? Number(planImplant.cranialCaudalThreadAngle)
                : null,
              screwLength: planImplant.screwLength ? Number(planImplant.screwLength) : null,
            },
          });
        }
      }

      enqueueSnackbar('Updating implant interbodies saved', {
        variant: 'success',
      });

      props.onClose(true);
    } catch (err: any) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the implant interbodies', {
        variant: 'error',
      });
    }
  };

  const getValidationSchema = () => {
    const schema: any = {};

    Object.entries(props.planImplantLevels).map(([level, planImplantLevel]) => {
      const implantType = caseUtils.getImplantType(planImplantLevel.partType);
      const implantMeasurementRange = getValidImplantMeasurementRanges(
        planImplantLevel.partType,
        form19,
      );
      schema[level] = Yup.object().shape({
        ap: Yup.number()
          .test('isInRangeAP', 'Value is out of range', function (value) {
            let result = false;

            if (value && implantMeasurementRange) {
              result =
                value >= implantMeasurementRange?.minAP && value <= implantMeasurementRange?.maxAP;
            }
            return result;
          })
          .required(),
        ml: Yup.number()
          .test('isInRangeML', 'Value is out of range', function (value) {
            let result = false;

            if (value && implantMeasurementRange) {
              result =
                value >= implantMeasurementRange?.minML && value <= implantMeasurementRange?.maxML;
            }
            return result;
          })
          .required(),
        screwLength: Yup.string()
          .test('hasScrewLength', 'Screw Length is required', function (_, context) {
            if (![ImplantType.ALIFX, ImplantType.ACDFX].includes(implantType)) {
              return true;
            }
            return !!context.parent?.screwLength;
          })
          .nullable(),
        bullet: Yup.mixed()
          .test('hasBullet', 'Bullet is required', function (_, context) {
            if ([ImplantType.TLIFO, ImplantType.TLIFC, ImplantType.TLIFCA].includes(implantType)) {
              return true;
            }

            const bullet = context.parent?.bullet as PlanImplantBulletType;
            if ([ImplantType.ALIF, ImplantType.ALIFX].includes(implantType)) {
              return (
                Number.isFinite(bullet?.insertionSide?.angle) &&
                Number.isFinite(bullet?.insertionSide?.height)
              );
            }

            if ([ImplantType.LLIF].includes(implantType)) {
              return (
                Number.isFinite(bullet?.insertionSide?.angle) &&
                Number.isFinite(bullet?.insertionSide?.height) &&
                Number.isFinite(bullet?.threadedSide?.angle) &&
                Number.isFinite(bullet?.threadedSide?.height)
              );
            }

            return false;
          })
          .nullable(),
        position: Yup.mixed().nullable(),
        rotation: Yup.mixed().nullable(),
        metadata: Yup.string().required(),
      });
    });

    return Yup.object().shape(schema);
  };

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<PlanImplantLevels>({
    defaultValues: initialValues,
    // @ts-ignore
    resolver: yupResolver(getValidationSchema()),
  });

  const handleParseNtopFile = (text: string, planImplantLevel: IPlanImplant) => {
    try {
      const implantType = caseUtils.getImplantType(planImplantLevel.partType);
      const parsedData = nTopFileParser.parseNtopTextFile(
        text,
        planImplantLevel.level,
        implantType,
        planImplantLevel.partType,
      );
      const currentValues = getValues();

      const currentPlanImplant = currentValues[planImplantLevel.level] as IPlanImplant;
      currentValues[planImplantLevel.level] = {
        ...currentPlanImplant,
        ...parsedData,
        screwLength: currentPlanImplant.screwLength,
      } as IPlanImplant;

      reset(currentValues);

      enqueueSnackbar(
        `Level from text file imported for ${format.formatLevelType(planImplantLevel.level)}`,
        {
          variant: 'success',
        },
      );
    } catch (e) {
      enqueueSnackbar((e as unknown as Error).message, { variant: 'error' });
    }
  };

  const disabled = props.activeCase?.stage !== CaseStageType.Planning;
  const loading = isSubmitting || form19Loading || upsertPlanImplantLoading;
  const screwLengthMenuItems = [
    CaseSpineProfile.CervicalStandard,
    CaseSpineProfile.CervicalStandardMinusC7,
    CaseSpineProfile.CervicalStandardPlusC8,
  ].includes(props.activeCase?.spineProfile as unknown as CaseSpineProfile)
    ? cervicalScrewLengthMenuItems
    : lumbarScrewLengthMenuItems;

  return (
    <CustomDialog
      maxWidth={'xl'}
      open={props.open}
      title={`Edit Plan Ntop Implants`}
      onClose={() => {
        reset();
        props.onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          variant={'contained'}
          disabled={disabled}
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Save'}
          loading={loading}
        />,
      ]}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align={'left'}>
              <Typography variant={'body1'} noWrap>
                <strong>LEVEL</strong>
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant={'body1'} noWrap>
                <strong>NTOP FILE DATA</strong>
              </Typography>
            </TableCell>
            {props.hasImplantsWithScrews ? (
              <TableCell align={'center'} style={{ width: 175 }}>
                <Typography variant={'body1'} noWrap>
                  <strong>SCREW LENGTH</strong>
                </Typography>
              </TableCell>
            ) : null}
            {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
              <TableCell align={'center'} style={{ width: 175 }}>
                <Typography variant={'body1'} noWrap>
                  <strong>ANGLES</strong>
                </Typography>
              </TableCell>
            ) : null}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(getValues()).map(([level, planImplantLevel]) => {
            const partType = planImplantLevel.partType;
            const implantType = caseUtils.getImplantType(partType);
            const implantHasScrews = [ImplantType.ALIFX, ImplantType.ACDFX].includes(implantType);

            return (
              <TableRow key={level}>
                <TableCell>
                  <Stack>
                    <Typography>
                      <strong>{format.formatInterbodyLevel(planImplantLevel.level)}</strong>
                    </Typography>
                    <Typography>{format.formatPartType(planImplantLevel.partType)}</Typography>
                  </Stack>
                </TableCell>
                <TableCell align={'center'}>
                  <Stack direction={'row'} spacing={2}>
                    <Stack alignItems={'flex-start'}>
                      <Typography variant={'button'} color={'textSecondary'}>
                        Size
                      </Typography>
                      <Typography>
                        <strong>AP</strong>&nbsp;{planImplantLevel.ap}
                      </Typography>
                      <Typography>
                        <strong>ML</strong>&nbsp;{planImplantLevel.ml}
                      </Typography>
                    </Stack>
                    <Stack alignItems={'flex-start'}>
                      <Typography variant={'button'} color={'textSecondary'}>
                        POS.
                      </Typography>
                      <Typography>
                        <strong>ROT.</strong>&nbsp;
                        {planImplantLevel.rotation ? (
                          <>
                            [{planImplantLevel.rotation.x},{planImplantLevel.rotation.y},
                            {planImplantLevel.rotation.z}]
                          </>
                        ) : (
                          <>&mdash;</>
                        )}
                      </Typography>
                      <Typography>
                        <strong>TRN.</strong>&nbsp;
                        {planImplantLevel.position ? (
                          <>
                            [{planImplantLevel.position.x},{planImplantLevel.position.y},
                            {planImplantLevel.position.z}]
                          </>
                        ) : (
                          <>&mdash;</>
                        )}
                      </Typography>
                    </Stack>
                    {[ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
                      implantType,
                    ) ? (
                      <Stack alignItems={'flex-start'}>
                        <Typography variant={'button'} color={'textSecondary'} noWrap>
                          Bullet (Ins.)
                        </Typography>
                        <Typography>
                          <strong>Angle</strong>&nbsp;
                          {planImplantLevel?.bullet?.insertionSide?.angle}
                        </Typography>
                        <Typography>
                          <strong>Height</strong>&nbsp;
                          {planImplantLevel?.bullet?.insertionSide?.height}
                        </Typography>
                      </Stack>
                    ) : null}
                    {implantType === ImplantType.LLIF ? (
                      <Stack alignItems={'flex-start'}>
                        <Typography variant={'button'} color={'textSecondary'} noWrap>
                          Bullet (Thd.)
                        </Typography>
                        <Typography>
                          <strong>Angle</strong>&nbsp;
                          {planImplantLevel?.bullet?.threadedSide?.angle}
                        </Typography>
                        <Typography>
                          <strong>Height</strong>&nbsp;
                          {planImplantLevel?.bullet?.threadedSide?.height}
                        </Typography>
                      </Stack>
                    ) : null}
                    <Stack alignItems={'flex-start'}>
                      <Typography variant={'button'} color={'textSecondary'}>
                        Other
                      </Typography>
                      <Typography noWrap>
                        <strong>Thread Height</strong>&nbsp;
                        {planImplantLevel?.threadHeight}
                      </Typography>
                    </Stack>
                    {planImplantLevel.metadata ? (
                      <Stack justifyContent={'center'}>
                        <NtopMetadataPopover metadata={planImplantLevel.metadata} />
                      </Stack>
                    ) : null}
                  </Stack>
                  {errors[level as LevelType] ? (
                    <Typography color={'error'}>There are errors with the nTop data</Typography>
                  ) : null}
                </TableCell>
                {props.hasImplantsWithScrews ? (
                  <TableCell align={'center'}>
                    {implantHasScrews ? (
                      <SelectFieldRHF
                        name={`${level}.screwLength`}
                        label={'Screw Length'}
                        control={control as unknown as Control}
                        menuItems={screwLengthMenuItems}
                        hideNone={true}
                      />
                    ) : (
                      <>&mdash;</>
                    )}
                  </TableCell>
                ) : null}
                {hasFeatureFlag?.(FeatureFlag.angledInstrumentsEnabled) ? (
                  <TableCell align={'center'}>
                    <Stack gap={1}>
                      {!hasPelvisHighPelvicIncidence && !hasPelvisOblique && !hasPelvisHighCrest ? (
                        <>&mdash;</>
                      ) : null}

                      {hasPelvisHighPelvicIncidence &&
                      [PartType.ALIF, PartType.ALIF_X_TWO_DOWN].includes(
                        planImplantLevel.partType,
                      ) ? (
                        <SelectFieldRHF
                          name={`${level}.cranialCaudalThreadAngle`}
                          label={'Cranial-Caudal'}
                          control={control as unknown as Control}
                          menuItems={positiveAngleMenuItems}
                          disabled={disabled || loading}
                          hideNone={true}
                        />
                      ) : null}
                      {hasPelvisOblique && [ImplantType.LLIF].includes(implantType) ? (
                        <SelectFieldRHF
                          onChange={() => {
                            // @ts-ignore
                            setValue(`${level}.cranialCaudalThreadAngle`, '0', {
                              shouldValidate: true,
                            });
                          }}
                          name={`${level}.obliqueThreadAngle`}
                          label={'Oblique'}
                          control={control as unknown as Control}
                          menuItems={positiveAngleMenuItems}
                          disabled={disabled || loading}
                          hideNone={true}
                        />
                      ) : null}
                      {hasPelvisHighCrest && [ImplantType.LLIF].includes(implantType) ? (
                        <SelectFieldRHF
                          onChange={() => {
                            // @ts-ignore
                            setValue(`${level}.obliqueThreadAngle`, '0', { shouldValidate: true });
                          }}
                          name={`${level}.cranialCaudalThreadAngle`}
                          label={'Cranial-Caudal'}
                          control={control as unknown as Control}
                          menuItems={positiveAndNegativeAngleMenuItems}
                          disabled={disabled || loading}
                          hideNone={true}
                        />
                      ) : null}
                      {![ImplantType.ALIF, ImplantType.ALIFX, ImplantType.LLIF].includes(
                        implantType,
                      ) ? (
                        <>&mdash;</>
                      ) : null}
                    </Stack>
                  </TableCell>
                ) : null}
                <TableCell align={'center'}>
                  <FileInput
                    key={level}
                    onFileRead={(text) => handleParseNtopFile(text, planImplantLevel)}
                    disabled={disabled || loading}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </CustomDialog>
  );
}
