import React, { useEffect, useRef } from 'react';
import CustomDialog from '../../../components/CustomDialog';
import { ProgressButton } from '@workflow-nx/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { IImplantPartDetail, ImplantType, format } from '@workflow-nx/common';
import { CREATE_IMPLANT_PART_DETAIL } from '../../../gql';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import ImplantPartDetailForm, {
  ImplantFormType,
  ImplantPartDetailsFormHandle,
  getImplantPartDetailSchema,
} from './ImplantPartDetailForm';

export function CreateImplantPartDetailDialog(props: {
  implantType: ImplantType;
  open: boolean;
  prevImplantPartDetail?: IImplantPartDetail;
  onClose: (shouldUpdate: boolean) => void;
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [createImplantPartDetail, { loading }] = useMutation(CREATE_IMPLANT_PART_DETAIL);
  const formRef = useRef<ImplantPartDetailsFormHandle | null>(null);

  const formMethods = useForm<ImplantFormType>({
    defaultValues: {
      ...props?.prevImplantPartDetail,
      implantType: props.implantType,
      drawingNumber: '',
      activeDate: '',
      drawingVersionNumber: undefined,
      drawingDocumentId: '',
      drawingVersionId: '',
      form037VersionNumber: undefined,
      form037DocumentId: '',
      form037VersionId: '',
    },
    resolver: yupResolver(
      getImplantPartDetailSchema('CREATE', props?.prevImplantPartDetail),
    ) as unknown as Resolver<ImplantFormType>,
  });
  const {
    handleSubmit,
    reset,
    formState: { dirtyFields, isSubmitting },
  } = formMethods;

  const handleSubmitForm: SubmitHandler<ImplantFormType> = async (data) => {
    try {
      const submitData = await formRef.current?.uploadAssetsAndGenerateAPIVariables(
        data,
        dirtyFields as Partial<Readonly<ImplantFormType>>,
      );

      await createImplantPartDetail({
        variables: submitData,
      });

      reset();
      enqueueSnackbar('New implant part version created', {
        variant: 'success',
      });
      props.onClose(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred creating the new version', {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    reset({
      ...props?.prevImplantPartDetail,
      implantType: props.implantType,
      activeDate: '',
      drawingVersionNumber: undefined,
      drawingDocumentId: '',
      drawingVersionId: '',
      form037VersionNumber: undefined,
      form037DocumentId: '',
      form037VersionId: '',
    });
  }, [props.prevImplantPartDetail]);

  return (
    <CustomDialog
      maxWidth={'md'}
      open={props.open}
      title={`${format.formatImplantPartDetailPartType(props.implantType)} - New Version`}
      onClose={() => {
        reset();
        props.onClose(false);
      }}
      positiveActionButtons={[
        <ProgressButton
          onClick={(evt) => handleSubmit(handleSubmitForm)(evt)}
          label={'Create New Version'}
          disabled={loading}
          loading={isSubmitting}
        />,
      ]}
    >
      {props.open ? (
        <FormProvider {...formMethods}>
          <ImplantPartDetailForm
            defaultNumberValues={{
              drawingVersionNumber: props?.prevImplantPartDetail?.drawingVersionNumber,
              form037VersionNumber: props?.prevImplantPartDetail?.form037VersionNumber,
            }}
            ref={formRef}
          />
        </FormProvider>
      ) : null}
    </CustomDialog>
  );
}
