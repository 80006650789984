import { useMutation, useQuery } from '@apollo/client';
import { faChevronCircleDown, faChevronCircleUp } from '@fortawesome/pro-solid-svg-icons';
import { Box, Typography } from '@mui/material';
import { ConfigurationKeyType, ConfigurationValueType, IConfiguration } from '@workflow-nx/common';
import { IconFontButton } from '@workflow-nx/ui';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { FIND_SITE_CONFIGURATIONS, UPSERT_SITE_CONFIGURATIONS } from '../../../gql';
import { AwsSimpleEmailServiceForm } from './AwsSimpleEmailServiceForm';
import { AwsSimpleNotificationServiceForm } from './AwsSimpleNotificationServiceForm';
import { EasyPostForm } from './EasyPostForm';
import { EmailForm } from './EmailForm';
import { FreeAgentForm } from './FreeAgentForm';
import { GreenlightGuruForm } from './GreenlightGuruForm';
import { OneSignalForm } from './OneSignalForm';
import { DaisyForm } from './DaisyForm';
import { AwsS3AssetsBucketForm } from './AwsS3AssetsBucketForm';
import { RosieForm } from './RosieForm';

export type ConfigurationDataType = {
  id: string;
  title: string;
  fields: {
    id: string;
    label: string;
    type: ConfigurationValueType;
    value?: string | number;
  }[];
};

export function ServicesForm() {
  const { enqueueSnackbar } = useSnackbar();
  const [upsertConfigurations] = useMutation(UPSERT_SITE_CONFIGURATIONS);
  const [open, setOpen] = useState(false);

  const { data, refetch, loading } = useQuery(FIND_SITE_CONFIGURATIONS, {
    fetchPolicy: 'network-only',
  });

  const handleSubmitForm = async (formData: IConfiguration) => {
    try {
      await upsertConfigurations({
        variables: {
          configurations: formData,
        },
      });

      setOpen(false);
      enqueueSnackbar('Configuration settings updated', {
        variant: 'success',
      });
      refetch();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('An error occurred updating the configuration settings', {
        variant: 'error',
      });
    }
  };

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h4'}>Configure Services</Typography>
        <Box>
          <IconFontButton
            icon={open ? faChevronCircleUp : faChevronCircleDown}
            onClick={handleClose}
          />
        </Box>
      </Box>
      {open && !loading ? (
        <Box>
          <AwsS3AssetsBucketForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.AwsS3AssetsBucket,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <AwsSimpleEmailServiceForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) =>
                  config.key === ConfigurationKeyType.AwsSimpleEmailService,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <AwsSimpleNotificationServiceForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) =>
                  config.key === ConfigurationKeyType.AwsSimpleNotificationService,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          {/*
          <CloudDesignForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.CloudDesign,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
*/}
          <DaisyForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.Daisy,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <RosieForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.Rosie,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <EasyPostForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.EasyPost,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <EmailForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.Email,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <FreeAgentForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.FreeAgent,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <OneSignalForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.OneSignal,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          <GreenlightGuruForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.GreenlightGuru,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
          {/*
          <OnShapeForm
            configurationData={
              data?.siteConfigurations?.find(
                (config: IConfiguration) => config.key === ConfigurationKeyType.OnShape,
              )?.value
            }
            handleSubmit={handleSubmitForm}
          />
*/}
        </Box>
      ) : null}
    </>
  );
}
